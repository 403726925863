import { connect } from 'react-redux';
import Dashboard from '../components/Dashboard/Dashboard';
import {
  getDashboardReport,
  exportListData,
  getDashboardOverViewReport,
  getAllDashboardReportForDemo,
  getDashboardOverViewReportForDemo,
} from '../actions/DashboardActions';

import { storeUserConfigsFromSitesTable } from '../actions/ReportActions';

import { resetAccountSwitchSuccess } from '../actions/HeaderActions';
import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapDispatchToProps = (dispatch) => ({
  getAllDashboardReport: (value) => dispatch(getDashboardReport(value)),
  getAllDashboardReportForDemo: (value) =>
    dispatch(getAllDashboardReportForDemo(value)),
  resetAccountSwitchSuccess: () => dispatch(resetAccountSwitchSuccess()),
  exportListData: (value) => dispatch(exportListData(value)),
  getDashboardOverViewReport: (value) =>
    dispatch(getDashboardOverViewReport(value)),
  getDashboardOverViewReportForDemo: (value) =>
    dispatch(getDashboardOverViewReportForDemo(value)),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  storeUserConfigsFromSitesTable: (value) =>
    dispatch(storeUserConfigsFromSitesTable(value)),
});

const mapStateToProps = (state) => ({
  allProducts: state.dashboard.allProducts,
  allProductsForFilter: state.dashboard.allProductsForFilter,
  siteList: state.dashboard.siteList,
  wpVersion: state.dashboard.wpVersion,
  phpVersion: state.dashboard.phpVersion,
  pluginVersion: state.dashboard.pluginVersion,
  status: state.dashboard.status,
  language: state.dashboard.language,
  de_active_reasons: state.dashboard.de_active_reasons,
  activeSitesCount: state.dashboard.activeSitesCount,
  deactivedSitesCount: state.dashboard.deactivedSitesCount,
  analytics: state.dashboard.analytics,
  mailchimpAnalytics: state.dashboard.mailchimpAnalytics,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  accountSwitchSuccess: state.auth && state.auth.accountSwitchSuccess,
  totalUserCount: state.dashboard.totalUserCount,
  newUserCount: state.dashboard.newUserCount,
  overviewLoader: state.dashboard.overviewLoader,
  dashboardSearchLoader: state.dashboard.dashboardSearchLoader,
  innitialCall: state.dashboard.innitialCall,
  sitesConfig: state.dashboard.sitesConfig,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,

  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
