import { all, put, takeLatest } from 'redux-saga/effects';
import {
  ONBOARD,
  ONBOARD_SUCCESS,
  ONBOARD_FAILURE,
  ONBOARD_VALIDATION_FAILED,
} from '../actions/OnboardActions';
import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* onboardAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/verify-onboard-user`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);

  if (response) {
    yield put({
      type: ONBOARD_SUCCESS,
      credentials: response && response.data,
    });
  } else if (error) {
    const { data } = error;
    if (data.status === 'validation_error') {
      yield put({
        type: ONBOARD_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    }
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: ONBOARD_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* onboard() {
  yield takeLatest(ONBOARD, onboardAttempt);
}

export default function* rootSaga() {
  yield all([onboard()]);
}
