import React from 'react';
import profileImg from '../../../assets/img/dummy-profile.png';

const statusOptions = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
  { value: 3, label: 'Invited' },
];

const TeamMemberList = ({
  handleEditModal,
  userData,
  handelFindTeamMembers,
}) => (
  <>
    <tr>
      <td className="TeamMemberImage">
        <img src={profileImg} alt="" />
      </td>
      <td>
        <p>{userData.name}</p>
      </td>
      <td>
        <p>{userData.email}</p>
      </td>
      <td>
        <p>{userData.username}</p>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex">
            <p
              className={`LabelButton ${
                userData.is_verified === 1 ? 'SuccessAlert' : 'DangerAlert'
              }`}
            >
              {userData.is_verified === 1 ? 'Verified' : 'Unverified'}
            </p>
          </div>
          <div className="d-flex">
            <p
              className={`SmallButton ml-3 ${
                userData.status === 1 ? 'SuccessButton' : 'DangerButton'
              }`}
            >
              {statusOptions
                .filter((f) => f.value === userData.status)
                .map((option) => option.label.toUpperCase())}
            </p>
          </div>
        </div>
      </td>

      <td className="ActionButtonColumn">
        <p className="ActionButtonGroup">
          <button
            onClick={(e) => handleEditModal(userData.id)}
            className="ActionButton PrimaryAlert"
            title="Edit"
          >
            <span className="Icon">
              <i className="insight wpi-edit" />
            </span>
          </button>
          <button
            onClick={(e) => handelFindTeamMembers(userData.id)}
            className="ActionButton WarningAlert"
            title="TeamMembers"
          >
            <span className="Icon">
              <i className="insight wpi-users" />
            </span>
          </button>
        </p>
      </td>
    </tr>
  </>
);

export default TeamMemberList;
