import { connect } from 'react-redux';
import DemoSiteApplyForm from '../components/Auth/DemoSiteApplyForm';
import { applyForDemo } from '../actions/DemoSiteApplyAction';

const mapStateToProps = (state) => ({
  demoSiteApplied: state.DemoSiteApply.demoSiteApplied,
  loader: state.DemoSiteApply.loader,
  validationError: state.DemoSiteApply.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  applyForDemo: (value) => dispatch(applyForDemo(value)),
});

const Demo = connect(mapStateToProps, mapDispatchToProps)(DemoSiteApplyForm);

export default Demo;
