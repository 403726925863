export const LOAD_REPORT = 'LOAD_REPORT';
export const LOAD_REPORT_FOR_DEMO = 'LOAD_REPORT_FOR_DEMO';
export const RENDER_REPORT = 'RENDER_REPORT';
export const LOAD_REPORT_FAILED = 'LOAD_REPORT_FAILED';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const RESET_PAGE = 'RESET_PAGE';

export const STORE_USER_CONFIG_FROM_SITES_TABLE =
  'STORE_USER_CONFIG_FROM_SITES_TABLE';
export const STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS =
  'STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS';
export const STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED =
  'STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED';

export function getAllReport(data) {
  return {
    type: LOAD_REPORT,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function allReportForDemo(data) {
  return {
    type: LOAD_REPORT_FOR_DEMO,
    credentials: data,
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_REQUEST_SUCCESS,
  };
}

export function storeUserConfigsFromSitesTable(data) {
  return {
    type: STORE_USER_CONFIG_FROM_SITES_TABLE,
    credentials: data,
  };
}
