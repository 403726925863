export const PASSWORD_UPDATE = 'PASSWORD_UPDATE';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';

export const PASSWORD_UPDATE_VALIDATION_FAILED =
  'PASSWORD_UPDATE_VALIDATION_FAILED';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILPROFILE_UPDATE_SUCCESSE_UPDATE';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const PROFILE_IMAGE_DELETE = 'PROFILE_IMAGE_DELETE';
export const PROFILE_IMAGE_DELETE_SUCCESS = 'PROFILE_IMAGE_DELETE_SUCCESS';
export const PROFILE_IMAGE_DELETE_FAILED = 'PROFILE_IMAGE_DELETE_FAILED';

export const PROFILE_UPDATE_VALIDATION_FAILED =
  'PROFILE_UPDATE_VALIDATION_FAILED';

export const GET_MAILCHIMP_API = 'GET_MAILCHIMP_API';
export const MAILCHIMP_API_SUCCESS = 'MAILCHIMP_API_SUCCESS';
export const MAILCHIMP_API_FAILED = 'MAILCHIMP_API_FAILED';

export const STORE_MAILCHIMP_API = 'STORE_MAILCHIMP_API';
export const STORE_MAILCHIMP_API_SUCCESS = 'STORE_MAILCHIMP_API_SUCCESS';
export const STORE_MAILCHIMP_API_FAILED = 'STORE_MAILCHIMP_API_FAILED';

export const RESET_SETTINGS_REQUEST_SUCCESS = 'RESET_SETTINGS_REQUEST_SUCCESS';

export const GET_USER_CONFIG = 'GET_USER_CONFIG';
export const GET_USER_CONFIG_SUCCESS = 'GET_USER_CONFIG_SUCCESS';
export const GET_USER_CONFIG_FAILED = 'GET_USER_CONFIG_FAILED';

export const STORE_USER_CONFIG = 'STORE_USER_CONFIG';
export const STORE_USER_CONFIG_SUCCESS = 'STORE_USER_CONFIG_SUCCESS';
export const STORE_USER_CONFIG_FAILED = 'STORE_USER_CONFIG_FAILED';

export const GET_COMPANY_PERMISSIONS = 'GET_COMPANY_PERMISSIONS';
export const GET_COMPANY_PERMISSIONS_SUCCESS =
  'GET_COMPANY_PERMISSIONS_SUCCESS';
export const GET_COMPANY_PERMISSIONS_FAILED = 'GET_COMPANY_PERMISSIONS_FAILED';

export const UPDATE_PERMISSION_PER_ROLE = 'UPDATE_PERMISSION_PER_ROLE';
export const UPDATE_PERMISSION_PER_ROLE_SUCCESS =
  'UPDATE_PERMISSION_PER_ROLE_SUCCESS';
export const UPDATE_PERMISSION_PER_ROLE_FAILED =
  'UPDATE_PERMISSION_PER_ROLE_FAILED';

export const GET_MAILCHIMP_EMAILS = 'GET_MAILCHIMP_EMAILS';
export const GET_MAILCHIMP_EMAILS_SUCCESS = 'GET_MAILCHIMP_EMAILS_SUCCESS';
export const GET_MAILCHIMP_EMAILS_FAILED = 'GET_MAILCHIMP_EMAILS_FAILED';

export const MAILCHIMP_EMAIL_SUBCRIPTION_ACTION =
  'MAILCHIMP_EMAIL_SUBCRIPTION_ACTION';
export const MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS =
  'MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS';
export const MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_FAILED =
  'MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_FAILED';

export const MAILCHIMP_EMAIL_CHECKBOX_ACTION =
  'MAILCHIMP_EMAIL_CHECKBOX_ACTION';
export const MAILCHIMP_EMAIL_CHECKBOX_ACTION_SUCCESS =
  'MAILCHIMP_EMAIL_CHECKBOX_ACTION_SUCCESS';
export const MAILCHIMP_EMAIL_CHECKBOX_FAILED =
  'MAILCHIMP_EMAIL_CHECKBOX_FAILED';

export const RESET_MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS =
  'RESET_MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS';

export const GET_MAIL_WEBHOOK = 'GET_MAIL_WEBHOOK';
export const GET_MAIL_WEBHOOK_SUCCESS = 'GET_MAIL_WEBHOOK_SUCCESS';
export const GET_MAIL_WEBHOOK_FAILED = 'GET_MAIL_WEBHOOK_FAILED';

export const ADD_MAIL_WEBHOOK = 'ADD_MAIL_WEBHOOK';
export const ADD_MAIL_WEBHOOK_SUCCESS = 'ADD_MAIL_WEBHOOK_SUCCESS';
export const ADD_MAIL_WEBHOOK_FAILED = 'ADD_MAIL_WEBHOOK_FAILED';

export const UPDATE_MAIL_WEBHOOK = 'UPDATE_MAIL_WEBHOOK';
export const UPDATE_MAIL_WEBHOOK_SUCCESS = 'UPDATE_MAIL_WEBHOOK_SUCCESS';
export const UPDATE_MAIL_WEBHOOK_FAILED = 'UPDATE_MAIL_WEBHOOK_FAILED';

export const DELETE_MAIL_WEBHOOK = 'DELETE_MAIL_WEBHOOK';
export const DELETE_MAIL_WEBHOOK_SUCCESS = 'DELETE_MAIL_WEBHOOK_SUCCESS';
export const DELETE_MAIL_WEBHOOK_FAILED = 'DELETE_MAIL_WEBHOOK_FAILED';

export const RESET_MAIL_WEBHOOK_REQUEST_SUCCESS =
  'RESET_MAIL_WEBHOOK_REQUEST_SUCCESS';

export function resetMailWebhookRequestSuccess() {
  return {
    type: RESET_MAIL_WEBHOOK_REQUEST_SUCCESS,
  };
}
export function addMailWebhook(data) {
  return {
    type: ADD_MAIL_WEBHOOK,
    credentials: data,
  };
}
export function passwordUpdate(data) {
  return {
    type: PASSWORD_UPDATE,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function getMailWebhook(data) {
  return {
    type: GET_MAIL_WEBHOOK,
    credentials: data,
  };
}
export function updateMailWebhook(data) {
  return {
    type: UPDATE_MAIL_WEBHOOK,
    credentials: data,
  };
}

export function deleteMailWebhook(data) {
  return {
    type: DELETE_MAIL_WEBHOOK,
    credentials: data,
  };
}

export function resetMailChimpEmailSubscriptionAction() {
  return {
    type: RESET_MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS,
  };
}
export function emailSubscriptionAction(data) {
  return {
    type: MAILCHIMP_EMAIL_SUBCRIPTION_ACTION,
    credentials: data,
  };
}
export function mailChimpEmailAction(data) {
  return {
    type: MAILCHIMP_EMAIL_CHECKBOX_ACTION,
    credentials: data,
  };
}
export function getMailChimpEmails(data) {
  return {
    type: GET_MAILCHIMP_EMAILS,
    credentials: data,
  };
}
export function savePermissionPerRole(data) {
  return {
    type: UPDATE_PERMISSION_PER_ROLE,
    credentials: data,
  };
}
export function getCompanyPermissions(data) {
  return {
    type: GET_COMPANY_PERMISSIONS,
    credentials: data,
  };
}
export function profileUpdate(data) {
  return {
    type: PROFILE_UPDATE,
    credentials: data,
  };
}
export function deleteProfileImage() {
  return {
    type: PROFILE_IMAGE_DELETE,
  };
}

export function getMailChimpApiKey(data) {
  return {
    type: GET_MAILCHIMP_API,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function storeMailChimpApiKey(data) {
  return {
    type: STORE_MAILCHIMP_API,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function getUserConfig(data) {
  return {
    type: GET_USER_CONFIG,
    credentials: {
      ...data,
    },
  };
}
export function storeUserConfigs(data) {
  return {
    type: STORE_USER_CONFIG,
    credentials: {
      ...data,
    },
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_SETTINGS_REQUEST_SUCCESS,
  };
}
