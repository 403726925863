import { connect } from 'react-redux';
import TeamMembers from '../components/TeamMember/TeamMembers';
import {
  getAllteamMember,
  createTeamMember,
  teamMemberUpdate,
  teamMemberDelete,
  resetRequestSuccess,
  resetPage,
  resetFirstTime,
} from '../actions/TeamMemberActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapStateToProps = (state) => ({
  memberlist: state.teamMember.memberlist,
  currentPage: state.teamMember.currentPage,
  totalPage: state.teamMember.totalPage,
  total: state.teamMember.total,
  roles: state.teamMember.roles,
  productList: state.teamMember.productList,
  requestSuccess: state.teamMember.requestSuccess,
  loading: state.util.loading,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  firstTime: state.teamMember.firstTime,
  teamMemberListLoader: state.teamMember.teamMemberListLoader,
  modalLoader: state.teamMember.modalLoader,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,
  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const mapDispatchToProps = (dispatch) => ({
  allTeamMember: (value) => dispatch(getAllteamMember(value)),
  addTeamMember: (value) => dispatch(createTeamMember(value)),
  updateTeamMember: (value) => dispatch(teamMemberUpdate(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  deleteTeamMember: (value) => dispatch(teamMemberDelete(value)),
  resetPage: () => dispatch(resetPage()),
  resetFirstTime: () => dispatch(resetFirstTime()),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
});

const TeamMembersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembers);

export default TeamMembersContainer;
