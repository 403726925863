import { connect } from 'react-redux';
import SuperAdminUsers from '../../components/SuperAdmin/Users/Users';
import {
  getAllUsers,
  createUser,
  userUpdate,
  userDelete,
  resetRequestSuccess,
  resetPage,
  updateSubscriptionOfUser,
} from '../../actions/SuperAdminUsersActions';

const mapStateToProps = (state) => ({
  userList: state.superAdminUser.userList,
  currentPage: state.superAdminUser.currentPage,
  totalPage: state.superAdminUser.totalPage,
  total: state.superAdminUser.total,
  requestSuccess: state.superAdminUser.requestSuccess,
  validationError: state.superAdminUser.validationError,
  loading: state.util.loading,
  modalLodar: state.superAdminUser.modalLodar,
  userListLoader: state.superAdminUser.userListLoader,
  allPackages: state.superAdminUser.allPackages,
  allPermissions: state.superAdminUser.allPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  allUsers: (value) => dispatch(getAllUsers(value)),
  addUser: (value) => dispatch(createUser(value)),
  updateUser: (value) => dispatch(userUpdate(value)),
  updateSubscriptionOfUser: (value) =>
    dispatch(updateSubscriptionOfUser(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  deleteUser: (value) => dispatch(userDelete(value)),
  resetPage: () => dispatch(resetPage()),
});

const UsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminUsers);

export default UsersContainer;
