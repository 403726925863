import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'rc-pagination';
import PackageList from './PackageList';
import PackageModal from './PackageModal';

const typeOptions = [
  {
    value: 'basic',
    label: 'Basic',
  },
  {
    value: 'higher',
    label: 'Higher',
  },
];

const modeOptions = [
  {
    value: 'public',
    label: 'Public',
  },
  {
    value: 'private',
    label: 'Private',
  },
];

const statusOptions = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'In-Active',
  },
];

const SuperAdminAllPackages = ({
  history,
  packagelist,
  allPermissions,
  allPackages,
  currentPage,
  totalPage,
  total,
  packageListLodaer,
  resetRequestSuccess,
  requestSuccess,
  addPackage,
  updatePackage,
}) => {
  const [show, setShow] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(currentPage);
  const [search, setSearch] = useState('');
  const [packageData, setPackageData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState(undefined);

  const [type, setType] = useState(typeOptions[0].value);

  const [slug, setSlug] = useState(undefined);
  const [mode, setMode] = useState(modeOptions[0].value);

  const [status, setStatus] = useState(statusOptions[0].value);

  const [price, setPrice] = useState(undefined);

  const [permissions, setPermissions] = useState([]);

  const [packageId, setPackageId] = useState(undefined);
  const [editPackage, setEditPackage] = useState(false);

  const [confirmBox, setConfirmBox] = useState(false);
  // const [confirmBoxTitle, setConfirmBoxTitle] = useState('');
  // const handelConfirmBoxClose = () => setConfirmBox(false);

  const [err, setErr] = useState({
    name: '',
    type: '',
    slug: '',
    mode: '',
    status: '',
    price: '',
    permissions: '',
  });

  /* eslint-disable */
  useEffect(() => {
    allPackages({
      page: 1,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    if (packagelist.length > 0) {
      var dataArray = packagelist.map((Package) => {
        var newArray = {};
        newArray.name = Package.name;
        newArray.id = Package.id;
        newArray.price = Package.price;
        newArray.type = Package.type;
        newArray.slug = Package.slug;
        newArray.mode = Package.mode;
        newArray.status = Package.status;
        return newArray;
      });
      setPackageData(dataArray);
    } else {
      setPackageData([]);
    }
  }, [packagelist]);

  useEffect(() => {
    if (allPermissions.length > 0) {
      var dataArray = allPermissions.map((Permission) => {
        var newArray = {};
        newArray.name = Permission.name;
        newArray.slug = Permission.slug;
        newArray.value = Permission.value;
        return newArray;
      });
      setPermissions(dataArray);
    } else {
      setPermissions([]);
    }
  }, [allPermissions]);

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess && setConfirmBox(false);
    requestSuccess &&
      allPackages({
        page: page,
        per_page: perPage,
      });
    requestSuccess && resetRequestSuccess();
  }, [requestSuccess]);

  useEffect(() => {
    !show && editPackage && setPage(page);
    !show && !editPackage && setPage(1);

    !show && setEditPackage(false);
    !show && setPackageId(undefined);

    !show &&
      setErr({
        name: '',
        type: '',
        slug: '',
        mode: '',
        status: '',
        price: '',
        permissions: '',
      });

    if (!show) {
      setName(undefined);
      setSlug(undefined);
      setType(typeOptions[0].value);
      setMode(modeOptions[0].value);
      setStatus(statusOptions[0].value);
      setPrice(undefined);

      let common = allPermissions.map((x) => {
        return x;
      });
      setPermissions(common);
    }
  }, [show]);

  useEffect(() => {
    !confirmBox && setPackageId(undefined);
  }, [confirmBox]);

  /* eslint-enable */

  const handelPageChange = (e) => {
    allPackages({
      search: search || undefined,
      page: e,
      per_page: perPage,
    });
  };

  const handelPageSizeChange = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
    allPackages({
      search: search || undefined,
      per_page: e.target.value,
    });
  };

  /* eslint-disable */

  /* eslint-enable */

  const handelSearch = (searchValue) => {
    setSearch(searchValue);
    if (searchValue === '') {
      allPackages({
        per_page: perPage,
      });
    }
  };

  const handelSearchEnter = (e) => {
    if (e.key === 'Enter') {
      allPackages({
        per_page: perPage,
        search: e.target.value,
      });
    }
  };

  const handelPackageTypeSelect = (e) => {
    setType(e.value);
  };
  const handelPackageModeSelect = (e) => {
    setMode(e.value);
  };
  const handelPackageStatusSelect = (e) => {
    setStatus(e.value);
  };

  const handelPermissionValueChange = (e, index) => {
    const newArr = permissions.map((item, i) => {
      if (i === index) {
        item.value = e.target.value;
      }
      return item;
    });

    setPermissions(newArr);
  };

  const validatePackage = () => {
    const errors = [];
    let nameError = '';
    let typeError = '';
    let slugError = '';
    let modeError = '';
    let statusError = '';
    let priceError = '';
    let permissionError = '';

    if (!name) {
      nameError = 'Package name can not be empty';
      errors.push('Package name can not be empty');
    }
    if (name && name.length === 0) {
      nameError = 'Package name can not be empty';
      errors.push('Package name can not be empty');
    }
    if (!type) {
      typeError = 'Please select a package type';
      errors.push('Please select a package type');
    }
    if (type && type.length === 0) {
      typeError = 'Please select a package type';
      errors.push('Please select a package type');
    }
    if (!slug) {
      slugError = 'Package slug can not empty';
      errors.push('Package slug can not empty');
    }
    if (slug && slug.length === 0) {
      slugError = 'Package slug can not empty';
      errors.push('Package slug can not empty');
    }
    if (!mode) {
      modeError = 'Please select a package mode';
      errors.push('Please select a package mode');
    }
    if (mode && mode.length === 0) {
      modeError = 'Please select a package mode';
      errors.push('Please select a package mode');
    }
    if (!status) {
      statusError = 'Please select a package status';
      errors.push('Please select a package status');
    }
    if (status && status.length === 0) {
      statusError = 'Please select a package status';
      errors.push('Please select a package status');
    }
    if (!price) {
      priceError = 'Price can not be empty';
      errors.push('Price can not be empty');
    }
    if (price && price.length === 0) {
      priceError = 'Price can not be empty';
      errors.push('Price can not be empty');
    }

    if (!permissions) {
      permissionError = 'Price can not be empty';
      errors.push('Price can not be empty');
    }
    // if (permissions && permissions.length === 0) {
    //   permissionError = 'Price can not be empty';
    //   errors.push('Price can not be empty');
    // }

    if (permissions && permissions.length > 0) {
      permissions.forEach((sp) => {
        if (!sp.value || (sp.value && sp.value === '')) {
          permissionError = 'Permissions can not empty';
          errors.push('Permissions can not empty');
        }
      });
    }

    if (errors.length > 0) {
      setErr({
        name: nameError,
        type: typeError,
        slug: slugError,
        mode: modeError,
        status: statusError,
        price: priceError,
        permissions: permissionError,
      });
    }
    return errors;
  };
  const submitPackagePackagePermissions = (e) => {
    const errors = validatePackage();

    if (errors.length > 0) {
    } else if (editPackage) {
      updatePackage({
        name,
        type,
        mode,
        slug,
        status,
        price,
        permissions,
        id: packageId,
      });
    } else {
      setPage(1);
      addPackage({
        name,
        type,
        mode,
        slug,
        status,
        price,
        permissions,
      });
    }
  };

  const handleEditModal = (id) => {
    setEditPackage(true);
    setShow(true);
    packagelist
      .filter((data) => data.id === id)
      .forEach((p) => {
        setName(p.name);
        setPackageId(id);
        setType(p.type);
        setMode(p.mode);
        setSlug(p.slug);
        setStatus(p.status);
        setPrice(p.price);
      });

    const p_obj = packagelist.find((p) => p.id === id);

    const keys = Object.keys(p_obj);

    const common = allPermissions.map((x) => {
      if (keys.includes(x.slug)) {
        x.value = p_obj[x.slug];
      }
      return x;
    });
    setPermissions(common);
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">Packages</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handelSearch(e.target.value)}
                onKeyPress={(e) => handelSearchEnter(e)}
                value={search}
              />
            </div>

            <button
              className="ml-3 Button PrimaryButton"
              data-toggle="modal"
              data-target="#AddMemberModal"
              onClick={handleShow}
            >
              <span>Add Package</span>
            </button>
          </div>
        </div>
        {packageListLodaer ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="DataViewTable">
            <div className="TableWrapper">
              <table className="DataTable">
                <thead>
                  <tr>
                    <th>Package Name</th>
                    <th>Type</th>
                    <th>Slug</th>
                    <th>Mode</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {packageData.map((p, index) => (
                    <PackageList
                      key={index}
                      packageData={p}
                      handleEditModal={handleEditModal}
                    />
                  ))}
                  {packageData && packageData.length === 0 && (
                    <>
                      <tr
                        style={{ textAlign: 'center' }}
                        className="Deactivated"
                      >
                        <td colSpan="7">No data found</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {total > 10 && (
              <div className="TableFooter">
                <div className="TableFooterContent">
                  <form action="">
                    <div className="d-flex align-items-center">
                      <label htmlFor="ChooseNumber">Show</label>
                      <div className="CustomSelectNumber">
                        <select
                          className="custom-select"
                          id="ChooseNumber"
                          onChange={(e) => handelPageSizeChange(e)}
                          value={perPage ? perPage : '10'}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <label htmlFor="ChooseNumber">Entires</label>
                    </div>
                  </form>

                  <Pagination
                    className="PaginationList ml-auto"
                    total={total}
                    current={currentPage}
                    onChange={(e) => handelPageChange(e)}
                    showTitle={false}
                    pageSize={perPage}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {editPackage ? 'Update Package' : 'Add Package'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <PackageModal
              name={name}
              setName={setName}
              type={type}
              setType={setType}
              slug={slug}
              setSlug={setSlug}
              mode={mode}
              setMode={setMode}
              status={status}
              setStatus={setStatus}
              price={price}
              setPrice={setPrice}
              err={err}
              typeOptions={typeOptions}
              modeOptions={modeOptions}
              statusOptions={statusOptions}
              handelPackageTypeSelect={handelPackageTypeSelect}
              handelPackageModeSelect={handelPackageModeSelect}
              handelPackageStatusSelect={handelPackageStatusSelect}
              permissions={permissions}
              handelPermissionValueChange={handelPermissionValueChange}
              submitPackagePackagePermissions={submitPackagePackagePermissions}
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default SuperAdminAllPackages;
