import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import UserList from './UserList';
import UserModal from './UserModal';
import SubscriptionModal from './SubscriptionModal';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
);

/* eslint-enable */

const SuperAdminUsers = ({
  history,
  userList,
  allUsers,
  addUser,
  requestSuccess,
  total,
  updateUser,
  resetRequestSuccess,
  deleteUser,
  currentPage,
  validationError,
  modalLodar,
  userListLoader,
  allPackages,
  allPermissions,
  updateSubscriptionOfUser,
}) => {
  const [show, setShow] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(currentPage);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userData, setUserData] = useState([]);

  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState({ label: 'select', value: '' });
  const [is_verified_status, setIs_verified_status] = useState({
    label: 'select',
    value: '',
  });
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [editUser, setEditUser] = useState(false);

  // subscription

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const handleSubscriptionClose = () => setShowSubscriptionModal(false);
  // const handleSubscriptionShow = () => setShowSubscriptionModal(true);

  const [subscriptionPermissions, setSubscriptionPermissions] = useState([]);

  const [subscriptionSlug, setSubscriptionSlug] = useState(undefined);
  const [subscriptionPrice, setSubscriptionPrice] = useState('');

  const packageSelectoptions = [
    { value: '', label: 'Select' },
    ...allPackages.map((P) => {
      const newArray = {};
      newArray.value = P.slug;
      newArray.label = P.name;
      return newArray;
    }),
  ];

  const [err, setErr] = useState({
    name: [],
    email: [],
    userName: [],
    password: [],
    status: [],
    is_verified_status: [],
  });

  const [errSubcription, setErrSubcription] = useState({
    slug: [],
    price: [],
    permission: '',
  });
  /* eslint-disable */
  useEffect(() => {
    allUsers({
      page: 1,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess && setShowSubscriptionModal(false);
    requestSuccess &&
      allUsers({
        page: page,
        per_page: perPage,
        search: search && search.length > 0 ? search : null,
      });
  }, [requestSuccess]);

  useEffect(() => {
    !show && editUser && setPage(page);
    !show && !editUser && setPage(1);

    !show && setEditUser(false);
    !show && resetRequestSuccess();
    !show && setEmail('');
    !show && setUserId('');
    !show && setStatus({ label: 'select', value: '' });
    !show && setIs_verified_status({ label: 'select', value: '' });
    !show && setPassword('');
    !show && setUserName('');
    !show && setName('');
    !show &&
      setErr({
        name: [],
        email: [],
        userName: [],
        password: [],
        status: [],
        is_verified_status: [],
      });
  }, [show]);

  useEffect(() => {
    !showSubscriptionModal && setUserId('');
    !showSubscriptionModal && setSubscriptionSlug(undefined);
    !showSubscriptionModal && setSubscriptionPrice('');
    !showSubscriptionModal && setSubscriptionPermissions([]);
    !showSubscriptionModal && resetRequestSuccess();
  }, [showSubscriptionModal]);

  useEffect(() => {
    if (userList.length > 0) {
      var dataArray = userList.map((Member) => {
        var newArray = {};
        newArray.name = Member.name;
        newArray.email = Member.email;
        newArray.username = Member.user_name;
        newArray.id = Member.id;
        newArray.status = Member.status;
        newArray.is_verified = Member.is_verified;
        newArray.image = '';
        return newArray;
      });
      setUserData(dataArray);
    } else {
      setUserData([]);
    }
  }, [userList]);

  useEffect(() => {
    if (
      validationError &&
      validationError[0] &&
      validationError[0].length > 0
    ) {
      var nameError = [];
      var emailError = [];
      var usernameError = [];
      var passwordError = [];
      var statusError = [];
      var is_verified_statusError = [];

      validationError[0].map((err) => {
        if (err.param === 'email') {
          emailError.push(err.msg);
        }
        if (err.param === 'password') {
          passwordError.push(err.msg);
        }
        if (err.param === 'status') {
          statusError.push(err.msg);
        }
        if (err.param === 'is_verified') {
          is_verified_statusError.push(err.msg);
        }
      });

      // if (validationError[0].name) {
      //   nameError = validationError[0].name;
      // }
      // if (validationError[0].email) {
      //   emailError = validationError[0].email;
      // }
      // if (validationError[0].username) {
      //   usernameError = validationError[0].username;
      // }
      // if (validationError[0].password) {
      //   passwordError = validationError[0].password;
      // }
      // if (validationError[0].status_id) {
      //   statusError = validationError[0].status_id;
      // }
      // if (validationError[0].is_verified) {
      //   is_verified_statusError = validationError[0].is_verified;
      // }

      setErr({
        ...err,
        // name: nameError,
        email: emailError,
        // userName: usernameError,
        password: passwordError,
        status: statusError,
        is_verified_status: is_verified_statusError,
      });
    } else {
      setErr({
        name: [],
        email: [],
        userName: [],
        password: [],
        status: [],
        is_verified_status: [],
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const handleEditModal = (id) => {
    setEditUser(true);
    setShow(true);
    userList
      .filter((data) => data.id === id)
      .forEach((p) => {
        setEmail(p.email);
        setName(p.name);
        setUserName(p.user_name);
        setStatus({ label: 'select', value: p.status });
        setIs_verified_status({ label: 'select', value: p.is_verified });
        setPassword('');
        setUserId(id);
      });
  };
  const validate = () => {
    const errors = [];
    const nameError = [];
    const emailError = [];
    const usernameError = [];
    const passwordError = [];
    const statusError = [];
    const is_verified_statusError = [];

    if (email.length === 0) {
      emailError.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    // if (name.length === 0) {
    //   nameError.push("Name can't be empty");
    //   errors.push("Name can't be empty");
    // }

    // if (userName.length === 0) {
    //   usernameError.push("Username can't be empty");
    //   errors.push("Username can't be empty");
    // }

    if (!editUser && password.length === 0) {
      passwordError.push("Password can't be empty");
      errors.push("Password can't be empty");
    }

    if (password.length > 0 && !strongPassRegex.test(password)) {
      errors.push(
        'Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
          'at least 1 numeric character, at least one special character, must be eight characters or longer'
      );
      passwordError.push(
        'Password must contain 1 lowercase, 1 uppercase,1 number and it must be 8 characters or longer'
      );
    }

    if (email.length > 0 && !validEmailRegex.test(email)) {
      errors.push('Email is not valid');
      emailError.push('Email is not valid');
    }
    if (status && status.value === '') {
      errors.push('Please select a Status');
      statusError.push('Please select a Status');
    }

    if (!status) {
      errors.push('Please select a Status');
      statusError.push('Please select a Status');
    }

    if (!is_verified_status) {
      errors.push('Please select a verification Status');
      is_verified_statusError.push('Please select a verification Status');
    }

    if (is_verified_status && is_verified_status.value === '') {
      errors.push('Please select a verification Status');
      is_verified_statusError.push('Please select verification a Status');
    }

    if (errors.length > 0) {
      setErr({
        name: nameError,
        email: emailError,
        userName: usernameError,
        password: passwordError,
        status: statusError,
        is_verified_status: is_verified_statusError,
      });
    }
    return errors;
  };

  const submitUser = () => {
    const errors = validate();
    if (errors.length > 0) {
    } else if (editUser) {
      updateUser({
        name,
        email,
        username: userName,
        password,
        status: status.value,
        is_verified: is_verified_status.value,
        userId,
      });
    } else {
      setPage(1);
      addUser({
        name,
        email,
        username: userName,
        password,
        status: status.value,
        is_verified: is_verified_status.value,
      });
    }
  };

  const handelPageChange = (e) => {
    setPage(e);
    allUsers({
      page: e,
      per_page: perPage,
      search: search && search.length > 0 ? search : null,
    });
  };

  const handelPageSizeChange = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
    allUsers({
      per_page: e.target.value,
      search: search && search.length > 0 ? search : null,
    });
  };

  const handelSearch = (searchValue) => {
    setSearch(searchValue);
    if (searchValue === '') {
      allUsers({
        per_page: perPage,
      });
    }
  };

  const handelSearchEnter = (e) => {
    if (e.key === 'Enter') {
      allUsers({
        per_page: perPage,
        search: e.target.value,
      });
    }
  };

  const handelFindTeamMembers = (userId) => {
    history.push(`/user/admin_user/team_members?user=${userId}`);
  };

  const handleShowUserSubscriptionModal = (userId) => {
    setShowSubscriptionModal(true);
    setUserId(userId);

    const userArray = userList.filter((data) => data.id === userId);

    userArray.forEach((p) => {
      const pack = p.packageInfo;
      if (pack.length > 0) {
        pack.forEach((p) => {
          p.slug && setSubscriptionSlug(p.slug);
          p.price && setSubscriptionPrice(p.price);

          const keys = Object.keys(p);
          const common = allPermissions.map((x) => {
            if (keys.includes(x.slug)) {
              x.value = p[x.slug];
            }
            return x;
          });
          setSubscriptionPermissions(common);
        });
      } else {
        setSubscriptionSlug(undefined);
        setSubscriptionPrice('');

        const common = allPermissions.map((x) => {
          x.value = null;

          return x;
        });

        setSubscriptionPermissions(common);
      }
    });
  };
  const handelSubscriptionPackageSelect = (e) => {
    const packageData = allPackages.filter((p) => p.slug === e.value);

    if (packageData && packageData.length > 0) {
      if (
        packageData[0].status !== 'Active' ||
        packageData[0].status !== 'active'
      ) {
        toast.warn('Can not assaign an In-active Package');
        return;
      }
    }

    if (packageData && packageData.length > 0) {
      const keys = Object.keys(packageData[0]);

      const common = allPermissions.map((x) => {
        if (keys.includes(x.slug)) {
          x.value = packageData[0][x.slug];
        }
        return x;
      });
      setSubscriptionPermissions(common);

      packageData.forEach((pack) => {
        setSubscriptionPrice(pack.price);
        setSubscriptionSlug(e.value);
      });
    } else {
      setSubscriptionPrice('');
    }
  };

  const handelPermissionValueChange = (e, index) => {
    const newArr = subscriptionPermissions.map((item, i) => {
      if (i === index) {
        item.value = e.target.value;
      }
      return item;
    });

    setSubscriptionPermissions(newArr);
  };

  const validateUpdateSubscription = () => {
    const errors = [];
    const subscriptionTypeError = [];
    const subscriptionPriceError = [];

    let permissionError = '';

    if (!subscriptionSlug) {
      subscriptionTypeError.push('Please select a subcription');
      errors.push('Please select a subcription');
    }

    if (subscriptionSlug && subscriptionSlug.length === 0) {
      subscriptionTypeError.push('Please select a subcription');
      errors.push('Please select a subcription');
    }

    if (!subscriptionPrice) {
      subscriptionPriceError.push('Price can not be empty');
      errors.push('Price can not be empty');
    }

    if (subscriptionPrice && subscriptionPrice.length === 0) {
      subscriptionPriceError.push('Price can not be empty');
      errors.push('Price can not be empty');
    }

    if (!subscriptionPermissions || subscriptionPermissions.length === 0) {
      permissionError = 'Permissions can not be empty';
      errors.push('Permissions can not be empty');
    }

    if (subscriptionPermissions && subscriptionPermissions.length > 0) {
      subscriptionPermissions.forEach((sp) => {
        // if(!sp.slug || (sp.slug && sp.slug === '')) {
        //   permissionError.push('permission slug can not empty')
        // }
        if (!sp.value || (sp.value && sp.value === '')) {
          permissionError = 'Permissions can not empty';
          errors.push('Permissions can not empty');
        }
      });
    }

    if (errors.length > 0) {
      setErrSubcription({
        slug: subscriptionTypeError,
        price: subscriptionPriceError,
        permission: permissionError,
      });
    }
    return errors;
  };

  const submitUserSubscription = (e) => {
    e.preventDefault();
    const subscription_errors = validateUpdateSubscription();

    if (subscription_errors.length > 0) {
    } else {
      updateSubscriptionOfUser({
        userId,
        permission: subscriptionPermissions,
        price: subscriptionPrice,
        packageSlug: subscriptionSlug,
      });
    }
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">Users</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handelSearch(e.target.value)}
                onKeyPress={(e) => handelSearchEnter(e)}
                value={search}
              />
            </div>
            <button
              className="ml-3 Button PrimaryButton"
              data-toggle="modal"
              data-target="#AddMemberModal"
              onClick={handleShow}
            >
              <span>Add User</span>
            </button>
          </div>
        </div>
        <div className="TabContent">
          {userListLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div className="DataViewTable">
              <div className="TableWrapper">
                <table className="DataTable">
                  <thead>
                    <tr>
                      <th />
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Username</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData &&
                      userData.length > 0 &&
                      userData.map((p, index) => (
                        <UserList
                          handleEditModal={handleEditModal}
                          key={index}
                          userData={p}
                          handelFindTeamMembers={handelFindTeamMembers}
                          handleShowUserSubscriptionModal={
                            handleShowUserSubscriptionModal
                          }
                        />
                      ))}
                    {userData && userData.length === 0 && (
                      <>
                        <tr
                          style={{ textAlign: 'center' }}
                          className="Deactivated"
                        >
                          <td colSpan="6">No data found</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {total > 10 && (
                <div className="TableFooter">
                  <div className="TableFooterContent">
                    <form action="">
                      <div className="d-flex align-items-center">
                        <label htmlFor="ChooseNumber">Show</label>
                        <div className="CustomSelectNumber">
                          <select
                            className="custom-select"
                            id="ChooseNumber"
                            onChange={(e) => handelPageSizeChange(e)}
                            value={perPage ? perPage : '10'}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <label htmlFor="ChooseNumber">Entires</label>
                      </div>
                    </form>
                    <Pagination
                      className="PaginationList ml-auto"
                      total={total}
                      current={currentPage}
                      onChange={(e) => handelPageChange(e)}
                      showTitle={false}
                      pageSize={perPage}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {editUser ? 'Update user' : 'Add new user'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <UserModal
              email={email}
              setEmail={setEmail}
              status={status}
              setStatus={setStatus}
              setIs_verified_status={setIs_verified_status}
              is_verified_status={is_verified_status}
              password={password}
              setPassword={setPassword}
              submitUser={submitUser}
              name={name}
              setName={setName}
              userName={userName}
              setUserName={setUserName}
              err={err}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showSubscriptionModal}
          onHide={handleSubscriptionClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              Update user package
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleSubscriptionClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <SubscriptionModal
              subscriptionPermissions={subscriptionPermissions}
              subscriptionPrice={subscriptionPrice}
              packageSelectoptions={packageSelectoptions}
              subscriptionSlug={subscriptionSlug}
              handelSubscriptionPackageSelect={handelSubscriptionPackageSelect}
              setSubscriptionPrice={setSubscriptionPrice}
              handelPermissionValueChange={handelPermissionValueChange}
              submitUserSubscription={submitUserSubscription}
              errSubcription={errSubcription}
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default SuperAdminUsers;
