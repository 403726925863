import { connect } from 'react-redux';
import SuperAdminAllProducts from '../../components/SuperAdmin/products/AllProducts';
import { getAllproducts } from '../../actions/SuperAdminProductsActions';

const mapDispatchToProps = (dispatch) => ({
  allProducts: (value) => dispatch(getAllproducts(value)),
});

const mapStateToProps = (state) => ({
  productlist: state.superAdminProductsReducer.productlist,
  allProductList: state.superAdminProductsReducer.allProductList,
  allCompanyList: state.superAdminProductsReducer.allCompanyList,
  currentPage: state.superAdminProductsReducer.currentPage,
  totalPage: state.superAdminProductsReducer.totalPage,
  total: state.superAdminProductsReducer.total,
  productListLodaer: state.superAdminProductsReducer.productListLodaer,
});

const SuperAdminProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminAllProducts);

export default SuperAdminProductContainer;
