import { all, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  LOAD_TEAM_MEMBER_LIST,
  RENDER_TEAM_MEMBER_LIST,
  CREATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_SUCCESS,
  LOAD_TEAM_MEMBER_FAILED,
  CREATE_TEAM_MEMBER_FAILED,
  UPDATE_TEAM_MEMBER_FAILED,
  DELETE_TEAM_MEMBER_FAILED,
  VALIDATION_FAILED,
} from '../actions/TeamMemberActions';
import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

import { defaultApi } from '../utils/axiosApi';

export function* fetchMemberList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/team-members`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_TEAM_MEMBER_LIST,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      productList: data.data && data.data.productList,
      roles: data.data && data.data.roles,
      memberlist: data.data && data.data.teamMembers,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_TEAM_MEMBER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* addTeamMember(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/team-members/save`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: CREATE_TEAM_MEMBER_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: CREATE_TEAM_MEMBER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updateTeamMember(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/team-members/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_TEAM_MEMBER_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: UPDATE_TEAM_MEMBER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* deleteTeamMember(credentials) {
  const credentials2 = credentials.credentials;
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/team-members/delete`;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: DELETE_TEAM_MEMBER_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: DELETE_TEAM_MEMBER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* createTeamMember() {
  yield takeLatest(CREATE_TEAM_MEMBER, addTeamMember);
}

export function* loadMemberList() {
  yield takeLatest(LOAD_TEAM_MEMBER_LIST, fetchMemberList);
}

export function* teamMemberUpdate() {
  yield takeLatest(UPDATE_TEAM_MEMBER, updateTeamMember);
}

export function* teamMemberDelete() {
  yield takeLatest(DELETE_TEAM_MEMBER, deleteTeamMember);
}

export default function* rootSaga() {
  yield all([
    loadMemberList(),
    createTeamMember(),
    teamMemberUpdate(),
    teamMemberDelete(),
  ]);
}
