import React from 'react';

const ProductBasicInfo = ({ data }) => (
  <>
    <section className="FormViewSection">
      <div className="FormViewGroup">
        <p className="FormViewTitle">Product Name :</p>
        <p className="FormViewData">{data.base_name}</p>
      </div>
      <div className="FormViewGroup">
        <p className="FormViewTitle">Base Name :</p>
        <p className="FormViewData">{data.base_name_slug}</p>
      </div>
      <div className="FormViewGroup">
        <p className="FormViewTitle">Version :</p>
        <p className="FormViewData">{data.version_no}</p>
      </div>
      <div className="FormViewGroup">
        <p className="FormViewTitle">Status :</p>
        <p className="FormViewData">
          {data.status && data.status === '1' ? 'Active' : 'Inactive'}
        </p>
      </div>
      <div className="FormViewGroup">
        <p className="FormViewTitle">Home URL :</p>
        <p className="FormViewData">{data.home_url}</p>
      </div>
      <div className="FormViewGroup">
        <p className="FormViewTitle">Demo URL :</p>
        <p className="FormViewData">{data.demo_url}</p>
      </div>
      <div className="FormViewGroup">
        <p className="FormViewTitle">Description :</p>
        <p className="FormViewData TextArea">{data.description}</p>
      </div>
    </section>
  </>
);

export default ProductBasicInfo;
