import React from 'react';

const PasswordChange = (props) => (
  <div className="SecondarySidebarContent">
    <section className="FormViewSection">
      <form action="" className="d-flex flex-column h-100">
        <div className="FormViewGroup">
          <p className="FormViewTitle">Old Password * :</p>
          <input
            type="password"
            className="form-control"
            placeholder="********"
            required
            onChange={(e) => props.setOldPassword(e.target.value)}
            value={props.oldPassword}
          />
        </div>

        {props.err && props.err.oldPassword && props.err.oldPassword !== '' && (
          <div className="FormViewGroup">
            <label className="FormViewTitle" />
            <p style={{ color: 'red' }} key={props.err.oldPassword}>
              {props.err.oldPassword}
            </p>
          </div>
        )}

        <div className="FormViewGroup">
          <p className="FormViewTitle">New Password * :</p>
          <input
            type="password"
            className="form-control"
            placeholder="********"
            required
            onChange={(e) => props.setNewPassword(e.target.value)}
            value={props.newPassword}
          />
        </div>
        {props.err && props.err.newPassword && props.err.newPassword !== '' && (
          <div className="FormViewGroup">
            <label className="FormViewTitle" />
            <p style={{ color: 'red' }} key={props.err.newPassword}>
              {props.err.newPassword}
            </p>
          </div>
        )}
        <div className="FormViewGroup">
          <p className="FormViewTitle">Confirm Password * :</p>
          <input
            type="password"
            className="form-control"
            placeholder="********"
            required
            onChange={(e) => props.setConfirmNewPassword(e.target.value)}
            value={props.confirmNewPassword}
          />
        </div>
        {props.err &&
          props.err.confirmNewPassword &&
          props.err.confirmNewPassword !== '' && (
            <div className="FormViewGroup">
              <label className="FormViewTitle" />
              <p style={{ color: 'red' }} key={props.err.confirmNewPassword}>
                {props.err.confirmNewPassword}
              </p>
            </div>
          )}
        <button
          onClick={props.updatePass}
          className="Button PrimaryButton ml-auto"
        >
          Submit
        </button>
      </form>
    </section>
  </div>
);

export default PasswordChange;
