import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import '../assets/css/loader-styles.css';

export default function ({ isOpen }) {
  return (
    <Modal
      className="custom-loader"
      show={isOpen}
      size="sm"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <Spinner animation="border" variant="light" />
      </Modal.Body>
    </Modal>
  );
}
