import { toast } from 'react-toastify';
import {
  REGISTER,
  ALL_PACKAGES,
  ALL_PACKAGES_SUCCESS,
  ALL_PACKAGES_FAILURE,
  REGISTER_FAILURE,
  REGISTER_VALIDATION_FAILED,
  RESET_REGISTER_VALIDATION_ERROR,
} from '../actions/RegistrationActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  allPackages: [],
  register: undefined,
  loading: false,
  validationError: [],
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_FAILURE:
      localStorage.removeItem('access_token');
      toast.success(action.message);
      return { ...state, register: undefined };

    case ALL_PACKAGES:
      return {
        ...state,
        loading: true,
      };
    case ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        allPackages: action.allPackages,
      };
    case ALL_PACKAGES_FAILURE:
      toast.success(action.message);
      return {
        ...state,
        loading: false,
      };
    case REGISTER_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        loading: false,
        validationError: action.errors,
      };
    case RESET_REGISTER_VALIDATION_ERROR:
      return {
        ...state,
        loading: false,
        validationError: [],
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
