import { toast } from 'react-toastify';
import {
  LOAD_USER_TEAM_MEMBER_FAILED,
  RENDER_USER_TEAM_MEMBER_LIST,
} from '../actions/SuperAdminUsersActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  currentPage: 1,
  totalPage: 1,
  total: 0,
  requestSuccess: false,
  loading: false,
  validationError: [],
  teamMemberList: [],
  mainUserData: {},
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case RENDER_USER_TEAM_MEMBER_LIST:
      return {
        ...state,
        teamMemberList: action.teamMemberList,
        mainUserData: action.mainUserData,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        loading: false,
      };
    case LOAD_USER_TEAM_MEMBER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
