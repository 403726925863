import { toast } from 'react-toastify';

export function convertUnixToDateTime(unixDate) {
  const date = new Date(unixDate * 1000);
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function formateDateTime(unixDate) {
  const date = new Date(unixDate / 1000);
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function convertUnixToDate(unixDate) {
  const date = new Date(unixDate * 1000);
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
}

export function colorVariation(r, g, b, opacity) {
  return `rgba(${r},${g},${b},${opacity})`;
}

export function LightenDarkenColor(colorCode, amount) {
  let usePound = false;

  if (colorCode[0] === '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  const num = parseInt(colorCode, 16);

  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const handelCopyToClipBoard = (id) => {
  const el = document.createElement('textarea');
  el.value = document.getElementById('copy-to-clipboard').innerText;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  toast.success('Code is copied to clipboard');
};
