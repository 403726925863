import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];

const MailWebhookModal = ({
  slug,
  link,
  tag,
  status,
  setEditMailWebhookModal,
  setMailWebhookLink,
  setMailWebhookTag,
  setMailWebhookStatus,
  setMailWebhookPluginSlug,
  mailWebhookProductsSelectOptions,
  mailWebhookPluginName,
  setMailWebhookPluginName,
  mailWebhookModalLoader,
  submitMailWebhook,
  changeMailWebhookProductChange,
  handelWebhookStatusChange,
  errMailWebhook,
}) => (
  <>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label>Plugin*</label>
        <Select
          className="SelectOption basic-single"
          classNamePrefix="react-select"
          isSearchable={false}
          options={mailWebhookProductsSelectOptions.filter((h) => !h.checked)}
          defaultValue={mailWebhookProductsSelectOptions.find(
            (op, index) => index === 0
          )}
          onChange={(e) => changeMailWebhookProductChange(e)}
          value={mailWebhookProductsSelectOptions.find(
            (op) => op.value === slug
          )}
        />
      </div>
      {errMailWebhook && errMailWebhook.slug && errMailWebhook.slug !== '' && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={slug}>
            {errMailWebhook.slug}
          </p>
        </div>
      )}

      <div className="TabContentRow">
        <label>Name*</label>
        <div style={{ width: '100%' }}>
          <input
            type="text"
            className="form-control"
            placeholder="webhook name"
            value={mailWebhookPluginName}
            onChange={(e) => setMailWebhookPluginName(e.target.value)}
          />
        </div>
      </div>

      {errMailWebhook && errMailWebhook.name && errMailWebhook.name !== '' && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={mailWebhookPluginName}>
            {errMailWebhook.name}
          </p>
        </div>
      )}

      <div className="TabContentRow">
        <label>Status*</label>
        <div style={{ width: '100%' }}>
          <Select
            className="SelectOption basic-single"
            classNamePrefix="react-select"
            isSearchable={false}
            onChange={(e) => handelWebhookStatusChange(e)}
            options={options}
            defaultValue={options.find((op, index) => index === 0)}
            value={options.find((op) => op.value === status)}
          />
        </div>
      </div>
      {errMailWebhook && errMailWebhook.status && errMailWebhook.status !== '' && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={status}>
            {errMailWebhook.status}
          </p>
        </div>
      )}

      <div className="TabContentRow">
        <label>URL link*</label>
        <div style={{ width: '100%' }}>
          <input
            type="text"
            className="form-control"
            placeholder="webhook url"
            value={link}
            onChange={(e) => setMailWebhookLink(e.target.value)}
          />
        </div>
      </div>

      {errMailWebhook && errMailWebhook.url && errMailWebhook.url !== '' && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={link}>
            {errMailWebhook.url}
          </p>
        </div>
      )}

      <div className="TabContentRow">
        <label>Tag*</label>
        <div style={{ width: '100%' }}>
          <input
            type="text"
            className="form-control"
            placeholder="webhook tag"
            value={tag}
            onChange={(e) => setMailWebhookTag(e.target.value)}
          />
        </div>
      </div>

      {errMailWebhook && errMailWebhook.tag && errMailWebhook.tag !== '' && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={tag}>
            {errMailWebhook.tag}
          </p>
        </div>
      )}

      <div className="d-flex mt-5">
        <button
          className="Button PrimaryButton ml-auto"
          onClick={() => submitMailWebhook()}
        >
          <span>
            {mailWebhookModalLoader ? (
              <Spinner animation="border" variant="light" />
            ) : (
              'Submit'
            )}
          </span>
        </button>
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  mailWebhookModalLoader: state.userSettings.mailWebhookModalLoader,
});

export default connect(mapStateToProps)(MailWebhookModal);
