import { all, put, takeLatest } from 'redux-saga/effects';
import {
  APPLY_FOR_DEMO_SITE,
  APPLY_FOR_DEMO_SITE_SUCCESS,
  APPLY_FOR_DEMO_SITE_FAILURE,
  APPLY_FOR_DEMO_SITE_VALIDATION_FAILED,
} from '../actions/DemoSiteApplyAction';

import { defaultApi } from '../utils/axiosApi';

export function* ApplyForDemoAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/demo-site/apply`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    yield put({
      type: APPLY_FOR_DEMO_SITE_SUCCESS,
      credentials: response && response.data,
    });
  } else if (error) {
    const { data } = error;
    if (data.status === 'validation_error') {
      yield put({
        type: APPLY_FOR_DEMO_SITE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: APPLY_FOR_DEMO_SITE_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* DemoApply() {
  yield takeLatest(APPLY_FOR_DEMO_SITE, ApplyForDemoAttempt);
}

export default function* rootSaga() {
  yield all([DemoApply()]);
}
