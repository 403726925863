import React from 'react';

const MetaInfoModal = ({
  handelMetaInfo,
  setmodalStep,
  productPhpRequiredVersion,
  productPhpTestedUpto,
  productWordpressRequiredVersion,
  productWordpressTestedUpto,
  setProductPhpRequiredVersion,
  setProductPhpTestedUpto,
  setProductWordpressRequiredVersion,
  setProductWordpressTestedUpto,
  err,
  hasErr,
  basicTabClick,
  setBasicTabClick,
  metaTabClick,
  setMetaTabClick,
  marketingTabClick,
  setMarketingTabClick,
  backToBasicInfo,
}) => (
  <>
    <div className="ModalControlGroup">
      <div className="ModalTabControllers">
        <button
          onClick={() => (basicTabClick ? setmodalStep(1) : 'return false;')}
          className="ModalTabControl Passed"
        >
          Basic Info
        </button>
        <button
          onClick={() => (metaTabClick ? setmodalStep(2) : 'return false;')}
          className="ModalTabControl Active"
        >
          Meta Info
        </button>
        <button onClick={() => handelMetaInfo()} className="ModalTabControl">
          Marketing Info
        </button>
      </div>
      <div className="progress">
        <div className="progress-bar" style={{ width: '66%' }} />
      </div>
    </div>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label className="w-100 mr-0 d-block text-center font-weight-bold">
          PHP
        </label>
      </div>
      <div className="TabContentRow">
        <label>Required Version*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Ex: 5.0.0"
          value={productPhpRequiredVersion}
          onChange={(e) => setProductPhpRequiredVersion(e.target.value)}
        />
      </div>
      {err &&
        err.productPhpRequiredVersion &&
        err.productPhpRequiredVersion.length > 0 && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={productPhpRequiredVersion}>
              {err.productPhpRequiredVersion}
            </p>
          </div>
        )}
      <div className="TabContentRow">
        <label>Tested Up To*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Ex: 4.0.0"
          value={productPhpTestedUpto}
          onChange={(e) => setProductPhpTestedUpto(e.target.value)}
        />
      </div>
      {err && err.productPhpTestedUpto && err.productPhpTestedUpto.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productPhpTestedUpto}>
            {err.productPhpTestedUpto}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label className="w-100 mr-0 d-block text-center font-weight-bold">
          WordPress
        </label>
      </div>
      <div className="TabContentRow">
        <label>Required Version*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Ex: 5.0.0"
          value={productWordpressRequiredVersion}
          onChange={(e) => setProductWordpressRequiredVersion(e.target.value)}
        />
      </div>
      {err &&
        err.productWordpressRequiredVersion &&
        err.productWordpressRequiredVersion.length > 0 && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={productWordpressRequiredVersion}>
              {err.productWordpressRequiredVersion}
            </p>
          </div>
        )}
      <div className="TabContentRow">
        <label>Tested Up To*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Ex: 4.0.0"
          value={productWordpressTestedUpto}
          onChange={(e) => setProductWordpressTestedUpto(e.target.value)}
        />
      </div>
      {err &&
        err.productWordpressTestedUpto &&
        err.productWordpressTestedUpto.length > 0 && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={productWordpressTestedUpto}>
              {err.productWordpressTestedUpto}
            </p>
          </div>
        )}
      <div className="d-flex mt-5">
        <button
          onClick={() => setmodalStep(1)}
          className="Button SemiButtonPrimary"
        >
          <span>Previous</span>
        </button>
        <button
          onClick={() => handelMetaInfo()}
          className="Button PrimaryButton ml-auto"
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  </>
);

export default MetaInfoModal;
