import { toast } from 'react-toastify';
import {
  LOAD_SUPER_ADMIN_PACKAGE_LIST,
  RENDER_SUPER_ADMIN_PACKAGE_LIST,
  LOAD_SUPER_ADMIN_PACKAGE_LIST_FAILED,
  ADD_SUPER_ADMIN_PACKAGE,
  ADD_SUPER_ADMIN_PACKAGE_SUCCESS,
  ADD_SUPER_ADMIN_PACKAGE_FAILED,
  RESET_SUPER_ADMIN_PACKAGE_REQUEST_SUCCESS,
  UPDATE_SUPER_ADMIN_PACKAGE,
  UPDATE_SUPER_ADMIN_PACKAGE_SUCCESS,
  UPDATE_SUPER_ADMIN_PACKAGE_FAILED,
} from '../actions/SuperAdminPackageActions';

import { SIGNED_OUT } from '../actions/index';

const initialState = {
  packagelist: [],
  currentPage: 1,
  totalPage: 1,
  total: 0,
  allPermissions: [],
  packageListLodaer: false,
  requestSuccess: false,
  modalLoader: false,
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case RENDER_SUPER_ADMIN_PACKAGE_LIST:
      return {
        ...state,
        packagelist: action.packageData,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        allPermissions: action.allPermissions,
        packageListLodaer: false,
      };
    case LOAD_SUPER_ADMIN_PACKAGE_LIST_FAILED:
      toast.error(action.message);
      return {
        ...state,
        packageListLodaer: false,
      };
    case LOAD_SUPER_ADMIN_PACKAGE_LIST:
      return {
        ...state,
        packageListLodaer: true,
      };
    case ADD_SUPER_ADMIN_PACKAGE:
      return {
        ...state,
        modalLoader: true,
      };
    case UPDATE_SUPER_ADMIN_PACKAGE:
      return {
        ...state,
        modalLoader: true,
      };
    case RESET_SUPER_ADMIN_PACKAGE_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
        currentPage: 1,
      };
    case ADD_SUPER_ADMIN_PACKAGE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };
    case UPDATE_SUPER_ADMIN_PACKAGE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };
    case ADD_SUPER_ADMIN_PACKAGE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case UPDATE_SUPER_ADMIN_PACKAGE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
