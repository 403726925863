import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import WpiLogo from '../../assets/SVG/logo-white.svg';
import wpiLogin from '../../assets/img/login.svg';

const OnboardForm = ({ history, onboard, userOnboard, validationError }) => {
  let name;
  let username;
  const [key, setKey] = useState('');

  const [err, setErr] = useState({
    name: [],
    username: [],
  });
  /* eslint-disable */

  useEffect(() => {
    if (onboard && onboard.redirect) {
      username.value = '';
      name.value = '';
      history.push('/');
    }
  }, [onboard]);

  useEffect(() => {
    const token = queryString.parse(history.location.search);
    setKey(token.key);
  }, []);

  useEffect(() => {
    if (validationError.length > 0) {
      let nameErr = [];
      let usernameErr = [];

      if (validationError[0].name) {
        nameErr = validationError[0].name;
      }
      if (validationError[0].userName) {
        usernameErr = validationError[0].username;
      }

      setErr({
        ...err,
        name: nameErr,
        username: usernameErr,
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const validateForm = () => {
    const errors = [];

    const nameErr = [];
    const usernameErr = [];

    if (name.value === '') {
      nameErr.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (name.length > 255) {
      nameErr.push("Name can't be over 255 charecter");
      errors.push("Name can't be over 255 charecter");
    }

    if (username.value === '') {
      usernameErr.push("User name can't be empty");
      errors.push("User name can't be empty");
    }

    if (username.length > 255) {
      usernameErr.push("User name can't be over 255 charecter");
      errors.push("User name can't be over 255 charecter");
    }

    if (errors.length > 0) {
      setErr({
        name: nameErr,
        username: usernameErr,
      });
    }

    return errors;
  };

  const submitOnboardForm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
    } else {
      userOnboard({
        name: name.value,
        username: username.value,
        key,
      });

      name.value = '';
      username.value = '';
    }
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitOnboardForm}>
              <Link className="BackButton mb-4" to="/auth/sign-in">
                <div className="BackButtonIcon">
                  <i className="insight wpi-sort-left" />
                </div>
                <div className="BackButtonText">Back to Login</div>
              </Link>
              <h2>User Information</h2>
              <div className="FormGroup mb-4">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                  id="name"
                  ref={(node) => {
                    name = node;
                  }}
                />
                {err && err.name && <p style={{ color: 'red' }}>{err.name}</p>}
              </div>
              <div className="FormGroup mb-4">
                <label>User Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your user name"
                  id="email"
                  ref={(node) => {
                    username = node;
                  }}
                />
                {err && err.username && (
                  <p style={{ color: 'red' }}>{err.username}</p>
                )}
              </div>
              <button className="Button PrimaryButton w-100">Submit</button>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

OnboardForm.prototypes = {
  resendVerification: PropTypes.shape({
    redirect: PropTypes.bool,
  }),
};

export default OnboardForm;
