import React from 'react';
import Pagination from 'rc-pagination';
import nodataImage from '../../assets/img/no_data_full.svg';

const SitesActivePluginInfo = (props) => {
  const plugin = props.data;
  return (
    <>
      <section className="DetailsViewSection">
        <div className="DetailsView">
          {/* <div className="DetailsViewHeading">
            <form action="">
              <div className="d-flex align-items-center">
                <div className="CustomSelectNumber">
                  <select className="custom-select" id="ChooseNumber">
                    <option value="10">A to Z</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                  </select>
                </div>
                <label htmlFor="ChooseNumber">Alphabetically Order</label>
              </div>
            </form>
            <div className="d-flex ml-auto">
              <div className="SearchForm">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => props.setActivePluginSearch(e.target.value)}
                  onKeyPress={props.handelActivePluginSearch}
                />
              </div>
            </div>
          </div> */}
          <div className="DetailsViewContent">
            <div className="row HasColGap">
              {plugin && plugin.length > 0 ? (
                plugin.map((d, i) => (
                  <div key={i} className="col-md-4">
                    <p className="Alert PrimaryAlert">{d}</p>
                  </div>
                ))
              ) : (
                <div style={{ alignItems: 'center', width: '100%' }}>
                  <p
                    style={{ textAlign: 'center' }}
                    className="Alert DangerAlert"
                  >
                    There is no active plugin
                  </p>
                  <img
                    src={nodataImage}
                    style={{ height: '600px', width: '100%' }}
                    alt=""
                  />
                </div>
              )}
            </div>
            {props.total > 18 && (
              <div className="d-flex mt-auto">
                <Pagination
                  className="PaginationList ml-auto mt-4"
                  total={props.total}
                  current={props.currentPage}
                  pageSize={18}
                  onChange={props.handelActivePluginPageChange}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SitesActivePluginInfo;
