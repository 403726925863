import axios from 'axios';
import { store } from '../configureStore';
import { loadingStart, loadingStop } from '../actions/index';

const reqData = {
  method: 'GET',
  url: '',
  queryParams: {},
  formData: {},
  bodyParams: {},
  pathParams: [],
  data: {},
};

function makeHeaders() {
  const headers = {
    'Content-Type': 'application/json',
  };
  return headers;
}
/*eslint-disable */
function makeHeaderWithToken(token) {
  const headers = {
    Authorization: `${'Bearer' + ' '}${token}`,
    'Content-Type': 'application/json',
  };
  return headers;
}

export function defaultApi(URL, method, details, hideLoader) {
  const token = localStorage.getItem('access_token');

  // axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
  // axios.defaults.xsrfCookieName = 'csrftoken';
  // axios.defaults.withCredentials = true;

  const api = axios.create({
    baseURL: URL,
    headers: token ? makeHeaderWithToken(token) : makeHeaders(),
  });

  let requestDetails = { ...reqData };
  requestDetails.url = URL;
  requestDetails.method = method;
  requestDetails.data = details;

  return apiCall(api, requestDetails, hideLoader)
    .then((response) => response)
    .catch((error) => error);
}

async function apiCall(api, requestDetails, hideLoader) {
  !hideLoader && store.dispatch(loadingStart());
  let apiReturn = {
    response: undefined,
    error: undefined,
  };

  try {
    const data = await api(requestDetails);
    apiReturn = { ...apiReturn, response: data };
  } catch (error) {
    apiReturn = { ...apiReturn, error: error && error.response };
  }

  !hideLoader && store.dispatch(loadingStop());

  return apiReturn;
}
