import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import Profile from './Profile';
import PasswordChange from './PasswordChange';
import MailChimpApiKey from './MailChimpApiKey';
import PreferredConfig from './PreferredConfig';
import RolesPermission from './RolesPermission';
import MailChimpEmails from './MailChimpEmails';
import MailWebhook from './MailWebhook.js';
import profileImg from '../../assets/img/dummy-profile.png';
import { LightenDarkenColor } from '../../utils/helper';

const cursorPointerStyle = {
  cursor: 'pointer',
};

const tableColumnNames = [
  { isChecked: true, name: 'url', lable: 'URL' },
  { isChecked: true, name: 'since', lable: 'Since' },
  { isChecked: true, name: 'deactivated', lable: 'Deactivated' },
  { isChecked: true, name: 'pluginVersion', lable: 'Version' },
  { isChecked: true, name: 'language', lable: 'Language' },
  { isChecked: true, name: 'alexaRank', lable: 'Alexa Rank' },
  { isChecked: true, name: 'status', lable: 'Status' },
  { isChecked: true, name: 'plugin_Name', lable: 'Plugin' },
  { isChecked: true, name: 'updatedTime', lable: 'Updated Time' },
  { isChecked: true, name: 'phpVersion', lable: 'PHP Version' },
  { isChecked: true, name: 'wpInsVersion', lable: 'WPIns' },
  { isChecked: true, name: 'siteVersion', lable: 'Site Version' },
];

const mailChimpEmailStatusOptions = [
  { value: 'all', label: 'All' },
  { value: 'subscribed', label: 'Subscribed' },
  { value: 'unsubscribed', label: 'Unsubscribed' },
  { value: 'invalid', label: 'Invalid' },
];

const statusOptions = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'In-Active',
  },
];

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
);
/* eslint-enable */

const d = new Date();
d.setDate(d.getDate() - 7);

const Settings = (props) => {
  const [userRole, setUserRole] = useState('');
  const [userPermission, setUserPermission] = useState([]);

  const [emailList, setEmailList] = useState([]);

  const [selectMailChimpEmailsAll, setSelectMailChimpEmailsAll] =
    useState(false);
  const [searchEmail, setSearchEmail] = useState('');
  const [secondarySidebarCollaspe, setSecondarySidebarCollaspe] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState('profile');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [siteTableConfig, setSiteTableConfig] = useState(tableColumnNames);
  const [orderByColumn, setOrderByColumn] = useState('updatedTime');
  const [orderByColumnSelectoptions, setOrderByColumnSelectoptions] =
    useState('DESC');

  const [permissionsPerRole, setPermissionsPerRole] = useState([]);
  const [activeRoleForPermission, setActiveRoleForPermission] = useState('');
  const [allPermissions, setAllPermissions] = useState([]);

  const [hasErr, setHasErr] = useState(false);
  const [err, setErr] = useState({
    oldPassword: [],
    newPassword: [],
    confirmNewPassword: [],
  });

  const [selectedProduct, setSelectedProduct] = useState({
    value: 'all',
    label: 'All',
  });
  const [selectedMailChimpStatus, setSelectedMailChimpStatus] = useState(
    mailChimpEmailStatusOptions[0]
  );

  const [perPage, setPerPage] = useState(10);

  const [startDate, setStartDate] = useState(new Date(d));
  const [endDate, setEndDate] = useState(new Date());
  const [focusedInput, setFocusedInput] = useState(null);
  const [dateDiff, setDateDiff] = useState(
    moment(endDate).diff(startDate, 'days')
  );
  const [allTimeDateFilter, setAllTimeDateFilter] = useState(false);

  // mail-webhook
  const [mailWebhookId, setMailWebhookId] = useState(undefined);
  const [mailWebhookLink, setMailWebhookLink] = useState('');

  const [mailWebhookSearch, setMailWebhookSearch] = useState('');
  const [mailWebhookPerPage, setMailWebhookPerPage] = useState(10);

  const [mailWebhookPluginSlug, setMailWebhookPluginSlug] = useState('');
  const [mailWebhookPluginName, setMailWebhookPluginName] = useState('');
  const [mailWebhookTag, setMailWebhookTag] = useState('');
  const [mailWebhookStatus, setMailWebhookStatus] = useState(
    statusOptions[0].value
  );
  const [mailWebhookProductsInfo, setMailWebhookProductsInfo] = useState([]);

  const [
    mailWebhookProductsSelectOptions,
    setMailWebhookProductsSelectOptions,
  ] = useState([
    {
      label: 'Select',
      value: '',
      checked: false,
    },
  ]);

  const [editMailWebhookModal, setEditMailWebhookModal] = useState(false);
  const [showMailWebhookModal, setShowMailWebhookModal] = useState(false);

  const [perPageMailWebhookModal, setPerPageMailWebhookModal] = useState(10);
  const handleMailWebhookModalClose = () => setShowMailWebhookModal(false);
  const handleMailWebhookModalShow = () => setShowMailWebhookModal(true);

  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBoxTitle, setConfirmBoxTitle] = useState('');
  const handelConfirmBoxClose = () => setConfirmBox(false);

  const [errMailWebhook, setErrMailWebhook] = useState({
    slug: '',
    name: '',
    status: '',
    url: '',
    tag: '',
  });

  /* eslint-disable */

  useEffect(() => {
    if (props.accessRemoved) {
      props.resetAccessRemoved();
      props.history.push('/user/dashboard');
    }
  }, [props.accessRemoved]);

  useEffect(() => {
    const { type } = queryString.parse(props.history.location.search);
    if (type) {
      setSelectedOption(type);
    }

    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      setUserRole(props.rolePermissionInfo[0].name);
      let permissions = props.rolePermissionInfo[0].permissions;
      if (permissions && permissions.length > 0) {
        let role_permission =
          props.rolePermissionInfo[0].permissions[0].permission;
        let newArray = [];
        role_permission.map((p) => {
          if (p[0] === 'MailChimp') {
            p[1].map((r) => {
              newArray.push(r);
            });
          }
        });
        setUserPermission(newArray);
      }
    }
  }, []);

  useEffect(() => {
    if (props.companyNotFound) {
      props.resetCompanyNotFound();
      props.history.push('/auth/create-company-form');
    }
  }, [props.companyNotFound]);

  useEffect(() => {
    if (props.mailWebhookRequestSuccess) {
      setEditMailWebhookModal(false);
      setShowMailWebhookModal(false);
      setConfirmBoxTitle('');
      setConfirmBox(false);

      props.getMailWebhook({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
      props.resetMailWebhookRequestSuccess();
    }
  }, [props.mailWebhookRequestSuccess]);

  useEffect(() => {
    if (props.mailWebhook && props.mailWebhook.length > 0) {
      let data = [];

      props.mailWebhook.map((hook) => {
        let obj = {
          id: hook.id,
          plugin_name: hook.plugin_name,
          plugin_slug: hook.plugin_slug,
          status: hook.status,
          tag: hook.tag,
          update_hook_name: hook.changed_name,
        };
        data.push(obj);
      });

      setMailWebhookProductsInfo(data);
    } else {
      setMailWebhookProductsInfo([]);
    }
  }, [props.mailWebhook]);

  useEffect(() => {
    if (
      props.productsForMailWebhook &&
      props.productsForMailWebhook.length > 0
    ) {
      let data = [
        {
          label: 'Select',
          value: '',
          checked: false,
        },
      ];

      props.productsForMailWebhook.forEach((pMhook) => {
        if (
          !props.mailWebhook.some((g) => {
            return g.plugin_slug === pMhook.base_name_slug;
          })
        ) {
          data.push({
            label: pMhook.base_name,
            value: pMhook.base_name_slug,
            checked: false,
          });
        } else {
          data.push({
            label: pMhook.base_name,
            value: pMhook.base_name_slug,
            checked: true,
          });
        }
      });

      setMailWebhookProductsSelectOptions(data);
    }
  }, [props.productsForMailWebhook]);

  useEffect(() => {
    if (props.validationError.length > 0) {
      let newPasswordErr = [];
      let confirmNewPasswordErr = [];

      props.validationError.map((err) => {
        if (err.param === 'password') {
          newPasswordErr.push(err.msg);
        }
        if (err.param === 'password_confirmation') {
          confirmNewPasswordErr.push(err.msg);
        }
      });

      if (props.validationError[0].password) {
        newPasswordErr = props.validationError[0].password;
      }
      if (props.validationError[0].password_confirmation) {
        confirmNewPasswordErr = props.validationError[0].password_confirmation;
      }

      setErr({
        ...err,
        newPassword: newPasswordErr,
        confirmNewPassword: confirmNewPasswordErr,
      });
    }
  }, [props.validationError]);

  useEffect(() => {
    setShow(false);
    if (selectedOption === 'mailchimp_api') {
      if (Object.keys(props.activeProfile).length <= 0) {
        toast.error('Company not found, Please provide a Company first');
        return;
      }
      props.mailchimpApiKey({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
    if (selectedOption === 'preferred_config') {
      props.getUserConfig({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
    if (selectedOption === 'role_permission') {
      props.getCompanyPermissions({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
    if (selectedOption === 'mailChimp_emails') {
      props.getMailChimpEmails({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        search_product: selectedProduct.value,
        search: null,
        per_page: perPage,
        page: 1,
        emailStatus: selectedMailChimpStatus.value,
        from_date:
          startDate.getFullYear() +
          '-' +
          (startDate.getMonth() + 1) +
          '-' +
          startDate.getDate(),
        to_date:
          endDate.getFullYear() +
          '-' +
          (endDate.getMonth() + 1) +
          '-' +
          endDate.getDate(),
      });
    }
    if (selectedOption === 'mail_webhook') {
      props.getMailWebhook({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
    selectedOption &&
      props.history.push({
        pathname: '/user/settings',
        search: `?type=${selectedOption}`,
      });
  }, [selectedOption]);

  useEffect(() => {
    if (props.emails && props.emails.length > 0) {
      var dataArray = props.emails.map((list) => {
        var newArray = {};
        newArray.id = list.id;
        newArray.email = list.email;
        newArray.plugin_slug = list.plugin_slug;
        newArray.mailchimp_response = list.mailchimp_response;
        newArray.mailchimp_status = list.mailchimp_status;
        newArray.mailchimp_list_id = list.mailchimp_list_id;
        newArray.checked = false;
        return newArray;
      });
      setEmailList(dataArray);
    } else {
      setEmailList([]);
    }
  }, [props.emails]);

  useEffect(() => {
    if (props.mailChimpRequestSuccess) {
      props.resetMailChimpEmailSubscriptionAction();

      props.getMailChimpEmails({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        search_product: selectedProduct.value,
        search: searchEmail !== '' ? searchEmail : null,
        per_page: perPage,
        page: 1,
        emailStatus: selectedMailChimpStatus.value,
        from_date:
          startDate.getFullYear() +
          '-' +
          (startDate.getMonth() + 1) +
          '-' +
          startDate.getDate(),
        to_date:
          endDate.getFullYear() +
          '-' +
          (endDate.getMonth() + 1) +
          '-' +
          endDate.getDate(),
      });
      setSelectMailChimpEmailsAll(false);
    }
  }, [props.mailChimpRequestSuccess]);

  const productSelectoptions = [
    { value: 'all', label: 'All' },
    ...props.allProducts.map((Product) => {
      const newArray = {};
      newArray.value = Product.base_name_slug;
      newArray.label = Product.base_name;
      return newArray;
    }),
  ];

  useEffect(() => {
    if (
      props.userConfig &&
      props.userConfig.siteListTable &&
      props.userConfig.siteListTable.show &&
      props.userConfig.siteListTable.show.length > 0
    ) {
      setSiteTableConfig(props.userConfig.siteListTable.show);
    } else {
      setSiteTableConfig(tableColumnNames);
    }

    if (
      props.userConfig &&
      props.userConfig.siteListTable &&
      props.userConfig.siteListTable.sort
    ) {
      setOrderByColumn(props.userConfig.siteListTable.sort[0]);
      setOrderByColumnSelectoptions(props.userConfig.siteListTable.sort[1]);
    }
  }, [props.userConfig]);

  useEffect(() => {
    let roles;
    if (props.allRoles && props.allRoles.length > 0) {
      roles = props.allRoles.filter((r) => r.name !== 'Demo_User');
    }
    props.allRoles &&
      props.allRoles.length > 0 &&
      setActiveRoleForPermission(roles[0].name);
    if (props.allPermissions && props.allPermissions.length > 0) {
      let t = props.allPermissions.reduce(function (r, a) {
        a.checked = false;
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, {});

      let arr = Object.entries(t);
      // let allTypes = Object.keys(t);
      if (
        roles.length > 0 &&
        props.companyPermissionsPerRole &&
        props.companyPermissionsPerRole.length > 0
      ) {
        let d = props.companyPermissionsPerRole.filter(
          (p) => p.role === roles[0].name
        );
        if (d && d.length > 0) {
          d[0].permission.map((p) => {
            if (p[1].every((e) => e.checked === true)) {
              p.checked = true;
            } else {
              p.checked = false;
            }
          });
          setAllPermissions(d[0].permission);
        } else {
          arr &&
            arr.length > 0 &&
            arr.map((p) => {
              if (p[1].every((e) => e.checked === true)) {
                p.checked = true;
              } else {
                p.checked = false;
              }
            });
          arr && arr.length > 0 && setAllPermissions(arr);
        }
      } else {
        arr &&
          arr.length > 0 &&
          arr.map((p) => {
            if (p[1].every((e) => e.checked === true)) {
              p.checked = true;
            } else {
              p.checked = false;
            }
          });
        arr && arr.length > 0 && setAllPermissions(arr);
      }
    }
  }, [props.companyPermissionsPerRole]);

  /* eslint-enable */

  const validatePasswordChange = () => {
    const errors = [];

    const oldPasswordErr = [];
    const newPasswordErr = [];
    const confirmNewPasswordErr = [];

    if (oldPassword === '') {
      oldPasswordErr.push("Old Password can't be empty");
      errors.push("Old Password can't be empty");
    }

    if (oldPassword.length > 255) {
      oldPasswordErr.push("Old Password can't be over 255 charecter");
      errors.push("Old Password can't be over 255 charecter");
    }

    if (newPassword === '') {
      newPasswordErr.push("New Password can't be empty");
      errors.push("New Password name can't be empty");
    }

    if (newPassword.length > 0 && !strongPassRegex.test(newPassword)) {
      errors.push(
        'New Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
          'at least 1 numeric character, at least one special character, must be eight characters or longer'
      );
      // newPasswordErr.push('New Password must contains all of these:');
      // newPasswordErr.push('At least 1 lowercase alphabetical character');
      // newPasswordErr.push('At least 1 uppercase alphabetical character');
      // newPasswordErr.push('At least 1 numeric character');
      // // newPasswordErr.push('At least one special character');
      // newPasswordErr.push('Must be eight characters or longer');

      newPasswordErr.push(
        'Password must contain 1 lowercase, 1 uppercase,1 number and it must be 8 characters or longer'
      );
    }

    if (newPassword.length > 255) {
      newPasswordErr.push("New Password can't be over 255 charecter");
      errors.push("New Password can't be over 255 charecter");
    }

    // if (newPassword.length < 6) {
    //   newPasswordErr.push('New Password should be atleast 6 charecter');
    //   errors.push('New Password should be atleast 6 charecter');
    // }

    if (confirmNewPassword === '') {
      confirmNewPasswordErr.push("Confirm Password url can't be empty");
      errors.push("Confirm Password url can't be empty");
    }

    if (confirmNewPassword.length > 255) {
      confirmNewPasswordErr.push(
        "Confirm Password url can't be over 255 charecter"
      );
      errors.push("Confirm Password url can't be over 255 charecter");
    }

    if (confirmNewPassword !== newPassword) {
      confirmNewPasswordErr.push(
        'Confirm Password not matched with New Password'
      );
      errors.push('Confirm Password not matched with New Password');
    }

    if (errors.length > 0) {
      setErr({
        oldPassword: oldPasswordErr,
        newPassword: newPasswordErr,
        confirmNewPassword: confirmNewPasswordErr,
      });
      setHasErr(true);
    }

    return errors;
  };

  const updatePass = (e) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }
    e.preventDefault();
    const errors = validatePasswordChange();
    if (errors.length > 0) {
    } else {
      props.updatePass({
        password: newPassword,
        password_confirmation: confirmNewPassword,
        old_password: oldPassword,
      });
    }
  };

  /* profile */

  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const [profile_image_delete, setProfile_image_delete] = useState(false);

  const [profile_image, setProfile_image] = useState(profileImg);
  const [profile_image_file, setProfile_image_file] = useState(undefined);

  const [profileErr, setProfileErr] = useState({
    email: [],
    name: [],
    username: [],
  });

  const submitProfileUpdate = (e) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    e.preventDefault();
    const errors = validateProfile();
    if (errors.length > 0) {
    } else {
      props.updateProfile({
        email,
        name,
        user_name: userName,
        image: profile_image_file,
      });
    }
  };

  const handelImageUpdate = (file) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.warn('Profile image size should be maximum 2 MB');
      return;
    }
    setProfile_image(URL.createObjectURL(file));
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Make a fileInfo Object
      const baseURL = reader.result;
      setProfile_image_file(baseURL);
    };
  };

  const validateProfile = () => {
    const errors = [];
    const UserNameErr = [];
    const NameErr = [];
    const EmailErr = [];

    if (email.length === 0) {
      errors.push("Email can't be empty");
      EmailErr.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email)) {
      errors.push('Email is not valid');
      EmailErr.push('Email is not valid');
    }

    if (userName && userName === '') {
      UserNameErr.push("User name can't be empty");
      errors.push("User name can't be empty");
    }

    if (name && name === '') {
      NameErr.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (errors.length > 0) {
      setProfileErr({
        email: EmailErr,
        name: NameErr,
        username: UserNameErr,
      });
    }

    return errors;
  };

  /* eslint-disable */

  useEffect(() => {
    !showMailWebhookModal && setMailWebhookLink('');
    !showMailWebhookModal && setMailWebhookPluginSlug('');
    !showMailWebhookModal && setMailWebhookPluginName('');
    !showMailWebhookModal && setMailWebhookTag('');
    !showMailWebhookModal && setMailWebhookStatus(statusOptions[0].value);
    !showMailWebhookModal &&
      setErrMailWebhook({
        slug: '',
        name: '',
        status: '',
        url: '',
        tag: '',
      });
  }, [showMailWebhookModal]);

  useEffect(() => {
    const userData = props.user;
    setName(userData.name);
    setUserName(userData.user_name);
    setEmail(userData.email);
    userData.image && setProfile_image(userData.image);
    userData.image && setProfile_image_delete(true);

    const desiredSettings = queryString.parse(props.location.search);
    if (desiredSettings.subMenu) {
      setSelectedOption('mailChimp_api');
    }
  }, []);

  useEffect(() => {
    props.requestSuccess && setShow(false);
    props.requestSuccess && setOldPassword('');
    props.requestSuccess && setNewPassword('');
    props.requestSuccess && setConfirmNewPassword('');

    props.requestSuccess && setHandelUpdateApiKey(false);
    props.requestSuccess &&
      setErr({
        oldPassword: [],
        newPassword: [],
        confirmNewPassword: [],
      });

    if (props.requestSuccess) {
      const user = props.user;
      setName(user.name);
      setUserName(user.user_name);
      setEmail(user.email);
      user.image && setProfile_image(user.image);
      user.image && setProfile_image_delete(true);
      !user.image && setProfile_image_delete(false);
      !user.image && setProfile_image(profileImg);
      !user.image && setProfile_image_file(undefined);
    }
  }, [props.requestSuccess]);

  useEffect(() => {
    !show &&
      setProfileErr({
        email: [],
        name: [],
        username: [],
      });
    !show && props.resetRequestSuccess();
  }, [show]);

  useEffect(() => {
    if (
      props.profileUpdateValidationError &&
      props.profileUpdateValidationError.length > 0
    ) {
      let NameErr = [];
      let EmailErr = [];
      let UserNameErr = [];

      if (props.profileUpdateValidationError[0].name) {
        NameErr = props.profileUpdateValidationError[0].name;
      }

      if (props.profileUpdateValidationError[0].email) {
        EmailErr = props.profileUpdateValidationError[0].email;
      }

      if (props.profileUpdateValidationError[0].username) {
        UserNameErr = props.profileUpdateValidationError[0].username;
      }

      setProfileErr({
        ...err,
        name: NameErr,
        username: UserNameErr,
        email: EmailErr,
      });
    } else {
      setProfileErr({
        email: [],
        name: [],
        username: [],
      });
    }
  }, [props.profileUpdateValidationError]);
  /* eslint-enable */

  /* mailChimp */
  const [handelMailChimpApiKey, setHandelMailChimpApiKey] = useState('');
  const [handelUpdateApiKey, setHandelUpdateApiKey] = useState(false);
  const [mailChimpAPIKeyErr, setMailChimpAPIKeyErr] = useState({
    apiKey: [],
  });
  /* eslint-disable */
  useEffect(() => {
    !handelUpdateApiKey && props.resetRequestSuccess();
    !handelUpdateApiKey &&
      setMailChimpAPIKeyErr({
        apiKey: [],
      });
    !handelUpdateApiKey && setHandelMailChimpApiKey('');
  }, [handelUpdateApiKey]);
  /* eslint-enable */
  const hadelStoreMailChimpApiKey = (e) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    if (Object.keys(props.activeProfile).length <= 0) {
      toast.error('Company not found, Please provide a Company first');
      return false;
    }
    e.preventDefault();
    const errors = validateAPIkey();
    if (errors.length > 0) {
    } else {
      props.storeMailchimpApiKey({
        apikey: handelMailChimpApiKey,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
  };

  const validateAPIkey = () => {
    const errors = [];
    const APIKeyErr = [];

    if (handelMailChimpApiKey && handelMailChimpApiKey.length === 0) {
      errors.push("MailChimp API key can't be empty");
      APIKeyErr.push("MailChimp API key can't be empty");
    }

    if (!handelMailChimpApiKey) {
      errors.push("MailChimp API key can't be empty");
      APIKeyErr.push("MailChimp API key can't be empty");
    }

    if (errors.length > 0) {
      setMailChimpAPIKeyErr({
        apiKey: APIKeyErr,
      });
    } else {
      setMailChimpAPIKeyErr({
        apiKey: [],
      });
    }
    return errors;
  };

  const updateApiKey = (e) => {
    e.preventDefault();
    // setHandelMailChimpApiKey(props.mailChimpApiKey.value);
    setHandelUpdateApiKey(true);
  };

  const cancelUpdateApiKey = (e) => {
    e.preventDefault();
    setHandelMailChimpApiKey(props.mailChimpApiKey.value);
    setHandelUpdateApiKey(false);
  };

  const handleClose = (e) => setShow(false);
  const handleShow = () => setShow(true);

  const togolSecondarySideBar = (toggoleSecondarySidebar) => {
    toggoleSecondarySidebar && setSecondarySidebarCollaspe(false);
    !toggoleSecondarySidebar && setSecondarySidebarCollaspe(true);
  };

  const onDragEnd = ({ destination, source, draggableId }) => {
    const names = [...siteTableConfig];
    const draggedTagItem = names.find(
      (item, index) => index === parseInt(draggableId)
    );
    if (!destination) return;
    if (!draggedTagItem) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    names.splice(source.index, 1);
    names.splice(destination.index, 0, draggedTagItem);
    setSiteTableConfig(names);
  };

  const onSubmitSettings = () => {
    props.storeUserConfigs({
      siteListTable: siteTableConfig,
      sortConfig: [orderByColumn, orderByColumnSelectoptions],
      account_type: props.accountType,
      company_id: props.activeProfile.id,
    });
  };
  const handelImageDelete = (e) => {
    props.deleteProfileImage();
  };

  const handelOrderByColumn = (e) => {
    setOrderByColumn(e.target.value);
  };

  const handelOrderByColumnSelectoptions = (e) => {
    setOrderByColumnSelectoptions(e.value);
  };

  const handelCheckBox = (e) => {
    const name = e.target.id;
    const { checked } = e.target;

    const list = siteTableConfig.map((item) =>
      item.name === name ? { ...item, isChecked: checked } : item
    );

    setSiteTableConfig(list);
  };
  const handelPermissionCheckBox = (e, roleName, permissionName) => {
    const { checked } = e.target;

    const arr = allPermissions.map((p) => {
      if (p[0] === roleName) {
        p[1].map((per) => {
          if (per.name === permissionName) {
            per.checked = checked;
          }
          return per;
        });
      }
      return p;
    });
    setPermissionsPerRole(arr);
  };
  const handlePermissionClickAll = (e, roleName) => {
    const { checked } = e.target;
    const arr = allPermissions.map((p) => {
      if (p[0] === roleName) {
        p[1].map((per) => {
          per.checked = checked;
          return per;
        });
        p.checked = checked;
      }
      return p;
    });
    setAllPermissions(arr);
  };
  const handelChangePermissionTabChange = (e, roleName) => {
    e.preventDefault();
    setActiveRoleForPermission(roleName);
    if (props.allPermissions && props.allPermissions.length > 0) {
      const t = props.allPermissions.reduce((r, a) => {
        a.checked = false;
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, {});
      const arr = Object.entries(t);

      if (
        props.allRoles &&
        props.allRoles.length > 0 &&
        props.companyPermissionsPerRole &&
        props.companyPermissionsPerRole.length > 0
      ) {
        const d = props.companyPermissionsPerRole.filter(
          (p) => p.role === roleName
        );

        if (d && d.length > 0) {
          d[0].permission.map((p) => {
            if (p[1].every((e) => e.checked === true)) {
              p.checked = true;
            } else {
              p.checked = false;
            }

            return p;
          });

          setAllPermissions(d[0].permission);
        } else {
          arr &&
            arr.length > 0 &&
            arr.map((p) => {
              if (p[1].every((e) => e.checked === true)) {
                p.checked = true;
              } else {
                p.checked = false;
              }
              return p;
            });

          arr && arr.length > 0 && setAllPermissions(arr);
        }
      }
    }
  };
  const submitPermissionPerRole = (e) => {
    e.preventDefault();
    if (allPermissions && allPermissions.length > 0) {
      props.savePermissionPerRole({
        role: activeRoleForPermission,
        permission: allPermissions,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    } else {
      const t = props.allPermissions.reduce((r, a) => {
        a.checked = false;
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, {});
      const arr = Object.entries(t);

      props.savePermissionPerRole({
        role: activeRoleForPermission,
        permission: arr,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
  };

  const handleSearchReport = (e) => {
    e.preventDefault();
    props.getMailChimpEmails({
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      search_product: selectedProduct.value,
      search: searchEmail,
      per_page: perPage,
      emailStatus: selectedMailChimpStatus.value,
      from_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      to_date: `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`,
    });
  };
  const handelSearchEmail = (e) => {
    setSearchEmail(e.target.value);
    if (e.target.value === '') {
      props.getMailChimpEmails({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        search_product: selectedProduct.value,
        search: null,
        per_page: perPage,
        emailStatus: selectedMailChimpStatus.value,
        from_date: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        to_date: `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`,
      });
    }
  };

  const handelSearch = (e) => {
    if (e.key === 'Enter') {
      props.getMailChimpEmails({
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        search_product: selectedProduct.value,
        search: searchEmail,
        per_page: perPage,
        emailStatus: selectedMailChimpStatus.value,
        from_date: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        to_date: `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`,
      });
    }
  };

  const changePageSize = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
    props.getMailChimpEmails({
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      search_product: selectedProduct.value,
      search: searchEmail,
      per_page: e.target.value,
      emailStatus: selectedMailChimpStatus.value,
      from_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      to_date: `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`,
    });
  };

  const handelPageChange = (e) => {
    props.getMailChimpEmails({
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      search_product: selectedProduct.value,
      search: searchEmail,
      per_page: perPage,
      page: e,
      emailStatus: selectedMailChimpStatus.value,
      from_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      to_date: `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`,
    });
  };

  const handelChangeAllTimeDateFilter = (e) => {
    const { checked } = e.target;
    setAllTimeDateFilter(checked);
    setStartDate(new Date(1970, 0, 1, 0, 0, 1));
    setEndDate(new Date());
    if (!checked) {
      const d = new Date();
      d.setDate(d.getDate() - 7);
      setStartDate(new Date(d));
      setEndDate(new Date());
    }
  };

  const handelEmailSubscriptionAction = (
    e,
    id,
    email,
    slug,
    list_id,
    statusAction
  ) => {
    e.preventDefault();

    const list = emailList.filter((f) => f.id === id);

    props.emailSubscriptionAction({
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      track_log_id: id,
      email,
      plugin_slug: slug,
      mailchimp_list_id: list_id,
      status: statusAction,
      list,
    });
  };

  const getCalendarInfo = () => (
    <div className="date-range-footer">
      <div className="p-3">
        <Button
          className={`date-range-picker-button ml-2 ${
            dateDiff && dateDiff === 1 ? '' : 'PrimaryButton'
          }`}
          style={{
            background:
              dateDiff && dateDiff === 1
                ? LightenDarkenColor('#623cea', -50)
                : undefined,
          }}
          onClick={() => {
            const date = new Date();
            setEndDate(moment(date).subtract(1, 'days').toDate());
            setStartDate(moment(date).subtract(1, 'days').toDate());
          }}
        >
          Yesterday
        </Button>
        <Button
          className={`date-range-picker-button ml-2 ${
            dateDiff && dateDiff === 0 ? '' : 'PrimaryButton'
          }`}
          style={{
            background:
              dateDiff && dateDiff === 0
                ? LightenDarkenColor('#623cea', -50)
                : undefined,
          }}
          onClick={() => {
            const date = new Date();
            setEndDate(moment(date).toDate());
            setStartDate(moment(date).subtract(0, 'days').toDate());
          }}
        >
          Today
        </Button>
        <Button
          className={`date-range-picker-button ml-2 ${
            dateDiff && dateDiff === 7 ? '' : 'PrimaryButton'
          }`}
          style={{
            background:
              dateDiff && dateDiff === 7
                ? LightenDarkenColor('#623cea', -50)
                : undefined,
          }}
          onClick={() => {
            const date = new Date();
            setEndDate(moment(date).toDate());
            setStartDate(moment(date).subtract(7, 'days').toDate());
          }}
        >
          Last 7 days
        </Button>
        <Button
          className={`date-range-picker-button ml-2 ${
            dateDiff && dateDiff === 15 ? '' : 'PrimaryButton'
          }`}
          style={{
            background:
              dateDiff && dateDiff === 15
                ? LightenDarkenColor('#623cea', -50)
                : undefined,
          }}
          onClick={() => {
            const date = new Date();
            setEndDate(moment(date).toDate());
            setStartDate(moment(date).subtract(15, 'days').toDate());
          }}
        >
          Last 15 days
        </Button>
        <Button
          className={`date-range-picker-button ml-2 ${
            dateDiff && dateDiff === 30 ? '' : 'PrimaryButton'
          }`}
          style={{
            background:
              dateDiff && dateDiff === 30
                ? LightenDarkenColor('#623cea', -50)
                : undefined,
          }}
          onClick={() => {
            const date = new Date();
            setEndDate(moment(date).toDate());
            setStartDate(moment(date).subtract(30, 'days').toDate());
          }}
        >
          Last 30 days
        </Button>
        {/* <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 60 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 60
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(60, 'days').toDate());
            }}
          >
            Last 60 days
          </Button> */}
      </div>
    </div>
  );

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let i;
    const years = [];
    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>
      );
    }
    return (
      <div
        className="monthSelector"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '15px',
        }}
      >
        <div>
          <select
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {years}
          </select>
        </div>
      </div>
    );
  };

  const handelMailChimpCheckBox = (
    e,
    id,
    email,
    plugin_slug,
    mailchimp_list_id,
    status
  ) => {
    const { name } = e.target;
    const { checked } = e.target;

    if (name === 'checkAll') {
      if (emailList && emailList.length > 20) {
        toast.warn("Can't select more than 20 email");
        return false;
      }
      setSelectMailChimpEmailsAll(checked);
      const list = emailList.map((item) => ({ ...item, checked }));
      setEmailList(list);
    } else {
      const checkedList = emailList.filter((item) => item.checked);
      if (checkedList && checkedList.length === 20) {
        toast.warn("Can't select more than 20 email");
        return false;
      }

      const list = emailList.map((item) =>
        item.id === parseInt(id) ? { ...item, checked } : item
      );
      setEmailList(list);
      setSelectMailChimpEmailsAll(emailList.every((list) => list.checked));
    }
  };

  const handelMailChimpAction = (e, status) => {
    e.preventDefault();
    const list = emailList.filter((item) => item.checked);
    props.mailChimpEmailAction({
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      status,
      emailList: list,
    });
  };

  const handelWebhookStatusChange = (e) => {
    setMailWebhookStatus(e.value);
  };

  const changeMailWebhookProductChange = (e) => {
    setMailWebhookPluginSlug(e.value);
  };

  const validateMailWebhook = () => {
    const errors = [];

    let pluginSlugErr = '';
    const pluginNameErr = '';
    let statusErr = '';
    let urlErr = '';
    let tagErr = '';

    if (
      !mailWebhookPluginSlug ||
      (mailWebhookPluginSlug && mailWebhookPluginSlug.length === 0)
    ) {
      pluginSlugErr = 'Please select a product';
      errors.push('Please select a product');
    }

    if (!mailWebhookLink || (mailWebhookLink && mailWebhookLink.length === 0)) {
      urlErr = 'URL can not be empty';
      errors.push('URL can not be empty');
    }

    if (
      !mailWebhookStatus ||
      (mailWebhookStatus && mailWebhookStatus.length === 0)
    ) {
      statusErr = 'Please select a status';
      errors.push('Please select a status');
    }

    if (!mailWebhookTag || (mailWebhookTag && mailWebhookTag.length === 0)) {
      tagErr = 'Tag can not be empty';
      errors.push('Tag can not be empty');
    }

    if (errors.length > 0) {
      setErrMailWebhook({
        slug: pluginSlugErr,
        name: pluginNameErr,
        status: statusErr,
        url: urlErr,
        tag: tagErr,
      });
    }

    return errors;
  };

  const submitMailWebhook = () => {
    const errors = validateMailWebhook();
    if (errors.length > 0) {
    } else if (editMailWebhookModal) {
      props.updateMailWebhook({
        account_type: props.accountType,
        company_id: props.activeProfile.id,

        hook_id: mailWebhookId,
        plugin_slug: mailWebhookPluginSlug,
        plugin_name: mailWebhookPluginName,
        url_link: mailWebhookLink,
        tag: mailWebhookTag,
        status: mailWebhookStatus,
      });
    } else {
      props.addMailWebhook({
        account_type: props.accountType,
        company_id: props.activeProfile.id,

        plugin_slug: mailWebhookPluginSlug,
        plugin_name: mailWebhookPluginName,
        url_link: mailWebhookLink,
        tag: mailWebhookTag,
        status: mailWebhookStatus,
      });
    }
  };

  const handelDeleteMailWebhook = (id) => {
    setConfirmBox(true);
    setConfirmBoxTitle('Are you sure to remove this webhook ?');
    setMailWebhookId(id);
  };

  const handelSubmitMailWebhookDelete = (e) => {
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    props.deleteMailWebhook({
      id: mailWebhookId,
      account_type: props.accountType,
      company_id: props.activeProfile.id,
    });
  };

  const handleEditModalForMailWebhook = (id) => {
    setEditMailWebhookModal(true);
    setShowMailWebhookModal(true);

    props.mailWebhook
      .filter((data) => data.id === id)
      .forEach((p) => {
        setMailWebhookPluginSlug(p.plugin_slug);
        setMailWebhookPluginName(p.plugin_name);
        setMailWebhookId(id);
        setMailWebhookLink(p.url_link);
        setMailWebhookTag(p.tag);
        setMailWebhookStatus(p.status);
      });
  };

  const handelPageChangeForMailWebhook = (e) => {
    props.getMailWebhook({
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      search:
        mailWebhookSearch && mailWebhookSearch.length > 0
          ? mailWebhookSearch
          : null,
    });
  };

  const handelSearchForMailWebhook = (searchValue) => {
    setMailWebhookSearch(searchValue);
    if (searchValue === '') {
      props.getMailWebhook({
        per_page: mailWebhookPerPage,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
  };

  const handelSearchEnterForMailWebhook = (e) => {
    if (e.key === 'Enter') {
      props.getMailWebhook({
        per_page: mailWebhookPerPage,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        search: e.target.value,
      });
    }
  };

  return (
    <>
      <div className="SectionTitleWrap SectionGap">
        <button
          onClick={(e) => togolSecondarySideBar(secondarySidebarCollaspe)}
          className={`HamburgerToggler ${
            secondarySidebarCollaspe ? 'Active' : ''
          }`}
        >
          <span />
          <span />
          <span />
        </button>
        <p className="SectionTitle" />
      </div>
      <div className="d-flex h-100 position-relative">
        <div
          className={`SecondarySidebar ${
            secondarySidebarCollaspe ? 'Show' : ''
          }`}
        >
          <ul className="SidebarNav">
            <li className="SidebarNavItem">
              <div
                style={cursorPointerStyle}
                onClick={(e) => setSelectedOption('profile')}
                className={`SidebarNavItemLink ${
                  selectedOption === 'profile' ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">profile</div>
              </div>
            </li>
            <li className="SidebarNavItem">
              <div
                style={cursorPointerStyle}
                onClick={(e) => setSelectedOption('password_change')}
                className={`SidebarNavItemLink ${
                  selectedOption === 'password_change' ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Password Change</div>
              </div>
            </li>
            <li className="SidebarNavItem">
              <div
                style={cursorPointerStyle}
                onClick={(e) => setSelectedOption('preferred_config')}
                className={`SidebarNavItemLink ${
                  selectedOption === 'preferred_config' ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Preferred Config</div>
              </div>
            </li>

            {props.accountType === 'Partner' && (
              <li className="SidebarNavItem">
                <div
                  style={cursorPointerStyle}
                  onClick={(e) => setSelectedOption('role_permission')}
                  className={`SidebarNavItemLink ${
                    selectedOption === 'role_permission' ? 'Active' : ''
                  }`}
                >
                  <div className="SidebarNavItemLinkText">
                    Role / Permission
                  </div>
                </div>
              </li>
            )}
            {props.accountType === 'Partner' ? (
              <li className="SidebarNavItem">
                <div
                  style={cursorPointerStyle}
                  onClick={(e) => setSelectedOption('mailchimp_api')}
                  className={`SidebarNavItemLink ${
                    selectedOption === 'mailchimp_api' ? 'Active' : ''
                  }`}
                >
                  <div className="SidebarNavItemLinkText">MailChimp API</div>
                </div>
              </li>
            ) : (
              userPermission
                .filter((p) => p.name === 'view-mailchimp' && p.checked)
                .map((b, index) => (
                  <li key={index} className="SidebarNavItem">
                    <div
                      style={cursorPointerStyle}
                      onClick={(e) => setSelectedOption('mailchimp_api')}
                      className={`SidebarNavItemLink ${
                        selectedOption === 'mailchimp_api' ? 'Active' : ''
                      }`}
                    >
                      <div className="SidebarNavItemLinkText">
                        MailChimp API
                      </div>
                    </div>
                  </li>
                ))
            )}
            {props.accountType === 'Partner' && (
              <li className="SidebarNavItem">
                <div
                  style={cursorPointerStyle}
                  onClick={(e) => setSelectedOption('mailchimp_emails')}
                  className={`SidebarNavItemLink ${
                    selectedOption === 'mailchimp_emails' ? 'Active' : ''
                  }`}
                >
                  <div className="SidebarNavItemLinkText">MailChimp Emails</div>
                </div>
              </li>
            )}

            <li className="SidebarNavItem">
              <div
                style={cursorPointerStyle}
                onClick={(e) => setSelectedOption('mail_webhook')}
                className={`SidebarNavItemLink ${
                  selectedOption === 'mail_webhook' ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Mail Webhook </div>
              </div>
            </li>

            {/* <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(3)}
                className={`SidebarNavItemLink ${
                  selectedOption === 3 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Subscription</div>
              </a>
            </li> */}
          </ul>
        </div>
        <div className="SecondarySidebarContent">
          {selectedOption === 'profile' && (
            <Profile
              accountType={props.accountType}
              email={email}
              setEmail={setEmail}
              name={name}
              setName={setName}
              userName={userName}
              setUserName={setUserName}
              handleShow={handleShow}
              handleClose={handleClose}
              profile_image={profile_image}
              setProfile_image={setProfile_image}
              err={profileErr}
              submitProfileUpdate={submitProfileUpdate}
              show={show}
              setShow={setShow}
              handelImageUpdate={handelImageUpdate}
              setingsLoader={props.setingsLoader}
              handelImageDelete={handelImageDelete}
              profile_image_delete={profile_image_delete}
            />
          )}
          {selectedOption === 'password_change' && (
            <PasswordChange
              err={err}
              hasErr={hasErr}
              setOldPassword={setOldPassword}
              setNewPassword={setNewPassword}
              setConfirmNewPassword={setConfirmNewPassword}
              oldPassword={oldPassword}
              newPassword={newPassword}
              confirmNewPassword={confirmNewPassword}
              updatePass={updatePass}
              setingsLoader={props.setingsLoader}
            />
          )}

          {selectedOption === 'mailchimp_api' && (
            <MailChimpApiKey
              mailChimpApiKey={props.mailChimpApiKey}
              setHandelMailChimpApiKey={setHandelMailChimpApiKey}
              hadelStoreMailChimpApiKey={hadelStoreMailChimpApiKey}
              mailChimpAPIKeyErr={mailChimpAPIKeyErr}
              handelUpdateApiKey={handelUpdateApiKey}
              setHandelUpdateApiKey={setHandelUpdateApiKey}
              updateApiKey={updateApiKey}
              handelMailChimpApiKey={handelMailChimpApiKey}
              cancelUpdateApiKey={cancelUpdateApiKey}
              setingsLoader={props.setingsLoader}
              userPermission={userPermission}
              accountType={props.accountType}
            />
          )}

          {selectedOption === 'preferred_config' && (
            <PreferredConfig
              setingsLoader={props.setingsLoader}
              setSiteTableConfig={setSiteTableConfig}
              siteTableConfig={siteTableConfig}
              onDragEnd={onDragEnd}
              orderByColumn={orderByColumn}
              setOrderByColumn={setOrderByColumn}
              handelOrderByColumn={handelOrderByColumn}
              orderByColumnSelectoptions={orderByColumnSelectoptions}
              setOrderByColumnSelectoptions={setOrderByColumnSelectoptions}
              handelOrderByColumnSelectoptions={
                handelOrderByColumnSelectoptions
              }
              onSubmitSettings={onSubmitSettings}
              handelCheckBox={handelCheckBox}
            />
          )}
          {selectedOption === 'role_permission' && (
            <RolesPermission
              setingsLoader={props.setingsLoader}
              permissionsPerRole={permissionsPerRole}
              allRoles={props.allRoles}
              allPermissions={allPermissions}
              handelPermissionCheckBox={handelPermissionCheckBox}
              activeRoleForPermission={activeRoleForPermission}
              handelChangePermissionTabChange={handelChangePermissionTabChange}
              handlePermissionClickAll={handlePermissionClickAll}
              submitPermissionPerRole={submitPermissionPerRole}
            />
          )}
          {selectedOption === 'mailchimp_emails' && (
            <MailChimpEmails
              setingsLoader={props.setingsLoader}
              permissionsPerRole={permissionsPerRole}
              productSelectoptions={productSelectoptions}
              emailList={emailList}
              setSelectedProduct={setSelectedProduct}
              selectedProduct={selectedProduct}
              handleSearchReport={handleSearchReport}
              handelSearchEmail={handelSearchEmail}
              handelSearch={handelSearch}
              searchEmail={searchEmail}
              total={props.totalEmails}
              currentPage={props.currentPage}
              totalPage={props.totalPage}
              changePageSize={changePageSize}
              perPage={perPage}
              handelChangeAllTimeDateFilter={handelChangeAllTimeDateFilter}
              allTimeDateFilter={allTimeDateFilter}
              startDate={startDate}
              endDate={endDate}
              getCalendarInfo={getCalendarInfo}
              renderMonthElement={renderMonthElement}
              setFocusedInput={setFocusedInput}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              dateDiff={dateDiff}
              setDateDiff={setDateDiff}
              handelPageChange={handelPageChange}
              handelEmailSubscriptionAction={handelEmailSubscriptionAction}
              handelMailChimpCheckBox={handelMailChimpCheckBox}
              selectMailChimpEmailsAll={selectMailChimpEmailsAll}
              handelMailChimpAction={handelMailChimpAction}
              setSelectedMailChimpStatus={setSelectedMailChimpStatus}
              selectedMailChimpStatus={selectedMailChimpStatus}
              mailChimpEmailStatusOptions={mailChimpEmailStatusOptions}
            />
          )}
          {selectedOption === 'mail_webhook' && (
            <MailWebhook
              setingsLoader={props.setingsLoader}
              mailWebhookLink={mailWebhookLink}
              setMailWebhookLink={setMailWebhookLink}
              mailWebhookTag={mailWebhookTag}
              setMailWebhookTag={setMailWebhookTag}
              mailWebhookStatus={mailWebhookStatus}
              setMailWebhookStatus={setMailWebhookStatus}
              mailWebhookProductsInfo={mailWebhookProductsInfo}
              setMailWebhookProductsInfo={setMailWebhookProductsInfo}
              statusOptions={statusOptions}
              handelWebhookStatusChange={handelWebhookStatusChange}
              modalLoader={props.mailWebhookModalLoader}
              mailWebhookProductsSelectOptions={
                mailWebhookProductsSelectOptions
              }
              showMailWebhookModal={showMailWebhookModal}
              setShowMailWebhookModal={setShowMailWebhookModal}
              perPageMailWebhookModal={perPageMailWebhookModal}
              setPerPageMailWebhookModal={setPerPageMailWebhookModal}
              handleMailWebhookModalClose={handleMailWebhookModalClose}
              handleMailWebhookModalShow={handleMailWebhookModalShow}
              editMailWebhookModal={editMailWebhookModal}
              setEditMailWebhookModal={setEditMailWebhookModal}
              mailWebhookPluginSlug={mailWebhookPluginSlug}
              setMailWebhookPluginSlug={setMailWebhookPluginSlug}
              mailWebhookPluginName={mailWebhookPluginName}
              setMailWebhookPluginName={setMailWebhookPluginName}
              handelSearchForMailWebhook={handelSearchForMailWebhook}
              handelSearchEnterForMailWebhook={handelSearchEnterForMailWebhook}
              mailWebhookSearch={mailWebhookSearch}
              setMailWebhookSearch={setMailWebhookSearch}
              mailWebhookPerPage={mailWebhookPerPage}
              setMailWebhookPerPage={setMailWebhookPerPage}
              handelPageChangeForMailWebhook={handelPageChangeForMailWebhook}
              handleEditModalForMailWebhook={handleEditModalForMailWebhook}
              handelDeleteMailWebhook={handelDeleteMailWebhook}
              confirmBox={confirmBox}
              setConfirmBox={setConfirmBox}
              confirmBoxTitle={confirmBoxTitle}
              setConfirmBoxTitle={setConfirmBoxTitle}
              handelConfirmBoxClose={handelConfirmBoxClose}
              setMailWebhookId={setMailWebhookId}
              handelSubmitMailWebhookDelete={handelSubmitMailWebhookDelete}
              submitMailWebhook={submitMailWebhook}
              changeMailWebhookProductChange={changeMailWebhookProductChange}
              webhookCurrentPage={props.currentPageForMailWebhook}
              webhookTotal={props.totalForMailWebhook}
              errMailWebhook={errMailWebhook}
            />
          )}
          {/* {selectedOption === 5 && (
            <Companies setingsLoader={props.setingsLoader} />
          )} */}
        </div>
      </div>
    </>
  );
};

export default Settings;
