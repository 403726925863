import { connect } from 'react-redux';
import ProductDetails from '../../components/SuperAdmin/products/ProductDetails';
import {
  fetchProductDetails,
  downloadSDK,
  resetSdkLink,
} from '../../actions/SuperAdminProductDetailsActions';

const mapStateToProps = (state) => ({
  productDetails: state.superAdminProductDetails.productDetails,
  detailsLoader: state.superAdminProductDetails.detailsLoader,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  sdkLink: state.superAdminProductDetails.sdk_link,
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetails: (value) => dispatch(fetchProductDetails(value)),
  downloadSDK: (value) => dispatch(downloadSDK(value)),
  resetSdkLink: () => dispatch(resetSdkLink()),
});

const ProductDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetails);

export default ProductDetailsContainer;
