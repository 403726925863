import { connect } from 'react-redux';
import SuperAdminUserTeamMember from '../../components/SuperAdmin/Users/teamMembers';
import {
  getAllUserTeamMembers,
  userUpdate,
  resetRequestSuccess,
  resetPage,
} from '../../actions/SuperAdminUsersActions';

const mapStateToProps = (state) => ({
  teamMemberList: state.superAdminUserTeamMember.teamMemberList,
  mainUserData: state.superAdminUserTeamMember.mainUserData,
  currentPage: state.superAdminUserTeamMember.currentPage,
  totalPage: state.superAdminUserTeamMember.totalPage,
  total: state.superAdminUserTeamMember.total,
  requestSuccess: state.superAdminUser.requestSuccess,
  validationError: state.superAdminUser.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUserTeamMembers: (value) => dispatch(getAllUserTeamMembers(value)),
  updateUser: (value) => dispatch(userUpdate(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  resetPage: () => dispatch(resetPage()),
});

const UserTeamMembersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminUserTeamMember);

export default UserTeamMembersContainer;
