import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const statusOptions = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
  { value: 3, label: 'Invite' },
];

const TeamMemberModal = ({
  email,
  setEmail,
  role,
  setRole,
  products,
  setProducts,
  status,
  setStatus,
  roles,
  productList,
  submitTeamMember,
  err,
  loading,
  handelProductsSelect,
  modalLoader,
  editTeamMember,
  company_name,
}) => {
  !editTeamMember && setStatus(statusOptions[2]);
  const roleOptions = roles
    .filter(
      (role) =>
        !(
          role.name === 'owner' ||
          role.name === 'partner' ||
          role.name === 'Demo_User'
        )
    )
    .map((r) => {
      const newArray = {};
      newArray.label = r.name;
      newArray.value = r.id;
      return newArray;
    });

  const productsOptions = [
    { value: 'all', label: 'All' },
    ...productList.map((product) => {
      const newArray = {};
      newArray.label = product.base_name;
      newArray.value = product.id;
      return newArray;
    }),
  ];

  const permissionProducts = productsOptions.filter(
    (pO) => products && products.some((p) => pO.value === p.value)
  );

  return (
    <>
      <div className="ModalTabContent">
        <div className="TabContentRow">
          <label>Company</label>
          <input
            type="text"
            className="form-control"
            placeholder="Company Name"
            value={company_name.name}
            id="company_name"
            disabled
          />
        </div>
        <div className="TabContentRow">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            value={email}
            id="email"
            disabled={editTeamMember}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {err && err.email && err.email !== '' && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={email}>
              {err.email}
            </p>
          </div>
        )}

        <div className="TabContentRow">
          <label>Role</label>
          <div style={{ width: '100%' }}>
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              isSearchable={false}
              id="role"
              onChange={(e) => setRole(e)}
              options={roleOptions}
              value={roleOptions.find((r) => r.value === role.value)}
            />
          </div>
        </div>
        {err && err.role && err.role !== '' && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={role}>
              {err.role}
            </p>
          </div>
        )}
        <div className="TabContentRow">
          <label>Products</label>
          <div style={{ width: '100%' }}>
            <Select
              isMulti
              className="SelectOption basic-multi-select"
              classNamePrefix="select react-select"
              id="products"
              onChange={(e) => handelProductsSelect(e)}
              options={productsOptions}
              value={permissionProducts}
            />
          </div>
        </div>
        {err && err.products && err.products !== '' && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={products}>
              {err.products}
            </p>
          </div>
        )}
        <div className="TabContentRow">
          <label>Status</label>
          <br />
          <div style={{ width: '100%' }}>
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              isSearchable={false}
              isDisabled={!editTeamMember}
              isOptionDisabled={!editTeamMember}
              id="status"
              onChange={(e) => setStatus(e)}
              options={
                editTeamMember
                  ? statusOptions.filter((o) => o.value !== 3)
                  : statusOptions
              }
              value={
                editTeamMember
                  ? statusOptions.find((s) => s.value === status.value)
                  : statusOptions[2]
              }
            />
          </div>
        </div>
        {err && err.status && err.status !== '' && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={status}>
              {err.status}
            </p>
          </div>
        )}
        <div className="d-flex mt-5">
          <button
            className="Button PrimaryButton ml-auto"
            onClick={() => submitTeamMember()}
          >
            <span>
              {modalLoader ? (
                <Spinner animation="border" variant="light" />
              ) : (
                'Submit'
              )}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.teamMember.teamMemberListLoader,
  modalLoader: state.teamMember.modalLoader,
  company_name: state.auth.signin && state.auth.signin.activeProfile,
});

export default connect(mapStateToProps)(TeamMemberModal);
