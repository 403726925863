import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SUPER_ADMIN_LOAD_REPORT,
  SUPER_ADMIN_RENDER_REPORT,
  SUPER_ADMIN_LOAD_REPORT_FAILED,
  SUPER_ADMIN_LOAD_STRESS_REPORT,
  SUPER_ADMIN_RENDER_STRESS_REPORT,
  SUPER_ADMIN_LOAD_REPORT_STRESS_FAILED,
  SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE,
  SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS,
  SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED,
} from '../actions/SuperAdminReportsActions';

import { TOKEN_ERROR } from '../actions/index';
import { defaultApi } from '../utils/axiosApi';

export function* fetchAllReports(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/reports/all`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_RENDER_REPORT,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      allPhpVersion: data.data && data.data.allPhpVersion,
      allWpInsVersion: data.data && data.data.allWpInsVersion,
      allWordpressVersion: data.data && data.data.allWordpressVersion,
      allProducts: data.data && data.data.allProducts,
      allUsers: data.data && data.data.allUsers,
      siteList: data.data && data.data.siteList,
      wpVersion: data.data && data.data.wpVersion,
      phpVersion: data.data && data.data.phpVersion,
      pluginVersion: data.data && data.data.pluginVersion,
      status: data.data && data.data.status,
      language: data.data && data.data.language,
      de_active_reasons: data.data && data.data.de_active_reasons,
      activeSitesCount: data.data && data.data.activeSitesCount,
      deactivedSitesCount: data.data && data.data.deactivedSitesCount,
      analytics: data.data && data.data.analytics,
      mailchimpAnalytics: data.data && data.data.mailChimpAnalytics,
      totalUserCount: data.data && data.data.totalUserCount,
      newUserCount: data.data && data.data.newUserCount,
      sitesConfig: data.data && data.data.sitesConfig,
      sortedBy: data.data && data.data.siteSort,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_LOAD_REPORT_FAILED,
        message: data.message,
      });
    }
  }
}
export function* fetchStressReports(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/reports/strss-report`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_RENDER_STRESS_REPORT,
      allProductsForStress: data.data && data.data.allProductsForStress,
      successAnalytics: data.data && data.data.successAnalytics,
      errorAnalytics: data.data && data.data.errorAnalytics,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_LOAD_REPORT_STRESS_FAILED,
        message: data.message,
      });
    }
  }
}

export function* saveSitesTableConfigAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/configs/save-from-report`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadAllReport() {
  yield takeLatest(SUPER_ADMIN_LOAD_REPORT, fetchAllReports);
}

export function* loadStressReport() {
  yield takeLatest(SUPER_ADMIN_LOAD_STRESS_REPORT, fetchStressReports);
}
export function* saveSitesTableConfig() {
  yield takeLatest(
    SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE,
    saveSitesTableConfigAttempt
  );
}

export default function* rootSaga() {
  yield all([loadAllReport(), loadStressReport(), saveSitesTableConfig()]);
}
