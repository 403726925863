export const APPLY_FOR_DEMO_SITE = 'APPLY_FOR_DEMO_SITE';
export const APPLY_FOR_DEMO_SITE_SUCCESS = 'APPLY_FOR_DEMO_SITE_SUCCESS';
export const APPLY_FOR_DEMO_SITE_FAILURE = 'APPLY_FOR_DEMO_SITE_FAILURE';
export const APPLY_FOR_DEMO_SITE_VALIDATION_FAILED =
  'APPLY_FOR_DEMO_SITE_VALIDATION_FAILED';

export function applyForDemo(data) {
  return {
    type: APPLY_FOR_DEMO_SITE,
    credentials: data,
  };
}
