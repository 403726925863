import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import sagas from './sagas';

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store =
  process.env.NODE_ENV === 'development'
    ? createStore(
        rootReducer(history),
        compose(
          applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            sagaMiddleware
          ),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      )
    : createStore(
        rootReducer(history),
        compose(
          applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            sagaMiddleware
          )
        )
      );

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
