import { toast } from 'react-toastify';
import {
  LOAD_PRODUCT_LIST,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  RENDER_PRODUCTS_LIST,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  RESET_REQUEST_SUCCESS,
  CREATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT_FAILED,
  VALIDATION_FAILED,
  LOAD_PRODUCT_FAILED,
  RESET_FIRST_TIME,
  FIND_MAILCHIMP_LIST,
  FIND_MAILCHIMP_LIST_FAILED,
  RENDER_MAILCHIMP_LIST,
} from '../actions/productsActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  productlist: [],
  currentPage: 1,
  totalPage: 1,
  userCount: [],
  total: 0,
  requestSuccess: false,
  loading: false,
  validationError: [],
  mailChimpList: [],
  firstTime: true,
  productlistLoader: false,
  modalLoader: false,
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case LOAD_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
        productlistLoader: true,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case FIND_MAILCHIMP_LIST:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case RENDER_PRODUCTS_LIST:
      if (action.message && action.message !== '') {
        toast.warn(action.message);
      }
      return {
        ...state,
        productlist: action.productData,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        userCount: action.userCount,
        total: action.total,
        loading: false,
        mailChimpList: action.mailChimpList,
        productlistLoader: false,
      };
    case RENDER_MAILCHIMP_LIST:
      if (action.message && action.message !== '') {
        toast.warn(action.message);
      }
      return {
        ...state,
        loading: false,
        mailChimpList: action.mailChimpList,
        modalLoader: false,
      };
    case LOAD_PRODUCT_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        productlistLoader: false,
      };
    case FIND_MAILCHIMP_LIST_FAILED:
      toast.error(action.message);
      return {
        ...state,
        loading: false,
        modalLoader: false,
      };
    case CREATE_PRODUCT_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };
    case UPDATE_PRODUCT_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };

    case DELETE_PRODUCT_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };

    case RESET_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        // modalLoader: false,
        // productlistLoader: false,
      };
    case RESET_FIRST_TIME:
      return {
        ...state,
        firstTime: false,
        // modalLoader: false,
        // productlistLoader: false,
      };

    case VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        validationError: action.errors,
        modalLoader: false,
      };

    case CREATE_PRODUCT_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case UPDATE_PRODUCT_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case DELETE_PRODUCT_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
