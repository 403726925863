import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Select from 'react-select';

const orderBySelectoptions = [
  { value: 'ASC', label: 'ASC' },
  { value: 'DESC', label: 'DESC' },
];

const sortColumn = [
  'updatedTime',
  'pluginVersion',
  'phpVersion',
  'wpInsVersion',
  'siteVersion',
  'alexaRank',
  'since',
];

const PreferredConfig = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <section className="FormViewSection">
      <h3 className="SectionTitle mb-4">Sites table config</h3>

      <div className="FormGroupWrapper order-data">
        <DragDropContext onDragEnd={props.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.siteTableConfig &&
                  props.siteTableConfig.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                      name={item.name}
                    >
                      {(provided) => (
                        <div
                          className="FormGroup"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="OrderableFormData">
                            <label className="Checkbox mr-3 mb-0">
                              <input
                                type="checkbox"
                                id={item.name}
                                name={index}
                                checked={item.isChecked}
                                onChange={(e) => props.handelCheckBox(e)}
                              />
                              <span>{item.lable}</span>
                            </label>
                            {sortColumn.includes(item.name) ? (
                              <div className="OrderType ml-auto">
                                <label className="OrderActive Radio mb-0">
                                  <input
                                    type="radio"
                                    value={item.name}
                                    name="orderByColumn"
                                    onChange={(e) =>
                                      props.handelOrderByColumn(e)
                                    }
                                    checked={props.orderByColumn === item.name}
                                  />
                                  <span>Order</span>
                                </label>
                                {props.orderByColumn === item.name && (
                                  <div
                                    style={{ minWidth: '150px' }}
                                    className="dropdown"
                                  >
                                    <Select
                                      name=""
                                      onChange={(e) =>
                                        props.handelOrderByColumnSelectoptions(
                                          e
                                        )
                                      }
                                      options={orderBySelectoptions}
                                      defaultValue={orderBySelectoptions[0]}
                                      className="SelectOption wp-dropdown"
                                      classNamePrefix="react-select"
                                      value={orderBySelectoptions.find(
                                        (r) =>
                                          r.value ===
                                          props.orderByColumnSelectoptions
                                      )}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <button onClick={props.onSubmitSettings} className="Button PrimaryButton">
        Submit
      </button>
    </section>
  );

export default PreferredConfig;
