import React from 'react';

const PlaceholderFileChange = (props) => (
  <>
    {!props.show ? (
      <section className="FormViewSection">
        <div className="d-flex mb-5">
          <button
            onClick={props.handleShow}
            className="Button PrimaryButton ml-auto"
          >
            <div className="Icon">
              <i className="insight wpi-edit" />
            </div>
            <span>Upload new file</span>
          </button>
        </div>
        <div className="FormViewDataWrapper">
          <div className="FormViewDataGroupLeft">
            <div className="FormViewGroup">
              <p className="FormViewTitle">Placeholder :</p>

              {props.placeholder_file && props.placeholder_file.length > 0 && (
                <div
                  className="d-flex"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <button
                    onClick={(e) => props.handelDwnloadPlaceholder(e)}
                    className="SmallButton PrimaryButton mb-4 ml-2"
                  >
                    <span>Download</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    ) : (
      <>
        <div className="SecondarySidebarContent">
          <section className="FormViewSection">
            <div className="FormViewGroup">
              <p className="FormViewTitle">placeholder File * :</p>
              <div
                className="d-flex"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <input
                  type="file"
                  onChange={(e) => props.setPlaceholder_file(e.target.files[0])}
                  accept=".txt"
                />
              </div>
            </div>
            <button
              onClick={(e) => props.submitPlaceholderUpdate(e)}
              className="Button PrimaryButton ml-auto"
            >
              Submit
            </button>
          </section>
        </div>
      </>
    )}
  </>
);

export default PlaceholderFileChange;
