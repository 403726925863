import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_COMPANY_LIST,
  LOAD_COMPANY_LIST_FAILED,
  RENDER_COMPANY_LIST,
  ADD_NEW_COMPANY,
  ADD_NEW_COMPANY_SUCCESS,
  ADD_NEW_COMPANY_FAILED,
  COMPANY_VALIDATION_FAILED,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILED,
  LIMIT_ERROR,
} from '../actions/CompanyActions';

import {
  ADD_NEW_COMPANY_FOR_USER,
  ADD_NEW_COMPANY_FOR_USER_SUCCESS,
  ADD_NEW_COMPANY_FOR_USER_FAILED,
  ADD_NEW_COMPANY_FOR_USER_VALIDATION_FAILED,
} from '../actions/createCompanyForUserAction';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* fetchCompanyList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/company/getAllCompany`;

  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;

    yield put({
      type: RENDER_COMPANY_LIST,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      companyList: data.data && data.data.companyList,
      roles: data.data && data.data.roles,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_COMPANY_LIST_FAILED,
        message: data.message,
      });
    }
  }
}

export function* saveNewCompany(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/company/save`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: ADD_NEW_COMPANY_SUCCESS,
      message: data.message,
      allProfile: data.data.allProfiles,
      activeProfile: data.data.activeProfiles,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'limit_error') {
      yield put({
        type: LIMIT_ERROR,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: COMPANY_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: ADD_NEW_COMPANY_FOR_USER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* addNewCompanyForUser(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/createForUser`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: ADD_NEW_COMPANY_FOR_USER_SUCCESS,
      message: data.message,
      allProfile: data.data.allProfiles,
      activeProfile: data.data.activeProfile,
      accountType: data.data.loggedInAccountType,
      user: data.data.user,
      token: data.data.token,
      loggedInAccountType: data.data.loggedInAccountType,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: ADD_NEW_COMPANY_FOR_USER_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: ADD_NEW_COMPANY_FAILED,
        message: data.message,
      });
    }
  }
}
export function* updateUserCompany(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/company/update`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_COMPANY_SUCCESS,
      message: data.message,
      allProfile: data.data.allProfiles,
      activeProfile: data.data.activeProfiles,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: COMPANY_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: UPDATE_COMPANY_FAILED,
        message: data.message,
      });
    }
  }
}

export function* deleteUserCompany(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/company/delete`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: DELETE_COMPANY_SUCCESS,
      message: data.message,
      allProfile: data.data.allProfiles,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: COMPANY_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: DELETE_COMPANY_FAILED,
        message: data.message,
      });
    }
  }
}

export function* companyList() {
  yield takeLatest(LOAD_COMPANY_LIST, fetchCompanyList);
}
export function* addNewCompany() {
  yield takeLatest(ADD_NEW_COMPANY, saveNewCompany);
}

export function* createNewCompanyForUser() {
  yield takeLatest(ADD_NEW_COMPANY_FOR_USER, addNewCompanyForUser);
}

export function* updateCompany() {
  yield takeLatest(UPDATE_COMPANY, updateUserCompany);
}
export function* deleteCompany() {
  yield takeLatest(DELETE_COMPANY, deleteUserCompany);
}

export default function* rootSaga() {
  yield all([
    companyList(),
    addNewCompany(),
    updateCompany(),
    deleteCompany(),
    createNewCompanyForUser(),
  ]);
}
