import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'rc-pagination';
import moment from 'moment';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import Dropdown from 'react-bootstrap/Dropdown';
import Select from 'react-select';

const MailChimpEmails = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <div className="ContentAreaBody" style={{ padding: '0px' }}>
      <section className="DataViewSection">
        <div className="DataViewFilter">
          <form action="">
            <div className="row HasColGap">
              <div className="Col-30">
                <div className="FormGroup">
                  <label htmlFor="">Product</label>
                  <Select
                    id="search_product"
                    onChange={(e) => props.setSelectedProduct(e)}
                    options={props.productSelectoptions}
                    defaultValue={props.productSelectoptions[0]}
                    value={props.selectedProduct}
                    className="SelectOption"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="Col-40" style={{ marginBottom: '20px' }}>
                <div className="FormGroup">
                  <div className="d-flex justify-content-between">
                    <label>Date Filter</label>
                    <div>
                      <label className="Checkbox">
                        <input
                          type="checkbox"
                          checked={props.allTimeDateFilter}
                          onChange={(e) =>
                            props.handelChangeAllTimeDateFilter(e)
                          }
                        />
                        <span> All Time</span>
                      </label>
                    </div>
                  </div>

                  <DateRangePicker
                    isOutsideRange={(endDate) =>
                      !isInclusivelyBeforeDay(endDate, moment())
                    }
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={moment(props.startDate)}
                    endDate={moment(props.endDate)}
                    displayFormat="YYYY-MM-DD"
                    onDatesChange={({ startDate, endDate }) => {
                      startDate && props.setStartDate(startDate.toDate());
                      endDate && props.setEndDate(endDate.toDate());
                    }}
                    focusedInput={props.focusedInput}
                    onFocusChange={(focusedInput) => {
                      props.setFocusedInput(focusedInput);
                    }}
                    renderCalendarInfo={() => props.getCalendarInfo()}
                    hideKeyboardShortcutsPanel
                    minimumNights={0}
                    renderMonthElement={props.renderMonthElement}
                    disabled={props.allTimeDateFilter}
                  />
                </div>
              </div>

              <div className="Col-30">
                <div className="FormGroup">
                  <label htmlFor="">Status</label>
                  <Select
                    id="search_status"
                    onChange={(e) => props.setSelectedMailChimpStatus(e)}
                    // options={
                    //   !props.allTimeDateFilter
                    //     ? props.mailChimpEmailStatusOptions.filter(
                    //         (f) => f.value !== 'invalid'
                    //       )
                    //     : props.mailChimpEmailStatusOptions
                    // }
                    options={props.mailChimpEmailStatusOptions}
                    defaultValue={props.mailChimpEmailStatusOptions[0]}
                    value={props.selectedMailChimpStatus}
                    className="SelectOption"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>

              <div className="Col-15 ml-auto">
                <div className="FormGroup">
                  <button
                    onClick={props.handleSearchReport}
                    className="Button PrimaryButton ml-auto"
                  >
                    <span>Filter</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="DataViewHeading">
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                onKeyPress={props.handelSearch}
                onChange={(e) => props.handelSearchEmail(e)}
                placeholder="Search"
                value={props.searchEmail}
              />
            </div>
            {/* <div class="dropdown ml-3">
              <button
                class="Button WarningButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="DropdownItem">
                  <a href="#">Subscribe</a>
                </li>
                <li class="DropdownItem">
                  <a href="#">Unsubscribe</a>
                </li>
                <li class="DropdownItem">
                  <a href="#">Remove</a>
                </li>
              </ul>
            </div> */}
            <Dropdown className="dropdown ml-3">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="Button PrimaryButton"
              >
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => props.handelMailChimpAction(e, 'subscribed')}
                >
                  Subscribe
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    props.handelMailChimpAction(e, 'unsubscribed')
                  }
                >
                  Unsubscribe
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={(e) => props.handelMailChimpAction(e, 'toggol')}
                >
                  Toggol
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="TabContent">
          <div className="DataViewTable">
            <form action="" className="TableWrapper">
              <table className="DataTable">
                <thead>
                  <tr>
                    <th className="Selector">
                      <label className="Checkbox">
                        <input
                          type="checkbox"
                          name="checkAll"
                          checked={props.selectMailChimpEmailsAll}
                          onChange={(e) => props.handelMailChimpCheckBox(e)}
                        />
                        <span />
                      </label>
                    </th>
                    <th>User Email</th>
                    {props.selectedProduct &&
                      props.selectedProduct.value === 'all' && (
                        <th>Plugin Slug</th>
                      )}

                    <th>Subscription Info</th>
                    <th>Response</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {props.emailList &&
                    props.emailList.length > 0 &&
                    props.emailList.map((list, index) => (
                      <tr key={index}>
                        <td className="Selector">
                          <label className="Checkbox">
                            <input
                              type="checkbox"
                              key={list.id}
                              name="checkbox"
                              checked={list.checked}
                              onChange={(e) =>
                                props.handelMailChimpCheckBox(
                                  e,
                                  list.id,
                                  list.email,
                                  list.plugin_slug,
                                  list.mailchimp_list_id,
                                  list.mailchimp_status === 'subscribed'
                                    ? 'unsubscribed'
                                    : 'subscribed'
                                )
                              }
                            />
                            <span />
                          </label>
                        </td>
                        <td>
                          <p>{list.email}</p>
                        </td>
                        {props.selectedProduct &&
                          props.selectedProduct.value === 'all' && (
                            <td>
                              <p>{list.plugin_slug}</p>
                            </td>
                          )}

                        <td>
                          <div className="d-flex">
                            <p
                              className={`LabelButton ${
                                list.mailchimp_status === 'subscribed'
                                  ? 'SuccessAlert'
                                  : 'DangerAlert'
                              }`}
                            >
                              {list.mailchimp_status}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p>{list.mailchimp_response}</p>
                        </td>
                        <td className="ActionButtonColumn">
                          <p className="ActionButtonGroup">
                            {list.mailchimp_status === 'subscribed' ? (
                              <button
                                onClick={(e) =>
                                  props.handelEmailSubscriptionAction(
                                    e,
                                    list.id,
                                    list.email,
                                    list.plugin_slug,
                                    list.mailchimp_list_id,
                                    'unsubscribed'
                                  )
                                }
                                className="ActionButton WarningAlert"
                                title="Unsubscribe"
                              >
                                <span className="Icon">
                                  <i className="insight wpi-bookmark" />
                                </span>
                              </button>
                            ) : (
                              <button
                                onClick={(e) =>
                                  props.handelEmailSubscriptionAction(
                                    e,
                                    list.id,
                                    list.email,
                                    list.plugin_slug,
                                    list.mailchimp_list_id,
                                    'subscribed'
                                  )
                                }
                                className="ActionButton SuccessAlert"
                                title="Subscribe"
                              >
                                <span className="Icon">
                                  <i className="insight wpi-bookmark" />
                                </span>
                              </button>
                            )}

                            {/* <a
                                href="#"
                                class="ActionButton DangerAlert"
                                title="Delete"
                              >
                                <span class="Icon">
                                  <i class="insight wpi-delete"></i>
                                </span>
                              </a> */}
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </form>
            <div className="TableFooter">
              <div className="TableFooterContent">
                {props.total > 10 && (
                  <>
                    <form action="">
                      <div className="d-flex align-items-center">
                        <label htmlFor="ChooseNumber">Show</label>
                        <div className="CustomSelectNumber">
                          <select
                            onChange={(e) => props.changePageSize(e)}
                            className="custom-select"
                            id="ChooseNumber"
                            value={props.perPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                          </select>
                        </div>
                        <label htmlFor="ChooseNumber">Entires</label>
                      </div>
                    </form>
                    <Pagination
                      className="PaginationList ml-auto"
                      total={props.total}
                      current={props.currentPage}
                      onChange={(e) => props.handelPageChange(e)}
                      showTitle={false}
                      pageSize={props.perPage}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

export default MailChimpEmails;
