export const ADD_NEW_COMPANY_FOR_USER = 'ADD_NEW_COMPANY_FOR_USER';
export const ADD_NEW_COMPANY_FOR_USER_SUCCESS =
  'ADD_NEW_COMPANY_FOR_USER_SUCCESS';
export const ADD_NEW_COMPANY_FOR_USER_FAILED =
  'ADD_NEW_COMPANY_FOR_USER_FAILED';

export const ADD_NEW_COMPANY_FOR_USER_VALIDATION_FAILED =
  'ADD_NEW_COMPANY_FOR_USER_VALIDATION_FAILED';

export const RESET_COMPANY_CREATE_FORM = 'RESET_COMPANY_CREATE_FORM';

export function createCompanyForUser(data) {
  return {
    type: ADD_NEW_COMPANY_FOR_USER,
    credentials: data,
  };
}
export function resetCompanyCreateForm() {
  return {
    type: RESET_COMPANY_CREATE_FORM,
  };
}
