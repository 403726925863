import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import ProductBasicInfo from './ProductBasicInfo';
import ProductMarketingInfo from './ProductMarketingInfo';
import ProductTrackingInfo from './ProductTrackingInfo';
import BasicInfoModal from './BasicInfoModal';
import MetaInfoModal from './MetaInfoModal';
import MarketingInfoModal from './MarketingInfoModal';

const ProductDetails = (props) => {
  const [userRole, setUserRole] = useState('');
  const [userPermission, setUserPermission] = useState([]);

  const [selectedOption, setSelectedOption] = useState(1);
  const [secondarySidebarCollaspe, setSecondarySidebarCollaspe] =
    useState(false);

  const [show, setShow] = useState(false);
  const [modalStep, setmodalStep] = useState(1);
  const [productUpdate, setProductUpdate] = useState(false);

  const [details, setDetails] = useState({});

  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productBaseName, setProductBaseName] = useState('');
  const [productVersion, setProductVersion] = useState('');
  const [productHomeUrl, setProductHomeUrl] = useState('');
  const [productDemoUrl, setProductDemoUrl] = useState('');
  const [productStatus, setProductStatus] = useState({
    value: '',
    label: 'select',
  });
  const [productDescription, setProductDescription] = useState('');

  const [productPhpRequiredVersion, setProductPhpRequiredVersion] =
    useState('');
  const [productPhpTestedUpto, setProductPhpTestedUpto] = useState('');
  const [productWordpressRequiredVersion, setProductWordpressRequiredVersion] =
    useState('');
  const [productWordpressTestedUpto, setProductWordpressTestedUpto] =
    useState('');

  const [productMailingList, setProductMailingList] = useState({
    value: '',
    label: 'select',
  });
  const [productTrackingOption, setProductTrackingOption] = useState({
    value: '',
    label: 'select',
  });
  const [productAskTrackingPermission, setProductAskTrackingPermission] =
    useState({ value: '', label: 'select' });
  const [productEnableDeactivationForm, setProductEnableDeactivationForm] =
    useState({ value: '', label: 'select' });
  const [
    productChooseNotificationFormate,
    setProductChooseNotificationFormate,
  ] = useState({ value: '', label: 'select' });
  const [hasErr, setHasErr] = useState(false);
  const [basicTabClick, setBasicTabClick] = useState(false);
  const [metaTabClick, setMetaTabClick] = useState(false);
  const [marketingTabClick, setMarketingTabClick] = useState(false);
  const [err, setErr] = useState({
    productName: [],
    productBaseName: [],
    productVersion: [],
    productHomeUrl: [],
    productDemoUrl: [],
    productStatus: [],
    productDescription: [],
    productPhpRequiredVersion: [],
    productPhpTestedUpto: [],
    productWordpressRequiredVersion: [],
    productWordpressTestedUpto: [],
    productMailingList: [],
    productTrackingOption: [],
    productAskTrackingPermission: [],
    productEnableDeactivationForm: [],
    productChooseNotificationFormate: [],
  });

  const handleClose = () => setShow(false);

  /* eslint-disable */
  useEffect(() => {
    const desiredReport = queryString.parse(props.history.location.search);
    props.getProductDetails({
      base_name: desiredReport.product,
      account_type: props.accountType,
      company_id: props.activeProfile.id,
    });
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      setUserRole(props.rolePermissionInfo[0].name);
      let permissions = props.rolePermissionInfo[0].permissions;
      if (permissions && permissions.length > 0) {
        let role_permission =
          props.rolePermissionInfo[0].permissions[0].permission;
        let newArray = [];
        role_permission.map((p) => {
          if (p[0] === 'Products') {
            p[1].map((r) => {
              newArray.push(r);
            });
          }
        });
        setUserPermission(newArray);
      }
    }
  }, []);

  useEffect(() => {
    if (props.productDetails && props.productDetails.length > 0) {
      var dataArray = props.productDetails[0];
      setDetails(dataArray);
    } else {
      setDetails({});
    }
  }, [props.productDetails]);

  useEffect(() => {
    props.requestSuccess && setShow(false);
    props.requestSuccess && setmodalStep(1);
    const desiredReport = queryString.parse(props.history.location.search);
    props.requestSuccess &&
      props.getProductDetails({
        base_name: desiredReport.product,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
  }, [props.requestSuccess]);

  useEffect(() => {
    !show && setProductUpdate(false);
    !show && props.resetRequestSuccess();
  }, [show]);

  useEffect(() => {
    if (props.sdkLink) {
      const link = document.createElement('a');
      link.setAttribute('href', props.sdkLink);
      link.setAttribute('download', details.productName);
      link.click();

      props.resetSdkLink();
    }
  }, [props.sdkLink]);

  useEffect(() => {
    if (props.validationError.length > 0) {
      setmodalStep(1);
      let NameErr = [];
      let baseNameErr = [];
      let AskTrackingPermissionErr = [];
      let enableDeactivationFormErr = [];
      let chooseNotificationFormate = [];
      let versionErr = [];

      if (validationError[0].base_name) {
        NameErr = validationError[0].base_name;
      }
      if (validationError[0].base_name_slug) {
        baseNameErr = validationError[0].base_name_slug;
      }
      if (validationError[0].require_opt_in) {
        AskTrackingPermissionErr = validationError[0].require_opt_in;
      }
      if (validationError[0].deactivate_form) {
        enableDeactivationFormErr = validationError[0].deactivate_form;
      }
      if (validationError[0].notification_format) {
        chooseNotificationFormate = validationError[0].notification_format;
      }
      if (validationError[0].version_no) {
        versionErr = validationError[0].version_no;
      }

      setErr({
        ...err,
        productName: NameErr,
        productBaseName: baseNameErr,
        productAskTrackingPermission: AskTrackingPermissionErr,
        productEnableDeactivationForm: enableDeactivationFormErr,
        productChooseNotificationFormate: chooseNotificationFormate,
        productVersion: versionErr,
      });
    }
  }, [props.validationError]);

  useEffect(() => {
    if (props.accessRemoved) {
      props.resetAccessRemoved();
      props.history.push('/user/dashboard');
    }
  }, [props.accessRemoved]);

  useEffect(() => {
    if (props.companyNotFound) {
      props.resetCompanyNotFound();
      props.history.push('/auth/create-company-form');
    }
  }, [props.companyNotFound]);

  /* eslint-enable */

  const handleEditModal = (id) => {
    setProductId(details.id);
    setProductName(details.base_name);
    setProductBaseName(details.base_name_slug);
    setProductVersion(details.version_no);
    setProductHomeUrl(details.home_url);
    setProductDemoUrl(details.demo_url);
    setProductStatus({
      value: details.status,
      label: details.status,
    });
    setProductDescription(details.description);
    setProductPhpRequiredVersion(details.meta_info.php_version);
    setProductPhpTestedUpto(details.meta_info.tested_php_version);
    setProductWordpressRequiredVersion(details.meta_info.wp_version);
    setProductWordpressTestedUpto(details.meta_info.tested_version);
    setProductMailingList(details.mailchimp_list_id);

    details &&
      details.marketing_info &&
      details.marketing_info.options_to_track.length > 0 &&
      setProductTrackingOption({
        value: details.marketing_info.options_to_track,
        label: details.marketing_info.options_to_track,
      });
    details &&
      details.marketing_info &&
      details.marketing_info.require_opt_in.length > 0 &&
      setProductAskTrackingPermission({
        value: details.marketing_info.require_opt_in,
        label: details.marketing_info.require_opt_in,
      });
    details &&
      details.marketing_info &&
      details.marketing_info.deactivate_form.length > 0 &&
      setProductEnableDeactivationForm({
        value: details.marketing_info.deactivate_form,
        label: details.marketing_info.deactivate_form,
      });
    details &&
      details.marketing_info &&
      details.marketing_info.notification_format.length > 0 &&
      setProductChooseNotificationFormate({
        value: details.marketing_info.notification_format,
        label: details.marketing_info.notification_format,
      });

    setShow(true);
    setProductUpdate(true);
    setBasicTabClick(true);
    setMetaTabClick(true);
    setMarketingTabClick(true);
  };

  const handleBasicInfo = () => {
    const errors = validateBasicInfo();
    if (errors.length > 0) {
    } else {
      setmodalStep(2);
      setBasicTabClick(true);
      setMetaTabClick(true);
    }
  };
  const handelMetaInfo = () => {
    const errors = validateMetaInfo();
    if (errors.length > 0) {
    } else {
      setmodalStep(3);
      setMetaTabClick(true);
      setMarketingTabClick(true);
    }
  };
  const handleMarketingInfo = () => {
    setmodalStep(1);
  };

  const validateBasicInfo = () => {
    const errors = [];

    const productNameErr = [];
    const productBaseNameErr = [];
    const productVersionErr = [];
    const productHomeUrlErr = [];
    const productDemoUrlErr = [];
    const productStatusErr = [];

    if (productName === '') {
      productNameErr.push("Product name can't be empty");
      errors.push("Product name can't be empty");
    }

    if (productName.length > 255) {
      productNameErr.push("Product name can't be over 255 charecter");
      errors.push("Product name can't be over 255 charecter");
    }

    if (productBaseName === '') {
      productBaseNameErr.push("Product base name can't be empty");
      errors.push("Product base name can't be empty");
    }

    if (productBaseName.length > 255) {
      productBaseNameErr.push("Product base name can't be over 255 charecter");
      errors.push("Product base name can't be over 255 charecter");
    }

    if (productHomeUrl === '') {
      productHomeUrlErr.push("Product home url can't be empty");
      errors.push("Product home url can't be empty");
    }

    if (productHomeUrl && productHomeUrl.length > 255) {
      productHomeUrlErr.push("Product home url can't be over 255 charecter");
      errors.push("Product home url can't be over 255 charecter");
    }

    if (productDemoUrl && productDemoUrl.length > 255) {
      productDemoUrlErr.push("Product demo url can't be over 255 charecter");
      errors.push("Product demo url can't be over 255 charecter");
    }

    if (productVersion === '') {
      productVersionErr.push("Version number can't be empty");
      errors.push("Version number can't be empty");
    }

    if (productVersion.length > 100) {
      productVersionErr.push("Version number can't be over 100 charecter");
      errors.push("Version number can't be over 100 charecter");
    }

    if (productStatus && productStatus.value === '') {
      productStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productStatus) {
      productStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (errors.length > 0) {
      setErr({
        productName: productNameErr,
        productBaseName: productBaseNameErr,
        productVersion: productVersionErr,
        productHomeUrl: productHomeUrlErr,
        productDemoUrl: productDemoUrlErr,
        productStatus: productStatusErr,
        productDescription: [],
      });
      setHasErr(true);
    }

    return errors;
  };

  const validateMetaInfo = () => {
    const errors = [];

    const productPhpRequiredVersionErr = [];
    const productPhpTestedUptoErr = [];
    const productWordpressRequiredVersionErr = [];
    const productWordpressTestedUptoErr = [];

    if (!productPhpRequiredVersion || productPhpRequiredVersion === '') {
      productPhpRequiredVersionErr.push("PHP required verion can't be empty");
      errors.push("PHP required verion can't be empty");
    }

    if (productPhpRequiredVersion && productPhpRequiredVersion.length > 100) {
      productPhpRequiredVersionErr.push(
        "PHP required verion can't be over 100 charecter"
      );
      errors.push("PHP required verion can't be over 100 charecter");
    }

    if (!productPhpTestedUpto || productPhpTestedUpto === '') {
      productPhpTestedUptoErr.push("PHP tested upto verion can't be empty");
      errors.push("PHP tested upto verion can't be empty");
    }

    if (productPhpTestedUpto && productPhpTestedUpto.length > 100) {
      productPhpTestedUptoErr.push(
        "PHP tested upto verion can't be over 100 charecter"
      );
      errors.push("PHP tested upto verion can't be over 100 charecter");
    }

    if (
      !productWordpressRequiredVersion ||
      productWordpressRequiredVersion === ''
    ) {
      productWordpressRequiredVersionErr.push(
        "WordPress required verion can't be empty"
      );
      errors.push("WordPress required verion can't be empty");
    }

    if (
      productWordpressRequiredVersion &&
      productWordpressRequiredVersion.length > 100
    ) {
      productWordpressRequiredVersionErr.push(
        "WordPress required verion can't be over 100 charecter"
      );
      errors.push("WordPress required verion can't be over 100 charecter");
    }

    if (!productWordpressTestedUpto || productWordpressTestedUpto === '') {
      productWordpressTestedUptoErr.push(
        "WordPress tested upto verion can't be empty"
      );
      errors.push("WordPress tested upto verion can't be empty");
    }

    if (productWordpressTestedUpto && productWordpressTestedUpto.length > 100) {
      productWordpressTestedUptoErr.push(
        "WordPress tested upto verion can't be over 100 charecter"
      );
      errors.push("WordPress tested upto verion can't be over 100 charecter");
    }

    if (errors.length > 0) {
      setErr({
        productPhpRequiredVersion: productPhpRequiredVersionErr,
        productPhpTestedUpto: productPhpTestedUptoErr,
        productWordpressRequiredVersion: productWordpressRequiredVersionErr,
        productWordpressTestedUpto: productWordpressTestedUptoErr,
      });
      setHasErr(true);
    }
    return errors;
  };
  const validateMarketingInfo = () => {
    const errors = [];

    const productTrackingOptionErr = [];
    const productAskTrackingPermissionErr = [];
    const productEnableDeactivationFormErr = [];
    const productChooseNotificationFormateErr = [];

    if (productTrackingOption && productTrackingOption.value === '') {
      productTrackingOptionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productTrackingOption) {
      productTrackingOptionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (
      productAskTrackingPermission &&
      productAskTrackingPermission.value === ''
    ) {
      productAskTrackingPermissionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productAskTrackingPermission) {
      productAskTrackingPermissionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (
      productEnableDeactivationForm &&
      productEnableDeactivationForm.value === ''
    ) {
      productEnableDeactivationFormErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productEnableDeactivationForm) {
      productEnableDeactivationFormErr.push('please select a option');
      errors.push('please select a option');
    }

    if (
      productChooseNotificationFormate &&
      productChooseNotificationFormate.value === ''
    ) {
      productChooseNotificationFormateErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productChooseNotificationFormate) {
      productChooseNotificationFormateErr.push('please select a option');
      errors.push('please select a option');
    }

    if (errors.length > 0) {
      setErr({
        productMailingList: [],
        productTrackingOption: productTrackingOptionErr,
        productAskTrackingPermission: productAskTrackingPermissionErr,
        productEnableDeactivationForm: productEnableDeactivationFormErr,
        productChooseNotificationFormate: productChooseNotificationFormateErr,
      });
      setHasErr(true);
    }
    return errors;
  };

  const submitProduct = () => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    const errors = validateMarketingInfo();
    if (errors.length > 0) {
    } else if (productUpdate) {
      props.updateProduct({
        id: productId,
        base_name: productName,
        base_name_slug: productBaseName,
        version_no: productVersion,
        home_url: productHomeUrl,
        demo_url: productDemoUrl,
        status: productStatus.value,
        description: productDescription,
        php_version: productPhpRequiredVersion,
        tested_php_version: productPhpTestedUpto,
        wp_version: productWordpressRequiredVersion,
        tested_version: productWordpressTestedUpto,
        mailchimp_list_id: productMailingList,
        options_to_track: productTrackingOption.value,
        require_opt_in: productAskTrackingPermission.value,
        deactivate_form: productEnableDeactivationForm.value,
        notification_format: productChooseNotificationFormate.value,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
  };

  const handelDownloadSdk = (name, basename, id) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    props.downloadSDK({
      name,
      base_name_slug: basename,
      id,
      account_type: props.accountType,
      company_id: props.activeProfile.id,
    });
  };

  const togolSecondarySideBar = (toggoleSecondarySidebar) => {
    toggoleSecondarySidebar && setSecondarySidebarCollaspe(false);
    !toggoleSecondarySidebar && setSecondarySidebarCollaspe(true);
  };

  return (
    <>
      <div className="SectionTitleWrap SectionGap">
        <button
          onClick={(e) => togolSecondarySideBar(secondarySidebarCollaspe)}
          className={`HamburgerToggler ${
            secondarySidebarCollaspe ? 'Active' : ''
          }`}
        >
          <span />
          <span />
          <span />
        </button>
        <a href="#productDetails" className="SectionTitle">
          {details.home_url}
        </a>
        {!props.detailsLoader &&
          userPermission
            .filter((p) => p.name === 'edit-product' && p.checked)
            .map((b) => (
              <button
                onClick={(e) => handleEditModal(1)}
                className="SmallButton PrimaryButton ml-auto"
              >
                <span>Edit</span>
              </button>
            ))}
      </div>
      <div className="d-flex h-100 position-relative">
        <div
          className={`SecondarySidebar ${
            secondarySidebarCollaspe ? 'Show' : ''
          }`}
        >
          <ul className="SidebarNav">
            <li className="SidebarNavItem">
              <a
                href="#productDetails"
                onClick={(e) => setSelectedOption(1)}
                className={`SidebarNavItemLink ${
                  selectedOption === 1 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Basic Info</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#productDetails"
                onClick={(e) => setSelectedOption(2)}
                className={`SidebarNavItemLink ${
                  selectedOption === 2 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Marketing Info</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#productDetails"
                onClick={(e) => setSelectedOption(3)}
                className={`SidebarNavItemLink ${
                  selectedOption === 3 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Tracking Info</div>
              </a>
            </li>
          </ul>
        </div>
        <div className="SecondarySidebarContent">
          {props.detailsLoader ? (
            <section className="FormViewSection">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '400px',
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            </section>
          ) : (
            <>
              {selectedOption === 1 && <ProductBasicInfo data={details} />}
              {selectedOption === 2 && (
                <ProductMarketingInfo data={details.marketing_info} />
              )}
              {selectedOption === 3 && (
                <ProductTrackingInfo
                  handelDownloadSdk={handelDownloadSdk}
                  data={details}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header bsPrefix="ModalHeader">
          <Modal.Title bsPrefix="modal-title">Update Product</Modal.Title>
          <button
            type="button"
            className="close ml-auto"
            data-dismiss="modal"
            onClick={handleClose}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="insight wpi-close" />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body bsPrefix="ModalBody">
          {modalStep === 1 && (
            <BasicInfoModal
              handleBasicInfo={handleBasicInfo}
              err={err}
              hasErr={hasErr}
              productUpdate={productUpdate}
              productName={productName}
              productBaseName={productBaseName}
              productVersion={productVersion}
              productHomeUrl={productHomeUrl}
              productDemoUrl={productDemoUrl}
              productStatus={productStatus}
              productDescription={productDescription}
              setProductName={setProductName}
              setProductBaseName={setProductBaseName}
              setProductVersion={setProductVersion}
              setProductHomeUrl={setProductHomeUrl}
              setProductDemoUrl={setProductDemoUrl}
              setProductStatus={setProductStatus}
              setProductDescription={setProductDescription}
              setmodalStep={setmodalStep}
              basicTabClick={basicTabClick}
              setBasicTabClick={setBasicTabClick}
              metaTabClick={metaTabClick}
              setMetaTabClick={setMetaTabClick}
              marketingTabClick={marketingTabClick}
              setMarketingTabClick={setMarketingTabClick}
            />
          )}
          {modalStep === 2 && (
            <MetaInfoModal
              setmodalStep={setmodalStep}
              handelMetaInfo={handelMetaInfo}
              productPhpRequiredVersion={productPhpRequiredVersion}
              productPhpTestedUpto={productPhpTestedUpto}
              productWordpressRequiredVersion={productWordpressRequiredVersion}
              productWordpressTestedUpto={productWordpressTestedUpto}
              setProductPhpRequiredVersion={setProductPhpRequiredVersion}
              setProductPhpTestedUpto={setProductPhpTestedUpto}
              setProductWordpressRequiredVersion={
                setProductWordpressRequiredVersion
              }
              setProductWordpressTestedUpto={setProductWordpressTestedUpto}
              err={err}
              hasErr={hasErr}
              basicTabClick={basicTabClick}
              setBasicTabClick={setBasicTabClick}
              metaTabClick={metaTabClick}
              setMetaTabClick={setMetaTabClick}
              marketingTabClick={marketingTabClick}
              setMarketingTabClick={setMarketingTabClick}
            />
          )}
          {modalStep === 3 && (
            <MarketingInfoModal
              userPermission={userPermission}
              handleMarketingInfo={handleMarketingInfo}
              setProductUpdate={setProductUpdate}
              setmodalStep={setmodalStep}
              submitProduct={submitProduct}
              productMailingList={productMailingList}
              productTrackingOption={productTrackingOption}
              productAskTrackingPermission={productAskTrackingPermission}
              productEnableDeactivationForm={productEnableDeactivationForm}
              productChooseNotificationFormate={
                productChooseNotificationFormate
              }
              setProductMailingList={setProductMailingList}
              setProductTrackingOption={setProductTrackingOption}
              setProductAskTrackingPermission={setProductAskTrackingPermission}
              setProductEnableDeactivationForm={
                setProductEnableDeactivationForm
              }
              setProductChooseNotificationFormate={
                setProductChooseNotificationFormate
              }
              err={err}
              hasErr={hasErr}
              basicTabClick={basicTabClick}
              setBasicTabClick={setBasicTabClick}
              metaTabClick={metaTabClick}
              setMetaTabClick={setMetaTabClick}
              marketingTabClick={marketingTabClick}
              setMarketingTabClick={setMarketingTabClick}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductDetails;
