export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const LOAD_PRODUCT_LIST = 'LOAD_PRODUCT_LIST';
export const RENDER_PRODUCTS_LIST = 'RENDER_PRODUCTS_LIST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';

export const LOAD_PRODUCT_FAILED = 'LOAD_PRODUCT_FAILED';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const VALIDATION_FAILED = 'VALIDATION_FAILED';

export const RESET_FIRST_TIME = 'RESET_FIRST_TIME';

export const FIND_MAILCHIMP_LIST = 'FIND_MAILCHIMP_LIST';
export const FIND_MAILCHIMP_LIST_FAILED = 'FIND_MAILCHIMP_LIST_FAILED';
export const RENDER_MAILCHIMP_LIST = 'RENDER_MAILCHIMP_LIST';

export function findMailChimpList(data) {
  return {
    type: FIND_MAILCHIMP_LIST,
    credentials: data,
  };
}

export function getAllproducts(product) {
  return {
    type: LOAD_PRODUCT_LIST,
    credentials: {
      ...product,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_REQUEST_SUCCESS,
  };
}

export function resetFirstTime() {
  return {
    type: RESET_FIRST_TIME,
  };
}

export function addProduct(product) {
  return {
    type: CREATE_PRODUCT,
    credentials: {
      ...product,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function updateProduct(product) {
  return {
    type: UPDATE_PRODUCT,
    credentials: {
      ...product,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function deleteProduct(product) {
  return {
    type: DELETE_PRODUCT,
    credentials: {
      ...product,
      token: localStorage.getItem('access_token'),
    },
  };
}
