import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_ALL_DETAILS,
  RENDER_DETAILS,
  LOAD_ALL_DETAILS_FAILED,
  LOAD_ALL_DETAILS_FOR_DEMO,
} from '../actions/SitesDetailsActions';

import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';
import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* fetchAllDetails(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/reports/site-details`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_DETAILS,
      siteDetails: data.data && data.data.siteDetails,
      logUpdate: data.data && data.data.logUpdatedInfo,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_ALL_DETAILS_FAILED,
        message: data.message,
      });
    }
  }
}
export function* fetchAllDetailsForDemo(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/demo-site-Report/site-details`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_DETAILS,
      siteDetails: data.data && data.data.siteDetails,
      logUpdate: data.data && data.data.logUpdatedInfo,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_ALL_DETAILS_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadAllDetails() {
  yield takeLatest(LOAD_ALL_DETAILS, fetchAllDetails);
}
export function* loadAllDetailsForDemo() {
  yield takeLatest(LOAD_ALL_DETAILS_FOR_DEMO, fetchAllDetailsForDemo);
}

export default function* rootSaga() {
  yield all([loadAllDetails(), loadAllDetailsForDemo()]);
}
