import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SUPER_ADMIN_LOAD_PRODUCT_DETAILS,
  SUPER_ADMIN_RENDER_PRODUCT_DETAILS,
  SUPER_ADMIN_LOAD_PRODUCT_DETAILS_FAILED,
  SUPER_ADMIN_DOWNLOAD_SDK,
  SUPER_ADMIN_DOWNLOAD_SDK_SUCCESS,
  SUPER_ADMIN_DOWNLOAD_SDK_FAILED,
} from '../actions/SuperAdminProductDetailsActions';

import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* fetchProductDetails(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/products/details`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_RENDER_PRODUCT_DETAILS,
      productDetails: data.data && data.data.productDetails,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_LOAD_PRODUCT_DETAILS_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchDownloadSdk(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/products/download-SDK`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_DOWNLOAD_SDK_SUCCESS,
      message: data.message,
      link: data && data.data && data.data.link,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_DOWNLOAD_SDK_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadProductDetails() {
  yield takeLatest(SUPER_ADMIN_LOAD_PRODUCT_DETAILS, fetchProductDetails);
}

export function* DownloadSDK() {
  yield takeLatest(SUPER_ADMIN_DOWNLOAD_SDK, fetchDownloadSdk);
}

export default function* rootSaga() {
  yield all([loadProductDetails(), DownloadSDK()]);
}
