import React from 'react';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];
const CompanyList = ({
  handleEditModal,
  companyData,
  handleMembersModal,
  handelDeleteCompany,
  userInfo,
}) => (
  <>
    <tr>
      <td>
        <p>{companyData.name}</p>
      </td>
      <td>
        <p>{companyData.userCount}</p>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {companyData.companyStatus && (
            <p
              className={`SmallButton ml-3 ${
                companyData.companyStatus === options[0].value
                  ? 'SuccessButton'
                  : 'DangerButton'
              }`}
            >
              {options
                .filter((f) => f.value === companyData.companyStatus)
                .map((option) => option.label.toUpperCase())}
            </p>
          )}
        </div>
      </td>
      <td>
        <p>{companyData.createdBy ? companyData.createdBy.name : null}</p>
      </td>
      <td className="ActionButtonColumn">
        <p className="ActionButtonGroup">
          <button
            onClick={(e) => handleEditModal(companyData.id)}
            className="ActionButton PrimaryAlert"
            title="Edit"
          >
            <span className="Icon">
              <i className="insight wpi-edit" />
            </span>
          </button>
          <button
            onClick={(e) => handleMembersModal(companyData.id)}
            className="ActionButton PrimaryAlert"
            title="members"
          >
            <span className="Icon">
              <i className="insight wpi-users" />
            </span>
          </button>
          {companyData.createdBy && companyData.createdBy.id === userInfo.id && (
            <button
              className="ActionButton DangerAlert"
              title="Delete"
              onClick={(e) => handelDeleteCompany(companyData.id)}
            >
              <span className="Icon">
                <i className="insight wpi-delete" />
              </span>
            </button>
          )}
        </p>
      </td>
    </tr>
  </>
);

export default CompanyList;
