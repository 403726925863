import { toast } from 'react-toastify';
import {
  SEND_FORGETPASS_LINK_SUCCESS,
  SEND_FORGETPASS_LINK_FAILURE,
  SEND_FORGETPASS_LINK_VALIDATION_FAILED,
} from '../actions/SendForgetPassLinkAction';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  forgetPassLink: undefined,
  validationError: [],
};

export default function forgetPassLink(state = initialState, action) {
  switch (action.type) {
    case SEND_FORGETPASS_LINK_SUCCESS:
      toast.success(action.credentials.message);
      const credentials = {
        redirect: true,
      };
      return { ...state, validationError: [], forgetPassLink: credentials };
    case SEND_FORGETPASS_LINK_FAILURE:
      toast.error(action.message);
      return { ...state, validationError: [], forgetPassLink: undefined };
    case SEND_FORGETPASS_LINK_VALIDATION_FAILED:
      return {
        ...state,
        validationError: action.errors,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
