import React from 'react';
const ResendVerification = (props) => {
  const user = props.userInfo;

  const handelResendVerification = () => {
    props.sendVerification({
      email: user.email,
    });
  };

  return (
    <div className="SecondarySidebarContent">
      <section className="FormViewSection">
        <div className="d-flex flex-column h-100">
          <div className="FormViewGroup">
            <p className="FormViewTitle">Email * :</p>
            {user.email}
          </div>
          <button
            onClick={handelResendVerification}
            className="Button PrimaryButton ml-auto"
          >
            Resend
          </button>
        </div>
      </section>
    </div>
  );
};

export default ResendVerification;
