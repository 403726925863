import { connect } from 'react-redux';
import SuperAdminAllPackages from '../../components/SuperAdmin/Packages/AllPackages';
import {
  getAllpackages,
  addPackage,
  resetRequestSuccess,
  updatePackage,
} from '../../actions/SuperAdminPackageActions';

const mapDispatchToProps = (dispatch) => ({
  allPackages: (value) => dispatch(getAllpackages(value)),
  updatePackage: (value) => dispatch(updatePackage(value)),
  addPackage: (value) => dispatch(addPackage(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
});

const mapStateToProps = (state) => ({
  packagelist: state.superAdminPackageReducer.packagelist,
  allPermissions: state.superAdminPackageReducer.allPermissions,
  currentPage: state.superAdminPackageReducer.currentPage,
  totalPage: state.superAdminPackageReducer.totalPage,
  total: state.superAdminPackageReducer.total,
  packageListLodaer: state.superAdminPackageReducer.packageListLodaer,
  requestSuccess: state.superAdminPackageReducer.requestSuccess,
  modalLoader: state.superAdminPackageReducer.modalLoader,
});

const SuperAdminPackageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminAllPackages);

export default SuperAdminPackageContainer;
