import { all, put, takeLatest } from 'redux-saga/effects';
import {
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  PASSWORD_UPDATE_VALIDATION_FAILED,
  PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILED,
  PROFILE_UPDATE_VALIDATION_FAILED,
  GET_MAILCHIMP_API,
  MAILCHIMP_API_SUCCESS,
  MAILCHIMP_API_FAILED,
  STORE_MAILCHIMP_API,
  STORE_MAILCHIMP_API_SUCCESS,
  STORE_MAILCHIMP_API_FAILED,
  GET_USER_CONFIG,
  GET_USER_CONFIG_SUCCESS,
  GET_USER_CONFIG_FAILED,
  STORE_USER_CONFIG,
  STORE_USER_CONFIG_SUCCESS,
  STORE_USER_CONFIG_FAILED,
  PROFILE_IMAGE_DELETE,
  PROFILE_IMAGE_DELETE_SUCCESS,
  PROFILE_IMAGE_DELETE_FAILED,
  GET_COMPANY_PERMISSIONS,
  GET_COMPANY_PERMISSIONS_SUCCESS,
  GET_COMPANY_PERMISSIONS_FAILED,
  UPDATE_PERMISSION_PER_ROLE,
  UPDATE_PERMISSION_PER_ROLE_SUCCESS,
  UPDATE_PERMISSION_PER_ROLE_FAILED,
  GET_MAILCHIMP_EMAILS,
  GET_MAILCHIMP_EMAILS_SUCCESS,
  GET_MAILCHIMP_EMAILS_FAILED,
  MAILCHIMP_EMAIL_SUBCRIPTION_ACTION,
  MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS,
  MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_FAILED,
  MAILCHIMP_EMAIL_CHECKBOX_ACTION,
  MAILCHIMP_EMAIL_CHECKBOX_ACTION_SUCCESS,
  MAILCHIMP_EMAIL_CHECKBOX_FAILED,
  GET_MAIL_WEBHOOK,
  GET_MAIL_WEBHOOK_SUCCESS,
  GET_MAIL_WEBHOOK_FAILED,
  UPDATE_MAIL_WEBHOOK,
  UPDATE_MAIL_WEBHOOK_SUCCESS,
  UPDATE_MAIL_WEBHOOK_FAILED,
  DELETE_MAIL_WEBHOOK,
  DELETE_MAIL_WEBHOOK_SUCCESS,
  DELETE_MAIL_WEBHOOK_FAILED,
  ADD_MAIL_WEBHOOK,
  ADD_MAIL_WEBHOOK_SUCCESS,
  ADD_MAIL_WEBHOOK_FAILED,
} from '../actions/UserSettingsActions';

import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* passwordUpdateAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/password/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;

    yield put({
      type: PASSWORD_UPDATE_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: PASSWORD_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: PASSWORD_UPDATE_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* profileUpdateAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/profile/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;

    yield put({
      type: PROFILE_UPDATE_SUCCESS,
      message: data.message,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: PROFILE_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: PROFILE_UPDATE_FAILED,
        message: data.message,
      });
    }
  }
}
export function* deleteProfileImageAttempt() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/profile/image-delete`;

  const { response, error } = yield defaultApi(endpoint, 'POST', null, true);

  if (response) {
    const { data } = response;

    yield put({
      type: PROFILE_IMAGE_DELETE_SUCCESS,
      message: data.message,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: PROFILE_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: PROFILE_IMAGE_DELETE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* mailChimpApiFetchAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-chimp`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: MAILCHIMP_API_SUCCESS,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: MAILCHIMP_API_FAILED,
        message: data.message,
      });
    }
  }
}
export function* mailChimpApiStoreAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-chimp/save`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: STORE_MAILCHIMP_API_SUCCESS,
      message: data.message,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: STORE_MAILCHIMP_API_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchUserConfig(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/configs`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: GET_USER_CONFIG_SUCCESS,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: GET_USER_CONFIG_FAILED,
        message: data.message,
      });
    }
  }
}

export function* userConfigStoreAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/configs/save`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: STORE_USER_CONFIG_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: STORE_USER_CONFIG_FAILED,
        message: data.message,
      });
    }
  }
}
export function* getCompanyPermissionsAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/company/permission/getAllRolePermissions`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: GET_COMPANY_PERMISSIONS_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: GET_COMPANY_PERMISSIONS_FAILED,
        message: data.message,
      });
    }
  }
}
export function* updateCompanyPermissionsPerRoleAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/company/permission/updatePermissionsPerRole`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_PERMISSION_PER_ROLE_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: UPDATE_PERMISSION_PER_ROLE_FAILED,
        message: data.message,
      });
    }
  }
}
export function* getMailChimpEmailsAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-chimp/getMailChimpEmails`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: GET_MAILCHIMP_EMAILS_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: GET_MAILCHIMP_EMAILS_FAILED,
        message: data.message,
      });
    }
  }
}
export function* emailSubCriptionActionAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-chimp/emailSubscriptionAction`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_FAILED,
        message: data.message,
      });
    }
  }
}
export function* getMailChimpEmailCheckboxActionAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-chimp/emailCheckboxAction`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: MAILCHIMP_EMAIL_CHECKBOX_ACTION_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: MAILCHIMP_EMAIL_CHECKBOX_FAILED,
        message: data.message,
      });
    }
  }
}
export function* getMailWebhookDataAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-webhook/get`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: GET_MAIL_WEBHOOK_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: GET_MAIL_WEBHOOK_FAILED,
        message: data.message,
      });
    }
  }
}
export function* updateMailWebhookDataAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-webhook/update`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_MAIL_WEBHOOK_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: UPDATE_MAIL_WEBHOOK_FAILED,
        message: data.message,
      });
    }
  }
}

export function* deleteMailWebhookDataAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-webhook/delete`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: DELETE_MAIL_WEBHOOK_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: DELETE_MAIL_WEBHOOK_FAILED,
        message: data.message,
      });
    }
  }
}
export function* addMailWebhookDataAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-webhook/add`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: ADD_MAIL_WEBHOOK_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: ADD_MAIL_WEBHOOK_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updatePassword() {
  yield takeLatest(PASSWORD_UPDATE, passwordUpdateAttempt);
}

export function* updateProfile() {
  yield takeLatest(PROFILE_UPDATE, profileUpdateAttempt);
}

export function* getMailChimpApi() {
  yield takeLatest(GET_MAILCHIMP_API, mailChimpApiFetchAttempt);
}

export function* StoreMailChimpApi() {
  yield takeLatest(STORE_MAILCHIMP_API, mailChimpApiStoreAttempt);
}

export function* getUserConfig() {
  yield takeLatest(GET_USER_CONFIG, fetchUserConfig);
}

export function* storeUserConfig() {
  yield takeLatest(STORE_USER_CONFIG, userConfigStoreAttempt);
}
export function* deleteProfileImage() {
  yield takeLatest(PROFILE_IMAGE_DELETE, deleteProfileImageAttempt);
}
export function* getCompanyPermissions() {
  yield takeLatest(GET_COMPANY_PERMISSIONS, getCompanyPermissionsAttempt);
}
export function* updateCompanyPermissionsPerRole() {
  yield takeLatest(
    UPDATE_PERMISSION_PER_ROLE,
    updateCompanyPermissionsPerRoleAttempt
  );
}
export function* getMailChimpEmails() {
  yield takeLatest(GET_MAILCHIMP_EMAILS, getMailChimpEmailsAttempt);
}
export function* getMailChimpEmailCheckboxAction() {
  yield takeLatest(
    MAILCHIMP_EMAIL_CHECKBOX_ACTION,
    getMailChimpEmailCheckboxActionAttempt
  );
}
export function* emailSubCriptionAction() {
  yield takeLatest(
    MAILCHIMP_EMAIL_SUBCRIPTION_ACTION,
    emailSubCriptionActionAttempt
  );
}

export function* getMailWebhookData() {
  yield takeLatest(GET_MAIL_WEBHOOK, getMailWebhookDataAttempt);
}
export function* updateMailWebhookData() {
  yield takeLatest(UPDATE_MAIL_WEBHOOK, updateMailWebhookDataAttempt);
}
export function* deleteMailWebhookData() {
  yield takeLatest(DELETE_MAIL_WEBHOOK, deleteMailWebhookDataAttempt);
}

export function* addMailWebhookData() {
  yield takeLatest(ADD_MAIL_WEBHOOK, addMailWebhookDataAttempt);
}

export default function* rootSaga() {
  yield all([
    updatePassword(),
    updateProfile(),
    getMailChimpApi(),
    StoreMailChimpApi(),
    getUserConfig(),
    storeUserConfig(),
    deleteProfileImage(),
    getCompanyPermissions(),
    updateCompanyPermissionsPerRole(),
    getMailChimpEmails(),
    emailSubCriptionAction(),
    getMailChimpEmailCheckboxAction(),
    getMailWebhookData(),
    updateMailWebhookData(),
    deleteMailWebhookData(),
    addMailWebhookData(),
  ]);
}
