import { all, put, takeLatest } from 'redux-saga/effects';
import {
  REGISTER,
  ALL_PACKAGES,
  ALL_PACKAGES_SUCCESS,
  ALL_PACKAGES_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_VALIDATION_FAILED,
} from '../actions/RegistrationActions';

import { defaultApi } from '../utils/axiosApi';

export function* registrationAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/register`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);

  if (response) {
    yield put({
      type: REGISTER_SUCCESS,
      credentials: response && response.data,
    });
  } else if (error) {
    const { data } = error;
    if (data.status === 'validation_error') {
      yield put({
        type: REGISTER_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: REGISTER_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* allPackages() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/all-packages`;

  const { response, error } = yield defaultApi(endpoint, 'GET');

  if (response) {
    yield put({
      type: ALL_PACKAGES_SUCCESS,
      allPackages: response && response.data && response.data.packages,
    });
  } else if (error) {
    yield put({
      type: ALL_PACKAGES_FAILURE,
    });
  }
}

export function* regiter() {
  yield takeLatest(REGISTER, registrationAttempt);
}

export function* getAllPackages() {
  yield takeLatest(ALL_PACKAGES, allPackages);
}

export default function* rootSaga() {
  yield all([regiter(), getAllPackages()]);
}
