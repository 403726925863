import React from 'react';

const SitesAdditionalInfo = (props) => {
  const details = props.data;
  return (
    <>
      <section className="ListViewSection">
        <ul className="ListView">
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitlePrimary">
                Site Name
              </div>
              <p className="Alert PrimaryAlert">{details.site_name}</p>
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent w-100">
              <div className="ListViewContentTitle ListViewContentTitleSuccess">
                Theme
              </div>
              <div className="AlertGroup">
                <p className="Alert SuccessAlert Active">{details.theme}</p>
              </div>
            </div>
          </li>
          <li className="ListViewItem HasMultipleContent">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleWarning">
                Server
              </div>
              <p className="Alert WarningAlert">{details.server}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleDanger">
                Site Language
              </div>
              <p className="Alert DangerAlert">{details.site_language}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleInfo">
                Text Decoration
              </div>
              <p className="Alert InfoAlert">{details.text_direction}</p>
            </div>
          </li>
          <li className="ListViewItem HasMultipleContent">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleLove">
                Multi Site
              </div>
              <p className="Alert LoveAlert">{details.multisite}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleYellow">
                Marketing Method
              </div>
              <p className="Alert YellowAlert">{details.marketing_method}</p>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default SitesAdditionalInfo;
