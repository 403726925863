export const LOAD_PRODUCT_DETAILS = 'LOAD_PRODUCT_DETAILS';
export const RENDER_PRODUCT_DETAILS = 'RENDER_PRODUCT_DETAILS';
export const LOAD_PRODUCT_DETAILS_FAILED = 'LOAD_PRODUCT_DETAILS_FAILED';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const RESET_PAGE = 'RESET_PAGE';

export const DOWNLOAD_SDK = 'DOWNLOAD_SDK';
export const RESET_DOWNLOAD_SDK = 'RESET_DOWNLOAD_SDK';
export const DOWNLOAD_SDK_SUCCESS = 'DOWNLOAD_SDK_SUCCESS';
export const DOWNLOAD_SDK_FAILED = 'DOWNLOAD_SDK_FAILED';

export function fetchProductDetails(data) {
  return {
    type: LOAD_PRODUCT_DETAILS,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function downloadSDK(data) {
  return {
    type: DOWNLOAD_SDK,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function resetSdkLink() {
  return {
    type: RESET_DOWNLOAD_SDK,
  };
}
