import { toast } from 'react-toastify';
import {
  RESEND_VERIFICATION_SUCCESS,
  RESEND_VERIFICATION_FAILURE,
  RESEND_VERIFICATION_VALIDATION_FAILED,
} from '../actions/resendVerificationAction';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  resendVerification: undefined,
  validationError: [],
};

export default function resendVerification(state = initialState, action) {
  switch (action.type) {
    case RESEND_VERIFICATION_SUCCESS:
      toast.success(action.message);
      const credentials = {
        redirect: true,
      };
      return { ...state, validationError: [], resendVerification: credentials };
    case RESEND_VERIFICATION_VALIDATION_FAILED:
      return {
        ...state,
        validationError: action.errors,
      };
    case RESEND_VERIFICATION_FAILURE:
      toast.error(action.message);
      return { ...state, resendVerification: undefined };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
