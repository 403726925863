import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import profileImg from '../../assets/img/dummy-profile.png';
import routes from '../../routes';
import {
  switchToAccount,
  resetSiginRedirect,
} from '../../actions/HeaderActions';

import { signOut } from '../../actions/index';
import { resetAccessRemoved } from '../../actions/index';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const user = props.user;
    const accountType = props.accountType;
    const activeProfile = props.activeProfile;
    const access_removed = props.accessRemoved;
    this.state = {
      user: user,
      accountType: accountType,
      activeProfile: activeProfile,
      activeRoute: routes.find(
        (r) => r.layout + r.path === props.location.pathname
      ),
      is_dropDown_open: false,
      accessRemoved: access_removed,
    };
    this.navDropDownRef = React.createRef();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.switchSuccess !== prevProps.switchSuccess &&
      this.props.switchSuccess
    ) {
      this.props.history.push('/user/dashboard');
      this.props.resetRedirect();
    }
    document.addEventListener('mousedown', this.handelNavDropDownChange, false);
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        activeRoute: routes.find(
          (r) => r.layout + r.path === this.props.location.pathname
        ),
        is_dropDown_open: false,
      });
    }
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user,
      });
    }
    if (this.props.accountType !== prevProps.accountType) {
      this.setState({
        accountType: this.props.accountType,
      });
    }
    if (this.props.accessRemoved !== prevProps.accessRemoved) {
      this.props.resetAccessRemoved();
      this.props.history.push('/user/dashboard');
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handelNavDropDownChange,
      false
    );
  }

  handelNavDropDownChange = (e) => {
    if (this.navDropDownRef.contains(e.target)) {
      return;
    }
    this.handelOutsideNavDropdownClick();
  };

  handelOutsideNavDropdownClick() {
    this.setState({ is_dropDown_open: false });
  }
  changeAccount(info) {
    this.setState({ is_dropDown_open: false });
    this.props.switchAccount({
      company_id: info.id,
    });
  }

  signOut = () => {
    localStorage.removeItem('access_token');
    this.props.userSignOut();
    this.props.history.push('/auth/sign-in');
  };
  goToSettings = () => {
    this.setState({ is_dropDown_open: false });
    this.props.activeProfile && this.props.activeProfile.type === 'SUPER_ADMIN'
      ? this.props.history.push('/user/admin_user/settings')
      : this.props.history.push('/user/settings');
  };

  toggleDropdown = (e) => {
    if (this.state.is_dropDown_open) {
      this.setState({ is_dropDown_open: false });
    } else {
      this.setState({ is_dropDown_open: true });
    }
  };

  render() {
    const { activeProfile, allProfile: teamInfo, accountType } = this.props;
    return (
      <header className="ContentAreaHeader" style={{ zIndex: '999px' }}>
        <div className="HeaderWrapper">
          <div className="ContentAreaHeaderLeft">
            <h3 className="SectionTitle">{this.state.activeRoute.name}</h3>
          </div>
          <div className="ContentAreaHeaderRight ml-auto">
            <Dropdown
              className="dropdown ProfileControl"
              onClick={this.toggleDropdown}
              show={this.state.is_dropDown_open}
              ref={(node) => (this.navDropDownRef = node)}
            >
              <Dropdown.Toggle
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="ProfileControlButton"
                variant="outline-light"
                id="profile-dropdown"
              >
                <div className="ProfileImage">
                  <img
                    src={
                      this.state.user && this.state.user.image
                        ? this.state.user.image
                        : profileImg
                    }
                    alt=""
                  />
                </div>
                <div className="ProfileDetails">
                  <h4 className="UserName">
                    {this.state.user &&
                      this.state.user.name &&
                      this.state.user.name.split(' ')[0][0].toUpperCase() +
                        this.state.user.name.split(' ')[0].slice(1)}
                  </h4>
                  {accountType !== 'SUPER_ADMIN' ? (
                    <h4 className="CompanyName">
                      {activeProfile ? activeProfile.name : ''}
                    </h4>
                  ) : (
                    <h4 className="CompanyName">( SUPER ADMIN )</h4>
                  )}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                show={this.state.is_dropDown_open}
                className={`dropdown-menu-right`}
              >
                <ul>
                  {teamInfo &&
                    teamInfo.map((info, index) => {
                      return (
                        <React.Fragment key={index}>
                          <li className="DropdownItem" key={index}>
                            <div
                              className="DropdownItemLink"
                              onClick={() => this.changeAccount(info)}
                            >
                              <div className="navigation-image">
                                <img src={profileImg} alt="" />
                              </div>
                              <p className="navigation-text">{info.name}</p>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  <li></li>
                  <li>
                    <button onClick={this.goToSettings}>
                      <div className="navigation-icon">
                        <i className="insight wpi-user"></i>
                      </div>
                      <p className="navigation-text">My Account</p>
                    </button>
                  </li>
                  <li>
                    <button onClick={this.signOut}>
                      <div className="navigation-icon">
                        <i className="insight wpi-logout"></i>
                      </div>
                      <p className="navigation-text">Logout</p>
                    </button>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeProfile: state.auth.signin && state.auth.signin.activeProfile,
    allProfile: state.auth.signin && state.auth.signin.allProfile,
    switchSuccess: state.auth.accountSwitchSuccess,
    user: state.auth.signin && state.auth.signin.userInfo,
    accountType: state.auth.signin && state.auth.signin.accountType,
    accessRemoved: state.auth && state.auth.accessRemoved,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchAccount: (value) => dispatch(switchToAccount(value)),
    resetRedirect: () => dispatch(resetSiginRedirect()),
    userSignOut: () => dispatch(signOut()),
    resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
