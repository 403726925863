import { connect } from 'react-redux';
import SignInForm from '../components/Auth/SignInForm';
import { signin } from '../actions/SigninActions';

const mapStateToProps = (state) => ({
  signin: state.auth.signin,
  signInLoader: state.auth.signInLoader,
  validationError: state.auth.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  userSignIn: (value) => dispatch(signin(value)),
});

const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInForm);

export default SignIn;
