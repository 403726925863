export const SUPER_ADMIN_PASSWORD_UPDATE = 'SUPER_ADMIN_PASSWORD_UPDATE';
export const SUPER_ADMIN_PASSWORD_UPDATE_SUCCESS =
  'SUPER_ADMIN_PASSWORD_UPDATE_SUCCESS';
export const SUPER_ADMIN_PASSWORD_UPDATE_FAILURE =
  'SUPER_ADMIN_PASSWORD_UPDATE_FAILURE';

export const SUPER_ADMIN_PASSWORD_UPDATE_VALIDATION_FAILED =
  'SUPER_ADMIN_PASSWORD_UPDATE_VALIDATION_FAILED';

export const SUPER_ADMIN_PROFILE_UPDATE = 'SUPER_ADMIN_PROFILE_UPDATE';
export const SUPER_ADMIN_PROFILE_UPDATE_SUCCESS =
  'SUPER_ADMIN_PROFILPROFILE_UPDATE_SUCCESSE_UPDATE';
export const SUPER_ADMIN_PROFILE_UPDATE_FAILED =
  'SUPER_ADMIN_PROFILE_UPDATE_FAILED';

export const SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED =
  'SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED';

export const SUPER_ADMIN_RESET_SETTINGS_REQUEST_SUCCESS =
  'SUPER_ADMIN_RESET_SETTINGS_REQUEST_SUCCESS';

export const SUPER_ADMIN_PLACEHOLDER_FILE = 'SUPER_ADMIN_PLACEHOLDER_FILE';

export const SUPER_ADMIN_PLACEHOLDER_FILE_SUCCESS =
  'SUPER_ADMIN_PLACEHOLDER_FILE_SUCCESS';

export const SUPER_ADMIN_PLACEHOLDER_FILE_FAILED =
  'SUPER_ADMIN_PLACEHOLDER_FILE_FAILED';

export const SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE =
  'SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE';
export const SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_SUCCESS =
  'SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_SUCCESS';
export const SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_FAILED =
  'SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_FAILED';
export const SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_VALIDATION_FAILED =
  'SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_VALIDATION_FAILED';

export const GET_SUPER_ADMIN_USER_CONFIG = 'GET_SUPER_ADMIN_USER_CONFIG';
export const GET_SUPER_ADMIN_USER_CONFIG_SUCCESS =
  'GET_SUPER_ADMIN_USER_CONFIG_SUCCESS';
export const GET_SUPER_ADMIN_USER_CONFIG_FAILED =
  'GET_SUPER_ADMIN_USER_CONFIG_FAILED';

export const STORE_SUPER_ADMIN_USER_CONFIG = 'STORE_SUPER_ADMIN_USER_CONFIG';
export const STORE_SUPER_ADMIN_USER_CONFIG_SUCCESS =
  'STORE_SUPER_ADMIN_USER_CONFIG_SUCCESS';
export const STORE_SUPER_ADMIN_USER_CONFIG_FAILED =
  'STORE_SUPER_ADMIN_USER_CONFIG_FAILED';

export const SUPER_ADMIN_PROFILE_IMAGE_DELETE =
  'SUPER_ADMIN_PROFILE_IMAGE_DELETE';
export const SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS =
  'SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS';
export const SUPER_ADMIN_PROFILE_IMAGE_DELETE_FAILED =
  'SUPER_ADMIN_PROFILE_IMAGE_DELETE_FAILED';

export function passwordUpdate(data) {
  return {
    type: SUPER_ADMIN_PASSWORD_UPDATE,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function profileUpdate(data) {
  return {
    type: SUPER_ADMIN_PROFILE_UPDATE,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function deleteSuperAdminUserProfileImage() {
  return {
    type: SUPER_ADMIN_PROFILE_IMAGE_DELETE,
  };
}

export function resetRequestSuccess() {
  return {
    type: SUPER_ADMIN_RESET_SETTINGS_REQUEST_SUCCESS,
  };
}

export function updatePlaceHolderFile(data) {
  return {
    type: SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE,
    credentials: data,
  };
}

export function getSuperAdminUserConfig() {
  return {
    type: GET_SUPER_ADMIN_USER_CONFIG,
  };
}
export function storeSuperAdminUserConfigs(data) {
  return {
    type: STORE_SUPER_ADMIN_USER_CONFIG,
    credentials: {
      ...data,
    },
  };
}

export function getPlaceHolderFile() {
  return {
    type: SUPER_ADMIN_PLACEHOLDER_FILE,
  };
}
