import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SUPER_ADMIN_LOAD_PRODUCT_LIST,
  SUPER_ADMIN_RENDER_PRODUCTS_LIST,
  SUPER_ADMIN_LOAD_PRODUCT_FAILED,
} from '../actions/SuperAdminProductsActions';
import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* fetchProductList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/products/all`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_RENDER_PRODUCTS_LIST,
      productData: data.data && data.data.productData,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      allProducts: data.data && data.data.allProductList,
      allCompanyList: data.data && data.data.allCompanies,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_LOAD_PRODUCT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadProductList() {
  yield takeLatest(SUPER_ADMIN_LOAD_PRODUCT_LIST, fetchProductList);
}

export default function* rootSaga() {
  yield all([loadProductList()]);
}
