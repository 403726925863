export const SUPER_ADMIN_LOAD_REPORT = 'SUPER_ADMIN_LOAD_REPORT';
export const SUPER_ADMIN_RENDER_REPORT = 'SUPER_ADMIN_RENDER_REPORT';
export const SUPER_ADMIN_LOAD_REPORT_FAILED = 'SUPER_ADMIN_LOAD_REPORT_FAILED';

export const SUPER_ADMIN_LOAD_STRESS_REPORT = 'SUPER_ADMIN_LOAD_STRESS_REPORT';
export const SUPER_ADMIN_RENDER_STRESS_REPORT =
  'SUPER_ADMIN_RENDER_STRESS_REPORT';
export const SUPER_ADMIN_LOAD_REPORT_STRESS_FAILED =
  'SUPER_ADMIN_LOAD_REPORT_STRESS_FAILED';

export const SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE =
  'SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE';
export const SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS =
  'SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS';
export const SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED =
  'SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED';

export function getAllReport(data) {
  return {
    type: SUPER_ADMIN_LOAD_REPORT,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function stressReport(data) {
  return {
    type: SUPER_ADMIN_LOAD_STRESS_REPORT,
    credentials: data,
  };
}

export function storeUserConfigsFromSitesTable(data) {
  return {
    type: SUPER_ADMIN_STORE_USER_CONFIG_FROM_SITES_TABLE,
    credentials: data,
  };
}
