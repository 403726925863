import React from 'react';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

const options = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const notificationOptions = [
  { value: '1', label: 'Option 1' },
  { value: '2', label: 'Option 2' },
  { value: '3', label: 'Option 3' },
];

const MarketingInfoModal = ({
  handelMarketingInfo,
  submitProduct,
  setmodalStep,
  productMailingList,
  productTrackingOption,
  productAskTrackingPermission,
  productEnableDeactivationForm,
  productChooseNotificationFormate,
  setProductMailingList,
  setProductTrackingOption,
  setProductAskTrackingPermission,
  setProductEnableDeactivationForm,
  setProductChooseNotificationFormate,
  setProductproductUpdate,
  hasErr,
  err,
  basicTabClick,
  setBasicTabClick,
  metaTabClick,
  setMetaTabClick,
  marketingTabClick,
  setMarketingTabClick,
  loading,
  mailChimpList,
  modalLoader,
  submitProductBtn,
  productUpdate,
}) => {
  const mailChimpVariable = mailChimpList || [];

  const maillingListOptions = [
    { value: '', label: 'Select MailChimp List' },
    ...mailChimpVariable.map((list) => {
      const newArray = {};
      newArray.label = list.name;
      newArray.value = list.id;
      return newArray;
    }),
  ];
  return (
    <>
      <div className="ModalControlGroup">
        <div className="ModalTabControllers">
          <button
            onClick={() => (basicTabClick ? setmodalStep(1) : 'return false;')}
            className="ModalTabControl Passed"
          >
            Basic Info
          </button>
          <button
            onClick={() => (metaTabClick ? setmodalStep(2) : 'return false;')}
            className="ModalTabControl Passed"
          >
            Meta Info
          </button>
          <button
            onClick={() =>
              marketingTabClick ? setmodalStep(3) : 'return false;'
            }
            className="ModalTabControl Active"
          >
            Marketing Info
          </button>
        </div>
        <div className="progress">
          <div className="progress-bar" style={{ width: '100%' }} />
        </div>
      </div>
      <div className="ModalTabContent">
        <div className="TabContentRow">
          <label>Mailing List</label>
          <div
            style={{
              height: '100%',
              width: '100%',
              // textAlign:
              //   !mailChimpList || mailChimpList.length === 0 ? 'right' : '',
            }}
          >
            {!submitProductBtn && modalLoader ? (
              <button className="Button SemiButtonDanger ml-auto">
                <Spinner animation="border" variant="dark" />
              </button>
            ) : (
              <>
                {mailChimpList && mailChimpList.length > 0 ? (
                  <Select
                    className="SelectOption basic-single"
                    classNamePrefix="react-select"
                    isSearchable
                    id="productMailingList"
                    onChange={(e) => setProductMailingList(e)}
                    options={maillingListOptions}
                    value={maillingListOptions.find(
                      ({ value }) => value === productMailingList.value
                    )}
                  />
                ) : productUpdate ? (
                  <Link
                    style={{ display: 'inline-block' }}
                    to="/user/settings?subMenu=MailChimpApi"
                  >
                    <button className="Button SemiButtonDanger ml-auto">
                      <span>Add/Update MailChimp API Key</span>
                    </button>
                  </Link>
                ) : (
                  <span style={{ color: 'red' }}>
                    No MailChimp Api Key Found
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        {err && err.productMailingList && err.productMailingList.length > 0 && (
          <div className="TabContentRow">
            <label />
            <p style={{ color: 'red' }} key={productMailingList}>
              {err.productMailingList}
            </p>
          </div>
        )}
        <div className="TabContentRow">
          <label>Tracking Option*</label>
          <div style={{ height: '100%', width: '100%' }} className="">
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              id="productTrackingOption"
              onChange={(e) => setProductTrackingOption(e)}
              options={options}
              isSearchable={false}
              value={options.find(
                ({ value }) => value === productTrackingOption.value
              )}
            />
          </div>
        </div>
        {err &&
          err.productTrackingOption &&
          err.productTrackingOption.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={productTrackingOption}>
                {err.productTrackingOption}
              </p>
            </div>
          )}
        <div className="TabContentRow">
          <label>Ask Tracking Permission*</label>
          <div style={{ height: '100%', width: '100%' }} className="">
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              id="productAskTrackingPermission"
              onChange={(e) => setProductAskTrackingPermission(e)}
              options={options}
              isSearchable={false}
              value={options.find(
                ({ value }) => value === productAskTrackingPermission.value
              )}
            />
          </div>
        </div>
        {err &&
          err.productAskTrackingPermission &&
          err.productAskTrackingPermission.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={productAskTrackingPermission}>
                {err.productAskTrackingPermission}
              </p>
            </div>
          )}
        <div className="TabContentRow">
          <label>Enable Deactivation Form*</label>
          <div style={{ height: '100%', width: '100%' }} className="">
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              id="productEnableDeactivationForm"
              onChange={(e) => setProductEnableDeactivationForm(e)}
              options={options}
              isSearchable={false}
              value={options.find(
                ({ value }) => value === productEnableDeactivationForm.value
              )}
            />
          </div>
        </div>
        {err &&
          err.productEnableDeactivationForm &&
          err.productEnableDeactivationForm.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={productEnableDeactivationForm}>
                {err.productEnableDeactivationForm}
              </p>
            </div>
          )}
        <div className="TabContentRow">
          <label>Notification Format*</label>
          <div style={{ height: '100%', width: '100%' }} className="">
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              id="productChooseNotificationFormate"
              onChange={(e) => setProductChooseNotificationFormate(e)}
              options={notificationOptions}
              isSearchable={false}
              value={notificationOptions.find(
                ({ value }) => value === productChooseNotificationFormate.value
              )}
            />
          </div>
        </div>
        {err &&
          err.productChooseNotificationFormate &&
          err.productChooseNotificationFormate.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p
                style={{ color: 'red' }}
                key={productChooseNotificationFormate}
              >
                {err.productChooseNotificationFormate}
              </p>
            </div>
          )}
        <div className="d-flex mt-5">
          <button
            onClick={() => setmodalStep(2)}
            className="Button SemiButtonPrimary"
          >
            <span>Previous</span>
          </button>
          <button
            onClick={() => submitProduct()}
            className="Button PrimaryButton ml-auto"
          >
            <span>
              {submitProductBtn && modalLoader ? (
                <Spinner animation="border" variant="light" />
              ) : (
                'Submit'
              )}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MarketingInfoModal;
