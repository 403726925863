import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SEND_FORGETPASS_LINK,
  SEND_FORGETPASS_LINK_SUCCESS,
  SEND_FORGETPASS_LINK_FAILURE,
  SEND_FORGETPASS_LINK_VALIDATION_FAILED,
} from '../actions/SendForgetPassLinkAction';

import { defaultApi } from '../utils/axiosApi';

export function* sendForgetPassLinkAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/password-reset-link`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);

  if (response) {
    yield put({
      type: SEND_FORGETPASS_LINK_SUCCESS,
      credentials: response && response.data,
    });
  } else if (error) {
    const { data } = error;
    if (data.status === 'validation_error') {
      yield put({
        type: SEND_FORGETPASS_LINK_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: SEND_FORGETPASS_LINK_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* sendForgetPassLink() {
  yield takeLatest(SEND_FORGETPASS_LINK, sendForgetPassLinkAttempt);
}

export default function* rootSaga() {
  yield all([sendForgetPassLink()]);
}
