export const USER_VERIFY = 'USER_VERIFY';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';
export const USER_VERIFY_USERNAME_NOT_FOUND = 'USER_VERIFY_USERNAME_NOT_FOUND';
export const USER_VERIFY_COMPANY_NOT_FOUND = 'USER_VERIFY_COMPANY_NOT_FOUND';

export function checkUserVerify(data) {
  return {
    type: USER_VERIFY,
    credentials: {
      ...data,
    },
  };
}
