import React from 'react';
import Pagination from 'rc-pagination';

const SitesActivePluginInfo = (props) => {
  const plugin = props.data;
  return (
    <>
      <section className="DetailsViewSection">
        <div className="DetailsView">
          <div className="DetailsViewHeading" />
          <div className="DetailsViewContent">
            <div className="row HasColGap">
              {plugin &&
                plugin.length > 0 &&
                plugin.map((d, i) => (
                  <div key={i} className="col-md-4">
                    <p className="Alert PrimaryAlert">{d}</p>
                  </div>
                ))}
            </div>
            {props.total > 18 && (
              <div className="d-flex mt-auto">
                <Pagination
                  className="PaginationList ml-auto mt-4"
                  total={props.total}
                  current={props.currentPage}
                  pageSize={18}
                  onChange={props.handelActivePluginPageChange}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SitesActivePluginInfo;
