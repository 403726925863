import { connect } from 'react-redux';
import Onboard from '../components/Auth/Onboard';
import { makeUserOnboard } from '../actions/OnboardActions';

const mapStateToProps = (state) => ({
  key: state.onboard.key,
  onboard: state.onboard.onboard,
  validationError: state.onboard.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  userOnboard: (value) => dispatch(makeUserOnboard(value)),
});

const userOnboard = connect(mapStateToProps, mapDispatchToProps)(Onboard);

export default userOnboard;
