import React, { useEffect } from 'react';

/* eslint-disable */
const UserVerify = (props) => {
  useEffect(() => {
    const token = props.match.params;
    props.checkUser({
      key: token.userKey,
    });
  }, []);

  useEffect(() => {
    const token = props.match.params;
    if (
      token &&
      props.verifyUser &&
      props.verifyUser.redirect &&
      props.verifyUser.redirect_to === 'onboard-form'
    ) {
      props.history.push({
        pathname: '/auth/onboard-form/',
        search: '?key=' + token.userKey,
      });
    } else if (
      token &&
      props.verifyUser &&
      props.verifyUser.redirect &&
      props.verifyUser.redirect_to === 'login'
    ) {
      props.history.push('/auth/sign-in');
    } else if (token && props.verifyUser && !props.verifyUser.redirect) {
      props.history.push('/auth/sign-in');
    }
  }, [props.verifyUser, props.history]);
  /* eslint-enable */

  return <></>;
};

export default UserVerify;
