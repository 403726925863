import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];

const UserModal = ({
  name,
  setName,
  err,
  submitCompany,
  loading,
  setCompanyStatus,
  companyStatus,
  modalLoader,
}) => (
  <>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label>Name*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Company name"
          value={name}
          id="name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {err && err.name && err.name.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={name}>
            {err.name}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Status*</label>
        <div style={{ width: '100%' }}>
          <Select
            className="SelectOption basic-single"
            classNamePrefix="react-select"
            isSearchable={false}
            id="companyStatus"
            onChange={(e) => setCompanyStatus(e)}
            options={options}
            value={options.find(
              (option) => option.value === companyStatus.value
            )}
          />
        </div>
      </div>
      {err && err.companyStatus && err.companyStatus.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={companyStatus}>
            {err.companyStatus}
          </p>
        </div>
      )}
      <div className="d-flex mt-5">
        <button
          className="Button PrimaryButton ml-auto"
          onClick={submitCompany}
        >
          <span>
            {modalLoader ? (
              <Spinner animation="border" variant="light" />
            ) : (
              'Submit'
            )}
          </span>
        </button>
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  modalLoader: state.superAdminCompanies.modalLoader,
});

export default connect(mapStateToProps)(UserModal);
