import { toast } from 'react-toastify';
import {
  LOAD_SUPER_ADMIN_COMPANY_LIST,
  RENDER_SUPER_ADMIN_COMPANY_LIST,
  LOAD_SUPER_ADMIN_COMPANY_FAILED,
  RESET_SUPER_ADMIN_COMPANY_REQUEST_SUCCESS,
  UPDATE_SUPER_ADMIN_COMPANY,
  UPDATE_SUPER_ADMIN_COMPANY_SUCCESS,
  UPDATE_SUPER_ADMIN_COMPANY_FAILED,
  CREATE_SUPER_ADMIN_COMPANY,
  CREATE_SUPER_ADMIN_COMPANY_SUCCESS,
  CREATE_SUPER_ADMIN_COMPANY_FAILED,
  UPDATE_SUPER_ADMIN_COMPANY_MEMBERS,
  UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_SUCCESS,
  UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_FAILED,
  SUPER_ADMIN_COMPANY_DELETE,
  SUPER_ADMIN_COMPANY_DELETE_SUCCESS,
  SUPER_ADMIN_COMPANY_DELETE_FAILED,
} from '../actions/SuperAdminCompanyActions';

import { SIGNED_OUT } from '../actions/index';

const initialState = {
  companyList: [],
  companyMembers: {},
  companyMembersCount: [],
  alluser: [],
  currentPage: 1,
  totalPage: 1,
  total: 0,
  requestSuccess: false,
  companyListLoader: false,
  modalLoader: false,
  validationError: [],
};

export default function teamMember(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUPER_ADMIN_COMPANY_LIST:
      return {
        ...state,
        companyListLoader: true,
      };
    case UPDATE_SUPER_ADMIN_COMPANY:
      return {
        ...state,
        modalLoader: true,
      };
    case SUPER_ADMIN_COMPANY_DELETE:
      return {
        ...state,
        modalLoader: true,
      };
    case SUPER_ADMIN_COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };
    case SUPER_ADMIN_COMPANY_DELETE_FAILED:
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case CREATE_SUPER_ADMIN_COMPANY:
      return {
        ...state,
        modalLoader: true,
      };
    case UPDATE_SUPER_ADMIN_COMPANY_MEMBERS:
      return {
        ...state,
        modalLoader: true,
      };
    case RENDER_SUPER_ADMIN_COMPANY_LIST:
      return {
        ...state,
        companyList: action.companyList,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        companyListLoader: false,
        companyMembers: action.companyMembers,
        companyMembersCount: action.companyMembersCount,
        allUser: action.allUser,
      };

    case RESET_SUPER_ADMIN_COMPANY_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
      };
    case LOAD_SUPER_ADMIN_COMPANY_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        companyListLoader: false,
      };
    case CREATE_SUPER_ADMIN_COMPANY_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };
    case UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };
    case UPDATE_SUPER_ADMIN_COMPANY_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };

    case UPDATE_SUPER_ADMIN_COMPANY_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case CREATE_SUPER_ADMIN_COMPANY_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
