import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import Select from 'react-select';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../assets/css/react_dates_overrides.css';

import '../../assets/css/reportCustomStyle.css';

import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { LightenDarkenColor } from '../../utils/helper';

import SitesReport from './SitesReport';
import ProductReport from '../Product/ProductReport';

const d = new Date();
d.setDate(d.getDate() - 7);

const tableColumnNames = [
  { isChecked: true, name: 'url', lable: 'URL' },
  { isChecked: true, name: 'since', lable: 'Since' },
  { isChecked: true, name: 'deactivated', lable: 'Deactivated' },
  { isChecked: true, name: 'pluginVersion', lable: 'Version' },
  { isChecked: true, name: 'language', lable: 'Language' },
  { isChecked: true, name: 'alexaRank', lable: 'Alexa Rank' },
  { isChecked: true, name: 'status', lable: 'Status' },
  { isChecked: true, name: 'plugin_Name', lable: 'Plugin' },
  { isChecked: true, name: 'updatedTime', lable: 'Updated Time' },
  { isChecked: true, name: 'phpVersion', lable: 'PHP Version' },
  { isChecked: true, name: 'wpInsVersion', lable: 'WPIns' },
  { isChecked: true, name: 'siteVersion', lable: 'Site Version' },
];

const Report = (props) => {
  const toggolerRef = useRef();

  const exportDropDownRef = useRef();
  const [showExportDropDown, setShowExportDropDown] = useState(false);

  const [siteSortBy, setSiteSortBy] = useState({
    original: 'updated_at',
    by: 'updatedTime',
    type: 'DESC',
  });

  const [selectAll, setSelectAll] = useState(false);
  const [allTimeDateFilter, setAllTimeDateFilter] = useState(false);

  const [startDate, setStartDate] = useState(new Date(d));
  const [endDate, setEndDate] = useState(new Date());
  const [focusedInput, setFocusedInput] = useState(null);
  const [dateDiff, setDateDiff] = useState(
    moment(endDate).diff(startDate, 'days')
  );
  const [selectedReport, setSelectedReport] = useState(null);
  const [searchSiteUrl, setSearchSiteUrl] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState({
    value: 'all',
    label: 'All',
  });
  const [selectedWpIns, setSelectedWpIns] = useState({
    value: null,
    label: 'Select WPIns version',
  });
  const [selectedPhpVersion, setSelectedPhpVersion] = useState({
    value: null,
    label: 'Select PHP version',
  });
  const [selectedWpVersion, setSelectedWpVersion] = useState({
    value: null,
    label: 'Select WP version',
  });
  const [siteListData, setSiteListData] = useState([]);

  const [showAllFilter, setShowAllFilter] = useState(false);
  const [tableColumnToggoler, setTableColumnToggoler] = useState(false);

  const [siteListShowColumn, setSiteListShowColumn] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [reportType, setReportType] = useState('');

  const productSelectoptions = [
    { value: 'all', label: 'All' },
    ...props.allProducts.map((Product) => {
      const newArray = {};
      newArray.value = Product.base_name_slug;
      newArray.label = Product.base_name;
      return newArray;
    }),
  ];

  const phpVersionSelectoptions = [
    {
      value: null,
      label: 'Select PHP version',
    },
    ...props.allPhpVersion.map((Product) => {
      const newArray = {};
      newArray.value = Product.version;
      newArray.label = Product.version;
      return newArray;
    }),
  ];

  const WpInsVersionSelectoptions = [
    {
      value: null,
      label: 'Select WPIns version',
    },
    ...props.allWpInsVersion.map((Product) => {
      const newArray = {};
      newArray.value = Product.version;
      newArray.label = Product.version;
      return newArray;
    }),
  ];

  const WordpressVersionSelectoptions = [
    {
      value: null,
      label: 'Select WP version',
    },
    ...props.allWordpressVersion.map((Product) => {
      const newArray = {};
      newArray.value = Product.version;
      newArray.label = Product.version;
      return newArray;
    }),
  ];
  const [oldFilterData, setOldFilterData] = useState({
    search_product: selectedProduct && selectedProduct.value,
    from_date: `${startDate.getFullYear()}-${
      startDate.getMonth() + 1
    }-${startDate.getDate()}`,
    to_date: `${endDate.getFullYear()}-${
      endDate.getMonth() + 1
    }-${endDate.getDate()}`,
    search_wp_version: selectedWpVersion.value,
    search_php_version: selectedPhpVersion.value,
    search_wpIns_version: selectedWpIns.value,
    search: searchSiteUrl,
    sortBy: siteSortBy,
    per_page: perPage,
  });
  /* eslint-disable */

  useEffect(() => {
    if (
      props.sitesConfig &&
      props.sitesConfig.siteListTable &&
      props.sitesConfig.siteListTable.show
    ) {
      setSiteListShowColumn(props.sitesConfig.siteListTable.show);
    } else {
      setSiteListShowColumn(tableColumnNames);
    }
  }, [props.sitesConfig]);

  useEffect(() => {
    if (props.sitesSort) {
      let by;
      if (
        props.sitesSort.by === 'since' ||
        props.sitesSort.by === 'created_at'
      ) {
        by = 'since';
      }
      if (
        props.sitesSort.by === 'updatedTime' ||
        props.sitesSort.by === 'updated_at'
      ) {
        by = 'updatedTime';
      }
      if (
        props.sitesSort.by === 'version' ||
        props.sitesSort.by === 'pluginVersion'
      ) {
        by = 'pluginVersion';
      }
      if (
        props.sitesSort.by === 'php_version' ||
        props.sitesSort.by === 'phpVersion'
      ) {
        by = 'phpVersion';
      }
      if (
        props.sitesSort.by === 'wpins_version' ||
        props.sitesSort.by === 'wpInsVersion'
      ) {
        by = 'wpInsVersion';
      }
      if (
        props.sitesSort.by === 'site_version' ||
        props.sitesSort.by === 'siteVersion'
      ) {
        by = 'siteVersion';
      }
      if (
        props.sitesSort.by === 'globalRank' ||
        props.sitesSort.by === 'alexaRank'
      ) {
        by = 'alexaRank';
      }

      setSiteSortBy({
        original: by,
        by: props.sitesSort.by,
        type: props.sitesSort.type,
      });
    } else {
      if (
        props.sitesConfig &&
        props.sitesConfig.siteListTable &&
        props.sitesConfig.siteListTable.sort
      ) {
        let by;
        if (props.sitesConfig.siteListTable.sort.by === 'since') {
          by = 'created_at';
        }
        if (props.sitesConfig.siteListTable.sort.by === 'updatedTime') {
          by = 'updated_at';
        }
        if (props.sitesConfig.siteListTable.sort.by === 'pluginVersion') {
          by = 'version';
        }
        if (props.sitesConfig.siteListTable.sort.by === 'phpVersion') {
          by = 'php_version';
        }
        if (props.sitesConfig.siteListTable.sort.by === 'wpInsVersion') {
          by = 'wpins_version';
        }
        if (props.sitesConfig.siteListTable.sort.by === 'siteVersion') {
          by = 'site_version';
        }
        if (props.sitesConfig.siteListTable.sort.by === 'alexaRank') {
          by = 'globalRank';
        }

        setSiteSortBy({
          original: props.sitesConfig.siteListTable.sort.by,
          by: by,
          type: props.sitesConfig.siteListTable.sort.type,
        });
      }
    }
  }, [props.sitesSort]);

  useEffect(() => {
    const desiredReport = queryString.parse(props.location.search);
    setSelectedReport(desiredReport.report);

    if (desiredReport.product) {
      const prevSelectedProduct = props.allProducts.filter(
        (f) => f.base_name_slug === desiredReport.product
      );

      prevSelectedProduct &&
      prevSelectedProduct.length > 0 &&
      props.allProducts &&
      props.allProducts.length > 0
        ? setSelectedProduct({
            value: prevSelectedProduct[0].base_name_slug,
            label: prevSelectedProduct[0].base_name,
          })
        : setSelectedProduct({
            value: desiredReport.product,
            label: desiredReport.product,
          });
    }
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.allReportForDemo({
        page: 1,
        search_product:
          desiredReport && desiredReport.product
            ? desiredReport.product
            : selectedProduct.value,
        per_page: perPage,
        from_date:
          startDate.getFullYear() +
          '-' +
          (startDate.getMonth() + 1) +
          '-' +
          startDate.getDate(),
        to_date:
          endDate.getFullYear() +
          '-' +
          (endDate.getMonth() + 1) +
          '-' +
          endDate.getDate(),
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
      });
    } else {
      props.allReport({
        page: 1,
        search_product:
          desiredReport && desiredReport.product
            ? desiredReport.product
            : selectedProduct.value,
        per_page: perPage,
        from_date:
          startDate.getFullYear() +
          '-' +
          (startDate.getMonth() + 1) +
          '-' +
          startDate.getDate(),
        to_date:
          endDate.getFullYear() +
          '-' +
          (endDate.getMonth() + 1) +
          '-' +
          endDate.getDate(),
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
      });
    }
    document.addEventListener('mousedown', handelTableColumnToggolerChange);
    document.addEventListener('mousedown', handelExportDropDownToggolerChange);

    return () => {
      document.removeEventListener(
        'mousedown',
        handelTableColumnToggolerChange
      );
      document.removeEventListener(
        'mousedown',
        handelExportDropDownToggolerChange
      );
    };
  }, []);

  const handelTableColumnToggolerChange = (e) => {
    if (
      Object.keys(toggolerRef).length > 0 &&
      toggolerRef.current &&
      !toggolerRef.current.contains(e.target)
    ) {
      setTableColumnToggoler(false);
    }
  };

  const handelExportDropDownToggolerChange = (e) => {
    if (
      Object.keys(exportDropDownRef).length > 0 &&
      exportDropDownRef.current &&
      !exportDropDownRef.current.contains(e.target)
    ) {
      setShowExportDropDown(false);
    }
  };

  const toggleExportDropdown = (e) => {
    if (showExportDropDown) {
      setShowExportDropDown(false);
    } else {
      setShowExportDropDown(true);
    }
  };

  useEffect(() => {
    if (props.allProducts && props.allProducts.length > 0) {
      const desiredReport = queryString.parse(props.location.search);
      setSelectedReport(desiredReport.report);

      if (desiredReport.product) {
        const prevSelectedProduct = props.allProducts.filter(
          (f) => f.base_name_slug === desiredReport.product
        );
        prevSelectedProduct &&
          prevSelectedProduct.length > 0 &&
          setSelectedProduct({
            value: prevSelectedProduct[0].base_name_slug,
            label: prevSelectedProduct[0].base_name,
          });
      }
    }
  }, [props.allProducts]);

  useEffect(() => {
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.requestSuccess &&
        props.allReportForDemo({
          page: props.currentPage,
          per_page: perPage,
          search_product: selectedProduct.value,
          from_date:
            startDate.getFullYear() +
            '-' +
            (startDate.getMonth() + 1) +
            '-' +
            startDate.getDate(),
          to_date:
            endDate.getFullYear() +
            '-' +
            (endDate.getMonth() + 1) +
            '-' +
            endDate.getDate(),
          sortBy: siteSortBy,
          account_type: props.accountType,
          company_id: props.activeProfile.id,
          sites_config:
            siteListShowColumn && siteListShowColumn.length > 0
              ? siteListShowColumn
              : null,
        });
    } else {
      props.requestSuccess &&
        props.allReport({
          page: props.currentPage,
          per_page: perPage,
          search_product: selectedProduct.value,
          from_date:
            startDate.getFullYear() +
            '-' +
            (startDate.getMonth() + 1) +
            '-' +
            startDate.getDate(),
          to_date:
            endDate.getFullYear() +
            '-' +
            (endDate.getMonth() + 1) +
            '-' +
            endDate.getDate(),
          sortBy: siteSortBy,
          account_type: props.accountType,
          company_id: props.activeProfile.id,
          sites_config:
            siteListShowColumn && siteListShowColumn.length > 0
              ? siteListShowColumn
              : null,
        });
    }

    props.requestSuccess && setReportType('');
  }, [props.requestSuccess]);

  useEffect(() => {
    if (props.siteList.length > 0) {
      var dataArray = props.siteList.map((list) => {
        var newArray = {};
        newArray.id = list.id;
        newArray.url = list.url;
        newArray.since = list.created_at
          ? moment(list.created_at).format('YYYY-MM-DD HH:mm')
          : null;
        newArray.deactivated = list.deactivated_date
          ? moment(new Date(list.deactivated_date * 1000)).format(
              'YYYY-MM-DD HH:mm'
            )
          : null;
        newArray.pluginVersion = list.version;
        newArray.language = list.site_language;
        newArray.alexaRank = list.globalRank;
        newArray.status = list.status;
        newArray.baseNameSlug = list.plugin_slug;
        newArray.plugin_Name = list.plugin;
        newArray.updatedTime = list.updated_at
          ? moment(list.updated_at).format('YYYY-MM-DD HH:mm')
          : null;
        newArray.phpVersion = list.php_version;
        newArray.wpInsVersion = list.wpins_version;
        newArray.siteVersion = list.site_version;
        newArray.isChecked = false;
        return newArray;
      });
      setSiteListData(dataArray);
    } else {
      setSiteListData([]);
    }

    selectedProduct &&
      selectedProduct.value !== 'all' &&
      setTableColumnToggoler(false);
    setSelectAll(false);
  }, [props.siteList]);

  useEffect(() => {
    const desiredReport = queryString.parse(props.history.location.search);
    setSelectedReport(desiredReport.report);
  }, [props.history.location.search]);

  useEffect(() => {
    let start = moment(startDate);
    let end = moment(endDate);
    setDateDiff(end.diff(start, 'days'));
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.accessRemoved) {
      props.resetAccessRemoved();
      props.history.push('/user/dashboard');
    }
  }, [props.accessRemoved]);

  useEffect(() => {
    if (props.companyNotFound) {
      props.resetCompanyNotFound();
      props.history.push('/auth/create-company-form');
    }
  }, [props.companyNotFound]);

  /* eslint-enable */

  const handleSelectedReportChange = (selectedReport) => {
    props.history.push({
      pathname: '/user/Reports',
      search: `?report=${selectedReport}`,
    });
  };

  const SitesDetailsData = (baseNameSlug, siteUrl) => {
    props.history.push({
      pathname: '/user/sites-details',
      search: `?product=${baseNameSlug}&url=${siteUrl}`,
    });
  };

  const handelPageChange = (e) => {
    setTableColumnToggoler(false);
    setReportType('site_list');
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.allReportForDemo({
        ...oldFilterData,
        search_product: selectedProduct.value,
        page: e,
        search:
          searchSiteUrl && searchSiteUrl.length > 0 ? searchSiteUrl : null,
        per_page: perPage,
        sortBy: siteSortBy,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        type_report: reportType && reportType ? reportType : 'site_list',
      });
    } else {
      props.allReport({
        ...oldFilterData,
        search_product: selectedProduct.value,
        page: e,
        search:
          searchSiteUrl && searchSiteUrl.length > 0 ? searchSiteUrl : null,
        per_page: perPage,
        sortBy: siteSortBy,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        type_report: reportType && reportType ? reportType : 'site_list',
      });
    }
  };

  const handelSearch = (e) => {
    setTableColumnToggoler(false);
    setReportType('site_list');
    if (e.key === 'Enter') {
      let role = '';
      if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
        role = props.rolePermissionInfo[0].name;
      }
      setOldFilterData({ ...oldFilterData, search: searchSiteUrl });
      if (role === 'Demo_User') {
        props.allReportForDemo({
          ...oldFilterData,
          search_product: selectedProduct.value,
          search: searchSiteUrl,
          sortBy: siteSortBy,
          per_page: perPage,
          account_type: props.accountType,
          company_id: props.activeProfile.id,
          sites_config:
            siteListShowColumn && siteListShowColumn.length > 0
              ? siteListShowColumn
              : null,
          type_report: reportType && reportType ? reportType : 'site_list',
        });
      } else {
        props.allReport({
          ...oldFilterData,
          search_product: selectedProduct.value,
          search: searchSiteUrl,
          sortBy: siteSortBy,
          per_page: perPage,
          account_type: props.accountType,
          company_id: props.activeProfile.id,
          sites_config:
            siteListShowColumn && siteListShowColumn.length > 0
              ? siteListShowColumn
              : null,
          type_report: reportType && reportType ? reportType : 'site_list',
        });
      }
    }
  };

  const handelSearchSiteUrl = (e) => {
    setSearchSiteUrl(e.target.value);
    setTableColumnToggoler(false);
    setReportType('site_list');
    if (e.target.value === '') {
      let role = '';
      if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
        role = props.rolePermissionInfo[0].name;
      }
      setOldFilterData({ ...oldFilterData, search: null });
      if (role === 'Demo_User') {
        props.allReportForDemo({
          ...oldFilterData,
          search_product: selectedProduct.value,
          search: null,
          sortBy: siteSortBy,
          per_page: perPage,
          account_type: props.accountType,
          company_id: props.activeProfile.id,
          sites_config:
            siteListShowColumn && siteListShowColumn.length > 0
              ? siteListShowColumn
              : null,
          type_report: reportType && reportType ? reportType : 'site_list',
        });
      } else {
        props.allReport({
          ...oldFilterData,
          search_product: selectedProduct.value,
          search: null,
          sortBy: siteSortBy,
          per_page: perPage,
          account_type: props.accountType,
          company_id: props.activeProfile.id,
          sites_config:
            siteListShowColumn && siteListShowColumn.length > 0
              ? siteListShowColumn
              : null,
          type_report: reportType && reportType ? reportType : 'site_list',
        });
      }
    }
  };

  const handleSearchReport = (e) => {
    e.preventDefault();
    setTableColumnToggoler(false);
    setOldFilterData({
      search_product: selectedProduct.value,
      from_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      to_date: `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`,
      search_wp_version: selectedWpVersion.value,
      search_php_version: selectedPhpVersion.value,
      search_wpIns_version: selectedWpIns.value,
      search: searchSiteUrl,
      sortBy: siteSortBy,
      per_page: perPage,
    });
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.allReportForDemo({
        search_product: selectedProduct.value,
        from_date: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        to_date: `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`,
        search_wp_version: selectedWpVersion.value,
        search_php_version: selectedPhpVersion.value,
        search_wpIns_version: selectedWpIns.value,
        search: searchSiteUrl,
        sortBy: siteSortBy,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        per_page: perPage,
      });
    } else {
      props.allReport({
        search_product: selectedProduct.value,
        from_date: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        to_date: `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`,
        search_wp_version: selectedWpVersion.value,
        search_php_version: selectedPhpVersion.value,
        search_wpIns_version: selectedWpIns.value,
        search: searchSiteUrl,
        sortBy: siteSortBy,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        per_page: perPage,
      });
    }

    const desiredReport = queryString.parse(props.location.search);
    desiredReport.product &&
      desiredReport.product !== selectedProduct.value &&
      props.history.push('/user/Reports?report=sites_reports');
    setReportType('');
  };

  const handelTableColumnToggoler = (e) => {
    e.preventDefault();
    if (tableColumnToggoler) {
      setTableColumnToggoler(false);
    } else {
      setTableColumnToggoler(true);
    }
  };

  const toggoleFilter = (e) => {
    e.preventDefault();
    setTableColumnToggoler(false);
    if (showAllFilter) {
      setSelectedPhpVersion({
        value: null,
        label: 'Select PHP version',
      });
      setSelectedWpIns({
        value: null,
        label: 'Select WPIns version',
      });
      setShowAllFilter(false);
    } else {
      setShowAllFilter(true);
    }
  };

  const changePageSize = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
    setTableColumnToggoler(false);
    setReportType('site_list');
    setOldFilterData({ ...oldFilterData, per_page: perPage });
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.allReportForDemo({
        ...oldFilterData,
        search:
          searchSiteUrl && searchSiteUrl.length > 0 ? searchSiteUrl : null,
        search_product: selectedProduct.value,
        per_page: e.target.value,
        sortBy: siteSortBy,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        type_report: reportType && reportType ? reportType : 'site_list',
      });
    } else {
      props.allReport({
        ...oldFilterData,
        search:
          searchSiteUrl && searchSiteUrl.length > 0 ? searchSiteUrl : null,
        search_product: selectedProduct.value,
        per_page: e.target.value,
        sortBy: siteSortBy,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        type_report: reportType && reportType ? reportType : 'site_list',
      });
    }
  };

  const handelCheckBox = (e) => {
    const { name } = e.target;
    const { checked } = e.target;
    const baseName = e.target.id;
    setTableColumnToggoler(false);

    if (name === 'checkAll') {
      setSelectAll(checked);
      const list = siteListData.map((item) => ({
        ...item,
        isChecked: checked,
      }));
      setSiteListData(list);
    } else {
      const list = siteListData.map((item) =>
        item.id === parseInt(name) && item.baseNameSlug === baseName
          ? { ...item, isChecked: checked }
          : item
      );
      setSiteListData(list);
      setSelectAll(siteListData.every((list) => list.isChecked));
    }
  };

  const handleColumnShowCheckbox = (e, name, checked) => {
    const list = siteListShowColumn.map((item) =>
      item.name === name ? { ...item, isChecked: checked } : item
    );
    setSiteListShowColumn(list);
    props.storeUserConfigsFromSitesTable({
      siteListTable: list,
      sortConfig: [siteSortBy.original, siteSortBy.type],
      account_type: props.accountType,
      company_id: props.activeProfile.id,
    });
  };

  const exportData = (e, pageOption) => {
    setTableColumnToggoler(false);
    e.preventDefault();
    const row_selected = siteListData.some((item) => item.isChecked === true);
    let list;
    if (row_selected) {
      list = siteListData.filter((item) => item.isChecked === true);
    } else {
      list = siteListData;
    }

    const newOptions = siteListShowColumn
      .filter((item) => item.isChecked === true)
      .map((item) => item.name);

    props.exportListData({
      sitelist: list,
      options: newOptions,
      pageOption,
      filterOption: oldFilterData,
      account_type: props.accountType,
      company_id: props.activeProfile.id,
      request_from: 'report',
    });
  };

  const changeSiteSortBy = (column, order) => {
    setTableColumnToggoler(false);
    let by;
    if (column === 'since') {
      by = 'created_at';
    }
    if (column === 'updatedTime') {
      by = 'updated_at';
    }
    if (column === 'pluginVersion') {
      by = 'version';
    }
    if (column === 'phpVersion') {
      by = 'php_version';
    }
    if (column === 'wpInsVersion') {
      by = 'wpins_version';
    }
    if (column === 'siteVersion') {
      by = 'site_version';
    }
    if (column === 'alexaRank') {
      by = 'globalRank';
    }
    setSiteSortBy({
      original: by,
      by: column,
      type: order,
    });
    setReportType('site_list');
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.allReportForDemo({
        ...oldFilterData,
        sortBy: {
          by,
          type: order,
        },
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        type_report: reportType && reportType ? reportType : 'site_list',
      });
    } else {
      props.allReport({
        ...oldFilterData,
        sortBy: {
          by,
          type: order,
        },
        account_type: props.accountType,
        company_id: props.activeProfile.id,
        sites_config:
          siteListShowColumn && siteListShowColumn.length > 0
            ? siteListShowColumn
            : null,
        type_report: reportType && reportType ? reportType : 'site_list',
      });
    }
  };

  const getCalendarInfo = () => {
    setTableColumnToggoler(false);
    return (
      <div className="date-range-footer">
        <div className="p-3">
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 1 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 1
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              const date = new Date();
              setEndDate(moment(date).subtract(1, 'days').toDate());
              setStartDate(moment(date).subtract(1, 'days').toDate());
            }}
          >
            Yesterday
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 0 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 0
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              const date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(0, 'days').toDate());
            }}
          >
            Today
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 7 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 7
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              const date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(7, 'days').toDate());
            }}
          >
            Last 7 days
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 15 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 15
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              const date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(15, 'days').toDate());
            }}
          >
            Last 15 days
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 30 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 30
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              const date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(30, 'days').toDate());
            }}
          >
            Last 30 days
          </Button>
          {/* <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 60 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 60
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(60, 'days').toDate());
            }}
          >
            Last 60 days
          </Button> */}
        </div>
      </div>
    );
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let i;
    const years = [];
    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>
      );
    }
    return (
      <div
        className="monthSelector"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '15px',
        }}
      >
        <div>
          <select
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {years}
          </select>
        </div>
      </div>
    );
  };

  const handleBlur = () => {
    setTimeout(() => {
      setTableColumnToggoler(false);
    }, 500);
  };
  const handelChangeAllTimeDateFilter = (e) => {
    const { checked } = e.target;
    setAllTimeDateFilter(checked);
    setStartDate(new Date(1970, 0, 1, 0, 0, 1));
    setEndDate(new Date());
    if (!checked) {
      const d = new Date();
      d.setDate(d.getDate() - 7);
      setStartDate(new Date(d));
      setEndDate(new Date());
    }
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewFilter">
          <form action="">
            <div className="row HasColGap">
              <div className="Col-30">
                <div className="FormGroup">
                  <label htmlFor="">Product</label>
                  <Select
                    id="search_product"
                    onChange={(e) => setSelectedProduct(e)}
                    onFocus={(e) => setTableColumnToggoler(false)}
                    options={productSelectoptions}
                    defaultValue={productSelectoptions[0]}
                    value={selectedProduct}
                    className="SelectOption"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="Col-30">
                <div className="FormGroup">
                  <div className="d-flex justify-content-between">
                    <label>Date Filter</label>
                    <div>
                      <label className="Checkbox">
                        <input
                          type="checkbox"
                          checked={allTimeDateFilter}
                          onChange={(e) => handelChangeAllTimeDateFilter(e)}
                        />
                        <span> All Time</span>
                      </label>
                    </div>
                  </div>

                  <DateRangePicker
                    isOutsideRange={(endDate) =>
                      !isInclusivelyBeforeDay(endDate, moment())
                    }
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={moment(startDate)}
                    endDate={moment(endDate)}
                    displayFormat="YYYY-MM-DD"
                    onDatesChange={({ startDate, endDate }) => {
                      startDate && setStartDate(startDate.toDate());
                      endDate && setEndDate(endDate.toDate());
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                      setFocusedInput(focusedInput);
                    }}
                    renderCalendarInfo={() => getCalendarInfo()}
                    hideKeyboardShortcutsPanel
                    minimumNights={0}
                    renderMonthElement={renderMonthElement}
                    disabled={allTimeDateFilter}
                  />
                </div>
              </div>
              <div className="Col-20">
                <div className="FormGroup">
                  <label htmlFor="">WordPress Version</label>
                  <Select
                    options={WordpressVersionSelectoptions}
                    onChange={(e) => setSelectedWpVersion(e)}
                    onFocus={(e) => setTableColumnToggoler(false)}
                    defaultValue={WordpressVersionSelectoptions[0]}
                    className="SelectOption"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="Col-15">
                <div className="FormGroup">
                  <button
                    onClick={(e) => toggoleFilter(e)}
                    className="Button SemiButtonBorderColor"
                  >
                    <div className="Icon">
                      <i
                        className={`insight ${
                          showAllFilter ? 'wpi-minus' : 'wpi-plus'
                        }`}
                      />
                    </div>
                    <span>{showAllFilter ? 'Less Filter' : 'More Filter'}</span>
                  </button>
                </div>
              </div>
              {showAllFilter && (
                <>
                  <div className="Col-20">
                    <div className="FormGroup">
                      <label htmlFor="">PHP Version</label>
                      <Select
                        name=""
                        id=""
                        onChange={(e) => setSelectedPhpVersion(e)}
                        onFocus={(e) => setTableColumnToggoler(false)}
                        defaultValue={phpVersionSelectoptions[0]}
                        options={phpVersionSelectoptions}
                        className="SelectOption"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="Col-20">
                    <div className="FormGroup">
                      <label htmlFor="">WPIns Version</label>
                      <Select
                        name=""
                        id=""
                        options={WpInsVersionSelectoptions}
                        onChange={(e) => setSelectedWpIns(e)}
                        onFocus={(e) => setTableColumnToggoler(false)}
                        defaultValue={WpInsVersionSelectoptions[0]}
                        className="SelectOption"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="Col-15 ml-auto">
                <div className="FormGroup">
                  <button
                    onClick={handleSearchReport}
                    className="Button PrimaryButton ml-auto"
                  >
                    <span>Filter</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="DataViewHeading">
          <div className="TabCard">
            <div
              onClick={() => handleSelectedReportChange('sites_reports')}
              className={`TabControl ${
                selectedReport === 'sites_reports' && 'Active'
              }`}
            >
              Site List
            </div>
            <div
              onClick={() => handleSelectedReportChange('other_reports')}
              className={`TabControl ${
                selectedReport === 'other_reports' && 'Active'
              }`}
            >
              Product Reports
            </div>
          </div>
          <div className="DataViewHeadingRightContent">
            {selectedReport === 'sites_reports' && (
              <div className="SearchForm">
                <input
                  type="text"
                  className="form-control"
                  onKeyPress={handelSearch}
                  onChange={(e) => handelSearchSiteUrl(e)}
                  placeholder="Search"
                  value={searchSiteUrl}
                />
              </div>
            )}
            {selectedReport === 'sites_reports' && (
              <Dropdown
                className="dropdown DropdownControl"
                onClick={() => toggleExportDropdown()}
                show={showExportDropDown}
                ref={exportDropDownRef}
              >
                <Dropdown.Toggle
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="ml-3 Button PrimaryButton"
                  id="profile-dropdown"
                >
                  <div className="Icon">
                    <i className="insight wpi-share" />
                  </div>
                  <span>Export</span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  show={showExportDropDown}
                  className="dropdown-menu-right"
                >
                  <ul>
                    <li>
                      <button onClick={(e) => exportData(e, 'this')}>
                        <p className="navigation-text">
                          Selected from this page
                        </p>
                      </button>
                    </li>
                    <li>
                      <button onClick={(e) => exportData(e, 'all')}>
                        <p className="navigation-text">All from above filter</p>
                      </button>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="TabContent">
          <>
            {selectedReport === 'sites_reports' ? (
              <SitesReport
                data={siteListData}
                total={props.total}
                currentPage={props.currentPage}
                perPage={perPage}
                handelPageChange={handelPageChange}
                tableColumnToggoler={tableColumnToggoler}
                handelTableColumnToggoler={handelTableColumnToggoler}
                SitesDetailsData={SitesDetailsData}
                setPerPage={setPerPage}
                changePageSize={changePageSize}
                handelCheckBox={handelCheckBox}
                selectAll={selectAll}
                reportLoader={props.reportLoader}
                siteSortBy={siteSortBy}
                setSiteSortBy={setSiteSortBy}
                changeSiteSortBy={changeSiteSortBy}
                siteListShowColumn={siteListShowColumn}
                handleColumnShowCheckbox={handleColumnShowCheckbox}
                handleBlur={handleBlur}
                toggolerRef={toggolerRef}
                handelTableColumnToggolerChange={
                  handelTableColumnToggolerChange
                }
              />
            ) : (
              <ProductReport
                name={oldFilterData.label}
                activeUser={props.activeSitesCount}
                inActiveUser={props.deactivedSitesCount}
                totalUserCount={props.totalUserCount}
                newUserCount={props.newUserCount}
                userPerPuginVersion={props.pluginVersion}
                userPerPhpVersion={props.phpVersion}
                userPerLaguages={props.language}
                userPerWordpressVersion={props.wpVersion}
                userPerStatus={props.status}
                userDeactivedReasons={props.de_active_reasons}
                analytics={props.analytics}
                mailchimpAnalytics={props.mailchimpAnalytics}
                reportLoader={props.reportLoader}
                selectedProduct={selectedProduct}
                reportType={reportType}
              />
            )}
          </>
        </div>
      </section>
    </>
  );
};

export default Report;
