import { toast } from 'react-toastify';
import {
  APPLY_FOR_DEMO_SITE,
  APPLY_FOR_DEMO_SITE_SUCCESS,
  APPLY_FOR_DEMO_SITE_FAILURE,
  APPLY_FOR_DEMO_SITE_VALIDATION_FAILED,
} from '../actions/DemoSiteApplyAction';

const initialState = {
  demoSiteApplied: false,
  loader: false,
  validationError: [],
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case APPLY_FOR_DEMO_SITE:
      return {
        ...state,
        loader: true,
      };
    case APPLY_FOR_DEMO_SITE_SUCCESS:
      toast.success(action.credentials.message);
      return {
        ...state,
        demoSiteApplied: true,
        validationError: [],
        loader: false,
      };
    case APPLY_FOR_DEMO_SITE_FAILURE:
      toast.error(action.message);
      return {
        ...state,
        demoSiteApplied: false,
        validationError: [],
        loader: false,
      };
    case APPLY_FOR_DEMO_SITE_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        loader: false,
        demoSiteApplied: false,
        validationError: action.errors,
      };

    default:
      return state;
  }
}
