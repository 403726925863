import React from 'react';
import moment from 'moment';

const SitesBasicInfo = (props) => {
  const details = props.data;
  return (
    <>
      <section className="ListViewSection">
        <ul className="ListView">
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitlePrimary">
                Plugin Slug
              </div>
              <p className="Alert PrimaryAlert">{details.plugin_slug}</p>
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleSuccess">
                URL
              </div>
              <a href={details.url} rel="noreferrer noopener" target="_blank">
                <p className="Alert SuccessAlert">{details.url}</p>
              </a>
            </div>
          </li>
          <li className="ListViewItem HasMultipleContent">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleWarning">
                WordPress Version
              </div>
              <p className="Alert WarningAlert">{details.site_version}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleDanger">
                PHP Version
              </div>
              <p className="Alert DangerAlert">{details.php_version}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleInfo">
                WPins Version
              </div>
              <p className="Alert InfoAlert">{details.wpins_version}</p>
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleLove">
                File Location
              </div>
              <p className="Alert LoveAlert">{details.file_location}</p>
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleSuccess">
                Alexa Rank
              </div>
              <p className="Alert SuccessAlert">{details.globalRank}</p>
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleYellow">
                Email
              </div>
              <p className="Alert YellowAlert">{details.email}</p>
            </div>
          </li>
          <li className="ListViewItem HasMultipleContent">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleWarning">
                MailChimp Status
              </div>
              <p className="Alert WarningAlert">{details.mailchimp_status}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleLove">
                MailChimp Response
              </div>
              <p className="Alert LoveAlert">{details.mailchimp_response}</p>
            </div>
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleDanger">
                MailChimp Updated
              </div>
              <p className="Alert DangerAlert">
                {details.mailchimp_updated_at
                  ? moment(details.mailchimp_updated_at).format(
                      'YYYY-MM-DD HH:mm'
                    )
                  : ''}
              </p>
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleSuccess">
                Last Status
              </div>
              <p className="Alert SuccessAlert">{details.status}</p>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default SitesBasicInfo;
