export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_VALIDATION_FAILED = 'SIGN_IN_VALIDATION_FAILED';

export const ACTIVE_COMPANY_NOT_FOUND = 'ACTIVE_COMPANY_NOT_FOUND';

export function signin({ email, password }) {
  return {
    type: SIGN_IN,
    credentials: {
      email,
      password,
    },
  };
}
