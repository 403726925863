import { all, fork } from 'redux-saga/effects';
import signInSaga from './SignInSaga';
import demoSiteApplySaga from './DemoSiteApply';
import sendForgetPassLinkSaga from './SendForgetPassLinkSaga';
import resendVerificationSaga from './ResendVerificationSaga';
import productSaga from './ProductsSaga';
import teamMemberSaga from './TeamMemberSaga';
import companySaga from './CompanySaga';
import reportSaga from './ReportSaga';
import siteDetailsSaga from './SitesDetailsSaga';
import productDetailsSaga from './ProductDetailsSaga';
import registrationSaga from './RegistrationSaga';
import onboardSaga from './OnboardSaga';
import verifyUserSaga from './VerifyUserSaga';
import userSettingsSaga from './userSettingsSaga';
import resetPassSaga from './ResetPasswordSaga';
import dashboardSaga from './DashboardSaga';
import headerSaga from './HeaderSaga';
import superAdminUsersSaga from './SuperAdminUsersSaga';
import superAdminProductsSaga from './SuperAdminProductsSaga';
import superAdminProductDetailsSaga from './SuperAdminProductDetailsSaga';
import superAdminReportsSaga from './SuperAdminReportsSaga';
import superAdminSitesDetailsaSaga from './SuperAdminSitesDetailsSaga';
import superAdminSettingsSaga from './SuperAdminSettingsSaga';
import superAdminCompaniesSaga from './SuperAdminCompaniesSaga';
import superAdminPackageSaga from './SuperAdminPackageSaga';

export default function* root() {
  yield all([
    fork(signInSaga),
    fork(demoSiteApplySaga),
    fork(sendForgetPassLinkSaga),
    fork(resendVerificationSaga),
    fork(productSaga),
    fork(companySaga),
    fork(teamMemberSaga),
    fork(reportSaga),
    fork(siteDetailsSaga),
    fork(productDetailsSaga),
    fork(registrationSaga),
    fork(onboardSaga),
    fork(verifyUserSaga),
    fork(userSettingsSaga),
    fork(resetPassSaga),
    fork(dashboardSaga),
    fork(headerSaga),
    fork(superAdminUsersSaga),
    fork(superAdminProductsSaga),
    fork(superAdminProductDetailsSaga),
    fork(superAdminReportsSaga),
    fork(superAdminSitesDetailsaSaga),
    fork(superAdminSettingsSaga),
    fork(superAdminCompaniesSaga),
    fork(superAdminPackageSaga),
  ]);
}
