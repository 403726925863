import { connect } from 'react-redux';
import ResendVerification from '../components/Common/resendVerificationFromPanel';
import { resendVerificationLinkFromPanel } from '../actions/resendVerificationAction';

const mapStateToProps = (state) => ({
  resendVerification: state.resendVerification.resendVerification,
  userInfo: state.auth.signin && state.auth.signin.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  sendVerification: (value) => dispatch(resendVerificationLinkFromPanel(value)),
});

const ResendVerificationFromUserPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendVerification);

export default ResendVerificationFromUserPanel;
