import { connect } from 'react-redux';
import Product from '../components/Product/Product';
import {
  getAllproducts,
  addProduct,
  updateProduct,
  deleteProduct,
  resetRequestSuccess,
  resetFirstTime,
  findMailChimpList,
} from '../actions/productsActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapDispatchToProps = (dispatch) => ({
  allProducts: (value) => dispatch(getAllproducts(value)),
  productObj: (value) => dispatch(addProduct(value)),
  updateProduct: (value) => dispatch(updateProduct(value)),
  deleteProduct: (value) => dispatch(deleteProduct(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  resetFirstTime: () => dispatch(resetFirstTime()),
  findMailChimpList: (value) => dispatch(findMailChimpList(value)),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
});

const mapStateToProps = (state) => ({
  productlist: state.productList.productlist,
  currentPage: state.productList.currentPage,
  totalPage: state.productList.totalPage,
  total: state.productList.total,
  userCount: state.productList.userCount,
  requestSuccess: state.productList.requestSuccess,
  validationError: state.productList.validationError,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  mailChimpList: state.productList.mailChimpList,
  firstTime: state.productList.firstTime,
  productlistLoader: state.productList.productlistLoader,
  modalLoader: state.productList.modalLoader,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,
  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const ProductContainer = connect(mapStateToProps, mapDispatchToProps)(Product);

export default ProductContainer;
