import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ productData }) {
  return (
    <>
      <tr>
        <td>
          <p>{productData.productName}</p>
        </td>
        <td>
          <p>{productData.ActiveSites}</p>
        </td>
        <td>
          <p>{productData.DeactivatedSites}</p>
        </td>
        <td>
          <div className="d-flex">
            <p
              className={`SmallButton ${
                productData.productStatus === 'Active'
                  ? 'SuccessButton'
                  : 'DangerButton'
              }`}
            >
              {productData.productStatus}
            </p>
          </div>
        </td>
        <td>
          <p className="ActionButtonGroup">
            <Link
              to={`/user/admin_user/product-details?product=${productData.productBaseName}`}
            >
              <button className="ActionButton InfoAlert" title="View">
                <span className="Icon">
                  <i className="insight wpi-eye" />
                </span>
              </button>
            </Link>
            <Link
              to={`/user/admin_user/Reports?report=sites_reports&product=${productData.productBaseName}`}
            >
              <button className="ActionButton WarningAlert" title="Chart">
                <span className="Icon">
                  <i className="insight wpi-chart" />
                </span>
              </button>
            </Link>
          </p>
        </td>
      </tr>
    </>
  );
}
