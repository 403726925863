export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const LOAD_USER_LIST = 'LOAD_USER_LIST';
export const RENDER_USER_LIST = 'RENDER_USER_LIST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const RESET_USER_REQUEST_SUCCESS = 'RESET_USER_REQUEST_SUCCESS';
export const RESET_USER_PAGE = 'RESET_USER_PAGE';

export const LOAD_USER_FAILED = 'UPDATE_USER_FAILED';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const LOAD_USER_TEAM_MEMBER_FAILED = 'LOAD_USER_TEAM_MEMBER_FAILED';
export const LOAD_USER_TEAM_MEMBER_LIST = 'LOAD_USER_TEAM_MEMBER_LIST';
export const RENDER_USER_TEAM_MEMBER_LIST = 'RENDER_USER_TEAM_MEMBER_LIST';

export const SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE =
  'SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE';
export const SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_SUCCESS =
  'SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_SUCCESS';
export const SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_FAILED =
  'SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_FAILED';

export const RESET_USER_TEAM_MEMBER_REQUEST_SUCCESS =
  'RESET_USER_TEAM_MEMBER_REQUEST_SUCCESS';
export const RESET_USER_TEAM_MEMBER_PAGE = 'RESET_USER_TEAM_MEMBER_PAGE';

export const USER_VALIDATION_FAILED = 'USER_VALIDATION_FAILED';
export const USER_TEAM_MEMBER_VALIDATION_FAILED =
  'USER_TEAM_MEMBER_VALIDATION_FAILED';

export function updateSubscriptionOfUser(data) {
  return {
    type: SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE,
    credentials: data,
  };
}

export function getAllUsers(data) {
  return {
    type: LOAD_USER_LIST,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function getAllUserTeamMembers(data) {
  return {
    type: LOAD_USER_TEAM_MEMBER_LIST,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function createUser(data) {
  return {
    type: CREATE_USER,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function userUpdate(data) {
  return {
    type: UPDATE_USER,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function userDelete(data) {
  return {
    type: DELETE_USER,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_USER_REQUEST_SUCCESS,
  };
}

export function resetPage() {
  return {
    type: RESET_USER_PAGE,
  };
}
