import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

const CompanyMembersModal = ({
  companyTeamMembers,
  companyOwner,
  allUserList,
  setCompanyOwner,
  submitCompanyMembers,
  loading,
  modalLoader,
}) => (
  <>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label>Owner</label>
        <div style={{ width: '100%' }}>
          <Select
            className="SelectOption basic-single"
            classNamePrefix="react-select"
            isSearchable
            id="companyOwner"
            onChange={(e) => setCompanyOwner(e)}
            options={allUserList}
            value={allUserList.find(
              (user) => user.value === companyOwner.value
            )}
          />
        </div>
      </div>
      <div className="d-flex mt-5">
        <button
          className="Button PrimaryButton ml-auto"
          onClick={submitCompanyMembers}
        >
          <span>
            {modalLoader ? (
              <Spinner animation="border" variant="light" />
            ) : (
              'Submit'
            )}
          </span>
        </button>
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  modalLoader: state.superAdminCompanies.modalLoader,
});

export default connect(mapStateToProps)(CompanyMembersModal);
