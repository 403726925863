import { toast } from 'react-toastify';
import {
  LOAD_ALL_DETAILS,
  RENDER_DETAILS,
  LOAD_ALL_DETAILS_FAILED,
  LOAD_ALL_DETAILS_FOR_DEMO,
} from '../actions/SitesDetailsActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  siteDetails: [],
  logUpdate: [],
  requestSuccess: false,
  loading: false,
  detailsLoader: false,
};

export default function teamMember(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALL_DETAILS:
      return {
        ...state,
        loading: true,
        detailsLoader: true,
      };
    case LOAD_ALL_DETAILS_FOR_DEMO:
      return {
        ...state,
        loading: true,
        detailsLoader: true,
      };
    case RENDER_DETAILS:
      return {
        ...state,
        siteDetails: action.siteDetails,
        logUpdate: action.logUpdate,
        loading: false,
        detailsLoader: false,
      };
    case LOAD_ALL_DETAILS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        detailsLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
