export const SEND_FORGETPASS_LINK = 'SEND_FORGETPASS_LINK';
export const SEND_FORGETPASS_LINK_SUCCESS = 'SEND_FORGETPASS_LINK_SUCCESS';
export const SEND_FORGETPASS_LINK_FAILURE = 'SEND_FORGETPASS_LINK_FAILURE';
export const SEND_FORGETPASS_LINK_VALIDATION_FAILED =
  'SEND_FORGETPASS_LINK_VALIDATION_FAILED';

export function forgetPassLink({ email }) {
  return {
    type: SEND_FORGETPASS_LINK,
    credentials: {
      email,
    },
  };
}
