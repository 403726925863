import React from 'react';
import { convertUnixToDateTime } from '../../utils/helper';

const SitesDeactivationInfo = (props) => {
  const details = props.data;
  return (
    <>
      <section className="ListViewSection">
        <ul className="ListView">
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitlePrimary">
                Date
              </div>
              {details.deactivated_date && (
                <p className="Alert PrimaryAlert">
                  {convertUnixToDateTime(details.deactivated_date)}
                </p>
              )}
            </div>
          </li>
          <li className="ListViewItem">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleSuccess">
                Reason
              </div>
              {details.deactivation_reason && (
                <p className="Alert SuccessAlert">
                  {details.deactivation_reason}
                </p>
              )}
            </div>
          </li>
          {details.deactivation_details && (
            <li className="ListViewItem">
              <div className="ListViewContent">
                <div className="ListViewContentTitle ListViewContentTitleWarning">
                  Details
                </div>
                <p className="Alert WarningAlert">
                  {details.deactivation_details}
                </p>
              </div>
            </li>
          )}
        </ul>
      </section>
    </>
  );
};

export default SitesDeactivationInfo;
