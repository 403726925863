import { toast } from 'react-toastify';
import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_VALIDATION_FAILED,
  SIGN_IN,
  ACTIVE_COMPANY_NOT_FOUND,
} from '../actions/SigninActions';

import {
  ADD_NEW_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
} from '../actions/CompanyActions';

import { REGISTER_SUCCESS } from '../actions/RegistrationActions';
import {
  ACCOUNT_SWITCH_SUCCESS,
  RESET_SIGIN_REDIRECT,
  RESET_ACCOUNT_SWITCH_SUCCESS,
  ACCOUNT_SWITCH_FAILED,
} from '../actions/HeaderActions';

import {
  ADD_NEW_COMPANY_FOR_USER,
  ADD_NEW_COMPANY_FOR_USER_SUCCESS,
  ADD_NEW_COMPANY_FOR_USER_FAILED,
  ADD_NEW_COMPANY_FOR_USER_VALIDATION_FAILED,
  RESET_COMPANY_CREATE_FORM,
} from '../actions/createCompanyForUserAction';

import {
  PROFILE_UPDATE_SUCCESS,
  PROFILE_IMAGE_DELETE_SUCCESS,
} from '../actions/UserSettingsActions';
import {
  SIGNED_OUT,
  ACCESS_REMOVED,
  RESET_ACCESS_REMOVED,
  RESET_COMPANY_NOT_FOUND,
  TOKEN_ERROR,
} from '../actions/index';

import {
  SUPER_ADMIN_PROFILE_UPDATE_SUCCESS,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS,
} from '../actions/SuperAdminSettingsAction';

const initialState = {
  signin: undefined,
  validationError: [],
  accountSwitchSuccess: false,
  signInLoader: false,
  createCompanyLoader: false,
  accessRemoved: false,
  companyNotFound: false,
  signout: false,
  role_permission_info: [],
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        signInLoader: true,
      };
    case ADD_NEW_COMPANY_FOR_USER:
      return {
        ...state,
        createCompanyLoader: true,
      };
    case ADD_NEW_COMPANY_FOR_USER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        createCompanyLoader: false,
      };

    case ADD_NEW_COMPANY_FOR_USER_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        signInLoader: false,
        validationError: action.errors,
        createCompanyLoader: false,
      };
    case SIGN_IN_SUCCESS:
      localStorage.getItem('access_token') &&
        localStorage.removeItem('access_token');
      localStorage.getItem('user_info') && localStorage.removeItem('user_info');

      localStorage.setItem('access_token', action.credentials.data.token);
      localStorage.setItem(
        'user_info',
        JSON.stringify(action.credentials.data.user)
      );
      toast.success(action.credentials.message);
      const credentials = {
        redirect: true,
        signInLoader: false,
        userInfo: action.credentials.data.user,
        token: action.credentials.data.token,
        activeProfile: action.credentials.data.activeProfile,
        allProfile: action.credentials.data.allProfiles,
        accountType: action.credentials.data.loggedInAccountType,
        role_permission_info: action.credentials.data.role_permission,
      };
      return {
        ...state,
        signin: credentials,
        signout: false,
      };
    case PROFILE_UPDATE_SUCCESS:
      const credentials1 = {
        ...state.signin,
        userInfo: action.data.user,
      };
      return { ...state, signin: credentials1 };
    case PROFILE_IMAGE_DELETE_SUCCESS:
      const profileImagecredentials1 = {
        ...state.signin,
        userInfo: action.data.user,
      };
      return { ...state, signin: profileImagecredentials1 };
    case SUPER_ADMIN_PROFILE_UPDATE_SUCCESS:
      const superAdminCredentials = {
        ...state.signin,
        userInfo: action.data.user,
      };
      return { ...state, signin: superAdminCredentials };
    case SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS:
      const superAdminProfileImageCredentials = {
        ...state.signin,
        userInfo: action.data.user,
      };
      return { ...state, signin: superAdminProfileImageCredentials };
    case SIGN_IN_FAILURE:
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_info');
      toast.error(action.message);
      return { ...state, signInLoader: false, signin: undefined };
    case SIGN_IN_VALIDATION_FAILED:
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_info');
      toast.error(action.message);
      return { ...state, signInLoader: false, validationError: action.errors };

    case ACCOUNT_SWITCH_SUCCESS:
      toast.success(action.message);
      const signInData = {
        ...state.signin,
        activeProfile: action.activeProfiles,
        allProfile: action.allProfile,
        accountType: action.loggedInAccountType,
        accountSwitchSuccess: true,
        role_permission_info: action.role_permission,
      };

      return { ...state, signin: signInData, accountSwitchSuccess: true };
    case ACCESS_REMOVED:
      const signInData_01 = {
        ...state.signin,
        activeProfile: action.data.activeProfile,
        allProfile: action.data.allProfiles,
        accountType: action.data.loggedInAccountType,
      };
      return {
        ...state,
        signin: signInData_01,
        accessRemoved: true,
      };
    case RESET_ACCESS_REMOVED:
      return {
        ...state,
        accessRemoved: false,
      };
    case RESET_COMPANY_NOT_FOUND:
      return {
        ...state,
        companyNotFound: false,
      };
    case ACCOUNT_SWITCH_FAILED:
      toast.error(action.message);
      return { ...state, accountSwitchSuccess: false };

    case ADD_NEW_COMPANY_SUCCESS:
      const signInData1 = {
        ...state.signin,
        allProfile: action.allProfile,
        activeProfile: action.activeProfile,
      };
      return { ...state, signin: signInData1 };
    case UPDATE_COMPANY_SUCCESS:
      const signInData2 = {
        ...state.signin,
        allProfile: action.allProfile,
        activeProfile: action.activeProfile,
      };
      return { ...state, signin: signInData2 };
    case DELETE_COMPANY_SUCCESS:
      const signInData3 = {
        ...state.signin,
        allProfile: action.allProfile,
      };
      return { ...state, signin: signInData3 };
    case RESET_SIGIN_REDIRECT:
      return { ...state, accountSwitchSuccess: false };
    case RESET_ACCOUNT_SWITCH_SUCCESS:
      return { ...state, accountSwitchSuccess: false };
    case REGISTER_SUCCESS:
      localStorage.setItem('access_token', action.credentials.data.token);
      localStorage.setItem(
        'user_info',
        JSON.stringify(action.credentials.data.user)
      );
      toast.success(action.credentials.message);
      const signInDataAfterRegister = {
        redirect: true,
        userInfo: action.credentials.data.user,
        token: action.credentials.data.token,
        activeProfile: action.credentials.data.activeProfile,
        allProfile: action.credentials.data.allProfiles,
        accountType: action.credentials.data.loggedInAccountType,
      };
      return {
        ...state,
        signin: signInDataAfterRegister,
      };
    case ACTIVE_COMPANY_NOT_FOUND:
      toast.error(action.message);
      localStorage.getItem('access_token') &&
        localStorage.removeItem('access_token');
      const credentials_03 = {
        redirect: false,
        signInLoader: false,
        redirect_to: 'company-create-form',
        accountType: action.data.loggedInAccountType,
        token: action.data.token,
      };
      return { ...state, signin: credentials_03, companyNotFound: true };
    case ADD_NEW_COMPANY_FOR_USER_SUCCESS:
      localStorage.getItem('access_token') &&
        localStorage.removeItem('access_token');
      localStorage.getItem('user_info') && localStorage.removeItem('user_info');

      localStorage.setItem('user_info', JSON.stringify(action.user));
      localStorage.setItem('access_token', action.token);

      toast.success(action.message);
      const credentials_04 = {
        redirect: true,
        createCompanyLoader: false,
        redirect_to: 'dashboard',
        allProfile: action.allProfiles,
        activeProfile: action.activeProfile,
        userInfo: action.user,
        token: action.token,
        accountType: action.loggedInAccountType,
      };
      return { ...state, signin: credentials_04 };

    case RESET_COMPANY_CREATE_FORM:
      localStorage.removeItem('access_token');
      const credentials_05 = {
        redirect: false,
        signInLoader: false,
        redirect_to: undefined,
        accountType: undefined,
        token: undefined,
      };
      return { ...state, signin: credentials_05 };

    case TOKEN_ERROR:
      return {
        ...state,
        signInLoader: false,
        signin: undefined,
        signout: true,
      };
    case SIGNED_OUT:
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_info');
      return { ...initialState };
    default:
      return state;
  }
}
