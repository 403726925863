import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const PasswordChange = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <div className="SecondarySidebarContent">
      <section className="FormViewSection">
        <form action="" className="d-flex flex-column h-100">
          <div className="FormViewGroup">
            <p className="FormViewTitle">Old Password * :</p>
            <input
              type="password"
              className="form-control"
              placeholder="********"
              required
              onChange={(e) => props.setOldPassword(e.target.value)}
              value={props.oldPassword}
            />
          </div>
          {props.err &&
            props.err.oldPassword &&
            props.err.oldPassword.length > 0 && (
              <div className="FormViewGroup">
                <p className="FormViewTitle" />
                {props.err &&
                  props.err.oldPassword &&
                  props.err.oldPassword.length > 0 && (
                    <p style={{ color: 'red' }}>{props.err.oldPassword}</p>
                  )}
              </div>
            )}
          <div className="FormViewGroup">
            <p className="FormViewTitle">New Password * :</p>
            <input
              type="password"
              className="form-control"
              placeholder="********"
              required
              onChange={(e) => props.setNewPassword(e.target.value)}
              value={props.newPassword}
            />
          </div>

          {props.err &&
            props.err.newPassword &&
            props.err.newPassword.length > 0 &&
            props.err.newPassword.map((err, index) => (
              <div className="FormViewGroup">
                <p className="FormViewTitle" />
                <p key={index} style={{ color: 'red' }}>
                  {err}
                </p>
              </div>
            ))}
          <div className="FormViewGroup">
            <p className="FormViewTitle">Confirm Password * :</p>
            <input
              type="password"
              className="form-control"
              placeholder="********"
              required
              onChange={(e) => props.setConfirmNewPassword(e.target.value)}
              value={props.confirmNewPassword}
            />
          </div>
          {props.err &&
            props.err.confirmNewPassword &&
            props.err.confirmNewPassword.length > 0 && (
              <div className="FormViewGroup">
                <p className="FormViewTitle" />
                {props.err &&
                  props.err.confirmNewPassword &&
                  props.err.confirmNewPassword !== '' && (
                    <p style={{ color: 'red' }}>
                      {props.err.confirmNewPassword}
                    </p>
                  )}
              </div>
            )}
          <button
            onClick={props.updatePass}
            className="Button PrimaryButton ml-auto"
          >
            Submit
          </button>
        </form>
      </section>
    </div>
  );

export default PasswordChange;
