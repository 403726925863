import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_PRODUCT_LIST,
  RENDER_PRODUCTS_LIST,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  LOAD_PRODUCT_FAILED,
  CREATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT_FAILED,
  VALIDATION_FAILED,
  FIND_MAILCHIMP_LIST,
  FIND_MAILCHIMP_LIST_FAILED,
  RENDER_MAILCHIMP_LIST,
} from '../actions/productsActions';
import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* fetchProductList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_PRODUCTS_LIST,
      productData: data.data && data.data.productData,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      userCount: data.data && data.data.userCount,
      total: data.data && data.data.total,
      message: data.data && data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_PRODUCT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchMailChimpList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/mail-chimp/list`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_MAILCHIMP_LIST,
      mailChimpList: data.data && data.data.mailChimpList,
      message: data.data && data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: FIND_MAILCHIMP_LIST_FAILED,
        message: data.message,
      });
    }
  }
}

export function* addProduct(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products/store`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: CREATE_PRODUCT_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: CREATE_PRODUCT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updateProduct(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_PRODUCT_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: UPDATE_PRODUCT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* deleteProduct(credentials) {
  const credentials2 = credentials.credentials;
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products/delete`;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: DELETE_PRODUCT_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: DELETE_PRODUCT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadProductList() {
  yield takeLatest(LOAD_PRODUCT_LIST, fetchProductList);
}
export function* loadMailChimpList() {
  yield takeLatest(FIND_MAILCHIMP_LIST, fetchMailChimpList);
}

export function* createProduct() {
  yield takeLatest(CREATE_PRODUCT, addProduct);
}

export function* productUpdate() {
  yield takeLatest(UPDATE_PRODUCT, updateProduct);
}

export function* productDelete() {
  yield takeLatest(DELETE_PRODUCT, deleteProduct);
}

export default function* rootSaga() {
  yield all([
    loadProductList(),
    createProduct(),
    productUpdate(),
    productDelete(),
    loadMailChimpList(),
  ]);
}
