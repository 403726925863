import { connect } from 'react-redux';
import ProductDetails from '../components/Product/ProductDetails';
import {
  fetchProductDetails,
  downloadSDK,
  resetSdkLink,
} from '../actions/ProductDetailsActions';
import { updateProduct, resetRequestSuccess } from '../actions/productsActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapStateToProps = (state) => ({
  productDetails: state.productDetails.productDetails,
  requestSuccess: state.productList.requestSuccess,
  loading: state.util.loading,
  validationError: state.productList.validationError,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  sdkLink: state.productDetails.sdk_link,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,
  detailsLoader: state.productDetails.detailsLoader,
  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetails: (value) => dispatch(fetchProductDetails(value)),
  updateProduct: (value) => dispatch(updateProduct(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  downloadSDK: (value) => dispatch(downloadSDK(value)),
  resetSdkLink: () => dispatch(resetSdkLink()),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
});

const ProductDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetails);

export default ProductDetailsContainer;
