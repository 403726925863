import { toast } from 'react-toastify';
import {
  USER_VERIFY,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAILURE,
  USER_VERIFY_USERNAME_NOT_FOUND,
} from '../actions/VerifyUserActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  verifyUser: undefined,
  redirect_to: undefined,
  loading: false,
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case USER_VERIFY:
      return {
        ...state,
        loading: true,
      };
    case USER_VERIFY_SUCCESS:
      toast.success(action.credentials.message);
      const credentials = {
        redirect: true,
        redirect_to: 'login',
      };
      return { ...state, verifyUser: credentials };
    case USER_VERIFY_USERNAME_NOT_FOUND:
      localStorage.removeItem('access_token');
      toast.error(action.message);
      const userVerifyData = {
        redirect: true,
        redirect_to: 'onboard-form',
      };
      return { ...state, verifyUser: userVerifyData };
    case USER_VERIFY_FAILURE:
      localStorage.removeItem('access_token');
      toast.error(action.message);
      const credentials1 = {
        redirect: false,
      };
      return { ...state, verifyUser: credentials1 };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
