import React from 'react';

export default function ({ packageData, handleEditModal }) {
  return (
    <>
      <tr>
        <td>
          <p>{packageData.name}</p>
        </td>
        <td>
          <p>{packageData.type}</p>
        </td>
        <td>
          <p>{packageData.slug}</p>
        </td>
        <td>
          <p>{packageData.mode}</p>
        </td>
        <td>
          <div className="d-flex">
            <p
              className={`SmallButton ${
                packageData.status === 'Active'
                  ? 'SuccessButton'
                  : 'DangerButton'
              }`}
            >
              {packageData.status}
            </p>
          </div>
        </td>
        <td>
          <p>{packageData.price}</p>
        </td>
        <td>
          <p className="ActionButtonGroup">
            <button
              onClick={(e) => handleEditModal(packageData.id)}
              className="ActionButton PrimaryAlert"
              title="Edit"
            >
              <span className="Icon">
                <i className="insight wpi-edit" />
              </span>
            </button>
          </p>
        </td>
      </tr>
    </>
  );
}
