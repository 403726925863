import { toast } from 'react-toastify';
import {
  PROFILE_UPDATE,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  PASSWORD_UPDATE_VALIDATION_FAILED,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILED,
  PROFILE_UPDATE_VALIDATION_FAILED,
  MAILCHIMP_API_SUCCESS,
  MAILCHIMP_API_FAILED,
  STORE_MAILCHIMP_API_SUCCESS,
  STORE_MAILCHIMP_API_FAILED,
  RESET_SETTINGS_REQUEST_SUCCESS,
  GET_MAILCHIMP_API,
  STORE_MAILCHIMP_API,
  GET_USER_CONFIG,
  GET_USER_CONFIG_SUCCESS,
  GET_USER_CONFIG_FAILED,
  STORE_USER_CONFIG,
  STORE_USER_CONFIG_SUCCESS,
  STORE_USER_CONFIG_FAILED,
  PROFILE_IMAGE_DELETE,
  PROFILE_IMAGE_DELETE_SUCCESS,
  PROFILE_IMAGE_DELETE_FAILED,
  GET_COMPANY_PERMISSIONS,
  GET_COMPANY_PERMISSIONS_SUCCESS,
  GET_COMPANY_PERMISSIONS_FAILED,
  UPDATE_PERMISSION_PER_ROLE,
  UPDATE_PERMISSION_PER_ROLE_SUCCESS,
  UPDATE_PERMISSION_PER_ROLE_FAILED,
  GET_MAILCHIMP_EMAILS,
  GET_MAILCHIMP_EMAILS_SUCCESS,
  GET_MAILCHIMP_EMAILS_FAILED,
  MAILCHIMP_EMAIL_SUBCRIPTION_ACTION,
  MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS,
  MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_FAILED,
  RESET_MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS,
  MAILCHIMP_EMAIL_CHECKBOX_ACTION,
  MAILCHIMP_EMAIL_CHECKBOX_ACTION_SUCCESS,
  MAILCHIMP_EMAIL_CHECKBOX_FAILED,
  GET_MAIL_WEBHOOK,
  GET_MAIL_WEBHOOK_SUCCESS,
  GET_MAIL_WEBHOOK_FAILED,
  UPDATE_MAIL_WEBHOOK,
  UPDATE_MAIL_WEBHOOK_SUCCESS,
  UPDATE_MAIL_WEBHOOK_FAILED,
  DELETE_MAIL_WEBHOOK,
  DELETE_MAIL_WEBHOOK_SUCCESS,
  DELETE_MAIL_WEBHOOK_FAILED,
  RESET_MAIL_WEBHOOK_REQUEST_SUCCESS,
  ADD_MAIL_WEBHOOK,
  ADD_MAIL_WEBHOOK_SUCCESS,
  ADD_MAIL_WEBHOOK_FAILED,
} from '../actions/UserSettingsActions';

import { SIGNED_OUT } from '../actions/index';

const initialState = {
  validationError: [],
  profileUpdateValidationError: [],
  requestSuccess: false,
  mailChimpApiKey: '',
  setingsLoader: false,
  configs: [],
  rolesPermissions: [],
  allRoles: [],
  allPermissions: [],
  allProducts: [],
  emails: [],
  totalEmails: 0,
  currentPage: 1,
  totalPage: 0,
  mailChimpRequestSuccess: false,
  mailWebhook: [],
  mailWebhookRequestSuccess: false,
  mailWebhookModalLoader: false,
  productsForMailWebhook: [],

  currentPageForMailWebhook: 1,
  totalPageForMailWebhook: 0,
  totalForMailWebhook: 0,
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case RESET_MAIL_WEBHOOK_REQUEST_SUCCESS:
      return {
        ...state,
        mailWebhookRequestSuccess: false,
      };
    case ADD_MAIL_WEBHOOK:
      return {
        ...state,
        mailWebhookModalLoader: true,
      };
    case ADD_MAIL_WEBHOOK_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        mailWebhookModalLoader: false,
        mailWebhookRequestSuccess: true,
      };
    case ADD_MAIL_WEBHOOK_FAILED:
      toast.error(action.message);
      return {
        ...state,
        mailWebhookModalLoader: false,
        mailWebhookRequestSuccess: false,
      };
    case DELETE_MAIL_WEBHOOK:
      return {
        ...state,
        mailWebhookModalLoader: true,
      };
    case DELETE_MAIL_WEBHOOK_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        mailWebhookModalLoader: false,
        mailWebhookRequestSuccess: true,
      };
    case DELETE_MAIL_WEBHOOK_FAILED:
      toast.error(action.message);
      return {
        ...state,
        mailWebhookModalLoader: false,
        mailWebhookRequestSuccess: false,
      };
    case UPDATE_MAIL_WEBHOOK:
      return {
        ...state,
        mailWebhookModalLoader: true,
      };
    case GET_MAIL_WEBHOOK:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_MAIL_WEBHOOK_SUCCESS:
      return {
        ...state,
        setingsLoader: false,
        mailWebhook: action.data.webhook,
        productsForMailWebhook: action.data.products,

        currentPageForMailWebhook: action.data.current_page,
        totalPageForMailWebhook: action.data.total_page,
        totalForMailWebhook: action.data.total,
      };

    case UPDATE_MAIL_WEBHOOK_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        mailWebhookModalLoader: false,
        mailWebhookRequestSuccess: true,
      };
    case UPDATE_MAIL_WEBHOOK_FAILED:
      toast.error(action.message);
      return {
        ...state,
        mailWebhookModalLoader: false,
        mailWebhookRequestSuccess: false,
      };
    case GET_MAIL_WEBHOOK_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case STORE_MAILCHIMP_API:
      return {
        ...state,
        setingsLoader: true,
      };
    case MAILCHIMP_EMAIL_CHECKBOX_ACTION:
      return {
        ...state,
        setingsLoader: true,
      };
    case MAILCHIMP_EMAIL_SUBCRIPTION_ACTION:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_MAILCHIMP_EMAILS:
      return {
        ...state,
        setingsLoader: true,
      };
    case UPDATE_PERMISSION_PER_ROLE:
      return {
        ...state,
        setingsLoader: true,
      };
    case PROFILE_IMAGE_DELETE:
      return {
        ...state,
        setingsLoader: true,
      };
    case STORE_USER_CONFIG:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_COMPANY_PERMISSIONS:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_USER_CONFIG:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_USER_CONFIG_SUCCESS:
      return {
        ...state,
        configs: action.data.configs,
        setingsLoader: false,
      };
    case GET_MAILCHIMP_EMAILS_SUCCESS:
      return {
        ...state,
        allProducts: action.data.allProducts,
        emails: action.data.emails,
        totalEmails: action.data.total,
        currentPage: action.data.current_page,
        totalPage: action.data.totalPage,
        setingsLoader: false,
      };
    case MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        mailChimpRequestSuccess: true,
        setingsLoader: false,
      };
    case MAILCHIMP_EMAIL_CHECKBOX_ACTION_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        mailChimpRequestSuccess: true,
        setingsLoader: false,
      };
    case MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        mailChimpRequestSuccess: false,
        setingsLoader: false,
      };
    case MAILCHIMP_EMAIL_CHECKBOX_FAILED:
      toast.error(action.message);
      return {
        ...state,
        mailChimpRequestSuccess: false,
        setingsLoader: false,
      };
    case RESET_MAILCHIMP_EMAIL_SUBCRIPTION_ACTION_SUCCESS:
      return {
        ...state,
        mailChimpRequestSuccess: false,
      };
    case GET_COMPANY_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolesPermissions: action.data.rolesPermissions,
        allRoles: action.data.allRoles,
        allPermissions: action.data.allPermissions,
        setingsLoader: false,
      };
    case GET_COMPANY_PERMISSIONS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case STORE_USER_CONFIG_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        configs: action.data.configs,
        setingsLoader: false,
      };
    case PROFILE_UPDATE:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_MAILCHIMP_API:
      return {
        ...state,
        setingsLoader: true,
      };
    case PASSWORD_UPDATE:
      return {
        ...state,
        setingsLoader: true,
      };
    case PASSWORD_UPDATE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        setingsLoader: false,
      };
    case UPDATE_PERMISSION_PER_ROLE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        setingsLoader: false,
      };
    case PROFILE_IMAGE_DELETE_SUCCESS:
      localStorage.removeItem('user_info');
      localStorage.setItem('user_info', JSON.stringify(action.data.user));
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        setingsLoader: false,
      };
    case PROFILE_IMAGE_DELETE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        setingsLoader: false,
      };
    case PASSWORD_UPDATE_FAILURE:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case UPDATE_PERMISSION_PER_ROLE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case STORE_USER_CONFIG_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case GET_USER_CONFIG_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case GET_MAILCHIMP_EMAILS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case PROFILE_UPDATE_SUCCESS:
      localStorage.removeItem('user_info');
      localStorage.setItem('user_info', JSON.stringify(action.data.user));
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        setingsLoader: false,
      };
    case PROFILE_UPDATE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        setingsLoader: false,
      };

    case PASSWORD_UPDATE_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        validationError: action.errors,
        setingsLoader: false,
      };
    case PROFILE_UPDATE_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        profileUpdateValidationError: action.errors,
        requestSuccess: false,
        setingsLoader: false,
      };
    case MAILCHIMP_API_SUCCESS:
      return {
        ...state,
        mailChimpApiKey: action.data.apiKey,
        setingsLoader: false,
      };
    case STORE_MAILCHIMP_API_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        mailChimpApiKey: action.data.apiKey,
        requestSuccess: true,
        setingsLoader: false,
      };
    case MAILCHIMP_API_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case STORE_MAILCHIMP_API_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        setingsLoader: false,
      };
    case RESET_SETTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
      };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
