export const LOAD_ALL_DETAILS = 'LOAD_ALL_DETAILS';
export const LOAD_ALL_DETAILS_FOR_DEMO = 'LOAD_ALL_DETAILS_FOR_DEMO';
export const RENDER_DETAILS = 'RENDER_DETAILS';
export const LOAD_ALL_DETAILS_FAILED = 'LOAD_ALL_DETAILS_FAILED';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const RESET_PAGE = 'RESET_PAGE';

export function getAllDetails(data) {
  return {
    type: LOAD_ALL_DETAILS,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function siteDetailsForDemo(data) {
  return {
    type: LOAD_ALL_DETAILS_FOR_DEMO,
    credentials: data,
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_REQUEST_SUCCESS,
  };
}
