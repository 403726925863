import React from 'react';
import { Link } from 'react-router-dom';

export default function ({
  productData,
  handleEditModal,
  handelProductView,
  handelDeleteProduct,
  userPermission,
  accountType,
}) {
  return (
    <>
      <tr>
        <td>
          <p>{productData.productName}</p>
        </td>
        <td>
          <p>{productData.ActiveSites}</p>
        </td>
        <td>
          <p>{productData.DeactivatedSites}</p>
        </td>
        <td>
          <div className="d-flex">
            <p
              className={`SmallButton ${
                productData.productStatus === 'Active'
                  ? 'SuccessButton'
                  : 'DangerButton'
              }`}
            >
              {productData.productStatus === 'Active' ? 'Active' : 'Inactive'}
            </p>
          </div>
        </td>
        <td>
          {accountType === 'Partner' ? (
            <p className="ActionButtonGroup">
              <button
                onClick={() => handleEditModal(productData.id)}
                className="ActionButton PrimaryAlert"
                title="Edit"
              >
                <span className="Icon">
                  <i className="insight wpi-edit" />
                </span>
              </button>

              <button
                onClick={(e) => handelProductView(productData.productBaseName)}
                className="ActionButton InfoAlert"
                title="View"
              >
                <span className="Icon">
                  <i className="insight wpi-eye" />
                </span>
              </button>

              <Link
                to={`/user/Reports?report=other_reports&product=${productData.productBaseName}`}
              >
                <button className="ActionButton WarningAlert" title="Chart">
                  <span className="Icon">
                    <i className="insight wpi-chart" />
                  </span>
                </button>
              </Link>
              <Link
                to={`/user/Reports?report=sites_reports&product=${productData.productBaseName}`}
              >
                <button className="ActionButton WarningAlert" title="Sites">
                  <span className="Icon">
                    <i className="insight wpi-report" />
                  </span>
                </button>
              </Link>
              <button
                className="ActionButton DangerAlert"
                title="Delete"
                onClick={(e) => handelDeleteProduct(productData.id)}
              >
                <span className="Icon">
                  <i className="insight wpi-delete" />
                </span>
              </button>
            </p>
          ) : (
            <p className="ActionButtonGroup">
              {userPermission
                .filter((p) => p.name === 'edit-product' && p.checked)
                .map((b) => (
                  <button
                    onClick={() => handleEditModal(productData.id)}
                    className="ActionButton PrimaryAlert"
                    title="Edit"
                  >
                    <span className="Icon">
                      <i className="insight wpi-edit" />
                    </span>
                  </button>
                ))}

              {userPermission
                .filter((p) => p.name === 'view-product' && p.checked)
                .map((b) => (
                  <button
                    onClick={(e) =>
                      handelProductView(productData.productBaseName)
                    }
                    className="ActionButton InfoAlert"
                    title="View"
                  >
                    <span className="Icon">
                      <i className="insight wpi-eye" />
                    </span>
                  </button>
                ))}

              <Link
                to={`/user/Reports?report=other_reports&product=${productData.productBaseName}`}
              >
                <button className="ActionButton WarningAlert" title="Chart">
                  <span className="Icon">
                    <i className="insight wpi-chart" />
                  </span>
                </button>
              </Link>
              <Link
                to={`/user/Reports?report=sites_reports&product=${productData.productBaseName}`}
              >
                <button className="ActionButton WarningAlert" title="Sites">
                  <span className="Icon">
                    <i className="insight wpi-report" />
                  </span>
                </button>
              </Link>
              {userPermission
                .filter((p) => p.name === 'delete-product' && p.checked)
                .map((b) => (
                  <button
                    className="ActionButton DangerAlert"
                    title="Delete"
                    onClick={(e) => handelDeleteProduct(productData.id)}
                  >
                    <span className="Icon">
                      <i className="insight wpi-delete" />
                    </span>
                  </button>
                ))}
            </p>
          )}
        </td>
      </tr>
    </>
  );
}
