import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SUPER_ADMIN_LOAD_ALL_DETAILS,
  SUPER_ADMIN_RENDER_DETAILS,
  SUPER_ADMIN_LOAD_ALL_DETAILS_FAILED,
} from '../actions/SuperAdminSitesDetailsActions';

import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* fetchAllDetails(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/reports/sites-details`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_RENDER_DETAILS,
      siteDetails: data.data && data.data.siteDetails,
      logUpdate: data.data && data.data.logUpdatedInfo,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_LOAD_ALL_DETAILS_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadAllDetails() {
  yield takeLatest(SUPER_ADMIN_LOAD_ALL_DETAILS, fetchAllDetails);
}

export default function* rootSaga() {
  yield all([loadAllDetails()]);
}
