import React from 'react';
import Pagination from 'rc-pagination';
import moment from 'moment';

const SitesLogInfo = (props) => {
  const allLog = props.data;
  return (
    <>
      <section className="DetailsViewSection">
        <div className="DetailsView">
          <div className="DetailsViewHeading">
            <div className="ListViewContent">
              <div className="ListViewContentTitle ListViewContentTitleWarning">
                Last log update time
              </div>
              {props.lastLogTime.length > 0 && (
                <p className="Alert WarningAlert">
                  {moment(new Date(props.lastLogTime[0].created_at)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="DetailsViewContent">
            <div className="ListViewContent mb-4">
              <div className="ListViewContentTitle ListViewContentTitlePrimary">
                Log update time
              </div>
            </div>
            <div className="row HasColGap">
              {allLog &&
                allLog.length > 0 &&
                allLog.map((l, i) => (
                  <div key={i} className="col-md-4">
                    <p className="Alert PrimaryAlert">
                      {moment(new Date(l.created_at)).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </p>
                  </div>
                ))}
            </div>
            {props.total > 18 && (
              <div className="d-flex mt-auto">
                <Pagination
                  className="PaginationList ml-auto mt-4"
                  total={props.total}
                  current={props.currentPage}
                  onChange={props.handelLogPageChange}
                  pageSize={18}
                  showPrevNextJumpers={false}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SitesLogInfo;
