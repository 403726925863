import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const RolesPermission = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <section className="FormViewSection">
      <div className="h-100">
        <div className="SectionTitleWrap">
          <div className="SectionTitle">Permissions</div>
        </div>
        <div className="TabControllerWrapper">
          <ul className="nav nav-pills" id="pills-tab">
            {props.allRoles &&
              props.allRoles.length > 0 &&
              props.allRoles
                .filter((p) => p.name !== 'Demo_User')
                .map((r, index) => (
                  <li className="nav-item" key={r.name + index}>
                    <button
                      className={`TabButton PrimaryAlert ${
                        props.activeRoleForPermission &&
                        props.activeRoleForPermission === r.name
                          ? 'Active'
                          : ''
                      }`}
                      onClick={(e) =>
                        props.handelChangePermissionTabChange(e, r.name)
                      }
                    >
                      {r.name}
                    </button>
                  </li>
                ))}
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="row row-cols-md-2 row-cols-1">
                {props.allPermissions &&
                  props.allPermissions.length > 0 &&
                  props.allPermissions.map((p, indexKey) => (
                    <>
                      <div key={p[0] + indexKey} className="col">
                        <div className="CustomFieldCard">
                          <div className="CustomFieldCardHead">
                            <label className="Checkbox">
                              <input
                                type="checkbox"
                                checked={p.checked}
                                onChange={(e) =>
                                  props.handlePermissionClickAll(e, p[0])
                                }
                              />
                              <span>{p[0]}</span>
                            </label>
                          </div>
                          <div className="CustomFieldCardBody">
                            {p[1] &&
                              p[1].length > 0 &&
                              p[1].map((permission, index) => (
                                <label
                                  className="Checkbox"
                                  key={permission.name + index}
                                >
                                  <input
                                    type="checkbox"
                                    checked={permission.checked}
                                    onChange={(e) =>
                                      props.handelPermissionCheckBox(
                                        e,
                                        p[0],
                                        permission.name
                                      )
                                    }
                                  />
                                  <span>{permission.name}</span>
                                </label>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <button
                onClick={(e) => props.submitPermissionPerRole(e)}
                className="Button PrimaryButton"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

export default RolesPermission;
