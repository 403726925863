import { connect } from 'react-redux';
import UserVerify from '../components/Auth/UserVerify';
import { checkUserVerify } from '../actions/VerifyUserActions';

const mapStateToProps = (state) => ({
  verifyUser: state.verifyUser.verifyUser,
});

const mapDispatchToProps = (dispatch) => ({
  checkUser: (value) => dispatch(checkUserVerify(value)),
});

const verifyUser = connect(mapStateToProps, mapDispatchToProps)(UserVerify);

export default verifyUser;
