import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_REPORT,
  RENDER_REPORT,
  LOAD_REPORT_FAILED,
  LOAD_REPORT_FOR_DEMO,
  STORE_USER_CONFIG_FROM_SITES_TABLE,
  STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS,
  STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED,
} from '../actions/ReportActions';
import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';
import { defaultApi } from '../utils/axiosApi';
import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* fetchAllReports(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/reports/reports`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_REPORT,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      allPhpVersion: data.data && data.data.allPhpVersion,
      allWpInsVersion: data.data && data.data.allWpInsVersion,
      allWordpressVersion: data.data && data.data.allWordpressVersion,
      allProducts: data.data && data.data.allProducts,
      siteList: data.data && data.data.siteList,
      wpVersion: data.data && data.data.wpVersion,
      phpVersion: data.data && data.data.phpVersion,
      pluginVersion: data.data && data.data.pluginVersion,
      status: data.data && data.data.status,
      language: data.data && data.data.language,
      de_active_reasons: data.data && data.data.de_active_reasons,
      activeSitesCount: data.data && data.data.activeSitesCount,
      deactivedSitesCount: data.data && data.data.deactivedSitesCount,
      analytics: data.data && data.data.analytics,
      mailchimpAnalytics: data.data && data.data.mailChimpAnalytics,
      totalUserCount: data.data && data.data.totalUserCount,
      newUserCount: data.data && data.data.newUserCount,
      sitesConfig: data.data && data.data.sitesConfig,
      sortedBy: data.data && data.data.siteSort,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_REPORT_FAILED,
        message: data.message,
      });
    }
  }
}
export function* fetchAllReportsForDemo(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/demo-site-Report/reports`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_REPORT,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      allPhpVersion: data.data && data.data.allPhpVersion,
      allWpInsVersion: data.data && data.data.allWpInsVersion,
      allWordpressVersion: data.data && data.data.allWordpressVersion,
      allProducts: data.data && data.data.allProducts,
      siteList: data.data && data.data.siteList,
      wpVersion: data.data && data.data.wpVersion,
      phpVersion: data.data && data.data.phpVersion,
      pluginVersion: data.data && data.data.pluginVersion,
      status: data.data && data.data.status,
      language: data.data && data.data.language,
      de_active_reasons: data.data && data.data.de_active_reasons,
      activeSitesCount: data.data && data.data.activeSitesCount,
      deactivedSitesCount: data.data && data.data.deactivedSitesCount,
      analytics: data.data && data.data.analytics,
      mailchimpAnalytics: data.data && data.data.mailChimpAnalytics,
      totalUserCount: data.data && data.data.totalUserCount,
      newUserCount: data.data && data.data.newUserCount,
      sitesConfig: data.data && data.data.sitesConfig,
      sortedBy: data.data && data.data.siteSort,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_REPORT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* storeUserConfigFromSitesTableAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/configs/save-from-report`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: STORE_USER_CONFIG_FROM_SITES_TABLE_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: STORE_USER_CONFIG_FROM_SITES_TABLE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadAllReport() {
  yield takeLatest(LOAD_REPORT, fetchAllReports);
}

export function* loadAllReportForDemo() {
  yield takeLatest(LOAD_REPORT_FOR_DEMO, fetchAllReportsForDemo);
}

export function* storeUserConfigFromSitesTable() {
  yield takeLatest(
    STORE_USER_CONFIG_FROM_SITES_TABLE,
    storeUserConfigFromSitesTableAttempt
  );
}

export default function* rootSaga() {
  yield all([
    loadAllReport(),
    loadAllReportForDemo(),
    storeUserConfigFromSitesTable(),
  ]);
}
