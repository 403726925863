import React from 'react';
import Chart from 'react-apexcharts';
import Spinner from 'react-bootstrap/Spinner';

import '../../../assets/css/reportCustomStyle.css';

const innerHeight = 600;

let successAnalyticsCounts = [];
let successAnalyticsDates = [];
let errorAnalyticsCounts = [];

const StressReport = (props) => {
  successAnalyticsCounts = props.successAnalytics.map((data) => data.count);
  errorAnalyticsCounts = props.errorAnalytics.map((data) => data.count);
  successAnalyticsDates = props.successAnalytics.map((v) => v.date);

  const AreaChartSeries = [
    {
      name: 'Success count',
      data: successAnalyticsCounts,
    },
    {
      name: 'Error count',
      data: errorAnalyticsCounts,
    },
  ];

  const AreaChartOption = {
    chart: {
      height: 350,
      type: 'area',
      id: 'Stree Analytics',
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      width: 3,
      categories: successAnalyticsDates,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
    tooltip: {
      x: {
        format: 'yyyy-mm-dd',
      },
    },
  };

  return (
    <>
      {/* analytic section  */}
      <section className="AnalyticSection SectionGap">
        <div className="SectionPanel StressPanel">
          <div className="PanelTitle">
            <div className="SectionTitle">Analytics</div>
            <div className="WpiTooltip">
              <button className="WpiTooltipButton">
                <i className="insight wpi-info" />
              </button>
              <div className="WpiTooltipText">Data shown in UTC Timezone</div>
            </div>
          </div>
          <div className="PanelItem">
            {props.stressReportLoader ? (
              <div
                className=""
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: innerHeight <= 750 ? 600 : `${innerHeight}px`,
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            ) : (
              <Chart
                options={AreaChartOption}
                series={AreaChartSeries}
                type="area"
                height={innerHeight <= 750 ? 600 : innerHeight}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default StressReport;
