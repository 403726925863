import { toast } from 'react-toastify';
import {
  SUPER_ADMIN_RENDER_PRODUCT_DETAILS,
  SUPER_ADMIN_LOAD_PRODUCT_DETAILS_FAILED,
  SUPER_ADMIN_RESET_DOWNLOAD_SDK,
  SUPER_ADMIN_DOWNLOAD_SDK_SUCCESS,
  SUPER_ADMIN_DOWNLOAD_SDK_FAILED,
  SUPER_ADMIN_LOAD_PRODUCT_DETAILS,
} from '../actions/SuperAdminProductDetailsActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  productDetails: [],
  detailsLoader: false,
};

export default function productDetails(state = initialState, action) {
  switch (action.type) {
    case SUPER_ADMIN_LOAD_PRODUCT_DETAILS:
      return {
        ...state,
        detailsLoader: true,
      };
    case SUPER_ADMIN_RENDER_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.productDetails,
        detailsLoader: false,
      };
    case SUPER_ADMIN_LOAD_PRODUCT_DETAILS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        detailsLoader: false,
      };
    case SUPER_ADMIN_DOWNLOAD_SDK_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        sdk_link: action.link,
      };

    case SUPER_ADMIN_RESET_DOWNLOAD_SDK:
      return {
        ...state,
        sdk_link: undefined,
      };
    case SUPER_ADMIN_DOWNLOAD_SDK_FAILED:
      toast.error(action.message);
      return state;
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
