import React from 'react';
import Pagination from 'rc-pagination';
import nodataImage from '../../assets/img/no_data_full.svg';

const SitesInActivePluginInfo = (props) => {
  const plugin = props.data;
  return (
    <>
      <section className="DetailsViewSection">
        <div className="DetailsView">
          {/* <div className="DetailsViewHeading">
            <div className="d-flex ml-auto">
              <div className="SearchForm">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div>
          </div> */}
          <div className="DetailsViewContent">
            <div className="row HasColGap">
              {plugin && plugin.length > 0 ? (
                plugin.map((d, i) => (
                  <div key={i} className="col-md-4">
                    <p className="Alert PrimaryAlert">{d}</p>
                  </div>
                ))
              ) : (
                <div style={{ alignItems: 'center', width: '100%' }}>
                  <p
                    style={{ textAlign: 'center' }}
                    className="Alert DangerAlert"
                  >
                    There is no deactive plugin
                  </p>
                  <img
                    src={nodataImage}
                    style={{ height: '600px', width: '100%' }}
                    alt=""
                  />
                </div>
              )}
            </div>
            {props.total > 18 && (
              <div className="d-flex mt-auto">
                <Pagination
                  className="PaginationList ml-auto mt-4"
                  total={props.total}
                  current={props.currentPage}
                  pageSize={18}
                  onChange={props.handelInactivePluginPageChange}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SitesInActivePluginInfo;
