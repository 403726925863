import { all, put, takeLatest } from 'redux-saga/effects';
import {
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_VALIDATION_FAILED,
} from '../actions/ResetPasswordActions';

import { defaultApi } from '../utils/axiosApi';

export function* passwordResetAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/password/reset-user`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);

  if (response) {
    const { data } = response;

    yield put({
      type: PASSWORD_RESET_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'validation_error') {
      yield put({
        type: PASSWORD_RESET_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: PASSWORD_RESET_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* resetPassword() {
  yield takeLatest(PASSWORD_RESET, passwordResetAttempt);
}

export default function* rootSaga() {
  yield all([resetPassword()]);
}
