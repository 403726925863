import { connect } from 'react-redux';
import SendForgetPassLink from '../components/Auth/SendForgetPassLink';
import { forgetPassLink } from '../actions/SendForgetPassLinkAction';

const mapStateToProps = (state) => ({
  forgetPassLink: state.sendForgetPassLink.forgetPassLink,
  validationError: state.sendForgetPassLink.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  userForgetPass: (value) => dispatch(forgetPassLink(value)),
});

const ForgetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendForgetPassLink);

export default ForgetPasswordContainer;
