import { connect } from 'react-redux';
import Report from '../components/Report/Report';

import {
  getAllReport,
  allReportForDemo,
  storeUserConfigsFromSitesTable,
} from '../actions/ReportActions';

import { exportListData } from '../actions/DashboardActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapStateToProps = (state) => ({
  currentPage: state.report.currentPage,
  totalPage: state.report.totalPage,
  total: state.report.total,
  requestSuccess: state.report.requestSuccess,
  loading: state.util.loading,
  sitesConfig: state.report.sitesConfig,
  sitesSort: state.report.sortedBy,

  allPhpVersion: state.report.allPhpVersion,
  allWpInsVersion: state.report.allWpInsVersion,
  allWordpressVersion: state.report.allWordpressVersion,
  allProducts: state.report.allProducts,
  siteList: state.report.siteList,
  wpVersion: state.report.wpVersion,
  phpVersion: state.report.phpVersion,
  pluginVersion: state.report.pluginVersion,
  status: state.report.status,
  language: state.report.language,
  de_active_reasons: state.report.de_active_reasons,
  activeSitesCount: state.report.activeSitesCount,
  deactivedSitesCount: state.report.deactivedSitesCount,
  analytics: state.report.analytics,
  mailchimpAnalytics: state.report.mailchimpAnalytics,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  totalUserCount: state.report.totalUserCount,
  newUserCount: state.report.newUserCount,
  reportLoader: state.report.reportLoader,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,

  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const mapDispatchToProps = (dispatch) => ({
  allReport: (value) => dispatch(getAllReport(value)),
  allReportForDemo: (value) => dispatch(allReportForDemo(value)),
  exportListData: (value) => dispatch(exportListData(value)),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
  storeUserConfigsFromSitesTable: (value) =>
    dispatch(storeUserConfigsFromSitesTable(value)),
});

const ReportContainer = connect(mapStateToProps, mapDispatchToProps)(Report);

export default ReportContainer;
