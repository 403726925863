import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SUPER_ADMIN_PASSWORD_UPDATE,
  SUPER_ADMIN_PASSWORD_UPDATE_SUCCESS,
  SUPER_ADMIN_PASSWORD_UPDATE_FAILURE,
  SUPER_ADMIN_PASSWORD_UPDATE_VALIDATION_FAILED,
  SUPER_ADMIN_PROFILE_UPDATE,
  SUPER_ADMIN_PROFILE_UPDATE_SUCCESS,
  SUPER_ADMIN_PROFILE_UPDATE_FAILED,
  SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_SUCCESS,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_FAILED,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_VALIDATION_FAILED,
  SUPER_ADMIN_PLACEHOLDER_FILE,
  SUPER_ADMIN_PLACEHOLDER_FILE_SUCCESS,
  SUPER_ADMIN_PLACEHOLDER_FILE_FAILED,
  GET_SUPER_ADMIN_USER_CONFIG,
  GET_SUPER_ADMIN_USER_CONFIG_SUCCESS,
  GET_SUPER_ADMIN_USER_CONFIG_FAILED,
  STORE_SUPER_ADMIN_USER_CONFIG,
  STORE_SUPER_ADMIN_USER_CONFIG_SUCCESS,
  STORE_SUPER_ADMIN_USER_CONFIG_FAILED,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE_FAILED,
} from '../actions/SuperAdminSettingsAction';

import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* passwordUpdateAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/password/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;

    yield put({
      type: SUPER_ADMIN_PASSWORD_UPDATE_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: SUPER_ADMIN_PASSWORD_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_PASSWORD_UPDATE_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* profileUpdateAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/profile/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;

    yield put({
      type: SUPER_ADMIN_PROFILE_UPDATE_SUCCESS,
      message: data.message,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_PROFILE_UPDATE_FAILED,
        message: data.message,
      });
    }
  }
}
export function* profileImageDeleteAttempt() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/profile/image-delete`;

  const { response, error } = yield defaultApi(endpoint, 'POST', null, true);

  if (response) {
    const { data } = response;

    yield put({
      type: SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS,
      message: data.message,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_PROFILE_IMAGE_DELETE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updatePlaceholderFile(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/update-placeholder`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);

  if (response) {
    const { data } = response;

    yield put({
      type: SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_FAILED,
        message: data.message,
      });
    }
  }
}
export function* getPlaceholderAttempt() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/SDK/placeholder`;

  const { response, error } = yield defaultApi(endpoint, 'GET');

  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_PLACEHOLDER_FILE_SUCCESS,
      message: data.message,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_PLACEHOLDER_FILE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchSuperAdminUserConfig() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/configs`;

  const { response, error } = yield defaultApi(endpoint, 'GET', null, true);

  if (response) {
    const { data } = response;

    yield put({
      type: GET_SUPER_ADMIN_USER_CONFIG_SUCCESS,
      data: data.data,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: GET_SUPER_ADMIN_USER_CONFIG_FAILED,
        message: data.message,
      });
    }
  }
}

export function* superAdminUserConfigStoreAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/settings/configs/save`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const { data } = response;
    yield put({
      type: STORE_SUPER_ADMIN_USER_CONFIG_SUCCESS,
      data: data.data,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: STORE_SUPER_ADMIN_USER_CONFIG_FAILED,
        message: data.message,
      });
    }
  }
}

export function* getSuperAdminUserConfig() {
  yield takeLatest(GET_SUPER_ADMIN_USER_CONFIG, fetchSuperAdminUserConfig);
}

export function* storeSuperAdminUserConfig() {
  yield takeLatest(
    STORE_SUPER_ADMIN_USER_CONFIG,
    superAdminUserConfigStoreAttempt
  );
}

export function* updatePassword() {
  yield takeLatest(SUPER_ADMIN_PASSWORD_UPDATE, passwordUpdateAttempt);
}

export function* updateProfile() {
  yield takeLatest(SUPER_ADMIN_PROFILE_UPDATE, profileUpdateAttempt);
}
export function* profileImageDelete() {
  yield takeLatest(SUPER_ADMIN_PROFILE_IMAGE_DELETE, profileImageDeleteAttempt);
}

export function* updatePlaceholder() {
  yield takeLatest(SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE, updatePlaceholderFile);
}

export function* getPlaceholder() {
  yield takeLatest(SUPER_ADMIN_PLACEHOLDER_FILE, getPlaceholderAttempt);
}

export default function* rootSaga() {
  yield all([
    updatePassword(),
    updateProfile(),
    updatePlaceholder(),
    getPlaceholder(),
    getSuperAdminUserConfig(),
    storeSuperAdminUserConfig(),
    profileImageDelete(),
  ]);
}
