export const SUPER_ADMIN_LOAD_PRODUCT_LIST = 'SUPER_ADMIN_LOAD_PRODUCT_LIST';
export const SUPER_ADMIN_RENDER_PRODUCTS_LIST = 'RENDER_PRODUCTS_LIST';
export const SUPER_ADMIN_LOAD_PRODUCT_FAILED = 'LOAD_PRODUCT_FAILED';

export function getAllproducts(product) {
  return {
    type: SUPER_ADMIN_LOAD_PRODUCT_LIST,
    credentials: {
      ...product,
      token: localStorage.getItem('access_token'),
    },
  };
}
