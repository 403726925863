import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_USER_LIST,
  RENDER_USER_LIST,
  LOAD_USER_FAILED,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  USER_VALIDATION_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  LOAD_USER_TEAM_MEMBER_FAILED,
  LOAD_USER_TEAM_MEMBER_LIST,
  RENDER_USER_TEAM_MEMBER_LIST,
  SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE,
  SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_SUCCESS,
  SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_FAILED,
} from '../actions/SuperAdminUsersActions';
import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* fetchUserList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/user/all`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_USER_LIST,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      userList: data.data && data.data.userData,
      allPackages: data.data && data.data.allPackages,
      allPermissions: data.data && data.data.allPermissions,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: LOAD_USER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchUserTeamMemberList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/user/team-member/all`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_USER_TEAM_MEMBER_LIST,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      teamMemberList: data.data && data.data.teamMemberData,
      mainUserData: data.data && data.data.mainUserData,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: LOAD_USER_TEAM_MEMBER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* addUser(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/user/create`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: CREATE_USER_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: USER_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: CREATE_USER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* userUpdate(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/user/update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_USER_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: USER_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: UPDATE_USER_FAILED,
        message: data.message,
      });
    }
  }
}

export function* userUpdateSubcriptionAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/user/subcription-update`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'validation_error') {
      yield put({
        type: USER_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadUserList() {
  yield takeLatest(LOAD_USER_LIST, fetchUserList);
}

export function* loadUserTeamMemberList() {
  yield takeLatest(LOAD_USER_TEAM_MEMBER_LIST, fetchUserTeamMemberList);
}

export function* createUser() {
  yield takeLatest(CREATE_USER, addUser);
}

export function* updateUser() {
  yield takeLatest(UPDATE_USER, userUpdate);
}

export function* updateUserSubscription() {
  yield takeLatest(
    SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE,
    userUpdateSubcriptionAttempt
  );
}

export default function* rootSaga() {
  yield all([
    loadUserList(),
    createUser(),
    updateUser(),
    loadUserTeamMemberList(),
    updateUserSubscription(),
  ]);
}
