import { connect } from 'react-redux';
import ResentVerificationLink from '../components/Auth/ResentVerificationLink';
import { resendVerificationAction } from '../actions/resendVerificationAction';

const mapStateToProps = (state) => ({
  resendVerification: state.resendVerification,
  validationError: state.resendVerification.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  userVerification: (value) => dispatch(resendVerificationAction(value)),
});

const ResentVerificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResentVerificationLink);

export default ResentVerificationContainer;
