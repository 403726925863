import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

const PackageModal = ({
  name,
  setName,
  type,
  setType,
  slug,
  setSlug,
  mode,
  setMode,
  status,
  setStatus,
  price,
  setPrice,
  err,
  permissionSelectoptions,
  modalLoader,
  typeOptions,
  handelPackageTypeSelect,
  setTypeOptions,
  modeOptions,
  setModeOptions,
  statusOptions,
  setStatusOptions,
  handelPackageModeSelect,
  handelPackageStatusSelect,
  permissions,
  handelPermissionValueChange,
  submitPackagePackagePermissions,
}) => (
  <>
    <div className="ModalTabContent PackageModalContent">
      <div className="row HasColGap">
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Name*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Package Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {err && err.name && err.name.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={name}>
                {err.name}
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Type*</label>
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              isSearchable={false}
              onChange={(e) => handelPackageTypeSelect(e)}
              options={typeOptions}
              defaultValue={typeOptions.find((op, index) => index === 0)}
              value={typeOptions.find((op) => op.value === type)}
            />
          </div>
          {err && err.type && err.type.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={type}>
                {err.type}
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Slug*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
          </div>
          {err && err.slug && err.slug.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={slug}>
                {err.slug}
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Mode*</label>
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              isSearchable={false}
              onChange={(e) => handelPackageModeSelect(e)}
              options={modeOptions}
              defaultValue={modeOptions.find((op, index) => index === 0)}
              value={modeOptions.find((op) => op.value === mode)}
            />
          </div>
          {err && err.mode && err.mode.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={mode}>
                {err.mode}
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Status*</label>
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              isSearchable={false}
              onChange={(e) => handelPackageStatusSelect(e)}
              options={statusOptions}
              defaultValue={statusOptions.find((op, index) => index === 0)}
              value={statusOptions.find((op) => op.value === status)}
            />
          </div>
          {err && err.status && err.status.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={status}>
                {err.status}
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Price*</label>
            <input
              type="number"
              className="form-control"
              placeholder="$00.00"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          {err && err.price && err.price.length > 0 && (
            <div className="TabContentRow">
              <label />
              <p style={{ color: 'red' }} key={price}>
                {err.price}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="SectionTitleWrap mt-4">
        <h4 className="SectionTitle">Permission</h4>
        {/* <div className="ml-auto">
            <button className="ActionButton PrimaryButton">
              <div className="Icon">
                <i className="insight wpi-plus"></i>
              </div>
            </button>
          </div> */}
      </div>
      {err && err.permissions && err.permissions.length > 0 && (
        <p style={{ color: 'red' }} key={1}>
          {err.permissions}
        </p>
      )}
      <div className="RemovablePermissionGroup">
        {permissions &&
          permissions.length > 0 &&
          permissions.map((ps, index) => (
            <div key={index} className="RemovablePermission">
              <div className="RemovablePermissionInner">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={ps.name}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Value"
                      value={ps.value}
                      onChange={(e) => handelPermissionValueChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
    <div className="d-flex">
      <button
        className="Button PrimaryButton ml-auto"
        onClick={(e) => submitPackagePackagePermissions(e)}
      >
        <span>
          {modalLoader ? (
            <Spinner animation="border" variant="light" />
          ) : (
            'Save'
          )}
        </span>
      </button>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  modalLoader: state.superAdminPackageReducer.modalLoader,
});

export default connect(mapStateToProps)(PackageModal);
