import { connect } from 'react-redux';
import CompanyCreateForm from '../components/Auth/CompanyCreateForm';
import {
  createCompanyForUser,
  resetCompanyCreateForm,
} from '../actions/createCompanyForUserAction';

const mapStateToProps = (state) => ({
  userToken: state.auth.signin.token,
  companyCreateLoader: state.auth.createCompanyLoader,
  validationError: state.auth.validationError,
  companyCreateForUser: state.auth.signin,
});

const mapDispatchToProps = (dispatch) => ({
  userCompanyCreate: (value) => dispatch(createCompanyForUser(value)),
  resetCompanyCreateForm: () => dispatch(resetCompanyCreateForm()),
});

const userCompanyCreateForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCreateForm);

export default userCompanyCreateForm;
