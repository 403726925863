import { connect } from 'react-redux';
import Report from '../../components/SuperAdmin/Report/Report';

import {
  getAllReport,
  stressReport,
  storeUserConfigsFromSitesTable,
} from '../../actions/SuperAdminReportsActions';
import { exportListData } from '../../actions/DashboardActions';

const mapStateToProps = (state) => ({
  currentPage: state.superAdminReports.currentPage,
  totalPage: state.superAdminReports.totalPage,
  total: state.superAdminReports.total,

  allPhpVersion: state.superAdminReports.allPhpVersion,
  allWpInsVersion: state.superAdminReports.allWpInsVersion,
  allWordpressVersion: state.superAdminReports.allWordpressVersion,
  allProducts: state.superAdminReports.allProducts,
  siteList: state.superAdminReports.siteList,
  wpVersion: state.superAdminReports.wpVersion,
  phpVersion: state.superAdminReports.phpVersion,
  pluginVersion: state.superAdminReports.pluginVersion,
  status: state.superAdminReports.status,
  language: state.superAdminReports.language,
  de_active_reasons: state.superAdminReports.de_active_reasons,
  activeSitesCount: state.superAdminReports.activeSitesCount,
  deactivedSitesCount: state.superAdminReports.deactivedSitesCount,
  analytics: state.superAdminReports.analytics,
  mailchimpAnalytics: state.superAdminReports.mailchimpAnalytics,
  totalUserCount: state.superAdminReports.totalUserCount,
  newUserCount: state.superAdminReports.newUserCount,
  sitesConfig: state.superAdminReports.sitesConfig,
  sitesSort: state.superAdminReports.sortedBy,
  reportLoader: state.superAdminReports.reportLoader,

  successAnalytics: state.superAdminReports.successAnalytics,
  errorAnalytics: state.superAdminReports.errorAnalytics,

  stressReportLoader: state.superAdminReports.stressReportLoader,
  allProductsForStress: state.superAdminReports.allProductsForStress,
});

const mapDispatchToProps = (dispatch) => ({
  allReport: (value) => dispatch(getAllReport(value)),
  exportListData: (value) => dispatch(exportListData(value)),
  stressReport: (value) => dispatch(stressReport(value)),
  storeUserConfigsFromSitesTable: (value) =>
    dispatch(storeUserConfigsFromSitesTable(value)),
});

const ReportsContainer = connect(mapStateToProps, mapDispatchToProps)(Report);

export default ReportsContainer;
