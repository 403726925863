import { toast } from 'react-toastify';
import {
  LOAD_COMPANY_LIST,
  LOAD_COMPANY_LIST_FAILED,
  RENDER_COMPANY_LIST,
  RESET_COMPANY_REQUEST_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_NEW_COMPANY_SUCCESS,
  ADD_NEW_COMPANY_FAILED,
  COMPANY_VALIDATION_FAILED,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILED,
  LIMIT_ERROR,
} from '../actions/CompanyActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  companyList: [],
  currentPage: 1,
  totalPage: 1,
  roles: [],
  total: 0,
  requestSuccess: false,
  loading: false,
  firstTime: true,
  companyListLoader: false,
  modalLoader: false,
  validationError: [],
};

export default function teamMember(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_COMPANY:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case LOAD_COMPANY_LIST:
      return {
        ...state,
        loading: true,
        companyListLoader: true,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case RENDER_COMPANY_LIST:
      return {
        ...state,
        companyList: action.companyList,
        roles: action.roles,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        loading: false,
        companyListLoader: false,
      };
    case ADD_NEW_COMPANY_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };
    case DELETE_COMPANY_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };
    case UPDATE_COMPANY_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };

    case RESET_COMPANY_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        currentPage: 1,
      };
    case LOAD_COMPANY_LIST_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        companyListLoader: false,
      };

    case ADD_NEW_COMPANY_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case LIMIT_ERROR:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };

    case DELETE_COMPANY_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case UPDATE_COMPANY_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case COMPANY_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
        validationError: action.errors,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
