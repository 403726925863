import { connect } from 'react-redux';
import Settings from '../components/Settings/Settings';
import {
  passwordUpdate,
  profileUpdate,
  getMailChimpApiKey,
  storeMailChimpApiKey,
  getUserConfig,
  resetRequestSuccess,
  storeUserConfigs,
  deleteProfileImage,
  getCompanyPermissions,
  savePermissionPerRole,
  getMailChimpEmails,
  emailSubscriptionAction,
  resetMailChimpEmailSubscriptionAction,
  mailChimpEmailAction,
  getMailWebhook,
  updateMailWebhook,
  deleteMailWebhook,
  resetMailWebhookRequestSuccess,
  addMailWebhook,
} from '../actions/UserSettingsActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  validationError: state.userSettings.validationError,
  profileUpdateValidationError: state.userSettings.profileUpdateValidationError,
  requestSuccess: state.userSettings.requestSuccess,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  mailChimpApiKey: state.userSettings.mailChimpApiKey,
  userConfig: state.userSettings.configs,
  companyPermissionsPerRole: state.userSettings.rolesPermissions,
  allRoles: state.userSettings.allRoles,
  allPermissions: state.userSettings.allPermissions,
  setingsLoader: state.userSettings.setingsLoader,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,
  user: state.auth.signin && state.auth.signin.userInfo,
  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,

  allProducts: state.userSettings.allProducts,
  emails: state.userSettings.emails,
  totalEmails: state.userSettings.totalEmails,
  currentPage: state.userSettings.currentPage,
  totalPage: state.userSettings.totalPage,
  mailChimpRequestSuccess: state.userSettings.mailChimpRequestSuccess,
  productsForMailWebhook: state.userSettings.productsForMailWebhook,
  mailWebhook: state.userSettings.mailWebhook,
  mailWebhookRequestSuccess: state.userSettings.mailWebhookRequestSuccess,
  mailWebhookModalLoader: state.userSettings.mailWebhookModalLoader,

  currentPageForMailWebhook: state.userSettings.currentPageForMailWebhook,
  totalPageForMailWebhook: state.userSettings.totalPageForMailWebhook,
  totalForMailWebhook: state.userSettings.totalForMailWebhook,
});

const mapDispatchToProps = (dispatch) => ({
  updatePass: (value) => dispatch(passwordUpdate(value)),
  updateProfile: (value) => dispatch(profileUpdate(value)),
  mailchimpApiKey: (value) => dispatch(getMailChimpApiKey(value)),
  getUserConfig: (value) => dispatch(getUserConfig(value)),
  getCompanyPermissions: (value) => dispatch(getCompanyPermissions(value)),
  storeMailchimpApiKey: (value) => dispatch(storeMailChimpApiKey(value)),
  storeUserConfigs: (value) => dispatch(storeUserConfigs(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
  deleteProfileImage: () => dispatch(deleteProfileImage()),
  savePermissionPerRole: (value) => dispatch(savePermissionPerRole(value)),
  getMailChimpEmails: (value) => dispatch(getMailChimpEmails(value)),
  emailSubscriptionAction: (value) => dispatch(emailSubscriptionAction(value)),
  resetMailChimpEmailSubscriptionAction: () =>
    dispatch(resetMailChimpEmailSubscriptionAction()),
  mailChimpEmailAction: (value) => dispatch(mailChimpEmailAction(value)),
  getMailWebhook: (value) => dispatch(getMailWebhook(value)),
  addMailWebhook: (value) => dispatch(addMailWebhook(value)),
  updateMailWebhook: (value) => dispatch(updateMailWebhook(value)),
  deleteMailWebhook: (value) => dispatch(deleteMailWebhook(value)),
  resetMailWebhookRequestSuccess: () =>
    dispatch(resetMailWebhookRequestSuccess()),
});

const UserSettingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);

export default UserSettingContainer;
