export const LOAD_COMPANY_LIST = 'LOAD_COMPANY_LIST';
export const LOAD_COMPANY_LIST_FAILED = 'LOAD_COMPANY_LIST_FAILED';
export const RENDER_COMPANY_LIST = 'RENDER_COMPANY_LIST';
export const RESET_COMPANY_REQUEST_SUCCESS = 'RESET_COMPANY_REQUEST_SUCCESS';

export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const ADD_NEW_COMPANY_SUCCESS = 'ADD_NEW_COMPANY_SUCCESS';
export const ADD_NEW_COMPANY_FAILED = 'ADD_NEW_COMPANY_FAILED';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';

export const COMPANY_VALIDATION_FAILED = 'COMPANY_VALIDATION_FAILED';
export const LIMIT_ERROR = 'LIMIT_ERROR';

export function getAllCompany(data) {
  return {
    type: LOAD_COMPANY_LIST,
    credentials: data,
  };
}
export function addNewCompany(data) {
  return {
    type: ADD_NEW_COMPANY,
    credentials: data,
  };
}
export function updateCompany(data) {
  return {
    type: UPDATE_COMPANY,
    credentials: data,
  };
}
export function deleteCompany(data) {
  return {
    type: DELETE_COMPANY,
    credentials: data,
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_COMPANY_REQUEST_SUCCESS,
  };
}
