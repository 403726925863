import { toast } from 'react-toastify';
import {
  LOAD_REPORT,
  RENDER_REPORT,
  LOAD_REPORT_FAILED,
  LOAD_REPORT_FOR_DEMO,
} from '../actions/ReportActions';

import { SIGNED_OUT } from '../actions/index';

const initialState = {
  currentPage: 1,
  totalPage: 1,
  total: 0,
  requestSuccess: false,
  loading: false,

  allPhpVersion: [],
  allWpInsVersion: [],
  allWordpressVersion: [],
  allProducts: [],
  siteList: [],
  wpVersion: [],
  phpVersion: [],
  pluginVersion: [],
  status: [],
  language: [],
  de_active_reasons: [],
  activeSitesCount: 0,
  deactivedSitesCount: 0,
  totalUserCount: 0,
  newUserCount: 0,
  analytics: [],
  mailchimpAnalytics: [],
  reportLoader: false,
  sitesConfig: [],
  sortedBy: [],
};

export default function report(state = initialState, action) {
  switch (action.type) {
    case LOAD_REPORT:
      return {
        ...state,
        loading: true,
        reportLoader: true,
      };
    case LOAD_REPORT_FOR_DEMO:
      return {
        ...state,
        loading: true,
        reportLoader: true,
      };

    case RENDER_REPORT:
      return {
        ...state,
        currentPage: action.currentPage
          ? action.currentPage
          : state.currentPage,
        totalPage:
          action.totalPage === 0
            ? 0
            : action.totalPage
            ? action.totalPage
            : state.totalPage,
        total:
          action.total === 0 ? 0 : action.total ? action.total : state.total,
        loading: false,
        reportLoader: false,
        sitesConfig: action.sitesConfig,
        sortedBy: action.sortedBy,
        siteList: action.siteList,

        allPhpVersion: action.allPhpVersion
          ? action.allPhpVersion
          : state.allPhpVersion,
        allWpInsVersion: action.allWpInsVersion
          ? action.allWpInsVersion
          : state.allWpInsVersion,
        allWordpressVersion: action.allWordpressVersion
          ? action.allWordpressVersion
          : state.allWordpressVersion,
        allProducts: action.allProducts
          ? action.allProducts
          : state.allProducts,

        wpVersion: action.wpVersion ? action.wpVersion : state.wpVersion,
        phpVersion: action.phpVersion ? action.phpVersion : state.phpVersion,
        pluginVersion: action.pluginVersion
          ? action.pluginVersion
          : state.pluginVersion,
        status: action.status ? action.status : state.status,
        language: action.language ? action.language : state.language,
        de_active_reasons: action.de_active_reasons
          ? action.de_active_reasons
          : state.de_active_reasons,
        activeSitesCount:
          action.activeSitesCount === 0
            ? 0
            : action.activeSitesCount
            ? action.activeSitesCount
            : state.activeSitesCount,
        deactivedSitesCount:
          action.deactivedSitesCount === 0
            ? 0
            : action.deactivedSitesCount
            ? action.deactivedSitesCount
            : state.deactivedSitesCount,
        analytics: action.analytics ? action.analytics : state.analytics,
        mailchimpAnalytics: action.mailchimpAnalytics
          ? action.mailchimpAnalytics
          : state.mailchimpAnalytics,
        totalUserCount: action.totalUserCount
          ? action.totalUserCount
          : state.totalUserCount,
        newUserCount:
          action.newUserCount === 0
            ? 0
            : action.newUserCount
            ? action.newUserCount
            : state.newUserCount,
      };

    case LOAD_REPORT_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        reportLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
