import React from 'react';
import { handelCopyToClipBoard } from '../../utils/helper';

const ProductTrackingInfo = ({ data, handelDownloadSdk }) => {
  const base_name = data.base_name_slug;
  const functionName = `${base_name.replace(/-/g, '_')}_start_plugin_tracking`;
  const cName = `WPInsights_${base_name.charAt(0).toUpperCase()}${base_name
    .replace(/-/g, '_')
    .slice(1)}`;
  const item_id = data.secret_key;

  const trackingData = () => `
  /**
    * This function allows you to track usage of your plugin
    * Place in your main plugin file
    * Refer to https://wpdeveloper.net/support for help
    */
   if( ! function_exists( '${functionName}' ) ) {
      function ${functionName}() {
          if( ! class_exists( '${cName}') ) {
              require_once dirname( __FILE__ ) . '/path/to/class-plugin-usage-tracker.php';
          }
        $tracker = ${cName}::get_instance( __FILE__, [
        'opt_in'       => true,
        'goodbye_form' => true,
        'item_id'      => '${item_id}'
      ] );
      $tracker->set_notice_options(array(
        'notice' => __( 'Notice Message Goes Here', '${base_name}' ),
        'extra_notice' => __( 'We collect non-sensitive diagnostic data and plugin usage information. 
        Your site URL, WordPress & PHP version, plugins & themes and email address to send you the 
        discount coupon. This data lets us make sure this plugin always stays compatible with the most 
        popular plugins and themes. No spam, I promise.', '${base_name}' ),
      ));
      $tracker->init();
      }
      ${functionName}();
  }`;

  return (
    <>
      <section className="FormViewSection">
        <div className="d-flex">
          <button
            onClick={(e) => handelCopyToClipBoard('copy-to-clipboard')}
            className="SmallButton PrimaryButton ml-auto mb-4"
          >
            <span>Copy To Clipboard</span>
          </button>
          <button
            onClick={(e) =>
              handelDownloadSdk(data.base_name, data.base_name_slug, data.id)
            }
            className="SmallButton PrimaryButton mb-4 ml-2"
          >
            <span>Download</span>
          </button>
        </div>
        <div className="FormViewGroup">
          <p className="FormViewDataPretext">
            <code id="copy-to-clipboard" className="language-php">
              {trackingData()}
            </code>
          </p>
        </div>
      </section>
    </>
  );
};

export default ProductTrackingInfo;
