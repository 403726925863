import React from 'react';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../actions/index';
import { ul, li } from '../../assets/css/style.css';
import WpiLogo from '../../assets/SVG/logo.svg';
import WpiSmallLogo from '../../assets/SVG/smallLogo.svg';
import routes from '../../routes';
import '../../assets/css/WP-insight-icon/style.css';

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user_info'));
    const accountType = props.accountType;
    this.state = {
      activePath: props.location.pathname,
      items:
        accountType === 'SUPER_ADMIN'
          ? routes.filter(
              (route) =>
                (route.sidebar === true &&
                  route.is_admin_route === true &&
                  !route.verified &&
                  user &&
                  user.is_verified === 1) ||
                (route.sidebar === true &&
                  route.is_admin_route === true &&
                  user &&
                  user.is_verified === 0 &&
                  route.verified)
            )
          : routes.filter(
              (route) =>
                (route.sidebar === true &&
                  route.is_admin_route === false &&
                  !route.verified &&
                  user &&
                  user.is_verified === 1) ||
                (route.sidebar === true &&
                  route.is_admin_route === false &&
                  user &&
                  user.is_verified === 0 &&
                  route.verified)
            ),
      toggleSideBar: false,
      secendaySideBarPath: '',
      toggoleSecendaySidebar: false,
    };
  }

  onItemClick = (path) => {
    this.setState({ activePath: path });
  };

  secondarySideBarClick = (path) => {
    if (this.state.toggoleSecendaySidebar) {
      this.setState({
        secendaySideBarPath: path,
        toggoleSecendaySidebar: false,
      });
    } else {
      this.setState({
        secendaySideBarPath: path,
        toggoleSecendaySidebar: true,
      });
    }
  };

  signOut = () => {
    localStorage.removeItem('access_token');
    this.props.userSignOut();
    this.props.history.push('/auth/sign-in');
  };

  handleToggle = (toggleSideBar) => {
    if (toggleSideBar === true) {
      this.setState({ toggleSideBar: false });
    }
    if (toggleSideBar === false) {
      this.setState({ toggleSideBar: true });
    }
  };

  render() {
    const { items, activePath, toggleSideBar } = this.state;
    return (
      <section
        className={`Sidebar ${toggleSideBar === true ? 'Collapsed' : ''}`}
      >
        <div className="SidebarContent">
          <div className="SidebarContentTop">
            <a className="SiteLogo" href="/user/dashboard">
              <img src={WpiLogo} alt="WPInsught Logo" />
            </a>
            <a href="/user/dashboard" className="SiteCollapsedLogo">
              <img src={WpiSmallLogo} alt="WPInsught Logo small" />
            </a>
            <div
              onClick={() => this.handleToggle(toggleSideBar)}
              className="SidebarToggler"
            >
              <i className="insight wpi-toggler"></i>
            </div>
          </div>
          <nav className="SidebarContentMiddle">
            <ul className="SidebarNav">
              {items.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.views.length === 0 && (
                      <NavItem
                        path={item.path}
                        layout={item.layout}
                        name={item.name}
                        onItemClick={this.onItemClick}
                        active={
                          item.layout + item.path ===
                          this.props.location.pathname
                        }
                        icon={item.icon}
                        mapkey={index}
                        key={index}
                        queryFilter={item.queryFilter}
                      />
                    )}
                    {item.views.length > 0 && (
                      <>
                        <li className="SidebarNavItem SidebarNavItemHasDropdown">
                          <NavDropItem
                            path={item.path}
                            name={item.name}
                            layout={item.layout}
                            onDropdownClick={this.secondarySideBarClick}
                            active={activePath === item.path}
                            icon={item.icon}
                          />
                          <ul
                            id={item.name}
                            className={`SidebarNavDropdownMenu ${
                              item.path === this.state.secendaySideBarPath &&
                              this.state.toggoleSecendaySidebar
                                ? 'collapse'
                                : 'show'
                            }`}
                          >
                            {item.views.map((ditem, i) => {
                              return (
                                <NavDropdownItem
                                  path={ditem.path}
                                  name={ditem.name}
                                  layout={item.layout}
                                  onItemClick={this.onItemClick}
                                  activePath={ditem.path === activePath}
                                  active={
                                    this.props.location.pathname +
                                      this.props.location.search ===
                                    item.layout + ditem.path + ditem.queryFilter
                                  }
                                  icon={ditem.icon}
                                  className="SidebarNavItemHasDropdown"
                                  mapkey={i}
                                  key={i}
                                  queryFilter={ditem.queryFilter}
                                  location={this.props.location}
                                />
                              );
                            })}
                          </ul>
                        </li>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
              <li className="SidebarNavItem">
                <button
                  style={{ textAlign: 'left', fontWeight: '500' }}
                  onClick={this.signOut}
                  className="SidebarNavItemLink"
                >
                  <div className="SidebarNavItemLinkIcon">
                    <i className="insight wpi-logout"></i>
                  </div>
                  <div className="SidebarNavItemLinkText">Logout</div>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    );
  }
}

const RouterSideNav = withRouter((props) => <SideNav {...props} />);

class NavItem extends React.Component {
  handleClick = () => {
    const { path, onItemClick } = this.props;
    onItemClick(path);
  };

  render() {
    const { active, icon, mapkey } = this.props;
    return (
      <li className="SidebarNavItem" key={mapkey}>
        {this.props.queryFilter ? (
          <Link
            to={this.props.layout + this.props.path + this.props.queryFilter}
            className={`SidebarNavItemLink ${active && 'Active'}`}
          >
            <div className="SidebarNavItemLinkIcon">
              <i className={icon}></i>
            </div>
            <div className="SidebarNavItemLinkText">{this.props.name}</div>
          </Link>
        ) : (
          <Link
            to={this.props.layout + this.props.path}
            className={`SidebarNavItemLink ${active && 'Active'}`}
          >
            <div className="SidebarNavItemLinkIcon">
              <i className={icon}></i>
            </div>
            <div className="SidebarNavItemLinkText">{this.props.name}</div>
          </Link>
        )}
      </li>
    );
  }
}

class NavDropItem extends React.Component {
  render() {
    const { path, active, icon, name, onDropdownClick } = this.props;
    return (
      <a
        href={`#${name}`}
        data-toggle="collapse"
        className={`SidebarNavItemLink ${active && 'Active'}`}
        onClick={() => onDropdownClick(path)}
      >
        <div className="SidebarNavItemLinkIcon">
          <i className={icon}></i>
        </div>
        <div className="SidebarNavItemLinkText">{name}</div>
      </a>
    );
  }
}

class NavDropdownItem extends React.Component {
  render() {
    const { mapkey, name, location, path, layout, queryFilter } = this.props;
    const dropDownType = queryString.parse(queryFilter);
    const fullDropDwonPath = location.pathname + location.search;
    const dropDrownPath = fullDropDwonPath.split('&')[0];
    let active;

    if (dropDownType.report) {
      active =
        dropDrownPath === layout + path + '?report=' + dropDownType.report;
    } else {
      active = dropDrownPath === layout + path + queryFilter;
    }

    return (
      <li
        className="SidebarNavDropdownMenuItem"
        active={active.toString()}
        key={mapkey}
      >
        <Link
          to={this.props.layout + this.props.path + this.props.queryFilter}
          className={`SidebarNavDropdownMenuItemLink  ${
            active ? 'Active' : ''
          }`}
        >
          {name}
        </Link>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSignOut: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => {
  return {
    accountType: state.auth.signin && state.auth.signin.accountType,
  };
};

const LeftSideBar = (props) => <RouterSideNav {...props} />;

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
