import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../utils/Loader';
import { signOut } from '../actions/index';

import LeftSideBar from '../components/Common/LeftSideBar';
import Header from '../components/Common/Header';

import routes from '../routes';

import { ProtectedRoute } from '../utils/ProtectedRoute';

function UserLayout({
  loading,
  signout,
  userSignOut,
  history,
  accountType,
  token,
}) {
  const getRoutes = (routes) => {
    const finalRoutes =
      accountType === 'SUPER_ADMIN'
        ? routes.filter((r) => r.is_admin_route === true)
        : routes.filter((r) => r.is_admin_route === false);

    const checkPath =
      history &&
      history.location &&
      history.location.pathname &&
      history.location.pathname.split('/');

    const lastRouteParams = checkPath[Object.keys(checkPath).length - 1];
    const lastRouteLocation = history && history.location;

    if (accountType === 'SUPER_ADMIN') {
      const routePaths = finalRoutes.map((f) => f.path);
      if (routePaths.includes(`/admin_user/${lastRouteParams}`)) {
        if (checkPath.length > 3) {
          return finalRoutes.map((prop, key) => {
            if (prop.collapse) {
              return this.getRoutes(prop.views);
            }
            if (prop.layout === '/user') {
              return (
                <ProtectedRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            return null;
          });
        }
        history.push({
          ...lastRouteLocation,
          pathname: `/user/admin_user/${lastRouteParams}`,
        });
      } else {
        history.push('/user/admin_user/dashboard');
      }
      window.location.reload();
    } else {
      const routePaths = finalRoutes.map((f) => f.path);
      if (routePaths.includes(`/${lastRouteParams}`)) {
        if (checkPath.length <= 3) {
          return finalRoutes.map((prop, key) => {
            if (prop.collapse) {
              return this.getRoutes(prop.views);
            }
            if (prop.layout === '/user') {
              return (
                <ProtectedRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            return null;
          });
        }
        history.push({
          ...lastRouteLocation,
          pathname: `/user/${lastRouteParams}`,
        });
      } else {
        history.push('/user/dashboard');
      }
      window.location.reload();
    }
  };

  useEffect(() => {
    if (signout && token) {
      userSignOut();
      history.push('/auth/sign-in');
    }
  }, [signout, history, userSignOut, token]);

  return (
    <div className="WpPageBody">
      <LeftSideBar />
      <main className="ContentArea">
        <Header />
        <Loader isOpen={loading} />
        <div className="ContentAreaBody">
          <Switch>{getRoutes(routes)}</Switch>
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  signout: state.auth && state.auth.signout,
  accountType: state.auth.signin && state.auth.signin.accountType,
  token: state.auth.signin && state.auth.signin && state.auth.signin.token,
});

const mapDispatchToProps = (dispatch) => ({
  userSignOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
