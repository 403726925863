import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';

import signinReducer from './SigninReducer';
import sendForgetPassLinkReducer from './SendForgetPassLinkReducer';
import resendVerificationReducer from './ResendVerificationReducer';
import productsReducer from './ProductsReducer';
import companyReducer from './CompanyReducer';
import teamMemberReducer from './TeamMemberReducer';
import reportReducer from './ReportReducer';
import siteDetailsReducer from './SiteDetailsReducer';
import productDetailsReducer from './ProductDetailsReducer';
import utilReducer from './UtilReducer';
import registrationReducer from './RegistrationReducer';
import onboradReducer from './OnboardReducer';
import userSettingsReducer from './UserSettingsReducers';
import resetPassReducer from './ResetPasswordReducer';
import verifyUserReducer from './VerifyUserReducer';
import dashboardReducer from './DashboardReducer';
import superAdminUserReducer from './SuperAdminUserReducer';
import superAdminUserTeamMemberReducer from './SuperAdminUserTeamMemberReducer';
import superAdminProductsReducer from './superAdminProductsReducer';
import superAdminProductDetailsReducer from './superAdminProductDetailsReducer';
import superAdminReportsReducer from './SuperAdminReportReducer';
import superAdminSitesDetailsReducer from './SuperAdminSitesDetailsReducer';
import superAdminSettingsReducer from './SuperAdminSettingsReducer';
import superAdminPackageReducer from './SuperAdminPackageReducer';

import superAdminCompanyReducer from './SuperAdminCompanyReducer';
import DemoSiteApplyReducer from './DemoSiteApply';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['signin'],
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, signinReducer),
    register: registrationReducer,
    sendForgetPassLink: sendForgetPassLinkReducer,
    resendVerification: resendVerificationReducer,
    productList: productsReducer,
    company: companyReducer,
    teamMember: teamMemberReducer,
    report: reportReducer,
    siteDetails: siteDetailsReducer,
    productDetails: productDetailsReducer,
    util: utilReducer,
    onboard: onboradReducer,
    userSettings: userSettingsReducer,
    resetPass: resetPassReducer,
    verifyUser: verifyUserReducer,
    dashboard: dashboardReducer,
    superAdminUser: superAdminUserReducer,
    superAdminUserTeamMember: superAdminUserTeamMemberReducer,
    superAdminProductsReducer,
    superAdminProductDetails: superAdminProductDetailsReducer,
    superAdminReports: superAdminReportsReducer,
    superAdminSiteDetails: superAdminSitesDetailsReducer,
    superAdminSettings: superAdminSettingsReducer,
    superAdminPackageReducer,
    superAdminCompanies: superAdminCompanyReducer,
    DemoSiteApply: DemoSiteApplyReducer,
  });
