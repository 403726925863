import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_SUPER_ADMIN_PACKAGE_LIST,
  RENDER_SUPER_ADMIN_PACKAGE_LIST,
  LOAD_SUPER_ADMIN_PACKAGE_LIST_FAILED,
  ADD_SUPER_ADMIN_PACKAGE,
  ADD_SUPER_ADMIN_PACKAGE_SUCCESS,
  ADD_SUPER_ADMIN_PACKAGE_FAILED,
  UPDATE_SUPER_ADMIN_PACKAGE,
  UPDATE_SUPER_ADMIN_PACKAGE_SUCCESS,
  UPDATE_SUPER_ADMIN_PACKAGE_FAILED,
} from '../actions/SuperAdminPackageActions';

import { TOKEN_ERROR } from '../actions/index';
import { defaultApi } from '../utils/axiosApi';

export function* fetchPackageList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/package/all`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_SUPER_ADMIN_PACKAGE_LIST,
      packageData: data.data && data.data.packageData,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      allPermissions: data.data && data.data.allPermissions,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: LOAD_SUPER_ADMIN_PACKAGE_LIST_FAILED,
        message: data.message,
      });
    }
  }
}

export function* addPackageAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/package/add`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: ADD_SUPER_ADMIN_PACKAGE_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: ADD_SUPER_ADMIN_PACKAGE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updatePackageAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/package/update`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_SUPER_ADMIN_PACKAGE_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: UPDATE_SUPER_ADMIN_PACKAGE_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadPackageList() {
  yield takeLatest(LOAD_SUPER_ADMIN_PACKAGE_LIST, fetchPackageList);
}
export function* addPackage() {
  yield takeLatest(ADD_SUPER_ADMIN_PACKAGE, addPackageAttempt);
}
export function* updatePackage() {
  yield takeLatest(UPDATE_SUPER_ADMIN_PACKAGE, updatePackageAttempt);
}

export default function* rootSaga() {
  yield all([loadPackageList(), addPackage(), updatePackage()]);
}
