export const RESEND_VERIFICATION = 'RESEND_VERIFICATION';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_FAILURE = 'RESEND_VERIFICATION_FAILURE';
export const RESEND_VERIFICATION_VALIDATION_FAILED =
  'RESEND_VERIFICATION_VALIDATION_FAILED';

export function resendVerificationAction({ email }) {
  return {
    type: RESEND_VERIFICATION,
    credentials: {
      email,
    },
  };
}

export function resendVerificationLinkFromPanel(data) {
  return {
    type: RESEND_VERIFICATION,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
