export const SUPER_ADMIN_LOAD_ALL_DETAILS = 'SUPER_ADMIN_LOAD_ALL_DETAILS';
export const SUPER_ADMIN_RENDER_DETAILS = 'SUPER_ADMIN_RENDER_DETAILS';
export const SUPER_ADMIN_LOAD_ALL_DETAILS_FAILED =
  'SUPER_ADMIN_LOAD_ALL_DETAILS_FAILED';

export function getAllDetails(data) {
  return {
    type: SUPER_ADMIN_LOAD_ALL_DETAILS,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
