import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ siteData, handelCheckBox, siteListShowColumn }) {
  return (
    <tr className={!siteData.deactivated ? '' : 'Deactivated'}>
      <td className="selector">
        <label className="Checkbox">
          <input
            type="checkbox"
            key={siteData.id}
            name={siteData.id}
            id={siteData.baseNameSlug}
            checked={siteData.isChecked}
            onChange={(e) => handelCheckBox(e)}
          />
          <span />
        </label>
      </td>

      {siteListShowColumn &&
        siteListShowColumn
          .filter((item) => item.isChecked)
          .map((column, index) =>
            column.name === 'url' ? (
              <td key={index} className="DetailsButtonWrapper">
                <p>
                  <a
                    href={siteData[column.name]}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {siteData[column.name]}
                  </a>
                </p>
                <Link
                  to={`/user/admin_user/sites-details?product=${
                    siteData.baseNameSlug
                  }&url=${siteData[column.name]}`}
                >
                  <button className="LabelButton PrimaryButton">Details</button>
                </Link>
              </td>
            ) : (
              <td key={index}>
                <p>{siteData[column.name]}</p>
              </td>
            )
          )}

      <td />
    </tr>
  );
}
