import { connect } from 'react-redux';
import SitesDetails from '../components/Report/SitesDetails';

import {
  getAllDetails,
  siteDetailsForDemo,
} from '../actions/SitesDetailsActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapStateToProps = (state) => ({
  allDetails: state.siteDetails.siteDetails,
  logUpdate: state.siteDetails.logUpdate,
  detailsLoader: state.siteDetails.detailsLoader,
  requestSuccess: state.siteDetails.requestSuccess,
  loading: state.siteDetails.loading,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  companyNotFound: state.auth && state.auth.companyNotFound,

  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const mapDispatchToProps = (dispatch) => ({
  siteDetails: (value) => dispatch(getAllDetails(value)),
  siteDetailsForDemo: (value) => dispatch(siteDetailsForDemo(value)),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
});

const SitesDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SitesDetails);

export default SitesDetailsContainer;
