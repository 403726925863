import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import Modal from 'react-bootstrap/Modal';
import Pagination from 'rc-pagination';
import WebhookList from './WebhookList';
import nodataImage from '../../assets/img/no_data_full.svg';
import MailWebhookModal from './MailWebhookModal.js';

const Webhook = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <div className="SecondarySidebarContent">
      <section className="DataViewSection">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">WebHooks</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) =>
                  props.handelSearchForMailWebhook(e.target.value)
                }
                onKeyPress={(e) => props.handelSearchEnterForMailWebhook(e)}
                value={props.mailWebhookSearch}
              />
            </div>

            <button
              className="ml-3 Button PrimaryButton"
              data-toggle="modal"
              data-target="#AddMemberModal"
              onClick={props.handleMailWebhookModalShow}
            >
              <span>Add Webhook</span>
            </button>
          </div>
        </div>
        <div className="TabContent">
          <div className="DataViewTable">
            <div className="TableWrapper">
              <table className="DataTable">
                <thead>
                  <tr>
                    <th>Plugin Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!props.setingsLoader &&
                  props.mailWebhookProductsInfo &&
                  props.mailWebhookProductsInfo.length > 0 ? (
                    props.mailWebhookProductsInfo.map((data, index) => (
                      <WebhookList
                        key={index}
                        data={data}
                        handleEditModalForMailWebhook={
                          props.handleEditModalForMailWebhook
                        }
                        handelDeleteMailWebhook={props.handelDeleteMailWebhook}
                      />
                    ))
                  ) : (
                    <tr style={{ textAlign: 'center' }} className="Deactivated">
                      <td colSpan="4">
                        <img
                          src={nodataImage}
                          style={{ height: '600px' }}
                          alt=""
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="TableFooter">
              <div className="TableFooterContent">
                {props.webhookTotal > 10 && (
                  <>
                    <form action="">
                      <div className="d-flex align-items-center">
                        <label htmlFor="ChooseNumber">Show</label>
                        <div className="CustomSelectNumber">
                          <select
                            onChange={(e) =>
                              props.setMailWebhookPerPage(e.target.value)
                            }
                            className="custom-select"
                            id="ChooseNumber"
                            value={props.mailWebhookPerPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <label htmlFor="ChooseNumber">Entires</label>
                      </div>
                    </form>
                    <Pagination
                      className="PaginationList ml-auto"
                      total={props.webhookTotal}
                      current={props.webhookCurrentPage}
                      onChange={(e) => props.handelPageChange(e)}
                      showTitle={false}
                      pageSize={props.mailWebhookPerPage}
                      showPrevNextJumpers={false}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={props.showMailWebhookModal}
          onHide={props.handleMailWebhookModalClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {props.editMailWebhookModal
                ? 'Update Webhook'
                : 'Add new Webhook'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={props.handleMailWebhookModalClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <MailWebhookModal
              slug={props.mailWebhookPluginSlug}
              link={props.mailWebhookLink}
              tag={props.mailWebhookTag}
              status={props.mailWebhookStatus}
              setEditMailWebhookModal={props.setEditMailWebhookModal}
              setMailWebhookLink={props.setMailWebhookLink}
              setMailWebhookTag={props.setMailWebhookTag}
              setMailWebhookStatus={props.setMailWebhookStatus}
              setMailWebhookPluginSlug={props.setMailWebhookPluginSlug}
              mailWebhookProductsSelectOptions={
                props.mailWebhookProductsSelectOptions
              }
              mailWebhookPluginName={props.mailWebhookPluginName}
              setMailWebhookPluginName={props.setMailWebhookPluginName}
              changeMailWebhookProductChange={
                props.changeMailWebhookProductChange
              }
              handelWebhookStatusChange={props.handelWebhookStatusChange}
              submitMailWebhook={props.submitMailWebhook}
              errMailWebhook={props.errMailWebhook}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={props.confirmBox}
          onHide={props.handelConfirmBoxClose}
          size="lg"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              Delete Confirmation
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={props.handelConfirmBoxClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <>
              <h4
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                {props.confirmBoxTitle}
              </h4>

              <p
                className="ActionButtonGroup"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <button
                  onClick={(e) => props.handelSubmitMailWebhookDelete()}
                  className="Button SuccessButton"
                  title="Yes"
                >
                  <span>
                    {props.modalLoader ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Yes'
                    )}
                  </span>
                </button>
                <button
                  className="Button DangerButton"
                  title="No"
                  disabled={props.modalLoader}
                  onClick={(e) => {
                    !props.modalLoader && props.setConfirmBox(false);
                    !props.modalLoader && props.setConfirmBoxTitle('');
                    !props.modalLoader && props.setMailWebhookId('');
                  }}
                >
                  No
                </button>
              </p>
            </>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );

export default Webhook;
