import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const ProfileModal = ({
  email,
  name,
  setName,
  userName,
  setUserName,
  setEmail,
  err,
  submitProfileUpdate,
  loading,
  modalLoader,
  profile_image,
  handelImageUpdate,
}) => (
  <>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label>Full Name :</label>
        <input
          type="text"
          className="form-control"
          value={name}
          placeholder="Your Name..."
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {err && err.name && err.name.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={name}>
            {err.name}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>User Name :</label>
        <input
          type="text"
          className="form-control"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="UserName..."
        />
      </div>
      {err && err.username && err.username.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={userName}>
            {err.username}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Email Address :</label>
        <input
          type="text"
          className="form-control"
          placeholder="Your Email Address..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {err && err.email && err.email.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={email}>
            {err.email}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Profile Image :</label>
        <div className="UpdateOption">
          <div className="ImageSelector" style={{ flexDirection: 'column' }}>
            <div className="ImgBox">
              <img
                src={profile_image}
                alt="profile"
                style={{ height: '100%' }}
                className="w-100"
              />
            </div>
            <label className="FileBrowseButton mt-2">
              <span className="text-break">Change Profile Picture</span>
              <input
                type="file"
                onChange={(e) => handelImageUpdate(e.target.files[0])}
                accept=".png, .jpg, .jpeg"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex mt-5">
        <button
          onClick={(e) => submitProfileUpdate(e)}
          className="Button PrimaryButton ml-auto"
        >
          <span>
            {modalLoader ? (
              <Spinner animation="border" variant="light" />
            ) : (
              'Submit'
            )}
          </span>
        </button>
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  modalLoader: state.superAdminSettings.modalLoader,
});

export default connect(mapStateToProps)(ProfileModal);
