import React from 'react';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];

const CompanyList = ({
  handleEditModal,
  companyData,
  activeProfile,
  handelDeleteCompany,
  userPermission,
  accountType,
  user,
}) => (
  <>
    <tr>
      <td>
        <p>{companyData.name}</p>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <p
            className={`SmallButton ml-3 ${
              companyData.companyStatus === options[0].value
                ? 'SuccessButton'
                : 'DangerButton'
            }`}
          >
            {options
              .filter((f) => f.value === companyData.companyStatus)
              .map((option) => option.label.toUpperCase())}
          </p>
        </div>
      </td>
      <td className="ActionButtonColumn">
        {accountType === 'Partner' ? (
          <p className="ActionButtonGroup">
            <button
              onClick={(e) => handleEditModal(companyData.id)}
              className="ActionButton PrimaryAlert"
              title="Edit"
            >
              <span className="Icon">
                <i className="insight wpi-edit" />
              </span>
            </button>
            <button
              className="ActionButton DangerAlert"
              title="Delete"
              onClick={(e) => handelDeleteCompany(companyData.id)}
            >
              <span className="Icon">
                <i className="insight wpi-delete" />
              </span>
            </button>
          </p>
        ) : (
          <p className="ActionButtonGroup">
            {user.id === companyData.companyBy ? (
              <>
                <button
                  onClick={(e) => handleEditModal(companyData.id)}
                  className="ActionButton PrimaryAlert"
                  title="Edit"
                >
                  <span className="Icon">
                    <i className="insight wpi-edit" />
                  </span>
                </button>
                <button
                  className="ActionButton DangerAlert"
                  title="Delete"
                  onClick={(e) => handelDeleteCompany(companyData.id)}
                >
                  <span className="Icon">
                    <i className="insight wpi-delete" />
                  </span>
                </button>
              </>
            ) : (
              <>
                {userPermission
                  .filter((p) => p.name === 'edit-company' && p.checked)
                  .map((b) => (
                    <button
                      onClick={(e) => handleEditModal(companyData.id)}
                      className="ActionButton PrimaryAlert"
                      title="Edit"
                    >
                      <span className="Icon">
                        <i className="insight wpi-edit" />
                      </span>
                    </button>
                  ))}
                {userPermission
                  .filter((p) => p.name === 'delete-company' && p.checked)
                  .map((up) => (
                    <>
                      {companyData.id !== activeProfile.id && (
                        <button
                          className="ActionButton DangerAlert"
                          title="Delete"
                          onClick={(e) => handelDeleteCompany(companyData.id)}
                        >
                          <span className="Icon">
                            <i className="insight wpi-delete" />
                          </span>
                        </button>
                      )}
                    </>
                  ))}
              </>
            )}
          </p>
        )}
      </td>
    </tr>
  </>
);

export default CompanyList;
