import { toast } from 'react-toastify';
import {
  SUPER_ADMIN_LOAD_ALL_DETAILS,
  SUPER_ADMIN_RENDER_DETAILS,
  SUPER_ADMIN_LOAD_ALL_DETAILS_FAILED,
} from '../actions/SuperAdminSitesDetailsActions';

import { SIGNED_OUT } from '../actions/index';

const initialState = {
  siteDetails: [],
  logUpdate: [],
};

export default function siteDetails(state = initialState, action) {
  switch (action.type) {
    case SUPER_ADMIN_LOAD_ALL_DETAILS:
      return {
        ...state,
        loading: true,
        detailsLoader: true,
      };
    case SUPER_ADMIN_RENDER_DETAILS:
      return {
        ...state,
        siteDetails: action.siteDetails,
        logUpdate: action.logUpdate,
        detailsLoader: false,
      };
    case SUPER_ADMIN_LOAD_ALL_DETAILS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        detailsLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
