import React from 'react';
import profileImg from '../../../assets/img/dummy-profile.png';

const statusOptions = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
  { value: 3, label: 'Invited' },
];

const UserList = ({
  handleEditModal,
  userData,
  handelFindTeamMembers,
  handleShowUserSubscriptionModal,
}) => {
  const user = JSON.parse(localStorage.getItem('user_info'));
  return (
    <>
      <tr>
        <td className="TeamMemberImage">
          <img src={profileImg} alt="" />
        </td>
        <td>
          <p>{userData.name}</p>
        </td>
        <td>
          <p>{userData.email}</p>
        </td>
        <td>
          <p>{userData.username}</p>
        </td>
        <td>
          <div className="d-flex align-items-center">
            {userData.email !== user.email && userData.id !== user.id ? (
              <>
                <div className="d-flex">
                  <p
                    className={`LabelButton ${
                      userData.is_verified === 1
                        ? 'SuccessAlert'
                        : 'DangerAlert'
                    }`}
                  >
                    {userData.is_verified === 1 ? 'Verified' : 'Unverified'}
                  </p>
                </div>
                <div className="d-flex">
                  <p
                    className={`SmallButton ml-3 ${
                      userData.status === 1 ? 'SuccessButton' : 'DangerButton'
                    }`}
                  >
                    {statusOptions
                      .filter((f) => f.value === userData.status)
                      .map((option) => option.label.toUpperCase())}
                  </p>
                </div>
              </>
            ) : (
              <p className="ColorPrimary">Main User</p>
            )}
          </div>
        </td>

        <td className="ActionButtonColumn">
          {userData.email !== user.email && userData.id !== user.id ? (
            <>
              <p className="ActionButtonGroup">
                <button
                  onClick={(e) => handleEditModal(userData.id)}
                  className="ActionButton PrimaryAlert"
                  title="Edit"
                >
                  <span className="Icon">
                    <i className="insight wpi-edit" />
                  </span>
                </button>
                <button
                  onClick={(e) => handleShowUserSubscriptionModal(userData.id)}
                  className="ActionButton PrimaryAlert"
                  title="Subscription"
                >
                  <span className="Icon">
                    <i className="insight wpi-subscription" />
                  </span>
                </button>
                {/* <button
                  onClick={(e) => handelFindTeamMembers(userData.id)}
                  class="ActionButton WarningAlert"
                  title="TeamMembers"
                >
                  <span class="Icon">
                    <i class="insight wpi-users"></i>
                  </span>
                </button> */}
                {/* <button
                  className="ActionButton DangerAlert"
                  title="Delete"
                  onClick={(e) => handelDeleteTeamMember(userData.id)}
                >
                  <span className="Icon">
                    <i className="insight wpi-delete"></i>
                  </span>
                </button> */}
              </p>
            </>
          ) : (
            <p className="ColorPrimary"> - </p>
          )}
        </td>
      </tr>
    </>
  );
};

export default UserList;
