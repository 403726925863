import { toast } from 'react-toastify';
import {
  LOAD_PRODUCT_DETAILS,
  RENDER_PRODUCT_DETAILS,
  LOAD_PRODUCT_DETAILS_FAILED,
  DOWNLOAD_SDK_SUCCESS,
  DOWNLOAD_SDK_FAILED,
  RESET_DOWNLOAD_SDK,
} from '../actions/ProductDetailsActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  productDetails: [],
  requestSuccess: false,
  loading: false,
  validationError: [],
  sdk_link: undefined,
  detailsLoader: false,
};

export default function productDetails(state = initialState, action) {
  switch (action.type) {
    case LOAD_PRODUCT_DETAILS:
      return {
        ...state,
        loading: true,
        detailsLoader: true,
      };

    case DOWNLOAD_SDK_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        sdk_link: action.link,
      };
    case RESET_DOWNLOAD_SDK:
      return {
        ...state,
        sdk_link: undefined,
      };
    case DOWNLOAD_SDK_FAILED:
      toast.error(action.message);
      return state;
    case RENDER_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.productDetails,
        loading: false,
        detailsLoader: false,
      };
    case LOAD_PRODUCT_DETAILS_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        detailsLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
