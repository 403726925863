import { all, put, takeLatest } from 'redux-saga/effects';
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_VALIDATION_FAILED,
  ACTIVE_COMPANY_NOT_FOUND,
} from '../actions/SigninActions';
import { defaultApi } from '../utils/axiosApi';

export function* signinAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/login`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    yield put({
      type: SIGN_IN_SUCCESS,
      credentials: response && response.data,
    });
  } else if (error) {
    const { data } = error;
    if (data.status === 'validation_error') {
      yield put({
        type: SIGN_IN_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: SIGN_IN_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* signin() {
  yield takeLatest(SIGN_IN, signinAttempt);
}

export default function* rootSaga() {
  yield all([signin()]);
}
