import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import WpiLogo from '../../assets/SVG/logo-white.svg';
import wpiLogin from '../../assets/img/login.svg';

const CompanyCreateForm = ({
  history,
  validationError,
  userToken,
  userCompanyCreate,
  companyCreateForUser,
  resetCompanyCreateForm,
  companyCreateLoader,
}) => {
  let comapnyName;
  const [userKey, setUserKey] = useState('');
  const [err, setErr] = useState({
    comapnyName: [],
  });
  /* eslint-disable */

  useEffect(() => {
    if (companyCreateForUser && companyCreateForUser.redirect) {
      comapnyName.value = '';
      history.push('/user/dashboard');
    }
  }, [companyCreateForUser]);

  useEffect(() => {
    setUserKey(userToken);
  }, []);

  useEffect(() => {
    if (validationError.length > 0) {
      let companyNameErr = [];

      if (validationError[0].name) {
        companyNameErr = validationError[0].name;
      }

      setErr({
        ...err,
        name: companyNameErr,
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const validateForm = () => {
    const errors = [];
    const companyNameErr = [];

    if (comapnyName.value === '') {
      companyNameErr.push("Company name can't be empty");
      errors.push("Company name can't be empty");
    }

    if (comapnyName.length > 255) {
      companyNameErr.push("Company name can't be over 255 charecter");
      errors.push("Company name can't be over 255 charecter");
    }

    if (errors.length > 0) {
      setErr({
        name: companyNameErr,
      });
    }

    return errors;
  };

  const submitCompanyCreateForm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length !== 0) {
      userCompanyCreate({
        name: comapnyName.value,
        key: userKey,
      });
    }
  };
  const resetCompanyCreateFormfunc = (e) => {
    e.preventDefault();
    resetCompanyCreateForm();
    history.push('/auth/sign-in');
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitCompanyCreateForm}>
              <Link
                className="BackButton mb-4"
                onClick={resetCompanyCreateFormfunc}
              >
                <div className="BackButtonIcon">
                  <i className="insight wpi-sort-left" />
                </div>
                <div className="BackButtonText">Back to Login</div>
              </Link>
              <h2>Company Information</h2>
              <div className="FormGroup mb-4">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company name"
                  id="name"
                  ref={(node) => {
                    comapnyName = node;
                  }}
                />
                {err && err.name && <p style={{ color: 'red' }}>{err.name}</p>}
              </div>
              <button className="Button PrimaryButton w-100">
                <span>
                  {companyCreateLoader ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    'Submit'
                  )}
                </span>
              </button>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default CompanyCreateForm;
