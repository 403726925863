import { toast } from 'react-toastify';
import {
  CREATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  LOAD_TEAM_MEMBER_LIST,
  RENDER_TEAM_MEMBER_LIST,
  CREATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_SUCCESS,
  RESET_REQUEST_SUCCESS,
  RESET_PAGE,
  LOAD_TEAM_MEMBER_FAILED,
  CREATE_TEAM_MEMBER_FAILED,
  UPDATE_TEAM_MEMBER_FAILED,
  DELETE_TEAM_MEMBER_FAILED,
  RESET_FIRST_TIME,
} from '../actions/TeamMemberActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  memberlist: [],
  currentPage: 1,
  totalPage: 1,
  roles: [],
  productList: [],
  total: 0,
  requestSuccess: false,
  loading: false,
  firstTime: true,
  teamMemberListLoader: false,
  modalLoader: false,
};

export default function teamMember(state = initialState, action) {
  switch (action.type) {
    case LOAD_TEAM_MEMBER_LIST:
      return {
        ...state,
        loading: true,
        teamMemberListLoader: true,
      };
    case CREATE_TEAM_MEMBER:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case UPDATE_TEAM_MEMBER:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case DELETE_TEAM_MEMBER:
      return {
        ...state,
        loading: true,
        modalLoader: true,
      };
    case RENDER_TEAM_MEMBER_LIST:
      return {
        ...state,
        productList: action.productList,
        roles: action.roles,
        memberlist: action.memberlist,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        loading: false,
        teamMemberListLoader: false,
      };

    case CREATE_TEAM_MEMBER_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };

    case UPDATE_TEAM_MEMBER_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };

    case DELETE_TEAM_MEMBER_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLoader: false,
      };

    case RESET_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        currentPage: 1,
      };

    case RESET_PAGE:
      return {
        ...state,
        currentPage: 1,
        loading: false,
      };
    case RESET_FIRST_TIME:
      return {
        ...state,
        firstTime: false,
      };
    case LOAD_TEAM_MEMBER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        teamMemberListLoader: false,
      };
    case CREATE_TEAM_MEMBER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case UPDATE_TEAM_MEMBER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case DELETE_TEAM_MEMBER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLoader: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
