import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../utils/Loader';

import routes from '../routes';

function AuthLayout({ loading, history, user }) {
  /* eslint-disable */
  useEffect(() => {
    const token = localStorage.getItem('access_token');

    if (token) {
      user.user_type === 'SUPER_ADMIN'
        ? history.push('/user/admin_user/dashboard')
        : history.push('/user/dashboard');
    }
  }, []);
  /* eslint-enable */

  const getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });

  return (
    <>
      <Loader isOpen={loading} />
      <Switch>{getRoutes(routes)}</Switch>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  user: state.auth && state.auth.signin && state.auth.signin.userInfo,
});

export default connect(mapStateToProps)(AuthLayout);
