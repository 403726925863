import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const statusOptions = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
];

const is_verifiedOptions = [
  { value: 1, label: 'Verified' },
  { value: 0, label: 'Unverified' },
];

const UserModal = ({
  email,
  setEmail,
  err,
  setStatus,
  status,
  name,
  setName,
  userName,
  setUserName,
  submitUser,
  setIs_verified_status,
  is_verified_status,
  password,
  setPassword,
  loading,
  modalLodar,
}) => (
  <>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Your email address"
          value={email}
          id="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {err && err.email && err.email.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={email}>
            {err.email}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Full Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="User full name"
          value={name}
          id="name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {err && err.name && err.name.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={name}>
            {err.name}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Username</label>
        <input
          type="text"
          className="form-control"
          placeholder="Username"
          value={userName}
          id="name"
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      {err && err.userName && err.userName.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={userName}>
            {err.userName}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Set new password"
          value={password}
          id="name"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {err && err.password && err.password.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={password}>
            {err.password}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Status</label>
        <br />
        <div style={{ width: '100%' }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            id="status"
            onChange={(e) => setStatus(e)}
            options={statusOptions}
            value={statusOptions.find((s) => s.value === status.value)}
          />
        </div>
      </div>
      {err && err.status && err.status.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={status}>
            {err.status}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Verified Status</label>
        <br />
        <div style={{ width: '100%' }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            id="status"
            onChange={(e) => setIs_verified_status(e)}
            options={is_verifiedOptions}
            value={is_verifiedOptions.find(
              (s) => s.value === is_verified_status.value
            )}
          />
        </div>
      </div>
      {err && err.is_verified_status && err.is_verified_status.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={is_verified_status}>
            {err.is_verified_status}
          </p>
        </div>
      )}
      <div className="d-flex mt-5">
        <button className="Button PrimaryButton ml-auto" onClick={submitUser}>
          <span>
            {modalLodar ? (
              <Spinner animation="border" variant="light" />
            ) : (
              'Submit'
            )}
          </span>
        </button>
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  modalLodar: state.superAdminUser.modalLodar,
});

export default connect(mapStateToProps)(UserModal);
