import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import OverviewCardData from './OverviewCardData';
import SitesListTableBody from './SitesListTableBody';

import nodataImage from '../../../assets/img/no_data_full.svg';
import TogglerIconSvg from '../../../assets/img/toggler-square.svg';

import { colorVariation } from '../../../utils/helper';

const innerHeight = 600;

const opacity = 1.0;
const dateSelectoptions = [
  { value: 1, label: 'Yesterday' },
  { value: 0, label: 'Today' },
  { value: 7, label: 'Last 7 days' },
  { value: 15, label: 'Last 15 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 365, label: 'Last 1 Year' },
  { value: 'custom', label: 'Custom Date' },
];

const tableColumnNames = [
  { isChecked: true, name: 'url', lable: 'URL' },
  { isChecked: true, name: 'since', lable: 'Since' },
  { isChecked: true, name: 'deactivated', lable: 'Deactivated' },
  { isChecked: true, name: 'pluginVersion', lable: 'Version' },
  { isChecked: true, name: 'language', lable: 'Language' },
  { isChecked: true, name: 'alexaRank', lable: 'Alexa Rank' },
  { isChecked: true, name: 'status', lable: 'Status' },
  // 'plugin_slug',
  { isChecked: true, name: 'plugin_Name', lable: 'Plugin' },
  { isChecked: true, name: 'updatedTime', lable: 'Updated Time' },
  { isChecked: true, name: 'phpVersion', lable: 'PHP Version' },
  { isChecked: true, name: 'wpInsVersion', lable: 'WPIns' },
  { isChecked: true, name: 'siteVersion', lable: 'Site Version' },
];

let pluginVerionName = [];
let pluginVersionCount = [];
let pluginVerionChartColor = [];
let phpVersionName = [];
let phpVersionCount = [];
let phpVersionChartColor = [];
let laguagesChartColor = [];
let wpVersionChartColor = [];
let statusChartColor = [];
let deactivatedReasonChartColor = [];
let laguagesName = [];
let laguagesCount = [];
let wpVersionName = [];
let wpVersionCount = [];
let statusName = [];
let statusCount = [];

let deactivatedReasonName = [];
let deactivatedReasonCount = [];

let analyticsDates = [];
let analyticsCounts = [];

let mailChimpPluginCount = [];
let mailChimpPluginSuccessDataCount = [];
let mailChimpPluginUnSuccessDataCount = [];

const ChartOptions = {
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 1600,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: true,
        },
      },
    },
    {
      breakpoint: 650,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
    {
      breakpoint: 500,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
    {
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
  ],
  legend: {
    position: 'right',
    horizontalAlign: 'left',
    formatter(seriesName, opts) {
      let total = 0;
      for (const x of opts.w.globals.series) {
        total += x;
      }
      const selected = opts.w.globals.series[opts.seriesIndex];
      return `${seriesName} : ${selected} ( ${(
        (selected / total) *
        100
      ).toFixed(2)} % )`;
      // return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
    },
  },
  tooltip: {
    enabled: true,
    custom({ series, seriesIndex, dataPointIndex, w }) {
      let total = 0;
      for (const x of series) {
        total += x;
      }
      const selected = series[seriesIndex];
      return `${w.config.labels[seriesIndex]} : ${selected} ( ${(
        (selected / total) *
        100
      ).toFixed(2)} % )`;
    },
  },
};

const d = new Date();
d.setDate(d.getDate() - 7);

const Dashboard = (props) => {
  const superAdminToggolerRef = useRef();

  const exportDropDownRef = useRef();
  const customDateRangeRef = useRef();

  const [showExportDropDown, setShowExportDropDown] = useState(false);

  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(new Date(d));
  const [endDate, setEndDate] = useState(new Date());
  const [showAll, setShowAll] = useState(false);
  const [siteListData, setSiteListData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tableColumnToggoler, setTableColumnToggoler] = useState(false);
  const [siteListShowColumn, setSiteListShowColumn] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    value: 'all',
    label: 'All',
  });
  const [selectedDateRange, setSelectedDateRange] = useState({
    value: 7,
    label: 'Last 7 days',
  });
  const [showDateRange, setShowDateRange] = useState(false);

  const productSelectoptions = [
    { value: 'all', label: 'All' },
    ...props.allProducts.map((Product) => {
      const newArray = {};
      newArray.value = Product.base_name_slug;
      newArray.label = Product.base_name;
      return newArray;
    }),
  ];

  const [dateDiff, setDateDiff] = useState(
    moment(endDate).diff(startDate, 'days')
  );

  /* eslint-disable */

  useEffect(() => {
    document.addEventListener('mousedown', handelTableColumnToggolerChange);
    document.addEventListener('mousedown', handelExportDropDownToggolerChange);
    document.addEventListener('mousedown', handelCustomDateRangeChange);

    return () => {
      document.removeEventListener(
        'mousedown',
        handelTableColumnToggolerChange
      );
      document.removeEventListener(
        'mousedown',
        handelExportDropDownToggolerChange
      );
      document.removeEventListener('mousedown', handelCustomDateRangeChange);
    };
  }, []);

  const handelTableColumnToggolerChange = (e) => {
    if (
      Object.keys(superAdminToggolerRef).length > 0 &&
      superAdminToggolerRef.current &&
      !superAdminToggolerRef.current.contains(e.target)
    ) {
      setTableColumnToggoler(false);
    }
  };

  const handelExportDropDownToggolerChange = (e) => {
    if (
      Object.keys(exportDropDownRef).length > 0 &&
      exportDropDownRef.current &&
      !exportDropDownRef.current.contains(e.target)
    ) {
      setShowExportDropDown(false);
    }
  };

  const handelCustomDateRangeChange = (e) => {
    if (
      Object.keys(customDateRangeRef).length > 0 &&
      customDateRangeRef.current &&
      !customDateRangeRef.current.contains(e.target)
    ) {
      setFocusedInput(null);
    }
  };

  const toggleExportDropdown = (e) => {
    if (showExportDropDown) {
      setShowExportDropDown(false);
    } else {
      setShowExportDropDown(true);
    }
  };

  useEffect(() => {
    if (
      props.sitesConfig &&
      props.sitesConfig.siteListTable &&
      props.sitesConfig.siteListTable.show
    ) {
      setSiteListShowColumn(props.sitesConfig.siteListTable.show);
    } else {
      setSiteListShowColumn(tableColumnNames);
    }
  }, [props.sitesConfig]);

  // useEffect(() => {
  //   props.getAllDashboardReportForSuperAdmin();
  // }, []);

  useEffect(() => {
    if (props.siteList.length > 0) {
      var dataArray = props.siteList.map((list) => {
        var newArray = {};
        newArray.id = list.id;
        newArray.url = list.url;
        newArray.since = list.created_at
          ? moment(list.created_at).format('YYYY-MM-DD HH:mm')
          : null;
        newArray.deactivated = list.deactivated_date
          ? moment(new Date(list.deactivated_date * 1000)).format(
              'YYYY-MM-DD HH:mm'
            )
          : null;
        newArray.pluginVersion = list.version;
        newArray.language = list.site_language;
        newArray.alexaRank = list.globalRank;
        newArray.status = list.status;
        newArray.baseNameSlug = list.plugin_slug;
        newArray.plugin_Name = list.plugin;
        newArray.updatedTime = list.updated_at
          ? moment(list.updated_at).format('YYYY-MM-DD HH:mm')
          : null;
        newArray.isChecked = false;
        newArray.phpVersion = list.php_version;
        newArray.wpInsVersion = list.wpins_version;
        newArray.siteVersion = list.site_version;
        return newArray;
      });
      setSiteListData(dataArray);
    } else {
      setSiteListData([]);
    }
    setSelectAll(false);
  }, [props.siteList]);

  useEffect(() => {
    props.getAllDashboardReportForSuperAdmin({
      search_product: selectedProduct.value,
      search_date_range: selectedDateRange.value,
    });
  }, [selectedProduct, selectedDateRange]);

  // useEffect(() => {
  //   !props.dashboardSearchLoader && firstCall && setFirstCall(false);
  // }, [props.dashboardSearchLoader]);

  // useEffect(() => {
  //   props.innitialCall &&
  //     firstCall &&
  //     props.dashboardSearchLoader &&
  //     setFirstCall(false);
  // }, [props.innitialCall]);

  /* eslint-enable */

  mailChimpPluginCount = props.mailchimpAnalytics.map((data) => data.plugin);

  mailChimpPluginSuccessDataCount = props.mailchimpAnalytics.map(
    (data) => data.subcribed
  );
  mailChimpPluginUnSuccessDataCount = props.mailchimpAnalytics.map(
    (data) => data.unsubscribed
  );
  const barChartDataseries = [
    {
      name: 'Success',
      data: mailChimpPluginSuccessDataCount,
    },
    {
      name: 'Unsuccess',
      data: mailChimpPluginUnSuccessDataCount,
    },
  ];

  const barChartDataOptions = {
    chart: {
      type: 'bar',
    },
    colors: ['#0000FF', '#dc3545'],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },

        barHeight: '50%',
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: mailChimpPluginCount,
    },
    yaxis: {
      categories: mailChimpPluginCount,
      labels: {
        formatter(val, opt) {
          const name = val;
          const success = opt && opt.w.globals.initialSeries[0].name;
          const successData =
            opt && opt.w.globals.initialSeries[0].data[opt.dataPointIndex];
          const unSuccess = opt && opt.w.globals.initialSeries[1].name;
          const unSuccessData =
            opt && opt.w.globals.initialSeries[1].data[opt.dataPointIndex];
          return opt
            ? [
                name,
                `${success}:${successData}`,
                `${unSuccess}:${unSuccessData}`,
              ]
            : undefined;
        },
      },
    },
    tooltip: {
      x: {
        show: true,
        formatter(value, { series, seriesIndex, dataPointIndex, w }) {
          return value;
        },
        title: {
          formatter(val, opt) {
            const name = val;
            return name;
          },
        },
      },
      y: {
        show: true,
        formatter(value, { series, seriesIndex, dataPointIndex, w }) {
          return value;
        },
      },
    },
  };

  analyticsCounts = props.analytics.map((data) => data.count);

  analyticsDates = props.analytics.map((v) => v.date);

  const AreaChartSeries = [
    {
      name: 'Update log',
      data: analyticsCounts,
    },
  ];

  const AreaChartOption = {
    chart: {
      height: 350,
      type: 'area',
      id: 'Update log',
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      width: 3,
      categories: analyticsDates,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
    tooltip: {
      x: {
        format: 'yyyy-mm-dd',
      },
    },
  };

  pluginVersionCount = props.pluginVersion.map((data) => data.user);

  pluginVerionName = props.pluginVersion.map((v) => v.version);
  let pluginVerionColorOpacity = opacity;
  const pluginVerionColorOpacityChange =
    pluginVersionCount && pluginVersionCount.length > 0
      ? 1 / (pluginVersionCount.length + 4)
      : 0;
  pluginVerionChartColor = pluginVerionName.map((v) => {
    const changedColor = colorVariation(255, 125, 0, pluginVerionColorOpacity);
    pluginVerionColorOpacity = Math.max(
      0.0,
      pluginVerionColorOpacity - pluginVerionColorOpacityChange
    );
    return changedColor;
  });

  /* plugin version chart */

  const pluginVersionChartSeries = pluginVersionCount;
  const pluginVersionChartOptions = {
    chart: {
      type: 'donut',
    },
    labels: pluginVerionName,
    colors: pluginVerionChartColor,
    ...ChartOptions,
  };

  phpVersionCount = props.phpVersion.map((d) => d.user);
  phpVersionName = props.phpVersion.map((d) => {
    const version = d.version.split('-');
    return version[0];
  });
  let phpVersionColorOpacity = opacity;
  const phpVersionColorOpacityChange =
    phpVersionCount && phpVersionCount.length > 0
      ? 1 / (phpVersionCount.length + 4)
      : 0;

  phpVersionChartColor = phpVersionName.map((v) => {
    const changedColor = colorVariation(98, 60, 234, phpVersionColorOpacity);
    phpVersionColorOpacity = Math.max(
      0.0,
      phpVersionColorOpacity - phpVersionColorOpacityChange
    );
    return changedColor;
  });

  const phpVersionChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: phpVersionName,
    colors: phpVersionChartColor,
    ...ChartOptions,
  };

  laguagesCount = props.language.map((d) => d.user);
  laguagesName = props.language.map((d) => d.version);
  let laguagesColorOpacity = opacity;
  const laguagesColorOpacityChange =
    laguagesCount && laguagesCount.length > 0
      ? 1 / (laguagesCount.length + 4)
      : 0;
  laguagesChartColor = laguagesName.map((l) => {
    const changedColor = colorVariation(0, 208, 255, laguagesColorOpacity);
    laguagesColorOpacity = Math.max(
      0.0,
      laguagesColorOpacity - laguagesColorOpacityChange
    );
    return changedColor;
  });

  const languageChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: laguagesName,
    colors: laguagesChartColor,
    ...ChartOptions,
  };

  wpVersionCount = props.wpVersion.map((d) => d.user);
  wpVersionName = props.wpVersion.map((d) => d.version);
  let wpVersionColorOpacity = opacity;
  const wpVersionColorOpacityChange =
    wpVersionCount && wpVersionCount.length > 0
      ? 1 / (wpVersionCount.length + 4)
      : 0;
  wpVersionChartColor = wpVersionName.map((v) => {
    const changedColor = colorVariation(229, 20, 46, wpVersionColorOpacity);
    wpVersionColorOpacity = Math.max(
      0.0,
      wpVersionColorOpacity - wpVersionColorOpacityChange
    );

    return changedColor;
  });

  const wordpressVersionChartOptios = {
    chart: {
      type: 'donut',
    },
    labels: wpVersionName,
    colors: wpVersionChartColor,
    ...ChartOptions,
  };

  statusCount = props.status.map((d) => d.user);
  statusName = props.status.map((d) => d.version);
  let statusColorOpacity = opacity;
  const statusColorOpacityChange =
    statusCount && statusCount.length > 0 ? 1 / (statusCount.length + 4) : 0;
  statusChartColor = statusName.map((v) => {
    const changedColor = colorVariation(67, 188, 156, statusColorOpacity);
    statusColorOpacity = Math.max(
      0.0,
      statusColorOpacity - statusColorOpacityChange
    );
    return changedColor;
  });
  const statusChartOptions = {
    chart: {
      type: 'donut',
    },
    labels: statusName,
    colors: statusChartColor,
    ...ChartOptions,
  };

  deactivatedReasonCount = props.de_active_reasons.map((d) => d.user);
  deactivatedReasonName = props.de_active_reasons.map((d) => d.version);
  let deactivatedReasonColorOpacity = opacity;
  const deactivatedReasonOpacityChange =
    deactivatedReasonCount && deactivatedReasonCount.length > 0
      ? 1 / (deactivatedReasonCount.length + 4)
      : 0;
  deactivatedReasonChartColor = deactivatedReasonName.map((v) => {
    const changedColor = colorVariation(
      215,
      131,
      235,
      deactivatedReasonColorOpacity
    );
    deactivatedReasonColorOpacity = Math.max(
      0.0,
      deactivatedReasonColorOpacity - deactivatedReasonOpacityChange
    );

    return changedColor;
  });

  const deactivatedResonsChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: deactivatedReasonName,
    colors: deactivatedReasonChartColor,
    ...ChartOptions,
  };

  const toggoleShowAll = (e) => {
    e.preventDefault();
    if (showAll) {
      setShowAll(false);
    } else {
      setShowAll(true);
    }
  };

  const handelCheckBox = (e) => {
    const { name } = e.target;
    const { checked } = e.target;
    const baseName = e.target.id;

    if (name === 'checkAll') {
      setSelectAll(checked);
      const list = siteListData.map((item) => ({
        ...item,
        isChecked: checked,
      }));
      setSiteListData(list);
    } else {
      const list = siteListData.map((item) =>
        item.id === name && item.baseNameSlug === baseName
          ? { ...item, isChecked: checked }
          : item
      );
      setSiteListData(list);
      setSelectAll(siteListData.every((list) => list.isChecked));
    }
  };

  const handelTableColumnToggoler = (e) => {
    e.preventDefault();
    if (tableColumnToggoler) {
      setTableColumnToggoler(false);
    } else {
      setTableColumnToggoler(true);
    }
  };

  const handleColumnShowCheckbox = (name, checked) => {
    const list = siteListShowColumn.map((item) =>
      item.name === name ? { ...item, isChecked: checked } : item
    );
    setSiteListShowColumn(list);
    props.storeUserConfigsFromSitesTable({
      siteListTable: list,
    });
  };

  const exportData = (e, pageOption) => {
    e.preventDefault();
    const row_selected = siteListData.some((item) => item.isChecked === true);
    let list;
    if (row_selected) {
      list = siteListData.filter((item) => item.isChecked === true);
    } else {
      list = siteListData;
    }

    const newOptions = siteListShowColumn
      .filter((item) => item.isChecked === true)
      .map((item) => item.name);

    // props.exportListData({
    //   sitelist: list,
    //   options: newOptions,
    // });

    const oldFilterData = {
      search_product: selectedProduct.value,
      search_date_range: showDateRange ? 'custom' : selectedDateRange.value,
      from_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      to_date: `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`,
    };

    props.exportListData({
      sitelist: list,
      options: newOptions,
      pageOption,
      filterOption: oldFilterData,
      request_from: 'dashboard',
      is_superAdmin: true,
    });
  };

  const clearCustomDateRange = (e) => {
    setShowDateRange(false);
    if (dateDiff && dateDiff === 1) {
      setSelectedDateRange(dateSelectoptions[1]);
    } else if (dateDiff && dateDiff === 0) {
      setSelectedDateRange(dateSelectoptions[2]);
    } else if (dateDiff && dateDiff === 7) {
      setSelectedDateRange(dateSelectoptions[3]);
    } else if (dateDiff && dateDiff === 15) {
      setSelectedDateRange(dateSelectoptions[4]);
    } else if (dateDiff && dateDiff === 30) {
      setSelectedDateRange(dateSelectoptions[5]);
    } else if (dateDiff && dateDiff === 365) {
      setSelectedDateRange(dateSelectoptions[6]);
    } else {
      setSelectedDateRange(dateSelectoptions[0]);
    }
  };

  const handelDateRangeSelect = (e) => {
    if (e.value === 'custom') {
      setShowDateRange(true);
    } else {
      const d = new Date();
      d.setDate(d.getDate() - e.value);
      setStartDate(new Date(d));
      setEndDate(new Date());
      setSelectedDateRange(e);
    }
  };

  const handelCustomDateSearch = (e) => {
    setDateDiff(moment(endDate).diff(startDate, 'days'));
    props.getAllDashboardReportForSuperAdmin({
      search_product: selectedProduct.value,
      search_date_range: 'custom',
      from_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      to_date: `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`,
    });
    setFocusedInput(null);
  };

  const getCalendarInfo = () => (
    <div className="date-range-footer">
      <div className="p-3">
        {/* <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 1 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 1
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).subtract(1, 'days').toDate());
              setStartDate(moment(date).subtract(1, 'days').toDate());
            }}
          >
            Yesterday
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 0 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 0
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(0, 'days').toDate());
            }}
          >
            Today
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 7 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 7
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(7, 'days').toDate());
            }}
          >
            Last 7 days
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 15 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 15
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(15, 'days').toDate());
            }}
          >
            Last 15 days
          </Button>
          <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 30 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 30
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(30, 'days').toDate());
            }}
          >
            Last 30 days
          </Button> */}
        <Button
          className="date-range-picker-button ml-2 PrimaryButton"
          onClick={(e) => handelCustomDateSearch(e)}
        >
          Apply
        </Button>
        {/* <Button
            className={`date-range-picker-button ml-2 ${
              dateDiff && dateDiff === 60 ? '' : 'PrimaryButton'
            }`}
            style={{
              background:
                dateDiff && dateDiff === 60
                  ? LightenDarkenColor('#623cea', -50)
                  : undefined,
            }}
            onClick={() => {
              let date = new Date();
              setEndDate(moment(date).toDate());
              setStartDate(moment(date).subtract(60, 'days').toDate());
            }}
          >
            Last 60 days
          </Button> */}
      </div>
    </div>
  );

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let i;
    const years = [];
    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>
      );
    }
    return (
      <div
        className="monthSelector"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '15px',
        }}
      >
        <div>
          <select
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {years}
          </select>
        </div>
      </div>
    );
  };

  const exploreMore = () => {
    props.history.push('/user/admin_user/Reports?report=sites_reports');
  };

  return (
    <>
      {/* overview section */}
      <section className="OverviewSection SectionGap">
        <div
          className={`SectionPanel PanelCollapsible ${
            props.allProducts && props.allProducts.length > 4 ? '' : 'Disable'
          }`}
        >
          <div className="PanelTitle">
            <div className="SectionTitle">Overview</div>
            {props.allProducts && props.allProducts.length > 4 && (
              <Button
                className="Button PrimaryButton ml-auto"
                variant="outline-light"
                onClick={toggoleShowAll}
              >
                {!showAll ? 'View All' : 'View Less'}
              </Button>
            )}
          </div>
          <div className="PanelItem">
            {props.dashboardSearchLoader ? (
              <div
                className="row row-cols-xl-4 HasColGap"
                style={{ justifyContent: 'center' }}
              >
                <div className="col">
                  <div
                    className="overviewloader"
                    style={{
                      padding: '20px 25px',
                      border: 'none',
                      flexDirection: 'column',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      minHeight: '124px',
                    }}
                  >
                    <Spinner animation="border" variant="dark" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {props.allProducts && props.allProducts.length > 0 ? (
                  <div className="row row-cols-xl-4 HasColGap">
                    {showAll
                      ? props.allProducts.map((Product, index) => (
                          <OverviewCardData
                            key={index}
                            productItemData={Product}
                          />
                        ))
                      : props.allProducts
                          .filter((_, i) => i <= 3)
                          .map((Product, index) => (
                            <OverviewCardData
                              key={index}
                              productItemData={Product}
                            />
                          ))}
                  </div>
                ) : (
                  <div
                    className="overviewloader"
                    style={{
                      padding: '20px 25px',
                      border: 'none',
                      flexDirection: 'column',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      minHeight: '124px',
                    }}
                  >
                    No Data Found
                  </div>
                )}
              </>
            )}
          </div>
          {props.allProducts && props.allProducts.length > 4 && (
            <div onClick={toggoleShowAll} className="PanelToggler">
              <div className="Icon">
                <i
                  className={`insight ${
                    showAll ? 'wpi-sort-down' : 'wpi-sort-up'
                  }`}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      {/* counter info */}
      <section className="CounterSection SectionGap">
        <div className="SectionTitleWrap">
          <div className="SectionTitle">Product Details</div>
          <div className="d-flex ml-auto flex-wrap">
            <div style={{ minWidth: '250px' }} className="dropdown ml-3">
              <Select
                name=""
                id="search_product"
                onChange={(e) => setSelectedProduct(e)}
                options={productSelectoptions}
                defaultValue={productSelectoptions[0]}
                className="SelectOption wp-dropdown"
                classNamePrefix="react-select"
              />
            </div>

            <div
              style={{ minWidth: '200px', zIndex: '99px' }}
              className="dropdown DatePickerWrapper ml-3"
            >
              {showDateRange ? (
                <div ref={customDateRangeRef}>
                  <DateRangePicker
                    isOutsideRange={(endDate) =>
                      !isInclusivelyBeforeDay(endDate, moment())
                    }
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={moment(startDate)}
                    endDate={moment(endDate)}
                    displayFormat="YYYY-MM-DD"
                    onDatesChange={({ startDate, endDate }) => {
                      startDate && setStartDate(startDate.toDate());
                      endDate && setEndDate(endDate.toDate());
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                      if (focusedInput === null) {
                        return;
                      }
                      setFocusedInput(focusedInput);
                    }}
                    renderCalendarInfo={() => getCalendarInfo()}
                    hideKeyboardShortcutsPanel
                    minimumNights={0}
                    renderMonthElement={renderMonthElement}
                  />
                  <button
                    onClick={(e) => clearCustomDateRange(e)}
                    className="CloseButton DangerAlert"
                    title="Close"
                  >
                    <span className="Icon">
                      <i className="insight wpi-close" />
                    </span>
                  </button>
                </div>
              ) : (
                <Select
                  name=""
                  id="search_date"
                  onChange={(e) => handelDateRangeSelect(e)}
                  options={dateSelectoptions}
                  defaultValue={selectedDateRange}
                  className="SelectOption wp-dropdown"
                  classNamePrefix="react-select"
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <>
        <section className="CounterSection SectionGap">
          <div className="row row-cols-md-4 HasColGap">
            <div className="col">
              <div className="CounterCard">
                <div className="CounterCardContent">
                  <div className="CounterCardIcon PrimaryCount">
                    <i className="insight wpi-user" />
                  </div>
                  <div className="CounterCardText">
                    {props.dashboardSearchLoader ? (
                      <div
                        className="dashboardSearchLoader"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <h4>{props.totalUserCount}</h4>
                    )}
                    <p>Total User</p>
                  </div>
                </div>
                <div className="WpiTooltip">
                  <button className="WpiTooltipButton">
                    <i className="insight wpi-info" />
                  </button>
                  <div className="WpiTooltipText">
                    Data updates in every 30 min
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="CounterCard">
                <div className="CounterCardContent">
                  <div className="CounterCardIcon SuccessCount">
                    <i className="insight wpi-user" />
                  </div>
                  <div className="CounterCardText">
                    {props.dashboardSearchLoader ? (
                      <div
                        className="dashboardSearchLoader"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <h4>{props.activeSitesCount}</h4>
                    )}
                    <p>Active User</p>
                  </div>
                </div>
                {/* <div className="IncDec Inc">
                        <div className="Icon">
                          <i className="insight wpi-arrow-up"></i>
                        </div>
                        <span>2.86% Increase</span>
                      </div> */}
              </div>
            </div>
            <div className="col">
              <div className="CounterCard">
                <div className="CounterCardContent">
                  <div className="CounterCardIcon DangerCount">
                    <i className="insight wpi-user" />
                  </div>
                  <div className="CounterCardText">
                    {props.dashboardSearchLoader ? (
                      <div
                        className="dashboardSearchLoader"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <h4>{props.deactivedSitesCount}</h4>
                    )}
                    <p>Inactive User</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="CounterCard">
                <div className="CounterCardContent">
                  <div className="CounterCardIcon YellowCount">
                    <i className="insight wpi-user" />
                  </div>
                  <div className="CounterCardText">
                    {props.dashboardSearchLoader ? (
                      <div
                        className="dashboardSearchLoader"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <h4>{props.newUserCount}</h4>
                    )}
                    <p>New User</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <>
          <section className="AnalyticSection SectionGap">
            <div className="SectionPanel">
              <div className="PanelTitle">
                <div className="SectionTitle">Analytics</div>
              </div>
              <div className="PanelItem">
                {props.dashboardSearchLoader ? (
                  <div
                    className="dashboardSearchLoader"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: innerHeight <= 750 ? 600 : `${innerHeight}px`,
                    }}
                  >
                    <Spinner animation="border" variant="dark" />
                  </div>
                ) : (
                  <>
                    {analyticsCounts && analyticsCounts.length > 0 ? (
                      <Chart
                        options={AreaChartOption}
                        series={AreaChartSeries}
                        type="area"
                        height={innerHeight <= 750 ? 600 : innerHeight}
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          height: '600px',
                        }}
                      >
                        No Data Found
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </section>
          {/* data view section */}
          <section className="DataViewSection SectionGap">
            <div className="DataViewHeading">
              <div className="DataViewHeadingLeftContent">
                <div className="SectionTitle">Site List</div>
              </div>
              {props.dashboardSearchLoader ||
                (siteListData && siteListData.length > 0 && (
                  <div className="DataViewHeadingRightContent">
                    <button
                      onClick={exploreMore}
                      className="Button SemiButtonPrimary"
                    >
                      <span>Explore More</span>
                    </button>

                    <Dropdown
                      className="dropdown DropdownControl"
                      onClick={() => toggleExportDropdown()}
                      show={showExportDropDown}
                      ref={exportDropDownRef}
                    >
                      <Dropdown.Toggle
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="ml-3 Button PrimaryButton"
                        variant="outline-light"
                        id="profile-dropdown"
                      >
                        <div className="Icon">
                          <i className="insight wpi-share" />
                        </div>
                        <span>Export</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        show={showExportDropDown}
                        className="dropdown-menu-right"
                      >
                        <ul>
                          <li>
                            <button onClick={(e) => exportData(e, 'this')}>
                              <p className="navigation-text">
                                Selected from this page
                              </p>
                            </button>
                          </li>
                          <li>
                            <button onClick={(e) => exportData(e, 'all')}>
                              <p className="navigation-text">
                                All from above filter
                              </p>
                            </button>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ))}
            </div>
            <div className="DataViewTable">
              <div className="TableWrapper">
                <table className="DataTable">
                  <thead>
                    {props.dashboardSearchLoader ||
                      (siteListData && siteListData.length > 0 && (
                        <tr>
                          <th className="selector">
                            <label className="Checkbox">
                              <input
                                type="checkbox"
                                name="checkAll"
                                checked={selectAll}
                                onChange={(e) => handelCheckBox(e)}
                              />
                              <span />
                            </label>
                          </th>

                          {siteListShowColumn &&
                            siteListShowColumn
                              .filter((item) => item.isChecked)
                              .map((column, index) => (
                                <th key={index}>
                                  <span className="d-flex align-items-center">
                                    {column.lable}
                                  </span>
                                </th>
                              ))}

                          <th
                            ref={superAdminToggolerRef}
                            className="TableToggler"
                          >
                            <div
                              className={`dropdown dropdown-menu-right ${
                                tableColumnToggoler ? 'show' : ''
                              }`}
                            >
                              <button
                                className="TogglerIcon"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                onClick={handelTableColumnToggoler}
                              >
                                <img src={TogglerIconSvg} alt="" />
                              </button>
                              <ul
                                className={`dropdown-menu ${
                                  tableColumnToggoler ? 'show' : ''
                                }`}
                              >
                                {siteListShowColumn &&
                                  siteListShowColumn.map((item, index) => (
                                    <li key={index} className="DropdownItem">
                                      <label className="Checkbox">
                                        <input
                                          type="checkbox"
                                          id={index}
                                          checked={
                                            item.isChecked ? 'checked' : ''
                                          }
                                          onChange={() =>
                                            item.isChecked
                                              ? handleColumnShowCheckbox(
                                                  item.name,
                                                  false
                                                )
                                              : handleColumnShowCheckbox(
                                                  item.name,
                                                  true
                                                )
                                          }
                                        />
                                        <span>{item.lable}</span>
                                      </label>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </th>
                        </tr>
                      ))}
                  </thead>
                  <tbody>
                    {props.dashboardSearchLoader ? (
                      <tr>
                        <td
                          colSpan="10"
                          style={{ textAlign: 'center' }}
                          height="600"
                        >
                          <Spinner animation="border" variant="dark" />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {siteListData.map((listData, i) => (
                          <SitesListTableBody
                            key={i}
                            siteData={listData}
                            handelCheckBox={handelCheckBox}
                            siteListShowColumn={siteListShowColumn}
                          />
                        ))}
                        {siteListData && siteListData.length === 0 && (
                          <>
                            <tr>
                              <td colSpan="10" style={{ textAlign: 'center' }}>
                                <img
                                  src={nodataImage}
                                  style={{ height: '600px' }}
                                  alt=""
                                />
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="TableFooter" />
            </div>
          </section>
          {/* graph section */}
          <section className="GraphSection SectionGap">
            <div className="row HasColGap">
              {selectedProduct && selectedProduct.value !== 'all' && (
                <div className="col-md-6">
                  <div className="GraphCard">
                    <div className="SectionTitle">Plugin Version</div>
                    <div className="GraphBody">
                      {props.dashboardSearchLoader ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            alignItems: 'center',
                            height: '350px',
                          }}
                        >
                          <Spinner animation="border" variant="dark" />
                        </div>
                      ) : (
                        <>
                          {pluginVersionCount &&
                          pluginVersionCount.length > 0 ? (
                            <Chart
                              options={pluginVersionChartOptions}
                              series={pluginVersionChartSeries}
                              type="donut"
                              height={350}
                            />
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                alignItems: 'center',
                                height: '350px',
                              }}
                            >
                              No Data Found
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-6">
                <div className="GraphCard">
                  <div className="SectionTitle">PHP Version</div>
                  <div className="GraphBody">
                    {props.dashboardSearchLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          height: '350px',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <>
                        {phpVersionCount && phpVersionCount.length > 0 ? (
                          <Chart
                            options={phpVersionChartOptions}
                            series={phpVersionCount}
                            type="pie"
                            height={350}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              alignItems: 'center',
                              height: '350px',
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="GraphCard">
                  <div className="SectionTitle">WordPress Version</div>
                  <div className="GraphBody">
                    {props.dashboardSearchLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          height: '350px',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <>
                        {wpVersionCount && wpVersionCount.length > 0 ? (
                          <Chart
                            options={wordpressVersionChartOptios}
                            series={wpVersionCount}
                            type="donut"
                            height={350}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              height: '350px',
                              alignItems: 'center',
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="GraphCard">
                  <div className="SectionTitle">Languages</div>
                  <div className="GraphBody">
                    {props.dashboardSearchLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          height: '350px',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <>
                        {laguagesCount && laguagesCount.length > 0 ? (
                          <Chart
                            options={languageChartOptions}
                            series={laguagesCount}
                            type="pie"
                            height={350}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              alignItems: 'center',
                              height: '350px',
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {selectedProduct && selectedProduct.value !== 'all' && (
                <>
                  <div className="col-md-6">
                    <div className="GraphCard">
                      <div className="SectionTitle">Status</div>
                      <div className="GraphBody">
                        {props.dashboardSearchLoader ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              alignItems: 'center',
                              height: '350px',
                            }}
                          >
                            <Spinner animation="border" variant="dark" />
                          </div>
                        ) : (
                          <>
                            {statusCount && statusCount.length > 0 ? (
                              <Chart
                                options={statusChartOptions}
                                series={statusCount}
                                type="donut"
                                height={350}
                              />
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  margin: 'auto',
                                  alignItems: 'center',
                                  height: '350px',
                                }}
                              >
                                No Data Found
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="GraphCard">
                      <div className="SectionTitle">Deactivated Reason</div>
                      <div className="GraphBody">
                        {props.dashboardSearchLoader ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              alignItems: 'center',
                              height: '350px',
                            }}
                          >
                            <Spinner animation="border" variant="dark" />
                          </div>
                        ) : (
                          <>
                            {deactivatedReasonCount &&
                            deactivatedReasonName.length === 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  margin: 'auto',
                                  height: '350px',
                                  alignItems: 'center',
                                }}
                              >
                                No Data Found
                              </div>
                            ) : (
                              <Chart
                                options={deactivatedResonsChartOptions}
                                series={deactivatedReasonCount}
                                type="pie"
                                height={350}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6" />
              <div className="col-md-9">
                <div className="GraphCard">
                  <div className="SectionTitle">MailChimp Analytics</div>
                  <div className="GraphBody">
                    {props.dashboardSearchLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          height: '400px',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <>
                        {mailChimpPluginCount &&
                        mailChimpPluginCount.length > 0 ? (
                          <Chart
                            options={barChartDataOptions}
                            series={barChartDataseries}
                            type="bar"
                            height={innerHeight <= 500 ? 400 : innerHeight}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              alignItems: 'center',
                              height: '400px',
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </>
    </>
  );
};

export default Dashboard;
