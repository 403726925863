import { connect } from 'react-redux';
import Registration from '../components/Auth/Registration';
import {
  register,
  getAllPackages,
  resetValidationError,
} from '../actions/RegistrationActions';

const mapStateToProps = (state) => ({
  register: state.auth.signin,
  allPackages: state.register.allPackages,
  validationError: state.register.validationError,
  location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
  userRegister: (value) => dispatch(register(value)),
  allPackages: () => dispatch(getAllPackages()),
  resetValidationError: () => dispatch(resetValidationError()),
});

const userRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);

export default userRegistration;
