import { all, put, takeLatest } from 'redux-saga/effects';
import {
  RESEND_VERIFICATION,
  RESEND_VERIFICATION_SUCCESS,
  RESEND_VERIFICATION_FAILURE,
  RESEND_VERIFICATION_VALIDATION_FAILED,
} from '../actions/resendVerificationAction';

import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* ResendVerificationAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/user-send-resend-verify-mail`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);
  if (response) {
    const data = response && response.data;
    yield put({
      type: RESEND_VERIFICATION_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const data = error && error.data;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    }
    if (data.status === 'validation_error') {
      yield put({
        type: RESEND_VERIFICATION_VALIDATION_FAILED,
        message: data.message,
        errors: data.data,
      });
    } else {
      yield put({
        type: RESEND_VERIFICATION_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* resendVerification() {
  yield takeLatest(RESEND_VERIFICATION, ResendVerificationAttempt);
}

export default function* rootSaga() {
  yield all([resendVerification()]);
}
