import { toast } from 'react-toastify';
import {
  SUPER_ADMIN_LOAD_PRODUCT_LIST,
  SUPER_ADMIN_RENDER_PRODUCTS_LIST,
  SUPER_ADMIN_LOAD_PRODUCT_FAILED,
} from '../actions/SuperAdminProductsActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  productlist: [],
  currentPage: 1,
  totalPage: 1,
  total: 0,
  allProductList: [],
  allCompanyList: [],
  productListLodaer: false,
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case SUPER_ADMIN_RENDER_PRODUCTS_LIST:
      return {
        ...state,
        productlist: action.productData,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        allProductList: action.allProducts,
        allCompanyList: action.allCompanyList,
        productListLodaer: false,
      };
    case SUPER_ADMIN_LOAD_PRODUCT_FAILED:
      toast.error(action.message);
      return {
        ...state,
        productListLodaer: false,
      };
    case SUPER_ADMIN_LOAD_PRODUCT_LIST:
      return {
        ...state,
        productListLodaer: true,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
