import { connect } from 'react-redux';

import SuperAdminSettings from '../../components/SuperAdmin/Settings/Settings';

import {
  passwordUpdate,
  profileUpdate,
  resetRequestSuccess,
  updatePlaceHolderFile,
  getPlaceHolderFile,
  storeSuperAdminUserConfigs,
  getSuperAdminUserConfig,
  deleteSuperAdminUserProfileImage,
} from '../../actions/SuperAdminSettingsAction';

const mapStateToProps = (state) => ({
  validationError: state.superAdminSettings.validationError,
  profileUpdateValidationError:
    state.superAdminSettings.profileUpdateValidationError,
  requestSuccess: state.superAdminSettings.requestSuccess,
  userConfig: state.superAdminSettings.configs,
  setingsLoader: state.superAdminSettings.setingsLoader,
  modalLoader: state.superAdminSettings.modalLoader,
  user: state.auth.signin && state.auth.signin.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updatePass: (value) => dispatch(passwordUpdate(value)),
  updateProfile: (value) => dispatch(profileUpdate(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  updatePlaceHolderFile: (value) => dispatch(updatePlaceHolderFile(value)),
  getPlaceHolderFile: () => dispatch(getPlaceHolderFile()),
  storeSuperAdminUserConfigs: (value) =>
    dispatch(storeSuperAdminUserConfigs(value)),
  getSuperAdminUserConfig: () => dispatch(getSuperAdminUserConfig()),
  deleteProfileImage: () => dispatch(deleteSuperAdminUserProfileImage()),
});

const SuperAdminSettingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminSettings);

export default SuperAdminSettingContainer;
