import { connect } from 'react-redux';
import SitesDetails from '../../components/SuperAdmin/Report/SitesDetails';

import { getAllDetails } from '../../actions/SuperAdminSitesDetailsActions';

const mapStateToProps = (state) => ({
  allDetails: state.superAdminSiteDetails.siteDetails,
  logUpdate: state.superAdminSiteDetails.logUpdate,
  detailsLoader: state.superAdminSiteDetails.detailsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  siteDetails: (value) => dispatch(getAllDetails(value)),
});

const SuperAdminSitesDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SitesDetails);

export default SuperAdminSitesDetailsContainer;
