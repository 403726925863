import React from 'react';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];

const WebhookList = ({
  data,
  handleEditModalForMailWebhook,
  handelDeleteMailWebhook,
}) => (
  <>
    <tr>
      <td>
        <p>{data.plugin_name}</p>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p
            className={`SmallButton ml-3 ${
              data.status === 'Active' ? 'SuccessButton' : 'DangerButton'
            }`}
          >
            {options
              .filter((f) => f.value === data.status)
              .map((option) => option.label.toUpperCase())}
          </p>
        </div>
      </td>
      <td className="ActionButtonColumn">
        <p className="ActionButtonGroup">
          <button
            onClick={(e) => handleEditModalForMailWebhook(data.id)}
            className="ActionButton PrimaryAlert"
            title="Edit"
          >
            <span className="Icon">
              <i className="insight wpi-edit" />
            </span>
          </button>
          <button
            className="ActionButton DangerAlert"
            title="Delete"
            onClick={(e) => handelDeleteMailWebhook(data.id)}
          >
            <span className="Icon">
              <i className="insight wpi-delete" />
            </span>
          </button>
        </p>
      </td>
    </tr>
  </>
);

export default WebhookList;
