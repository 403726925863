export const CREATE_TEAM_MEMBER = 'CREATE_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';
export const LOAD_TEAM_MEMBER_LIST = 'LOAD_TEAM_MEMBER_LIST';
export const RENDER_TEAM_MEMBER_LIST = 'RENDER_TEAM_MEMBER_LIST';
export const CREATE_TEAM_MEMBER_SUCCESS = 'CREATE_TEAM_MEMBER_SUCCESS';
export const UPDATE_TEAM_MEMBER_SUCCESS = 'CREATE_TEAM_MEMBER_SUCCESS';
export const DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBER_SUCCESS';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const RESET_PAGE = 'RESET_PAGE';

export const RESET_FIRST_TIME = 'RESET_FIRST_TIME';

export const LOAD_TEAM_MEMBER_FAILED = 'UPDATE_TEAM_MEMBER_FAILED';
export const CREATE_TEAM_MEMBER_FAILED = 'CREATE_TEAM_MEMBER_FAILED';
export const UPDATE_TEAM_MEMBER_FAILED = 'UPDATE_TEAM_MEMBER_FAILED';
export const DELETE_TEAM_MEMBER_FAILED = 'DELETE_TEAM_MEMBER_FAILED';

export const VALIDATION_FAILED = 'VALIDATION_FAILED';

export function getAllteamMember(data) {
  return {
    type: LOAD_TEAM_MEMBER_LIST,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function createTeamMember(data) {
  return {
    type: CREATE_TEAM_MEMBER,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function teamMemberUpdate(data) {
  return {
    type: UPDATE_TEAM_MEMBER,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function teamMemberDelete(data) {
  return {
    type: DELETE_TEAM_MEMBER,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_REQUEST_SUCCESS,
  };
}

export function resetPage() {
  return {
    type: RESET_PAGE,
  };
}

export function resetFirstTime() {
  return {
    type: RESET_FIRST_TIME,
  };
}
