import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import WpiLogo from '../../assets/SVG/logo-white.svg';
import wpiLogin from '../../assets/img/login.svg';

/* eslint-disable */

const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
);
/* eslint-enable */

const ResetPassword = (props) => {
  let password;
  let confirmPassword;

  const [err, setErr] = useState({
    password: [],
    confirmPassword: [],
  });

  const [key, setKey] = useState('');

  /* eslint-disable */

  useEffect(() => {
    if (props.resetPass && props.resetPass.redirect) {
      confirmPassword.value = '';
      password.value = '';
      props.history.push('/');
    }
  }, [props.resetPass]);

  useEffect(() => {
    const token = props.match.params;
    setKey(token.key);
  }, []);

  useEffect(() => {
    if (props.validationError.length > 0) {
      let passwordErr = [];
      let confirmPasswordErr = [];

      props.validationError.map((err) => {
        if (err.param === 'password') {
          passwordErr.push(err.msg);
        }
        if (err.param === 'password_confirmation') {
          confirmPasswordErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        password: passwordErr,
        confirmPassword: confirmPasswordErr,
      });
    }
  }, [props.validationError]);
  /* eslint-enable */

  const validateForm = () => {
    const errors = [];
    const passwordErr = [];
    const confirmPasswordErr = [];

    if (password.value === '') {
      passwordErr.push("Password can't be empty");
      errors.push("Persion number can't be empty");
    }

    if (password.value.length > 0 && !strongPassRegex.test(password.value)) {
      errors.push(
        'Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
          'at least 1 numeric character, at least one special character, must be eight characters or longer'
      );
      // passwordErr.push('Password must contains all of these:');
      // passwordErr.push('At least 1 lowercase alphabetical character');
      // passwordErr.push('At least 1 uppercase alphabetical character');
      // passwordErr.push('At least 1 numeric character');
      // // passwordErr.push('At least one special character');
      // passwordErr.push('Must be eight characters or longer');

      passwordErr.push(
        'Password must contain 1 lowercase, 1 uppercase,1 number and it must be 8 characters or longer'
      );
    }

    if (confirmPassword.value === '') {
      confirmPasswordErr.push("Confirm password can't be empty");
      errors.push("Product home url can't be empty");
    }

    if (password.value !== confirmPassword.value) {
      confirmPasswordErr.push(
        'Confirm password does not match with the password'
      );
      errors.push('Confirm password does not match with the password');
    }

    if (errors.length > 0) {
      setErr({
        password: passwordErr,
        confirmPassword: confirmPasswordErr,
      });
    }

    return errors;
  };

  const submitResetPass = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
    } else {
      props.userResetPass({
        password: password.value,
        password_confirmation: confirmPassword.value,
        key,
      });
    }
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitResetPass}>
              <Link className="BackButton mb-4" to="/auth/sign-in">
                <div className="BackButtonIcon">
                  <i className="insight wpi-sort-left" />
                </div>
                <div className="BackButtonText">Back to Login</div>
              </Link>
              <h2>Reset Password</h2>
              <div className="FormGroup mb-4">
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                  autoFocus
                  ref={(node) => {
                    password = node;
                  }}
                />

                {err.password.map((err, index) => (
                  <p key={index} style={{ color: 'red' }}>
                    {err}
                  </p>
                ))}
              </div>
              <div className="FormGroup mb-4">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                  ref={(node) => {
                    confirmPassword = node;
                  }}
                />
                {err && err.confirmPassword && (
                  <p style={{ color: 'red' }}>{err.confirmPassword}</p>
                )}
              </div>
              <button type="submit" className="Button PrimaryButton w-100 mb-4">
                Submit
              </button>
              <p className="mt-5 ColorSecondary">
                Don't Have an Account?{' '}
                <Link className="ColorPrimary" to="/auth/sign-in">
                  Sign Up
                </Link>
              </p>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
