import { all, put, takeLatest } from 'redux-saga/effects';
import {
  USER_VERIFY,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAILURE,
  USER_VERIFY_USERNAME_NOT_FOUND,
} from '../actions/VerifyUserActions';
import { TOKEN_ERROR } from '../actions/index';
import { defaultApi } from '../utils/axiosApi';

export function* verifyAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/check-user-verify`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);

  if (response) {
    const { data } = response;
    if (data.status === 'username-not-found') {
      yield put({
        type: USER_VERIFY_USERNAME_NOT_FOUND,
        message: data.message,
      });
    } else {
      yield put({
        type: USER_VERIFY_SUCCESS,
        credentials: data && data,
      });
    }
  } else if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: USER_VERIFY_FAILURE,
        message: data.message,
      });
    }
  }
}

export function* checkVerify() {
  yield takeLatest(USER_VERIFY, verifyAttempt);
}

export default function* rootSaga() {
  yield all([checkVerify()]);
}
