import { toast } from 'react-toastify';
import {
  SUPER_ADMIN_PROFILE_UPDATE_SUCCESS,
  SUPER_ADMIN_PROFILE_UPDATE_FAILED,
  SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED,
  SUPER_ADMIN_PASSWORD_UPDATE_SUCCESS,
  SUPER_ADMIN_PASSWORD_UPDATE_FAILURE,
  SUPER_ADMIN_PASSWORD_UPDATE_VALIDATION_FAILED,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_VALIDATION_FAILED,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_SUCCESS,
  SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_FAILED,
  SUPER_ADMIN_PLACEHOLDER_FILE_SUCCESS,
  SUPER_ADMIN_PLACEHOLDER_FILE_FAILED,
  SUPER_ADMIN_RESET_SETTINGS_REQUEST_SUCCESS,
  GET_SUPER_ADMIN_USER_CONFIG,
  GET_SUPER_ADMIN_USER_CONFIG_SUCCESS,
  GET_SUPER_ADMIN_USER_CONFIG_FAILED,
  STORE_SUPER_ADMIN_USER_CONFIG,
  STORE_SUPER_ADMIN_USER_CONFIG_SUCCESS,
  STORE_SUPER_ADMIN_USER_CONFIG_FAILED,
  SUPER_ADMIN_PROFILE_UPDATE,
  SUPER_ADMIN_PASSWORD_UPDATE,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS,
  SUPER_ADMIN_PROFILE_IMAGE_DELETE_FAILED,
} from '../actions/SuperAdminSettingsAction';

import { SIGNED_OUT } from '../actions/index';

const initialState = {
  validationError: [],
  profileUpdateValidationError: [],
  requestSuccess: false,
  setingsLoader: false,
  modalLoader: false,
  configs: [],
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case SUPER_ADMIN_PROFILE_UPDATE:
      return {
        ...state,
        modalLoader: true,
      };
    case SUPER_ADMIN_PROFILE_IMAGE_DELETE:
      return {
        ...state,
        setingsLoader: true,
      };
    case SUPER_ADMIN_PASSWORD_UPDATE:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_SUPER_ADMIN_USER_CONFIG:
      return {
        ...state,
        setingsLoader: true,
      };
    case GET_SUPER_ADMIN_USER_CONFIG_SUCCESS:
      return {
        ...state,
        configs: action.data.configs,
        setingsLoader: false,
      };
    case GET_SUPER_ADMIN_USER_CONFIG_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case STORE_SUPER_ADMIN_USER_CONFIG:
      return {
        ...state,
        setingsLoader: true,
      };
    case STORE_SUPER_ADMIN_USER_CONFIG_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        configs: action.data.configs,
        setingsLoader: false,
      };
    case STORE_SUPER_ADMIN_USER_CONFIG_FAILED:
      toast.error(action.message);
      return {
        ...state,
        setingsLoader: false,
      };
    case SUPER_ADMIN_PROFILE_UPDATE_SUCCESS:
      localStorage.removeItem('user_info');
      localStorage.setItem('user_info', JSON.stringify(action.data.user));
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        modalLoader: false,
      };
    case SUPER_ADMIN_PROFILE_IMAGE_DELETE_SUCCESS:
      localStorage.removeItem('user_info');
      localStorage.setItem('user_info', JSON.stringify(action.data.user));
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        setingsLoader: false,
      };
    case SUPER_ADMIN_PASSWORD_UPDATE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        setingsLoader: false,
      };
    case SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
      };
    case SUPER_ADMIN_PLACEHOLDER_FILE_SUCCESS:
      toast.success('file is being downloaded');
      const file = new Blob([action.data.file], { type: 'text/txt' });
      var csvURL = window.URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'placeholder.txt');
      tempLink.click();
      return {
        ...state,
        requestSuccess: true,
      };
    case SUPER_ADMIN_PROFILE_UPDATE_FAILED:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: false,
        modalLoader: false,
      };
    case SUPER_ADMIN_PROFILE_IMAGE_DELETE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        setingsLoader: false,
      };
    case SUPER_ADMIN_RESET_SETTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
      };
    case SUPER_ADMIN_PLACEHOLDER_FILE_FAILED:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: false,
      };
    case SUPER_ADMIN_PASSWORD_UPDATE_FAILURE:
      toast.error(action.message);
      return { ...state, setingsLoader: false };
    case SUPER_ADMIN_PROFILE_UPDATE_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        profileUpdateValidationError: action.errors,
        requestSuccess: false,
      };
    case SUPER_ADMIN_PASSWORD_UPDATE_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        validationError: action.errors,
        setingsLoader: false,
      };
    case SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        validationError: action.errors,
        requestSuccess: false,
      };
    case SUPER_ADMIN_PLACEHOLDER_FILE_UPDATE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
      };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
