import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import Spinner from 'react-bootstrap/Spinner';
import ProductBasicInfo from '../../Product/ProductBasicInfo';
import ProductMarketingInfo from '../../Product/ProductMarketingInfo';
import ProductTrackingInfo from '../../Product/ProductTrackingInfo';

const ProductDetails = (props) => {
  const [secondarySidebarCollaspe, setSecondarySidebarCollaspe] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [details, setDetails] = useState({});

  /* eslint-disable */
  useEffect(() => {
    const desiredReport = queryString.parse(props.history.location.search);
    props.getProductDetails({
      base_name: desiredReport.product,
    });
  }, []);

  useEffect(() => {
    if (props.productDetails && props.productDetails.length > 0) {
      var dataArray = props.productDetails[0];
      setDetails(dataArray);
    } else {
      setDetails({});
    }
  }, [props.productDetails]);

  useEffect(() => {
    if (props.sdkLink) {
      const link = document.createElement('a');
      link.setAttribute('href', props.sdkLink);
      link.setAttribute('download', details.productName);
      link.click();

      props.resetSdkLink();
    }
  }, [props.sdkLink]);

  /* eslint-enable */

  const togolSecondarySideBar = (toggoleSecondarySidebar) => {
    toggoleSecondarySidebar && setSecondarySidebarCollaspe(false);
    !toggoleSecondarySidebar && setSecondarySidebarCollaspe(true);
  };

  const handelDownloadSdk = (name, basename, id) => {
    props.downloadSDK({
      name,
      base_name_slug: basename,
      id,
      account_type: props.accountType,
      company_id: props.activeProfile ? props.activeProfile.id : undefined,
    });
  };

  return (
    <>
      <div className="SectionTitleWrap SectionGap">
        <button
          onClick={(e) => togolSecondarySideBar(secondarySidebarCollaspe)}
          className={`HamburgerToggler ${
            secondarySidebarCollaspe ? 'Active' : ''
          }`}
        >
          <span />
          <span />
          <span />
        </button>
        <a href="#productDetails" className="SectionTitle">
          {details.home_url}
        </a>
      </div>
      <div className="d-flex h-100 position-relative">
        <div
          className={`SecondarySidebar ${
            secondarySidebarCollaspe ? 'Show' : ''
          }`}
        >
          <ul className="SidebarNav">
            <li className="SidebarNavItem">
              <a
                href="#productDetails"
                onClick={(e) => setSelectedOption(1)}
                className={`SidebarNavItemLink ${
                  selectedOption === 1 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Basic Info</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#productDetails"
                onClick={(e) => setSelectedOption(2)}
                className={`SidebarNavItemLink ${
                  selectedOption === 2 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Marketing Info</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#productDetails"
                onClick={(e) => setSelectedOption(3)}
                className={`SidebarNavItemLink ${
                  selectedOption === 3 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Tracking Info</div>
              </a>
            </li>
          </ul>
        </div>
        <div className="SecondarySidebarContent">
          {props.detailsLoader ? (
            <section className="FormViewSection">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '400px',
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            </section>
          ) : (
            <>
              {selectedOption === 1 && <ProductBasicInfo data={details} />}
              {selectedOption === 2 && <ProductMarketingInfo data={details} />}
              {selectedOption === 3 && (
                <ProductTrackingInfo
                  data={details}
                  handelDownloadSdk={handelDownloadSdk}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
