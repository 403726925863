import React from 'react';

const ProductMarketingInfo = ({ data }) => (
  <section className="FormViewSection">
    <div className="FormViewGroup">
      <p className="FormViewTitle">Tracking Option :</p>
      <p className="FormViewData">
        {data.options_to_track && data.options_to_track === 'true'
          ? 'Yes'
          : 'No'}
      </p>
    </div>
    <div className="FormViewGroup">
      <p className="FormViewTitle">Ask Tracking Permission :</p>
      <p className="FormViewData">
        {data.require_opt_in && data.require_opt_in === 'true' ? 'Yes' : 'No'}
      </p>
    </div>
    <div className="FormViewGroup">
      <p className="FormViewTitle">Enable Deactivation Form :</p>
      <p className="FormViewData">
        {data.deactivate_form && data.deactivate_form === 'true' ? 'Yes' : 'No'}
      </p>
    </div>
    <div className="FormViewGroup">
      <p className="FormViewTitle">Choose Notification Format :</p>
      <p className="FormViewData">
        {data.notification_format && `Option ${data.notification_format}`}
      </p>
    </div>
  </section>
);

export default ProductMarketingInfo;
