export const SUPER_ADMIN_LOAD_PRODUCT_DETAILS =
  'SUPER_ADMIN_LOAD_PRODUCT_DETAILS';
export const SUPER_ADMIN_RENDER_PRODUCT_DETAILS =
  'SUPER_ADMIN_RENDER_PRODUCT_DETAILS';
export const SUPER_ADMIN_LOAD_PRODUCT_DETAILS_FAILED =
  'SUPER_ADMIN_LOAD_PRODUCT_DETAILS_FAILED';

export const SUPER_ADMIN_DOWNLOAD_SDK = 'SUPER_ADMIN_DOWNLOAD_SDK';
export const SUPER_ADMIN_RESET_DOWNLOAD_SDK = 'SUPER_ADMIN_RESET_DOWNLOAD_SDK';
export const SUPER_ADMIN_DOWNLOAD_SDK_SUCCESS =
  'SUPER_ADMIN_DOWNLOAD_SDK_SUCCESS';
export const SUPER_ADMIN_DOWNLOAD_SDK_FAILED =
  'SUPER_ADMIN_DOWNLOAD_SDK_FAILED';

export function fetchProductDetails(data) {
  return {
    type: SUPER_ADMIN_LOAD_PRODUCT_DETAILS,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function downloadSDK(data) {
  return {
    type: SUPER_ADMIN_DOWNLOAD_SDK,
    credentials: data,
  };
}

export function resetSdkLink() {
  return {
    type: SUPER_ADMIN_RESET_DOWNLOAD_SDK,
  };
}
