import React from 'react';
import profileImg from '../../assets/img/dummy-profile.png';

const statusOptions = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
  { value: 3, label: 'Invited' },
];

const MemberList = ({
  handleEditModal,
  memberData,
  roles,
  handelDeleteTeamMember,
  activeProfile,
  accountType,
  userPermission,
}) => {
  const user = JSON.parse(localStorage.getItem('user_info'));
  return (
    <>
      <tr>
        <td className="TeamMemberImage">
          <img src={memberData.image ? memberData.image : profileImg} alt="" />
        </td>
        <td>
          <p>{memberData.name}</p>
        </td>
        <td>
          <p>{memberData.email}</p>
        </td>
        <td>
          <div className="d-flex align-items-center">
            {accountType === 'Partner' ? (
              memberData.email !== user.email &&
              memberData.user_id !== user.id ? (
                <>
                  <p className="ColorPrimary">
                    {roles
                      .filter((f) => f.id === memberData.role)
                      .map((option) => option.name.toUpperCase())}
                  </p>
                  <p
                    className={`LabelButton ml-3 ${
                      memberData.emp_status === 1
                        ? 'PrimaryAlert'
                        : 'DangerAlert'
                    }`}
                  >
                    {statusOptions
                      .filter((f) => f.value === memberData.emp_status)
                      .map((option) => option.label.toUpperCase())}
                  </p>
                </>
              ) : (
                <p className="SmallButton SuccessButton">COMPANY OWNER</p>
              )
            ) : memberData.companyOwner ? (
              <>
                <p className="ml-2  SmallButton SuccessButton">COMPANY OWNER</p>
              </>
            ) : (
              <>
                <p className="ColorPrimary">
                  {roles
                    .filter((f) => f.id === memberData.role)
                    .map((option) => option.name.toUpperCase())}
                </p>
                <p
                  className={`LabelButton ml-3 ${
                    memberData.emp_status === 1 ? 'PrimaryAlert' : 'DangerAlert'
                  }`}
                >
                  {statusOptions
                    .filter((f) => f.value === memberData.emp_status)
                    .map((option) => option.label.toUpperCase())}
                </p>
                {memberData.email === user.email &&
                  memberData.user_id === user.id && (
                    <p className="ml-2  SmallButton SuccessButton">
                      ACCOUNT OWNER
                    </p>
                  )}
              </>
            )}
          </div>
        </td>
        <td>
          <div className="d-flex">
            {accountType === 'Partner' ? (
              memberData.email !== user.email &&
              memberData.user_id !== user.id ? (
                <>
                  {memberData.is_verified === 1 ? (
                    memberData.status && (
                      <p
                        className={`SmallButton ${
                          memberData.status === 1
                            ? 'SuccessButton'
                            : 'DangerButton'
                        }`}
                      >
                        {statusOptions
                          .filter((f) => f.value === memberData.status)
                          .map((option) => option.label.toUpperCase())}
                      </p>
                    )
                  ) : (
                    <p className="SmallButton DangerAlert">UNVERIFIED</p>
                  )}
                </>
              ) : (
                <p className="ColorPrimary"> - </p>
              )
            ) : (
              <>
                {memberData.is_verified === 1 ? (
                  memberData.status && (
                    <p
                      className={`SmallButton ${
                        memberData.status === 1
                          ? 'SuccessButton'
                          : 'DangerButton'
                      }`}
                    >
                      {statusOptions
                        .filter((f) => f.value === memberData.status)
                        .map((option) => option.label.toUpperCase())}
                    </p>
                  )
                ) : (
                  <p className="SmallButton DangerAlert">UNVERIFIED</p>
                )}
              </>
            )}
          </div>
        </td>
        <td className="ActionButtonColumn">
          {accountType === 'Partner' ? (
            memberData.email !== user.email &&
            memberData.user_id !== user.id ? (
              <>
                {accountType === 'Partner' ? (
                  <p className="ActionButtonGroup">
                    <button
                      onClick={(e) => handleEditModal(memberData.id)}
                      className="ActionButton PrimaryAlert"
                      title="Edit"
                    >
                      <span className="Icon">
                        <i className="insight wpi-edit" />
                      </span>
                    </button>

                    <button
                      className="ActionButton DangerAlert"
                      title="Delete"
                      onClick={(e) => handelDeleteTeamMember(memberData.id)}
                    >
                      <span className="Icon">
                        <i className="insight wpi-delete" />
                      </span>
                    </button>
                  </p>
                ) : (
                  <p className="ActionButtonGroup">
                    {userPermission
                      .filter((p) => p.name === 'edit-team_member' && p.checked)
                      .map((b) => (
                        <button
                          onClick={(e) => handleEditModal(memberData.id)}
                          className="ActionButton PrimaryAlert"
                          title="Edit"
                        >
                          <span className="Icon">
                            <i className="insight wpi-edit" />
                          </span>
                        </button>
                      ))}

                    {userPermission
                      .filter(
                        (p) => p.name === 'delete-team_member' && p.checked
                      )
                      .map((b) => (
                        <button
                          className="ActionButton DangerAlert"
                          title="Delete"
                          onClick={(e) => handelDeleteTeamMember(memberData.id)}
                        >
                          <span className="Icon">
                            <i className="insight wpi-delete" />
                          </span>
                        </button>
                      ))}
                  </p>
                )}
                <p className="ActionButtonGroup">
                  {userPermission
                    .filter((p) => p.name === 'edit-team_member' && p.checked)
                    .map((b) => (
                      <button
                        onClick={(e) => handleEditModal(memberData.id)}
                        className="ActionButton PrimaryAlert"
                        title="Edit"
                      >
                        <span className="Icon">
                          <i className="insight wpi-edit" />
                        </span>
                      </button>
                    ))}

                  {userPermission
                    .filter((p) => p.name === 'delete-team_member' && p.checked)
                    .map((b) => (
                      <button
                        className="ActionButton DangerAlert"
                        title="Delete"
                        onClick={(e) => handelDeleteTeamMember(memberData.id)}
                      >
                        <span className="Icon">
                          <i className="insight wpi-delete" />
                        </span>
                      </button>
                    ))}
                </p>
              </>
            ) : (
              <p className="ColorPrimary"> - </p>
            )
          ) : memberData.id !== activeProfile.employer_id ? (
            memberData.email !== user.email &&
            memberData.user_id !== user.id ? (
              <>
                <p className="ActionButtonGroup">
                  {userPermission
                    .filter((p) => p.name === 'edit-team_member' && p.checked)
                    .map((b) => (
                      <button
                        onClick={(e) => handleEditModal(memberData.id)}
                        className="ActionButton PrimaryAlert"
                        title="Edit"
                      >
                        <span className="Icon">
                          <i className="insight wpi-edit" />
                        </span>
                      </button>
                    ))}

                  {userPermission
                    .filter((p) => p.name === 'delete-team_member' && p.checked)
                    .map((b) => (
                      <button
                        className="ActionButton DangerAlert"
                        title="Delete"
                        onClick={(e) => handelDeleteTeamMember(memberData.id)}
                      >
                        <span className="Icon">
                          <i className="insight wpi-delete" />
                        </span>
                      </button>
                    ))}
                </p>
              </>
            ) : (
              <p className="ColorPrimary"> - </p>
            )
          ) : (
            <p className="ColorPrimary"> - </p>
          )}
        </td>
      </tr>
    </>
  );
};

export default MemberList;
