import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_PRODUCT_DETAILS,
  RENDER_PRODUCT_DETAILS,
  LOAD_PRODUCT_DETAILS_FAILED,
  DOWNLOAD_SDK,
  DOWNLOAD_SDK_SUCCESS,
  DOWNLOAD_SDK_FAILED,
} from '../actions/ProductDetailsActions';

import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* fetchProductDetails(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products/details`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_PRODUCT_DETAILS,
      productDetails: data.data && data.data.productDetails,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_PRODUCT_DETAILS_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchDownloadSdk(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products/download-SDK`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);
  if (response) {
    const { data } = response;
    yield put({
      type: DOWNLOAD_SDK_SUCCESS,
      message: data.message,
      link: data && data.data && data.data.link,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: DOWNLOAD_SDK_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadProductDetails() {
  yield takeLatest(LOAD_PRODUCT_DETAILS, fetchProductDetails);
}

export function* DownloadSDK() {
  yield takeLatest(DOWNLOAD_SDK, fetchDownloadSdk);
}

export default function* rootSaga() {
  yield all([loadProductDetails(), DownloadSDK()]);
}
