export const ONBOARD = 'ONBOARD';
export const ONBOARD_SUCCESS = 'ONBOARD_SUCCESS';
export const ONBOARD_FAILURE = 'ONBOARD_FAILURE';
export const ONBOARD_VALIDATION_FAILED = 'ONBOARD_VALIDATION_FAILED';

export function makeUserOnboard(data) {
  return {
    type: ONBOARD,
    credentials: {
      ...data,
    },
  };
}
