import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ productItemData }) {
  return (
    <>
      <div className="col">
        <Link
          to={`/user/admin_user/Reports?report=sites_reports&product=${productItemData.base_name_slug}`}
        >
          <div className="OverviewCard">
            <h4 className="ProductTitle">{productItemData.base_name}</h4>
            <p>{`Active Users ${productItemData.ActiveSites}`}</p>
            <p>{`InActive Users ${productItemData.DeactivatedSites}`}</p>
            <div className="WpiTooltip">
              <button className="WpiTooltipButton">
                <i className="insight wpi-info" />
              </button>
              <div className="WpiTooltipText">Data updates in every 30 min</div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
