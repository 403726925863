export const LOAD_SUPER_ADMIN_PACKAGE_LIST = 'LOAD_SUPER_ADMIN_PACKAGE_LIST';
export const RENDER_SUPER_ADMIN_PACKAGE_LIST =
  'RENDER_SUPER_ADMIN_PACKAGE_LIST';
export const LOAD_SUPER_ADMIN_PACKAGE_LIST_FAILED =
  'LOAD_SUPER_ADMIN_PACKAGE_LIST_FAILED';

export const ADD_SUPER_ADMIN_PACKAGE = 'ADD_SUPER_ADMIN_PACKAGE';

export const ADD_SUPER_ADMIN_PACKAGE_SUCCESS =
  'ADD_SUPER_ADMIN_PACKAGE_SUCCESS';
export const ADD_SUPER_ADMIN_PACKAGE_FAILED = 'ADD_SUPER_ADMIN_PACKAGE_FAILED';

export const UPDATE_SUPER_ADMIN_PACKAGE = 'UPDATE_SUPER_ADMIN_PACKAGE';
export const UPDATE_SUPER_ADMIN_PACKAGE_SUCCESS =
  'UPDATE_SUPER_ADMIN_PACKAGE_SUCCESS';
export const UPDATE_SUPER_ADMIN_PACKAGE_FAILED =
  'UPDATE_SUPER_ADMIN_PACKAGE_FAILED';

export const RESET_SUPER_ADMIN_PACKAGE_REQUEST_SUCCESS =
  'RESET_SUPER_ADMIN_PACKAGE_REQUEST_SUCCESS';

export function getAllpackages(data) {
  return {
    type: LOAD_SUPER_ADMIN_PACKAGE_LIST,
    credentials: data,
  };
}
export function addPackage(data) {
  return {
    type: ADD_SUPER_ADMIN_PACKAGE,
    credentials: data,
  };
}

export function updatePackage(data) {
  return {
    type: UPDATE_SUPER_ADMIN_PACKAGE,
    credentials: data,
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_SUPER_ADMIN_PACKAGE_REQUEST_SUCCESS,
  };
}
